import '../App.css';
import '../index.css';
import {
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import Signin from '../Signin/Signin';
import Forgotpassword from '../Signin/ForgotPassword';
import ComingSoon from '../Modules/Upcomingpages/ComingSoon';
import PageNotFound from '../Modules/Upcomingpages/404error';
import { localGetItem } from "../Utility/Services/CacheProviderService";
import Dashboard from '../Modules/Dashboard/Dashboard';
import ProfileManagement from '../Modules/Dashboard/ProfileManagement';
import { OPDashboard } from '../Modules/OPManagement/OPDashboard';
import Settings from '../Modules/Settings/Settings';
import PatientProfile from '../Modules/OPManagement/PatientProfile';
import BillingTransaction from '../Modules/OPManagement/BillingTransaction';
import OPConfiguration from '../Modules/OPManagement/OPConfiguration';
import Home from '../Modules/PharmacyManagement/Home/Dashboard';
import InventoryHome from '../Modules/PharmacyManagement/Inventory/MainIndex';
import VendorManagementHome from '../Modules/PharmacyManagement/VendorManagement/Index';
import TransactionsHome from '../Modules/PharmacyManagement/Transactions/Index';
import DrugDatabaseHome from '../Modules/PharmacyManagement/DrugDatabase/Index';
import ReportsHome from '../Modules/PharmacyManagement/Reports/Index';


function RouterComponent() {
  const isAuthenticated = localGetItem("isLoggedIn")
  return (
    <Switch>
      <Route exact path="/" render={props => !isAuthenticated ? <Signin {...props} /> : <Redirect to="/" />} />
      <Route exact path="/ForgotPassword" render={props => <Forgotpassword {...props} />} />
      <Switch>
        {/* <CircularProgress/> */}
        <Route exact path="/Dashboard" render={props => isAuthenticated ? <Dashboard {...props} /> : <Redirect to="/" />} />
        <Route exact path="/OPManagement" render={props => isAuthenticated ? <OPDashboard {...props} /> : <Redirect to="/" />} />
        <Route exact path="/OPManagement/Home" render={props => isAuthenticated ? <OPDashboard {...props} /> : <Redirect to="/" />} />
        <Route exact from="/OPManagement/BillingTransaction" render={props => isAuthenticated ? <BillingTransaction {...props} /> : <Redirect to="/" />} />
        <Route exact from="/Settings" render={props => isAuthenticated ? <Settings {...props} /> : <Redirect to="/" />} />
        <Route exact path="/OPManagement/PatientProfile" render={props => isAuthenticated ? <PatientProfile {...props} /> : <Redirect to="/" />} />
        <Route exact path="/OPManagement/OPConfiguration" render={props => isAuthenticated ? <OPConfiguration {...props} /> : <Redirect to="/" />} />
        <Route exact path="/PharmacyManagement" render={props => isAuthenticated ? <Home {...props} /> : <Redirect to="/" />} />
        <Route exact path="/PharmacyManagement/Home" render={props => isAuthenticated ? <Home {...props} /> : <Redirect to="/" />} />
        <Route exact path="/PharmacyManagement/Inventory" render={props => isAuthenticated ? <InventoryHome {...props} /> : <Redirect to="/" />} />
        <Route exact path="/PharmacyManagement/VendorManagement" render={props => isAuthenticated ? <VendorManagementHome {...props} /> : <Redirect to="/" />} />
        <Route exact path="/PharmacyManagement/Transactions" render={props => isAuthenticated ? <TransactionsHome {...props} /> : <Redirect to="/" />} />
        <Route exact path="/PharmacyManagement/DrugDatabase" render={props => isAuthenticated ? <DrugDatabaseHome {...props} /> : <Redirect to="/" />} />
        <Route exact path="/PharmacyManagement/Reports" render={props => isAuthenticated ? <ReportsHome {...props} /> : <Redirect to="/" />} />
        <Route exact path="/PharmacyManagement/Reports/" render={props => isAuthenticated ? <ReportsHome {...props} /> : <Redirect to="/" />} />
        <Route exact path="*" render={props => <PageNotFound {...props} />} />
      </Switch>
      {/* </CircularProgress> */}
    </Switch>
  )
}
export default RouterComponent;



