import React from "react";
import './PurchaseReturn.css';
import {
    Card,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    Tooltip,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    FormHelperText,
    Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Colors } from '../../../../../Styles/Colors';
import SearchIcon from '@mui/icons-material/Search';
import { Trans, withTranslation } from 'react-i18next';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FilterListIcon from '@mui/icons-material/FilterList';
import PaymentCard from '../../../../../Components/PaymentCard/PaymentCard';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import Dialog from '../../../../../Components/Dialog';
import Failed from '../../../../../Assets/Animation/Failed.gif';
import DatePicker from '../../../../../Components/DatePicker/DatePicker';
import { DateTime } from "luxon";
import { AmountFormat, DosageType } from "../../../../../Components/CommonFunctions/CommonFunctions";
import { localGetItem } from '../../../../../Utility/Services/CacheProviderService';
import { Serviceurls } from '../../../../../Utility/API/Serviceurls';
import RestAPIService from '../../../../../Utility/Services/RestAPIService';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import MedicationIcon from '@mui/icons-material/Medication';

class PurchaseReturn extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            purchaseReturnData: [],
            page: 0,
            rowsPerPage: 5,
            searchText: "",
            searchFieldShow: false,
            dateFilter: 0,
            datePickerPop: false,
            QueryFromDate: "",
            QueryToDate: "",
            defaultTblData: [],
            dialogBox: false,
            pharmacyID: localGetItem("Pharmacy_Id"),
            dateSortOrder: 'asc',
            dateSortIcon: 'none',
            vendornameSortOrder: 'asc',
            vendornameSortIcon: 'none',
            brandnameSortOrder: 'asc',
            brandnameSortIcon: 'none',
            genericnameSortOrder: 'asc',
            genericnameSortIcon: 'none',
            totalpriceSortOrder: 'asc',
            totalpriceSortIcon: 'none'


        }
    }

    componentDidMount() {
        this.getPurchaseReturn()
    }

    /* Table Get API Funtion start */
    getPurchaseReturn = () => {
        try {
            let QueryParams = `?pharmacy_id=${this.state.pharmacyID}&page=&page_size=all&from_date=${this.state.QueryFromDate}&to_date=${this.state.QueryToDate}`
            RestAPIService.getAll(Serviceurls.GET_PURCHASE_RETURN + QueryParams)
                .then(response => {
                    this.setState({
                        purchaseReturnData: response.data.data.result.length > 0 ? response.data.data.result : [],
                        defaultTblData: response.data.data.result,
                        cardPPQtyAmt: response.data.data.total_price_per_quantity,
                        cardTotalAmt: response.data.data.total_price
                    })
                })
                .catch(e => {
                    this.setState({ dialogBox: true, ErrorMsg: e.message });
                    setTimeout(() => {
                        this.setState({
                            dialogBox: false
                        })
                    }, 3000)
                });
        }
        catch (e) {
            this.setState({ dialogBox: true, ErrorMsg: e.message });
            setTimeout(() => {
                this.setState({
                    dialogBox: false
                })
            }, 3000)
        }
    }
    /* Table Get API Funtion end */

    /* Failed Dialogbox function end */
    dialogCloseFunction = () => {
        this.setState({
            dialogBox: false
        })
    }
    /* Failed Dialogbox function start */

    /* Table Get API Funtion start */

    /* pagechenge function start*/
    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    }
    /* pagechenge function end*/

    /* Rowpagechenge function start*/
    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value, page: 0 });
    }
    /* Rowpagechenge function end*/

    /* customdate popup function start*/
    PopClose = () => {
        this.setState({
            datePickerPop: false,
            QueryFromDate: "",
            QueryToDate: "",
            dateFilter: 0,
        }, () => this.getPurchaseReturn())
    }
    /* customdate popup function end*/

    /* customdate select function start*/
    CustomDates = (FromDate, ToDate) => {
        this.setState({
            QueryFromDate: FromDate,
            QueryToDate: ToDate,
            datePickerPop: false
        }, () => this.getPurchaseReturn())
    }
    /* customdate select function end*/

    /* customdate label function start*/
    DateHelperText = () => {
        if (this.state.dateFilter === 0) {
            return ""
        }
        else if (this.state.dateFilter === 1) {
            return DateTime.now().toFormat('dd-MM-yyyy')
        }
        else if (this.state.dateFilter === 2) {
            return DateTime.now().minus({ days: 1 }).toFormat('dd-MM-yyyy')
        }
        else if (this.state.dateFilter === 3) {
            return `${DateTime.now().minus({ days: 7 }).toFormat('dd-MM-yyyy')}  to  ${DateTime.now().toFormat('dd-MM-yyyy')}`
        }
        else if (this.state.dateFilter === 4) {
            return `${DateTime.now().minus({ days: 30 }).toFormat('dd-MM-yyyy')}  to  ${DateTime.now().toFormat('dd-MM-yyyy')}`
        }
        else if (this.state.dateFilter === 5) {
            return `${this.state.QueryFromDate.split("-").reverse().join("-")}  to  ${this.state.QueryToDate.split("-").reverse().join("-")}`
        }
    }
    /* customdate label function end*/

    render() {
        const { t } = this.props
        const filtereddata = this.state.purchaseReturnData.filter((data) => {
            return (
                (data.date === null ? null : data.date.toLowerCase().includes(this.state.searchText.toLowerCase())) ||
                (data.vendor_name === null ? null : data.vendor_name.toLowerCase().includes(this.state.searchText.toLowerCase())) ||
                (data.batch_no === null ? null : data.batch_no.toLowerCase().includes(this.state.searchText.toLowerCase())) ||
                (data.brand_name === null ? null : data.brand_name.toLowerCase().includes(this.state.searchText.toLowerCase())) ||
                (data.generic_name === null ? null : data.generic_name.toLowerCase().includes(this.state.searchText.toLowerCase())) ||
                (data.dosage_type === null ? null : data.dosage_type.toLowerCase().includes(this.state.searchText.toLowerCase())) ||
                (data.dosage_strength === null ? null : data.dosage_strength.toLowerCase().includes(this.state.searchText.toLowerCase())) ||
                (data.quantity === null ? null : Math.round(data.quantity * 100) === parseInt(Math.round(this.state.searchText * 100))) ||
                (data.price_per_quantity === null ? null : Math.round(data.price_per_quantity * 100) === parseInt(Math.round(this.state.searchText * 100))) ||
                (data.taxable_amount === null ? null : Math.round(data.taxable_amount * 100) === parseInt(Math.round(this.state.searchText * 100))) ||
                (data.total_price === null ? null : Math.round(data.total_price * 100) === parseInt(Math.round(this.state.searchText * 100)))
            );
        });
        // Sorting Function   ==== starts
        const sorting = (col, SortOrder) => {
            if (SortOrder === "asc") {
                const Sorted = [...this.state.purchaseReturnData].sort((a, b) => {
                    if (typeof (a[col]) == "string") {
                        return a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
                    } else {
                        return a[col] > b[col] ? 1 : -1
                    }
                })
                this.setState({ purchaseReturnData: Sorted })

                if (col === "date") {
                    this.setState({
                        dateSortOrder: 'desc',
                        dateSortIcon: 'asc',
                        vendornameSortOrder: "asc",
                        vendornameSortIcon: 'none',
                        brandnameSortOrder: "asc",
                        brandnameSortIcon: 'none',
                        genericnameSortOrder: "asc",
                        genericnameSortIcon: 'none',
                        totalpriceSortOrder: "asc",
                        totalpriceSortIcon: 'none',
                    })
                }
                else if (col === "vendor_name") {
                    this.setState({
                        dateSortOrder: 'asc',
                        dateSortIcon: 'none',
                        vendornameSortOrder: "desc",
                        vendornameSortIcon: 'asc',
                        brandnameSortOrder: "asc",
                        brandnameSortIcon: 'none',
                        genericnameSortOrder: "asc",
                        genericnameSortIcon: 'none',
                        totalpriceSortOrder: "asc",
                        totalpriceSortIcon: 'none',
                    })
                }
                else if (col === "brand_name") {
                    this.setState({
                        dateSortOrder: 'asc',
                        dateSortIcon: 'none',
                        vendornameSortOrder: 'asc',
                        vendornameSortIcon: 'none',
                        brandnameSortOrder: "desc",
                        brandnameSortIcon: 'asc',
                        genericnameSortOrder: "asc",
                        genericnameSortIcon: 'none',
                        totalpriceSortOrder: "asc",
                        totalpriceSortIcon: 'none',
                    })
                }
                else if (col === "generic_name") {
                    this.setState({
                        dateSortOrder: 'asc',
                        dateSortIcon: 'none',
                        vendornameSortOrder: 'asc',
                        vendornameSortIcon: 'none',
                        brandnameSortOrder: "asc",
                        brandnameSortIcon: 'none',
                        genericnameSortOrder: "desc",
                        genericnameSortIcon: 'asc',
                        totalpriceSortOrder: "asc",
                        totalpriceSortIcon: 'none',
                    })
                } else if (col === "total_price") {
                    this.setState({
                        dateSortOrder: 'asc',
                        dateSortIcon: 'none',
                        vendornameSortOrder: 'asc',
                        vendornameSortIcon: 'none',
                        brandnameSortOrder: "asc",
                        brandnameSortIcon: 'none',
                        genericnameSortOrder: "asc",
                        genericnameSortIcon: 'none',
                        totalpriceSortOrder: "desc",
                        totalpriceSortIcon: 'asc',
                    })
                }

            }
            else if (SortOrder === "desc") {
                const Sorted = [...this.state.purchaseReturnData].sort((a, b) => {
                    if (typeof (a[col]) == "string") {
                        return a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
                    } else {
                        return a[col] < b[col] ? 1 : -1
                    }
                })
                this.setState({ purchaseReturnData: Sorted })

                if (col === "date") {
                    this.setState({ dateSortOrder: 'default', dateSortIcon: "desc", })
                } else if (col === "vendor_name") {
                    this.setState({ vendornameSortOrder: 'default', vendornameSortIcon: "desc" })
                } else if (col === "brand_name") {
                    this.setState({ brandnameSortOrder: 'default', brandnameSortIcon: "desc" })
                } else if (col === "generic_name") {
                    this.setState({ genericnameSortOrder: 'default', genericnameSortIcon: "desc" })
                } else if (col === "total_price") {
                    this.setState({ totalpriceSortOrder: 'default', totalpriceSortIcon: "desc" })
                }
            } else if (SortOrder === "default") {
                this.setState({
                    dateSortOrder: 'asc',
                    dateSortIcon: 'none',
                    vendornameSortOrder: 'asc',
                    vendornameSortIcon: 'none',
                    brandnameSortOrder: 'asc',
                    brandnameSortIcon: 'none',
                    genericnameSortOrder: 'asc',
                    genericnameSortIcon: 'none',
                    totalpriceSortOrder: 'asc',
                    totalpriceSortIcon: 'none',
                    purchaseReturnData: this.state.defaultTblData

                })
            }
        }
        // Sorting Function   ==== ends


        //  Sorting Icons Change func === starts 
        const SortIcon = (sortType) => {
            if (sortType === "asc") {
                return <Tooltip disableInteractive placement='top' title={t("InAscendingOrder")}><KeyboardArrowUpIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
            }
            else if (sortType === 'desc') {
                return <Tooltip disableInteractive placement='top' title={t("InDecendingOrder")}><KeyboardArrowDownIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
            }
            else return <Tooltip disableInteractive placement='top' title={t("Sorting")}><FilterListIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
        }

        //  Sorting Icons Change func === ends  

        const handleDate = () => {
            var today = DateTime.now().toFormat('yyyy-MM-dd')
            var yesterday = DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd')
            var startweek = DateTime.now().minus({ days: 7 }).toFormat('yyyy-MM-dd')
            var startmonth = DateTime.now().minus({ days: 30 }).toFormat('yyyy-MM-dd')

            if (this.state.dateFilter === 0) {
                this.setState({
                    QueryFromDate: "",
                    QueryToDate: "",
                }, () => {
                    this.getPurchaseReturn()
                })
            }
            else if (this.state.dateFilter === 1) {
                this.setState({
                    QueryFromDate: today,
                    QueryToDate: today,
                }, () => {
                    this.getPurchaseReturn()
                })

            }
            else if (this.state.dateFilter === 2) {
                this.setState({
                    QueryFromDate: yesterday,
                    QueryToDate: yesterday,
                }
                    , () => {
                        this.getPurchaseReturn()
                    })
            }
            else if (this.state.dateFilter === 3) {
                this.setState({
                    QueryFromDate: startweek,
                    QueryToDate: yesterday,
                },
                    () => {
                        this.getPurchaseReturn()
                    })
            }
            else if (this.state.dateFilter === 4) {
                this.setState({
                    QueryFromDate: startmonth,
                    QueryToDate: yesterday,
                },
                    () => {
                        this.getPurchaseReturn()
                    })
            }
            else if (this.state.dateFilter === 5) {
                this.setState({ datePickerPop: true })
            }

        }
        return (
            <div>
                <div className="eMed_page_header">
                    <div className="eMed_purreturn_tblandcard">
                        <div id="eMed_table_name" style={{ color: Colors.TextColor }}>
                            {t(this.props.menuName)}
                        </div>
                        <div id="eMed_tbl_paymentcard">
                            <PaymentCard Color={Colors.orange} Icons={<AutoAwesomeMotionIcon />} Name={t("PurchasePrice/Quantity")} Amount={this.state.cardPPQtyAmt} />
                            <PaymentCard Color={Colors.blue} Icons={<CurrencyRupeeIcon />} Name={t("Total")} Amount={this.state.cardTotalAmt} />
                        </div>
                    </div>
                    <div id="eMed_tbl_filters">
                        <div>
                            <FormControl id="eMed_tbl_datefilter" >
                                <InputLabel sx={{ color: Colors.TextColor }}>{t("SelectDate")}</InputLabel>
                                <Select
                                    sx={{ color: Colors.TextColor }}
                                    size='small'
                                    onChange={(event) => { this.setState({ dateFilter: event.target.value }, () => handleDate()) }}
                                    value={this.state.dateFilter}
                                    labelId="demo-simple-select-required-label"
                                    label={t("SelectDate")}
                                    id="emed_demo-simple-select-required"
                                >
                                    <MenuItem value={0}>All</MenuItem>
                                    <MenuItem value={1}>Today</MenuItem>
                                    <MenuItem value={2}>Yesterday</MenuItem>
                                    <MenuItem value={3}>Last Week</MenuItem>
                                    <MenuItem value={4}>Last Month</MenuItem>
                                    <MenuItem value={5} onClick={() => { this.setState({ datePickerPop: true }) }} >Custom</MenuItem>
                                </Select>
                                <FormHelperText sx={{ color: Colors.HelperText }} id="eMed_form_helpertext">{this.DateHelperText()}</FormHelperText>
                            </FormControl>
                        </div>
                        {this.state.datePickerPop ? <DatePicker PopClose={this.PopClose} CustomDates={this.CustomDates} /> : null}

                        {this.state.searchFieldShow ?
                            <div id='eMed_tbl_searchbox'>
                                <TextField
                                    sx={{ input: { color: Colors.TextColor } }}
                                    size='small'
                                    focused
                                    onChange={(e) => this.setState({ searchText: e.target.value })}
                                    value={this.state.searchText}
                                    label={t("SearchHere")}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip disableInteractive title={t("Clear")} placement='top'><CloseIcon sx={{ color: Colors.ThemeColor, cursor: 'pointer' }} onClick={() => { this.setState({ searchText: '' }) }} /></Tooltip>
                                        ),
                                    }}
                                />
                            </div> : null}
                        <div className="eMed_tbl_searchicon">
                            <Tooltip disableInteractive placement='top' title={this.state.searchFieldShow ? t("CloseSearch") : t("OpenSearch")}>
                                <Button id="eMed_tbl_searchicon" variant='contained' style={{ backgroundColor: Colors.ThemeColor }} onClick={() => this.setState({ searchFieldShow: !this.state.searchFieldShow, searchText: '', cardOpen: false })} >
                                    {this.state.searchFieldShow ? <CloseIcon /> : <SearchIcon />}
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div>
                    <Card sx={{ bgcolor: Colors.TableCard }} className="eMed_tbl_card" >
                        <TableContainer className="eMed_tbl_container" >
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="eMed_tbl_headwidth_small eMed_sticky_start" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div style={{ color: Colors.TableHeaderTextColor }} className="eMed_sortable_center" id='eMed_tbl_headtext'>{t("S.No")}</div>
                                        </TableCell>
                                        <TableCell className='eMed_tbl_headwidth_large' id="eMed_purReturnsickyHeadtwo" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className="eMed_sortable_name">
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("BrandName")}</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("brand_name", this.state.brandnameSortOrder) }}>{SortIcon(this.state.brandnameSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className="eMed_sortable_center">
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("Date")}</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("date", this.state.dateSortOrder) }}>{SortIcon(this.state.dateSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className="eMed_sortable_name">
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("VendorName")}</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("vendor_name", this.state.vendornameSortOrder) }}>{SortIcon(this.state.vendornameSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div style={{ color: Colors.TableHeaderTextColor }} className="eMed_sortable_center" id='eMed_tbl_headtext'>{t("BatchNo")}</div>
                                        </TableCell>
                                        <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className="eMed_sortable_name">
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("GenericName")}</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("generic_name", this.state.genericnameSortOrder) }}>{SortIcon(this.state.genericnameSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div style={{ color: Colors.TableHeaderTextColor }} className="eMed_sortable_center" id='eMed_tbl_headtext'>{t("DosageType")}</div>
                                        </TableCell>
                                        <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div style={{ color: Colors.TableHeaderTextColor }} className="eMed_sortable_center" id='eMed_tbl_headtext'>{t("DosageStrength")}</div>
                                        </TableCell>
                                        <TableCell className='eMed_tbl_headwidth_small' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className="eMed_sortable_number">
                                                <div style={{ color: Colors.TableHeaderTextColor }} className="eMed_sortable_number" id='eMed_tbl_headtext'>{t("Quantity")}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell className='eMed_tbl_headwidth_small' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className="eMed_sortable_number">
                                                <div style={{ color: Colors.TableHeaderTextColor }} className="eMed_sortable_number" id='eMed_tbl_headtext'>{t("PP/Qty")}</div>
                                            </div>
                                        </TableCell>
                                        {/* <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className="eMed_sortable_number" id='eMed_tbl_headtext'>{t("GSTAmount")}(₹)</div>
                                        </TableCell> */}
                                        <TableCell className="eMed_tbl_headwidth_large eMed_sticky_end" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className="eMed_sortable_number">
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("TotalPrice")}(₹)</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("total_price", this.state.totalpriceSortOrder) }}>{SortIcon(this.state.totalpriceSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {filtereddata.length > 0 ? filtereddata
                                    .slice(this.state.page * this.state.rowsPerPage,
                                        this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                    .map((data, index) => {
                                        return (
                                            <TableBody key={index}>
                                                <TableRow>
                                                    <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="center" className="eMed_sticky_startbody">{index + 1}</TableCell>
                                                    <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} align="left" id="eMed_purReturnstickyBodyTwo">
                                                        <Tooltip title={data.brand_name}>
                                                            <div id="eMed_tbl_bodytext">
                                                                {data.brand_name.length > 10 ? data.brand_name.slice(0, 10) + ".." : data.brand_name}
                                                            </div>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="center">{data.date}</TableCell>
                                                    <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} align="left">
                                                        <Tooltip title={data.vendor_name}>
                                                            <div id="eMed_tbl_bodytext">
                                                                {data.vendor_name.length > 10 ? data.vendor_name.slice(0, 10) + ".." : data.vendor_name}
                                                            </div>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="center">{data.batch_no}</TableCell>
                                                    <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} align="left">
                                                        <Tooltip title={data.generic_name}>
                                                            <div id="eMed_tbl_bodytext">
                                                                {data.generic_name.length > 10 ? data.generic_name.slice(0, 10) + ".." : data.generic_name}
                                                            </div>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="center">{DosageType(data.dosage_type)}</TableCell>
                                                    <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="center">{data.dosage_strength}</TableCell>
                                                    <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="right">{data.quantity}</TableCell>
                                                    <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="right">{AmountFormat(data.price_per_quantity.toFixed(2))}</TableCell>
                                                    {/* <TableCell id="eMed_tbl_bodytext" align="right">{AmountFormat(data.taxable_amount)}</TableCell> */}
                                                    <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="right" className="eMed_sticky_endbody">{AmountFormat(data.total_price)}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )
                                    }) : <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_Norecords'>
                                    {t("NoRecords")}
                                </div>}
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component='div'
                            id="eMed_tbl_pagination"
                            sx={{ color: Colors.PagiantionTxtColor }}
                            rowsPerPageOptions={[5, 10, 15]}
                            count={filtereddata.length}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </Card>
                    {this.state.dialogBox ?
                        <Dialog
                            type={"Notification"}
                            DialogClose={this.dialogCloseFunction}
                            header={"Message"}
                            content={this.state.ErrorMsg}
                            icon={<img className="eMed_dilboxAnimations" alt="Warning" src={Failed} />}
                            secondary={"Close"}
                            closeButton={this.dialogCloseFunction}
                        /> : null}
                </div>

            </div>
        )
    }
}
export default withTranslation()(PurchaseReturn);