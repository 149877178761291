import * as React from 'react';
import './EditForm.css';
import { TextField, Stack, Button, InputAdornment, Radio, RadioGroup, FormControlLabel, IconButton } from '@mui/material';
import { Colors } from '../../../../Styles/Colors';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Dialog from '../../../../Components/Dialog';
import { Constants, StockAdjustment_Reason } from '../../../../Utility/Constants';
import Success from '../../../../Assets/Animation/Success.gif';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Loader from '../../../../Components/Loader';
import { Trans, withTranslation } from 'react-i18next';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import Failed from '../../../../Assets/Animation/Failed.gif';

class StockAdjustmentForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            expDate: null,
            brandName: "",
            genericName: "",
            dosageType: "",
            schedule: "",
            batchNumber: 0,
            quantity: 0,
            buyingPrice: "",
            freeQuantity: "",
            mrpUnits: "",
            reasonRadio: 1,
            updateDlgOpen: false,
            successDlgOpen: false,
            failedDlgOpen: false,
            isLoader: false,
            updateBtn: false,
            comments: "",
            editDetails: {},
            pharmacyID: this.props.pharmacyId

        }
    }

    componentDidMount() {
        this.setState({
            editDetails: this.props.Editdetails,

        }, () => {
            this.setState({
                brandName: this.state.editDetails.drug_name,
                genericName: this.state.editDetails.generic_name,
                dosageType: this.state.editDetails.dosage_type,
                schedule: this.state.editDetails.schedule,
                quantity: this.state.editDetails.stock_quantity,
                batchNumber: this.state.editDetails.batch_no,
                expDate: this.state.editDetails.expiry_date,
                buyingPrice: this.state.editDetails.buying_price,
                freeQuantity: this.state.editDetails.free_quantity,
                mrpUnits: this.state.editDetails.mrp_per_quantity
            })
        })
    }
    expDatePicker = (newvalue) => {
        this.setState({
            expDate: newvalue
        }, () => { this.updateButton() })
    }
    decimalNumInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        const re = /^(\d*)((\.(\d{0,2})?)?)$/;
        if (value === "" || re.test(value)) {
            this.setState({
                [name]: value,
            }, () => { this.updateButton() });

        }

    };

    numInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        const re = /^[0-9\b]+$/;
        if (value === "" || re.test(value)) {
            this.setState({
                [name]: value,
            }, () => { this.updateButton() });

        }

    };

    textInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        this.setState({
            [name]: value
        }, () => { this.updateButton() });
    };
    cancel = () => {
        this.setState({
            brandName: this.state.editDetails.drug_name,
            genericName: this.state.editDetails.generic_name,
            dosageType: this.state.editDetails.dosage_type,
            schedule: this.state.editDetails.schedule,
            quantity: this.state.editDetails.stock_quantity,
            batchNumber: this.state.editDetails.batch_no,
            expDate: this.state.editDetails.expiry_date,
            buyingPrice: this.state.editDetails.buying_price,
            freeQuantity: this.state.editDetails.free_quantity,
            mrpUnits: this.state.editDetails.mrp_per_quantity,
            updateBtn: false,
            reasonRadio: 1,
            buyingPriceerror: false,
            buyingPricehelpertext: false,
            mrpUnitserror: false,
            mrpUnitshelpertext: false,
            comments: "",



        })
    }
    decreaseQuantity = () => {
        var num = parseInt(this.state.quantity)
        this.setState({
            quantity: parseInt(this.state.quantity) === 0 ? 0 : num - 1
        }, () => { this.updateButton() })
    }
    increaseQuantity = () => {
        var num = parseInt(this.state.quantity)
        this.setState({
            quantity: num + 1
        }, () => { this.updateButton() })
    }
    Validate = () => {
        const { t } = this.props
        if (this.state.batchNumber === 0) {
            this.setState({
                batchNumbererror: true,
                batchNumberhelpertext: t("PleaseEnterBatchNumber")
            })
        } else {
            this.setState({
                batchNumbererror: false,
                batchNumberhelpertext: ""
            })
        } if (this.state.quantity === "") {
            this.setState({
                quantityhelpertext: t("PleaseEnterQuantity"),
                quantityerror: true
            })
        } else {
            this.setState({
                quantityerror: false,
                quantityhelpertext: ""
            })
        } if (this.state.buyingPrice === "") {
            this.setState({
                buyingPricehelpertext: t("PleaseEnterBuyingPrice(₹)"),
                buyingPriceerror: true,
            })
        } else if (this.state.buyingPrice === "0") {
            this.setState({
                buyingPriceerror: true,
                buyingPricehelpertext: t("BuyingPrice(₹)Morethen0")
            })
        } else {
            this.setState({
                buyingPriceerror: false,
                buyingPricehelpertext: ""
            })
        } if (this.state.freeQuantity === "") {
            this.setState({
                freeQuantityhelpertext: t("PleaseEnterFreeQuantity"),
                freeQuantityerror: true
            })
        } else {
            this.setState({
                freeQuantityerror: false,
                freeQuantityhelpertext: ""
            })
        } if (this.state.mrpUnits === "") {
            this.setState({
                mrpUnitserror: true,
                mrpUnitshelpertext: t("PleaseEnterMRP/Units(₹)")
            })
        } else if (this.state.mrpUnits === "0") {
            this.setState({
                mrpUnitserror: true,
                mrpUnitshelpertext: t("MRP/Units(₹)mustmorethen0")
            })
        } else {
            this.setState({
                mrpUnitserror: false,
                mrpUnitshelpertext: ""
            })
        } if (this.state.expDate === null) {
            this.setState({
                expDatehelpertext: t("PleaseEnterDate"),
                expDateerror: true
            })
        } else {
            this.setState({
                expDateerror: false,
                expDatehelpertext: ""
            })
        } if (this.state.comments === "") {
            this.setState({
                commentserror: true,
                commentshelpertext: t("PleaseEntercomments")
            })
        } else {
            this.setState({
                commentserror: false,
                commentshelpertext: "",
            })
        }
    }
    updateButton = () => {
        if (
            Object.keys(this.state.editDetails).length != 0 &&
            this.state.comments.replace(/\s/g, '').length != 0 &&
            this.state.quantity != this.state.editDetails.stock_quantity
        ) {
            this.setState({
                updateBtn: true
            })
        } else {
            this.setState({
                updateBtn: false
            })
        }
    }

    submit = () => {
        if (
            this.state.batchNumber.length > 0 &&
            this.state.quantity >= 0 &&
            this.state.buyingPrice > 0 &&
            this.state.freeQuantity >= 0 &&
            this.state.mrpUnits > 0 &&
            this.state.expDate != null &&
            this.state.comments.length > 0) {
            this.setState({
                updateBtn: true,
                updateDlgOpen: true,
                batchNumbererror: false,
                batchNumberhelpertext: false,
                quantityerror: false,
                quantityhelpertext: false,
                buyingPriceerror: false,
                buyingPricehelpertext: false,
                freeQuantityerror: false,
                freeQuantityhelpertext: false,
                mrpUnitserror: false,
                mrpUnitshelpertext: false,
                expDateerror: false,
                expDatehelpertext: false,
                commentserror: false,
                commentshelpertext: false
            })
        } else {
            this.Validate()
        }

    }
    dialogCloseFunction = () => {
        this.setState({
            updateDlgOpen: false,
            successDlgOpen: false

        })
    }
    closeDialog = () => {
        this.setState({
            successDlgOpen: false,
        }, () => { this.props.editClose(false) })
    }
    /* Open Save DialogBox Function Start */
    yesButtondata = () => {
        this.postStockAdjustmentDetails()
    }
    /* Open Save DialogBox Function End */

    /* Postmethod API function start */
    postStockAdjustmentDetails = () => {
        try {
            this.setState({
                isLoader: true
            })
            let data = {
                'pharmacy_id': this.state.pharmacyID,
                'drug_id': this.state.editDetails.drug_id,
                'available_quantity': parseInt(this.state.quantity),
                'batch_no': this.state.batchNumber,
                'reason': parseInt(this.state.reasonRadio),
                'comments': this.state.comments
            }
            let pharmacyID = `?pharmacy_id=${this.state.pharmacyID}`
            RestAPIService.update(pharmacyID, data, Serviceurls.STOCK_ADJUSTMENT_EDIT_FROM)
                .then(response => {
                    this.setState({
                        isLoader: false,
                    })
                    if (response.data.status === "Success") {
                        this.setState({
                            successDlgOpen: true
                        })
                        setTimeout(() => {
                            this.setState({
                                successDlgOpen: false
                            }, () => this.closeDialog())
                        }, 2000)
                    }

                })
                .catch(e => {
                    this.setState({ failedDlgOpen: true, errorMsg: e.message })
                    setTimeout(() => {
                        this.setState({
                            isLoader: false,
                            failedDlgOpen: false
                        })
                    }, 2000)
                });
        }
        catch (e) {
            this.setState({ failedDlgOpen: true, errorMsg: e.message })
            setTimeout(() => {
                this.setState({
                    isLoader: false,
                    failedDlgOpen: false
                })
            }, 2000)
        }

    }
    /* Postmethod API function end */

    render() {
        const { t } = this.props
        return (
            <div id='eMed_editForm'>
                <Loader loaderOpen={this.state.isLoader} />

                <div className='emed_formContainer'>
                    <div className='eMed_formTextBoxone'>
                        <TextField
                            disabled
                            className='eMed_priceBox'
                            label={t('BrandName')}
                            variant="outlined"
                            size='small'
                            name='brandName'
                            value={this.state.brandName}
                            onChange={this.textInput}
                            error={this.state.brandNameerror}
                            helperText={this.state.brandNamehelpertext} />
                        <TextField
                            disabled
                            className='eMed_priceBox'
                            label={t('GenericName')}
                            variant="outlined"
                            size='small'
                            name='genericName'
                            value={this.state.genericName}
                            onChange={this.textInput}
                            error={this.state.genericNameerror}
                            helperText={this.state.genericNamehelpertext} />
                        <TextField
                            disabled
                            className='eMed_priceBox'
                            label={t('DosageType')}
                            variant="outlined"
                            size='small'
                            name='dosageType'
                            value={this.state.dosageType}
                            onChange={this.textInput}
                            error={this.state.dosageTypeerror}
                            helperText={this.state.dosageTypehelpertext} />
                        <TextField
                            disabled
                            className='eMed_priceBox'
                            label={t('Schedule')}
                            variant="outlined"
                            size='small'
                            name='schedule'
                            value={this.state.schedule}
                            onChange={this.textInput}
                            error={this.state.scheduleerror}
                            helperText={this.state.schedulehelpertext} />
                    </div>
                    <div className='eMed_formTextBoxtwo'>
                        <TextField
                            disabled
                            className='eMed_priceBox'
                            label={t("BatchNumber")}
                            variant="outlined"
                            size='small'
                            name='batchNumber'
                            value={this.state.batchNumber}
                            onChange={this.textInput}
                            error={this.state.batchNumbererror}
                            helperText={this.state.batchNumberhelpertext}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                label={t("ExpireDate")}
                                disabled
                                value={this.state.expDate}
                                onChange={this.expDatePicker}
                                renderInput={(params) => <TextField {...params} size='small' className='eMed_priceBox'
                                    error={this.state.expDateerror} helperText={this.state.expDatehelpertext} />}
                            />
                        </LocalizationProvider>

                        <div className='eMed_quantity'>
                            <div className='eMed_quantityFiled'>
                                <div><IconButton id='eMed_quantityLBtn' disabled={this.state.quantity === 0 ? true : false} onClick={this.decreaseQuantity}><RemoveOutlinedIcon sx={{ color: this.state.quantity !== 0 ? Colors.ThemeColor : '#BFBFBF' }} /></IconButton></div>
                                <TextField
                                    id="eMed_quantityTextbox"
                                    onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4) }}
                                    label={t('Qty')}
                                    size='small'
                                    name='quantity'
                                    value={this.state.quantity}
                                    onChange={this.numInput}
                                />
                                <div><IconButton id='eMed_quantityRBtn' onClick={this.increaseQuantity}><AddOutlinedIcon style={{ color: Colors.ThemeColor }} /></IconButton></div>
                            </div>
                        </div>


                    </div>
                    <div className='eMed_formTextBoxtwo'>
                        <TextField
                            disabled
                            className='eMed_priceBox'
                            label={t("BuyingPrice/Unit(₹)")}
                            variant="outlined"
                            size='small'
                            name='buyingPrice'
                            value={this.state.buyingPrice}
                            onChange={this.decimalNumInput}
                            error={this.state.buyingPriceerror}
                            helperText={this.state.buyingPricehelpertext}
                            InputProps={{
                                readOnly: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <CurrencyRupeeIcon />
                                    </InputAdornment>
                                ),
                            }} />
                        <TextField
                            disabled
                            className='eMed_priceBox'
                            label={t("FreeQuantity")}
                            variant="outlined"
                            size='small'
                            name='freeQuantity'
                            value={this.state.freeQuantity}
                            onChange={this.numInput}
                            error={this.state.freeQuantityerror}
                            helperText={this.state.freeQuantityhelpertext}
                            InputProps={{ readOnly: true, }}
                        />

                        <TextField
                            disabled
                            className='eMed_priceBox'
                            label={t("MRP/Unit(₹)")}
                            variant="outlined"
                            size='small'
                            name='mrpUnits'
                            value={this.state.mrpUnits}
                            onChange={this.decimalNumInput}
                            error={this.state.mrpUnitserror}
                            helperText={this.state.mrpUnitshelpertext}
                            InputProps={{
                                readOnly: true,
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <CurrencyRupeeIcon />
                                    </InputAdornment>
                                ),
                            }} />
                    </div>

                    <div className='eMed_reasonSection'>
                        <div>
                            <h3>{t('EnterReason')}</h3>
                        </div>
                        <div>
                            <RadioGroup
                                id='eMed_reasonRadiobtn'
                                aria-labelledby="demo-radio-buttons-group-label"
                                value={this.state.reasonRadio}
                                name="radio-buttons-group"
                                onChange={(event) => this.setState({ reasonRadio: event.target.value })}
                            >
                                {StockAdjustment_Reason.map((data) =>
                                    <FormControlLabel value={data.id} control={<Radio />} label={data.name} />
                                )}
                            </RadioGroup>
                        </div>
                        <div>
                            <TextField
                                className='eMed_reasonTextBox'
                                label={t("Comments")}
                                multiline
                                rows={2}
                                name='comments'
                                value={this.state.comments}
                                onChange={this.textInput}
                                error={this.state.commentserror}
                                helperText={this.state.commentshelpertext}

                            />
                        </div>
                        <div className='eMed_reasonBtn'>
                            <Stack spacing={2} direction="row">
                                <Button variant="contained" disabled={!this.state.updateBtn} onClick={() => { this.submit(); }}>{t('Update')}</Button>
                                {this.state.updateDlgOpen ?
                                    <Dialog
                                        type={"Confirmation"}
                                        DialogClose={this.dialogCloseFunction}
                                        header={"Confirm"}
                                        content={"Do you want to save the record?"}
                                        primary={"Yes"}
                                        secondary={"No"}
                                        yesButton={this.yesButtondata} />
                                    : null}
                                {this.state.successDlgOpen ? <Dialog
                                    type={"Notification"}
                                    DialogClose={this.dialogCloseFunction}
                                    header={"Message"}
                                    content={Constants.SaveSuccess}
                                    icon={<img className="eMed_dilboxAnimations" alt="Warning" src={Success} />}
                                    secondary={"Close"}
                                    closeButton={this.closeDialog} /> : null}
                                {this.state.failedDlgOpen ? <Dialog
                                    type={"Notification"}
                                    DialogClose={this.dialogCloseFunction}
                                    header={"Message"}
                                    content={this.state.errorMsg}
                                    icon={<img className="eMed_dilboxAnimations" alt="Warning" src={Failed} />}
                                    secondary={"Close"}
                                    closeButton={this.closeDialog} /> : null}
                                <Button variant="outlined" onClick={this.cancel}>{t('Reset')}</Button>
                            </Stack>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default withTranslation()(StockAdjustmentForm);
