import React, { Component } from 'react';
import PieChart from '../Modules/Charts/PieChart';


export default class RevenuePieChart extends Component {
  render() {
    return (
      <div style={{width:"16vw"}}><PieChart/></div>
    )
  }
}
