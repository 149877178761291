import * as React from 'react';
import Box from '@mui/material/Box';
import ComingSoon from "../Upcomingpages/ComingSoon";

class ProfileManagement extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <ComingSoon/>
        )
    }

}

export default ProfileManagement;