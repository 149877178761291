import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import { Colors } from '../../../../../Styles/Colors';
import Tooltip from '@mui/material/Tooltip';
import { TablePagination } from '@mui/material';
import './DayEndReport.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DatePicker from '../../../../../Components/DatePicker/DatePicker';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PaymentCard from '../../../../../Components/PaymentCard/PaymentCard'
import { Button, FormHelperText } from '@mui/material';
import { DateTime } from "luxon";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { AmountFormat } from '../../../../../Components/CommonFunctions/CommonFunctions';
import { localGetItem } from '../../../../../Utility/Services/CacheProviderService'
import { Serviceurls } from '../../../../../Utility/API/Serviceurls';
import RestAPIService from '../../../../../Utility/Services/RestAPIService';
import Dialog from '../../../../../Components/Dialog';
import Failed from '../../../../../Assets/Animation/Failed.gif';

class DayEndOverview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Data: [],
            DataFilter: [],
            SearchFieldOpen: false,
            SearchText: "",
            page: 0,
            rowsPerPage: 5,
            datePickerPop: false,
            datefilter: 0,
            BillDateSortOrder: "asc",
            BillDateSortIcon: 'none',
            CashSortOrder: "asc",
            CashSortIcon: 'none',
            CardSortOrder: "asc",
            CardSortIcon: 'none',
            UpiSortOrder: "asc",
            UpiSortIcon: 'none',
            BankTransSortOrder: "asc",
            BankTransSortIcon: 'none',
            InsuranceSortOrder: "asc",
            InsuranceSortIcon: 'none',
            TotalSortOrder: "asc",
            TotalSortIcon: 'none',
            QueryFrmDte: "",
            QueryToDte: "",
            PharmacyID: localGetItem("Pharmacy_Id")
        }
    }

    componentDidMount() {
        this.getDayEndOverview()
    }

    getDayEndOverview = () => {
        try {
            let QueryParams = `?pharmacy_id=${this.state.PharmacyID}&page=&page_size=all&from_date=${this.state.QueryFrmDte}&to_date=${this.state.QueryToDte}`
            RestAPIService.getAll(Serviceurls.GET_SALES_DAYEND + QueryParams)
                .then(response => {
                    this.setState({
                        Data: response.data.data.result,
                        DataFilter: response.data.data.result,
                        CashTotal: response.data.data.cash_total,
                        CardTotal: response.data.data.card_total,
                        UPITotal: response.data.data.upi_total,
                        BankTransTotal: response.data.data.bank_transfer_total,
                        Insurance: response.data.data.insurance_total,
                        TotalAmount: response.data.data.total_amount
                    })
                })
                .catch(e => {
                    this.setState({ dialogBox: true, ErrorMsg: e.message });
                    setTimeout(() => {
                        this.setState({
                            dialogBox: false
                        })
                    }, 3000)
                });

        }
        catch (e) {
            this.setState({ dialogBox: true, ErrorMsg: e.message });
            setTimeout(() => {
                this.setState({
                    dialogBox: false
                })
            }, 3000)
        }
    }

    //  Mui table Pagination Functions --- starts

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value, page: 0 });
    }

    //  Mui table Pagination Functions --- ends

    PopClose = () => {
        this.setState({
            datePickerPop: false,
            datefilter: 0,
            QueryFrmDte: "",
            QueryToDte: "",
        }, () => this.getDayEndOverview())
    }

    CustomDates = (FromDate, ToDate) => {
        this.setState({
            QueryFrmDte: FromDate,
            QueryToDte: ToDate,
            datePickerPop: false
        }, () => this.getDayEndOverview())
    }
    DateHelperText = () => {
        if (this.state.datefilter === 0) {
            return " "
        }
        else if (this.state.datefilter === 1) {
            return DateTime.now().toFormat('dd-MM-yyyy')
        }
        else if (this.state.datefilter === 2) {
            return DateTime.now().minus({ days: 1 }).toFormat('dd-MM-yyyy')
        }
        else if (this.state.datefilter === 3) {
            return `${DateTime.now().minus({ days: 7 }).toFormat('dd-MM-yyyy')}  To  ${DateTime.now().toFormat('dd-MM-yyyy')}`
        }
        else if (this.state.datefilter === 4) {
            return `${DateTime.now().minus({ days: 30 }).toFormat('dd-MM-yyyy')}  To  ${DateTime.now().toFormat('dd-MM-yyyy')}`
        }
        else if (this.state.datefilter === 5) {
            return `${this.state.QueryFrmDte.split("-").reverse().join("-")}  To  ${this.state.QueryToDte.split("-").reverse().join("-")}`
        }
    }


    dialogCloseFunction = () => {
        this.setState({
            dialogBox: false
        })
    }


    render() {
        const { t } = this.props

        //  filter table data based on search Text
        const filtereddata = this.state.Data.filter((data) => {
            return (
                (data.invoice_date === null ? null : data.invoice_date.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
                (data.invoice_number === null ? null : data.invoice_number.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
                (data.bank_transfer === null ? null : Math.round(data.bank_transfer * 100) === parseInt(Math.round(this.state.SearchText * 100))) ||
                (data.card === null ? null : Math.round(data.card * 100) === parseInt(Math.round(this.state.SearchText * 100))) ||
                (data.cash === null ? null : Math.round(data.cash * 100) === parseInt(Math.round(this.state.SearchText * 100))) ||
                (data.insurance === null ? null : Math.round(data.insurance * 100) === parseInt(Math.round(this.state.SearchText * 100))) ||
                (data.upi === null ? null : Math.round(data.upi * 100) === parseInt(Math.round(this.state.SearchText * 100))) ||
                (data.total === null ? null : Math.round(data.total * 100) === parseInt(Math.round(this.state.SearchText * 100)))
            );
        });

        // Search Icon OnClick Function == ends

        // Sorting Function   ==== starts
        const sorting = (col, SortOrder) => {
            if (SortOrder === "asc") {
                const Sorted = [...this.state.Data].sort((a, b) => {
                    if (typeof (a[col]) == "string") {
                        return a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
                    } else {
                        return a[col] > b[col] ? 1 : -1
                    }
                })
                this.setState({ Data: Sorted })

                if (col === "invoice_date") {
                    this.setState({
                        BillDateSortOrder: "desc",
                        BillDateSortIcon: 'asc',
                        CashSortOrder: "asc",
                        CashSortIcon: 'none',
                        CardSortOrder: "asc",
                        CardSortIcon: 'none',
                        UpiSortOrder: "asc",
                        UpiSortIcon: 'none',
                        BankTransSortOrder: "asc",
                        BankTransSortIcon: 'none',
                        InsuranceSortOrder: "asc",
                        InsuranceSortIcon: 'none',
                        TotalSortOrder: "asc",
                        TotalSortIcon: 'none',
                    })
                }
                else if (col === "cash") {
                    this.setState({
                        BillDateSortOrder: "asc",
                        BillDateSortIcon: 'none',
                        CashSortOrder: "desc",
                        CashSortIcon: 'asc',
                        CardSortOrder: "asc",
                        CardSortIcon: 'none',
                        UpiSortOrder: "asc",
                        UpiSortIcon: 'none',
                        BankTransSortOrder: "asc",
                        BankTransSortIcon: 'none',
                        InsuranceSortOrder: "asc",
                        InsuranceSortIcon: 'none',
                        TotalSortOrder: "asc",
                        TotalSortIcon: 'none',
                    })
                }
                else if (col === "card") {
                    this.setState({
                        BillDateSortOrder: "asc",
                        BillDateSortIcon: 'none',
                        CashSortOrder: "asc",
                        CashSortIcon: 'none',
                        CardSortOrder: "desc",
                        CardSortIcon: 'asc',
                        UpiSortOrder: "asc",
                        UpiSortIcon: 'none',
                        BankTransSortOrder: "asc",
                        BankTransSortIcon: 'none',
                        InsuranceSortOrder: "asc",
                        InsuranceSortIcon: 'none',
                        TotalSortOrder: "asc",
                        TotalSortIcon: 'none',
                    })
                }
                else if (col === "upi") {
                    this.setState({
                        BillDateSortOrder: "asc",
                        BillDateSortIcon: 'none',
                        CashSortOrder: "asc",
                        CashSortIcon: 'none',
                        CardSortOrder: "asc",
                        CardSortIcon: 'none',
                        UpiSortOrder: "desc",
                        UpiSortIcon: 'asc',
                        BankTransSortOrder: "asc",
                        BankTransSortIcon: 'none',
                        InsuranceSortOrder: "asc",
                        InsuranceSortIcon: 'none',
                        TotalSortOrder: "asc",
                        TotalSortIcon: 'none',
                    })
                }
                else if (col === "bank_transfer") {
                    this.setState({
                        BillDateSortOrder: "asc",
                        BillDateSortIcon: 'none',
                        CashSortOrder: "asc",
                        CashSortIcon: 'none',
                        CardSortOrder: "asc",
                        CardSortIcon: 'none',
                        UpiSortOrder: "asc",
                        UpiSortIcon: 'none',
                        BankTransSortOrder: "desc",
                        BankTransSortIcon: 'asc',
                        InsuranceSortOrder: "asc",
                        InsuranceSortIcon: 'none',
                        TotalSortOrder: "asc",
                        TotalSortIcon: 'none',
                    })
                }
                else if (col === "insurance") {
                    this.setState({
                        BillDateSortOrder: "asc",
                        BillDateSortIcon: 'none',
                        CashSortOrder: "asc",
                        CashSortIcon: 'none',
                        CardSortOrder: "asc",
                        CardSortIcon: 'none',
                        UpiSortOrder: "asc",
                        UpiSortIcon: 'none',
                        BankTransSortOrder: "asc",
                        BankTransSortIcon: 'none',
                        InsuranceSortOrder: "desc",
                        InsuranceSortIcon: 'asc',
                        TotalSortOrder: "asc",
                        TotalSortIcon: 'none',
                    })
                }
                else if (col === "total") {
                    this.setState({
                        BillDateSortOrder: "asc",
                        BillDateSortIcon: 'none',
                        CashSortOrder: "asc",
                        CashSortIcon: 'none',
                        CardSortOrder: "asc",
                        CardSortIcon: 'none',
                        UpiSortOrder: "asc",
                        UpiSortIcon: 'none',
                        BankTransSortOrder: "asc",
                        BankTransSortIcon: 'none',
                        InsuranceSortOrder: "asc",
                        InsuranceSortIcon: 'none',
                        TotalSortOrder: "desc",
                        TotalSortIcon: 'asc',
                    })
                }

            }
            else if (SortOrder === "desc") {
                const Sorted = [...this.state.Data].sort((a, b) => {
                    if (typeof (a[col]) == "string") {
                        return a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
                    } else {
                        return a[col] < b[col] ? 1 : -1
                    }
                })
                this.setState({ Data: Sorted })

                if (col === "invoice_date") {
                    this.setState({ BillDateSortOrder: 'default', BillDateSortIcon: "desc" })
                }
                else if (col === "cash") {
                    this.setState({ CashSortOrder: 'default', CashSortIcon: "desc" })
                }
                else if (col === "card") {
                    this.setState({ CardSortOrder: 'default', CardSortIcon: "desc" })
                }
                else if (col === "upi") {
                    this.setState({ UpiSortOrder: 'default', UpiSortIcon: "desc" })
                }
                else if (col === "bank_transfer") {
                    this.setState({ BankTransSortOrder: 'default', BankTransSortIcon: "desc" })
                }
                else if (col === "insurance") {
                    this.setState({ InsuranceSortOrder: 'default', InsuranceSortIcon: "desc" })
                }
                else if (col === "total") {
                    this.setState({ TotalSortOrder: 'default', TotalSortIcon: "desc" })
                }
            }
            else if (SortOrder === "default") {
                this.setState({
                    BillDateSortOrder: "asc",
                    BillDateSortIcon: 'none',
                    CashSortOrder: "asc",
                    CashSortIcon: 'none',
                    CardSortOrder: "asc",
                    CardSortIcon: 'none',
                    UpiSortOrder: "asc",
                    UpiSortIcon: 'none',
                    BankTransSortOrder: "asc",
                    BankTransSortIcon: 'none',
                    InsuranceSortOrder: "asc",
                    InsuranceSortIcon: 'none',
                    TotalSortOrder: "asc",
                    TotalSortIcon: 'none',
                    Data: this.state.DataFilter
                })
            }
        }
        // Sorting Function   ==== ends

        //  Sorting Icons Change func === starts 
        const SortIcon = (sortType) => {
            if (sortType === "asc") {
                return <Tooltip disableInteractive placement='top' title={t("InAscendingOrder")}><KeyboardArrowUpIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
            }
            else if (sortType === 'desc') {
                return <Tooltip disableInteractive placement='top' title={t("InDecendingOrder")}><KeyboardArrowDownIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
            }
            else return <Tooltip disableInteractive placement='top' title={t("Sorting")}><FilterListIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
        }

        //  Sorting Icons Change func === ends 

        const handleDate = () => {
            var today = DateTime.now().toFormat('yyyy-MM-dd')
            var yesterday = DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd')
            var startweek = DateTime.now().minus({ days: 7 }).toFormat('yyyy-MM-dd')
            var startmonth = DateTime.now().minus({ days: 30 }).toFormat('yyyy-MM-dd')

            if (this.state.datefilter === 0) {
                this.setState({
                    QueryFrmDte: "",
                    QueryToDte: "",
                }, () => {
                    this.getDayEndOverview()
                })
            }
            else if (this.state.datefilter === 1) {
                this.setState({
                    QueryFrmDte: today,
                    QueryToDte: today,
                }, () => {
                    this.getDayEndOverview()
                })

            }
            else if (this.state.datefilter === 2) {
                this.setState({
                    QueryFrmDte: yesterday,
                    QueryToDte: yesterday,
                }
                    , () => {
                        this.getDayEndOverview()
                    })
            }
            else if (this.state.datefilter === 3) {
                this.setState({
                    QueryFrmDte: startweek,
                    QueryToDte: yesterday,
                },
                    () => {
                        this.getDayEndOverview()
                    })
            }
            else if (this.state.datefilter === 4) {
                this.setState({
                    QueryFrmDte: startmonth,
                    QueryToDte: yesterday,
                },
                    () => {
                        this.getDayEndOverview()
                    })
            }
            else if (this.state.datefilter === 5) {
                this.setState({ datePickerPop: true })
            }

        }


        const handleChange = (event) => {
            this.setState({ datefilter: event.target.value }, () => handleDate());
        };
        return (
            <div>
                <div className="eMed_page_header">
                    <div id='eMed_name_totalcard'>
                        <div id="eMed_table_name" style={{ color: Colors.TextColor }}>
                            {t(this.props.menuName)}
                        </div>
                        {
                            this.state.CardOpen ?
                                <div onClick={() => { this.setState({ CardOpen: !this.state.CardOpen }) }} id="eMed_tbl_paymentcard" >
                                    <PaymentCard Color={Colors.blue} Icons={<CurrencyRupeeIcon />} Name={t("Cash")} Amount={this.state.CashTotal} />
                                    <PaymentCard Color={Colors.orange} Icons={<CreditCardIcon />} Name={t("Card")} Amount={this.state.CardTotal} />
                                    <PaymentCard Color={Colors.liteGreen} Icons={<SearchIcon />} Name={t("UPI")} Amount={this.state.UPITotal} />
                                    <PaymentCard Color={Colors.liteViolet} Icons={<AccountBalanceIcon />} Name={t("BankTransfer")} Amount={this.state.BankTransTotal} />
                                    <PaymentCard Color={Colors.brown} Icons={<CreditCardIcon />} Name={t("Insurance")} Amount={this.state.Insurance} />
                                </div> :
                                <div id="eMed_tbl_paymentcard" onClick={() => { this.setState({ CardOpen: !this.state.CardOpen, SearchFieldOpen: false }) }} style={{ display: this.state.CardOpen ? "none" : "inline" }}>
                                    <PaymentCard Color={Colors.defaulttheme} Icons={<SearchIcon />} Name={"Total"} Amount={this.state.TotalAmount} />
                                </div>
                        }
                    </div>

                    <div id='eMed_tbl_filters'>
                        <div>
                            <FormControl id="eMed_tbl_datefilter">
                                <InputLabel sx={{ color: Colors.TextColor }}>{t("SelectDate")}</InputLabel>
                                <Select
                                    sx={{ color: Colors.TextColor }}
                                    size='small'
                                    value={this.state.datefilter}
                                    label="Custom Date"
                                    onChange={handleChange}
                                >
                                    <MenuItem value={0}>{t("All")}</MenuItem>
                                    <MenuItem value={1}>{t("Today")}</MenuItem>
                                    <MenuItem value={2}>{t("Yesterday")}</MenuItem>
                                    <MenuItem value={3}>{t("Lastweek")}</MenuItem>
                                    <MenuItem value={4}>{t("LastMonth")}</MenuItem>
                                    <MenuItem value={5} onClick={() => this.setState({ datePickerPop: true })}>{t("Custom")}</MenuItem>
                                </Select>
                                <FormHelperText id="eMed_form_helpertext">{this.DateHelperText()}</FormHelperText>
                            </FormControl>
                        </div>
                        {this.state.SearchFieldOpen ?
                            <div id="eMed_tbl_searchbox" >
                                <TextField
                                    sx={{ input: { color: Colors.TextColor } }}
                                    size='small'
                                    focused
                                    onChange={(e) => this.setState({ SearchText: e.target.value })}
                                    value={this.state.SearchText}
                                    label={t("SearchHere")}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip disableInteractive title={t("Clear")} placement='top'><CloseIcon sx={{ color: Colors.ThemeColor }} onClick={() => { this.setState({ SearchText: '' }) }} /></Tooltip>
                                        ),
                                    }}
                                />
                            </div> : null}
                        <Tooltip disableInteractive placement="top-start" title={this.state.SearchFieldOpen ? t("CloseSearch") : t("OpenSearch")}>
                            <Button id="eMed_tbl_searchicon" variant='contained' style={{ backgroundColor: Colors.ThemeColor }} onClick={() => this.setState({ SearchFieldOpen: !this.state.SearchFieldOpen, CardOpen: false, SearchText: '' })} >
                                {this.state.SearchFieldOpen ? <CloseIcon /> : <SearchIcon />}
                            </Button>
                        </Tooltip>
                    </div>
                </div >
                <div>
                    <Card className='eMed_tbl_card' sx={{ bgcolor: Colors.TableCard }}>
                        <TableContainer className='eMed_tbl_container'>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="eMed_sticky_start" align='center' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>
                                                {t("SNo")}
                                            </div>
                                        </TableCell>
                                        <TableCell className='eMed_tbl_headwidth_small' id="eMed_sticky_col2_head" align='center' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className='eMed_sortable_center'>
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("BillDate")}</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("invoice_date", this.state.BillDateSortOrder) }}>{SortIcon(this.state.BillDateSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell className='emed_Billno_head' id="eMed_nonsticky_head" align='center' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext' className='eMed_sortable_center' >
                                                {t("BillNo")}
                                            </div>
                                        </TableCell>
                                        <TableCell id="eMed_nonsticky_head" className='eMed_tbl_headwidth_small' align='center' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className='eMed_sortable_number'>
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("Cash")}(₹)</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("cash", this.state.CashSortOrder) }}>{SortIcon(this.state.CashSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell id="eMed_nonsticky_head" className='eMed_tbl_headwidth_small' align='center' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className='eMed_sortable_number'>
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("Card")}(₹)</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("card", this.state.CardSortOrder) }}>{SortIcon(this.state.CardSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell id="eMed_nonsticky_head" className='eMed_tbl_headwidth_small' align='center' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className=' eMed_sortable_number'>
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'> {t("UPI")}(₹)</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("upi", this.state.UpiSortOrder) }}>{SortIcon(this.state.UpiSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell id="eMed_nonsticky_head" className='eMed_tbl_headwidth_small' align='right' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className=' eMed_sortable_number'>
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("BankTransfer")}(₹)</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("bank_transfer", this.state.BankTransSortOrder) }}>{SortIcon(this.state.BankTransSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell id="eMed_nonsticky_head" className='eMed_tbl_headwidth_small' align='right' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className=' eMed_sortable_number'>
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("Insurance")}(₹)</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("insurance", this.state.InsuranceSortOrder) }}>{SortIcon(this.state.InsuranceSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                        {/* <TableCell id="eMed_nonsticky_head" align='right' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div style={{color:Colors.TableHeaderTextColor}} id='eMed_tbl_headtext'>
                                                {t("OutStandingAmount")}(₹)
                                            </div>
                                        </TableCell> */}
                                        <TableCell className='eMed_sticky_end eMed_tbl_headwidth_small' align='right' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className=' eMed_sortable_number'>
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("Total")}(₹)</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("total", this.state.TotalSortOrder) }}>{SortIcon(this.state.TotalSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {filtereddata.length > 0 ? filtereddata.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((data, index) => (
                                    <TableBody key={index}>
                                        <TableRow>
                                            <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='center' className="eMed_sticky_startbody">
                                                <div id="eMed_tbl_bodytext"> {index + 1}</div>
                                            </TableCell>
                                            <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='center' id="eMed_sticky_col2_body">
                                                <div id="eMed_tbl_bodytext">{data.invoice_date} </div>
                                            </TableCell>
                                            <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='center' id="eMed_nonsticky_head">
                                                <div id="eMed_tbl_bodytext"> {data.invoice_number} </div>
                                            </TableCell>
                                            <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='right' id="eMed_nonsticky_head">
                                                <div id="eMed_tbl_bodytext"> {AmountFormat(data.cash)} </div>
                                            </TableCell>
                                            <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='right' id="eMed_nonsticky_head">
                                                <div id="eMed_tbl_bodytext"> {AmountFormat(data.card)} </div>
                                            </TableCell>
                                            <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='right' id="eMed_nonsticky_head">
                                                <div id="eMed_tbl_bodytext"> {AmountFormat(data.upi)} </div>
                                            </TableCell>
                                            <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='right' id="eMed_nonsticky_head">
                                                <div id="eMed_tbl_bodytext"> {AmountFormat(data.bank_transfer)} </div>
                                            </TableCell>
                                            <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='right' id="eMed_nonsticky_head">
                                                <div id="eMed_tbl_bodytext"> {AmountFormat(data.insurance)} </div>
                                            </TableCell>
                                            <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} className='eMed_sticky_endbody' align='right'>
                                                <div id="eMed_tbl_bodytext"> {AmountFormat(data.total)} </div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )) :
                                    <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_Norecords'>
                                        {t("NoRecords")}
                                    </div>}
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component='div'
                            id="eMed_Table_Pagination"
                            sx={{
                                color: Colors.TextColor
                            }}
                            rowsPerPageOptions={[5, 10, 15]}
                            count={filtereddata.length}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                        {this.state.datePickerPop ? <DatePicker PopClose={this.PopClose} CustomDates={this.CustomDates} /> : null}
                        {this.state.dialogBox ?
                            <Dialog
                                type={"Notification"}
                                DialogClose={this.dialogCloseFunction}
                                header={"Message"}
                                content={this.state.ErrorMsg}
                                icon={<img className="eMed_dilboxAnimations" alt="Warning" src={Failed} />}
                                secondary={"Close"}
                                closeButton={this.dialogCloseFunction}
                            /> : null}
                    </Card>
                </div>
            </div >
        )
    }
}
export default withTranslation()(DayEndOverview)
