import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import { Colors } from '../../../../../Styles/Colors';
import Tooltip from '@mui/material/Tooltip';
import { TablePagination } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DatePicker from '../../../../../Components/DatePicker/DatePicker';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import '../../../../../Styles/CommonStyles.css'
import './SalesOverview.css';
import PaymentCard from '../../../../../Components/PaymentCard/PaymentCard'
import { Button, FormHelperText } from '@mui/material';
import { DateTime } from "luxon";
import { AmountFormat } from '../../../../../Components/CommonFunctions/CommonFunctions';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import { localGetItem } from '../../../../../Utility/Services/CacheProviderService'
import { Serviceurls } from '../../../../../Utility/API/Serviceurls';
import RestAPIService from '../../../../../Utility/Services/RestAPIService';
import Dialog from '../../../../../Components/Dialog';
import Failed from '../../../../../Assets/Animation/Failed.gif';

class SalesOverview extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Data: [],
      DataFilter: [],
      SearchFieldOpen: false,
      SearchText: "",
      page: 0,
      rowsPerPage: 5,
      datePickerPop: false,
      datefilter: 0,
      DateSortorder: 'asc',
      DateSortIcon: "none",
      AmountSortorder: 'asc',
      AmountSortIcon: "none",
      CGSTSortorder: 'asc',
      CGSTSortIcon: "none",
      SGSTSortorder: 'asc',
      SGSTSortIcon: "none",
      DisAmtSortorder: 'asc',
      DisAmtSortIcon: "none",
      RoundOffSortorder: 'asc',
      RoundOffSortIcon: "none",
      NetAmtSortorder: 'asc',
      NetAmtSortIcon: "none",
      QueryFrmDte: "",
      QueryToDte: "",
      PharmacyID: localGetItem("Pharmacy_Id")
    }
  }

  componentDidMount() {
    this.getSalesOverview()
  }

  getSalesOverview = () => {
    try {
      let QueryParams = `?pharmacy_id=${this.state.PharmacyID}&page=&page_size=all&from_date=${this.state.QueryFrmDte}&to_date=${this.state.QueryToDte}&start_time=&end_time=`
      RestAPIService.getAll(Serviceurls.GET_SALES_OVERVIEW + QueryParams)
        .then(response => {
          this.setState({
            Data: response.data.data.result,
            DataFilter: response.data.data.result,
            TotalAmt: response.data.data.total_amount,
            CGSTAmt: response.data.data.total_cgst_amount,
            DisAmt: response.data.data.total_discount_amount,
            NetAmt: response.data.data.total_net_amount,
            SGSTAmt: response.data.data.total_sgst_amount
          })
        })
        .catch(e => {
          this.setState({ dialogBox: true, ErrorMsg: e.message });
          setTimeout(() => {
            this.setState({
              dialogBox: false
            })
          }, 3000)
        });

    }
    catch (e) {
      this.setState({ dialogBox: true, ErrorMsg: e.message });
      setTimeout(() => {
        this.setState({
          dialogBox: false
        })
      }, 3000)
    }
  }

  //  Mui table Pagination Functions --- starts

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 });
  }

  //  Mui table Pagination Functions --- ends

  PopClose = () => {
    this.setState({
      datePickerPop: false,
      datefilter: 0,
      QueryFrmDte: "",
      QueryToDte: "",
    }, () => this.getSalesOverview())
  }

  CustomDates = (FromDate, ToDate) => {
    this.setState({
      QueryFrmDte: FromDate,
      QueryToDte: ToDate,
      datePickerPop: false
    }, () => this.getSalesOverview())
  }

  DateHelperText = () => {
    if (this.state.datefilter === 0) {
      return " "
    }
    else if (this.state.datefilter === 1) {
      return DateTime.now().toFormat('dd-MM-yyyy')
    }
    else if (this.state.datefilter === 2) {
      return DateTime.now().minus({ days: 1 }).toFormat('dd-MM-yyyy')
    }
    else if (this.state.datefilter === 3) {
      return `${DateTime.now().minus({ days: 7 }).toFormat('dd-MM-yyyy')}  to  ${DateTime.now().toFormat('dd-MM-yyyy')}`
    }
    else if (this.state.datefilter === 4) {
      return `${DateTime.now().minus({ days: 30 }).toFormat('dd-MM-yyyy')}  to  ${DateTime.now().toFormat('dd-MM-yyyy')}`
    }
    else if (this.state.datefilter === 5) {
      return this.state.QueryToDte === undefined ? null : `${this.state.QueryFrmDte.split("-").reverse().join("-")}  to  ${this.state.QueryToDte.split("-").reverse().join("-")}`
    }
  }

  dialogCloseFunction = () => {
    this.setState({
      dialogBox: false
    })
  }


  render() {

    const { t } = this.props
    const DataArray = this.state.DataFilter

    //  filter table data based on search Text
    const filtereddata = this.state.Data.filter((data) => {
      return (
        (data.invoice_date === null ? null : data.invoice_date.includes(this.state.SearchText)) ||
        (data.invoice_number === null ? null : data.invoice_number === parseInt(this.state.SearchText)) ||
        (data.billing_customer_name === null ? null : data.billing_customer_name.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
        (data.taxable_amount === null ? null : Math.round(data.taxable_amount * 100) === parseInt(Math.round(this.state.SearchText * 100))) ||
        (data.cgst_amount === null ? null : Math.round(data.cgst_amount * 100) === parseInt(Math.round(this.state.SearchText * 100))) ||
        (data.sgst_amount === null ? null : Math.round(data.sgst_amount * 100) === parseInt(Math.round(this.state.SearchText * 100))) ||
        (data.discount_amount === null ? null : Math.round(data.discount_amount * 100) === parseInt(Math.round(this.state.SearchText * 100))) ||
        (data.round_off === null ? null : Math.round(data.round_off * 100) === parseInt(Math.round(this.state.SearchText * 100))) ||
        (data.grand_total === null ? null : Math.round(data.grand_total * 100) === parseInt(Math.round(this.state.SearchText * 100)))
      );
    });

    // Search Icon OnClick Function == ends

    // Sorting Function   ==== starts
    const sorting = (col, SortOrder) => {
      if (SortOrder === "asc") {
        const Sorted = [...this.state.Data].sort((a, b) => {
          if (typeof (a[col]) == "string") {
            return a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
          } else {
            return a[col] > b[col] ? 1 : -1
          }
        })
        this.setState({ Data: Sorted })

        if (col === "invoice_date") {
          this.setState({
            DateSortorder: 'desc',
            DateSortIcon: "asc",
            AmountSortorder: 'asc',
            AmountSortIcon: "none",
            CGSTSortorder: 'asc',
            CGSTSortIcon: "none",
            SGSTSortorder: 'asc',
            SGSTSortIcon: "none",
            DisAmtSortorder: 'asc',
            DisAmtSortIcon: "none",
            RoundOffSortorder: 'asc',
            RoundOffSortIcon: "none",
            NetAmtSortorder: 'asc',
            NetAmtSortIcon: "none"
          })
        }
        else if (col === "taxable_amount") {
          this.setState({
            DateSortorder: 'asc',
            DateSortIcon: "none",
            AmountSortorder: 'desc',
            AmountSortIcon: "asc",
            CGSTSortorder: 'asc',
            CGSTSortIcon: "none",
            SGSTSortorder: 'asc',
            SGSTSortIcon: "none",
            DisAmtSortorder: 'asc',
            DisAmtSortIcon: "none",
            RoundOffSortorder: 'asc',
            RoundOffSortIcon: "none",
            NetAmtSortorder: 'asc',
            NetAmtSortIcon: "none"
          })
        }
        else if (col === "cgst_amount") {
          this.setState({
            DateSortorder: 'asc',
            DateSortIcon: "none",
            AmountSortorder: 'asc',
            AmountSortIcon: "none",
            CGSTSortorder: 'desc',
            CGSTSortIcon: "asc",
            SGSTSortorder: 'asc',
            SGSTSortIcon: "none",
            DisAmtSortorder: 'asc',
            DisAmtSortIcon: "none",
            RoundOffSortorder: 'asc',
            RoundOffSortIcon: "none",
            NetAmtSortorder: 'asc',
            NetAmtSortIcon: "none"
          })
        }
        else if (col === "sgst_amount") {
          this.setState({
            DateSortorder: 'asc',
            DateSortIcon: "none",
            AmountSortorder: 'asc',
            AmountSortIcon: "none",
            CGSTSortorder: 'asc',
            CGSTSortIcon: "none",
            SGSTSortorder: 'desc',
            SGSTSortIcon: "asc",
            DisAmtSortorder: 'asc',
            DisAmtSortIcon: "none",
            RoundOffSortorder: 'asc',
            RoundOffSortIcon: "none",
            NetAmtSortorder: 'asc',
            NetAmtSortIcon: "none"
          })
        }
        else if (col === "general_discount") {
          this.setState({
            DateSortorder: 'asc',
            DateSortIcon: "none",
            AmountSortorder: 'asc',
            AmountSortIcon: "none",
            CGSTSortorder: 'asc',
            CGSTSortIcon: "none",
            SGSTSortorder: 'asc',
            SGSTSortIcon: "none",
            DisAmtSortorder: 'desc',
            DisAmtSortIcon: "asc",
            RoundOffSortorder: 'asc',
            RoundOffSortIcon: "none",
            NetAmtSortorder: 'asc',
            NetAmtSortIcon: "none"
          })
        }
        else if (col === "round_off") {
          this.setState({
            DateSortorder: 'asc',
            DateSortIcon: "none",
            AmountSortorder: 'asc',
            AmountSortIcon: "none",
            CGSTSortorder: 'asc',
            CGSTSortIcon: "none",
            SGSTSortorder: 'asc',
            SGSTSortIcon: "none",
            DisAmtSortorder: 'asc',
            DisAmtSortIcon: "none",
            RoundOffSortorder: 'desc',
            RoundOffSortIcon: "asc",
            NetAmtSortorder: 'asc',
            NetAmtSortIcon: "none"
          })
        }
        else if (col === "grand_total") {
          this.setState({
            DateSortorder: 'asc',
            DateSortIcon: "none",
            AmountSortorder: 'asc',
            AmountSortIcon: "none",
            CGSTSortorder: 'asc',
            CGSTSortIcon: "none",
            SGSTSortorder: 'asc',
            SGSTSortIcon: "none",
            DisAmtSortorder: 'asc',
            DisAmtSortIcon: "none",
            RoundOffSortorder: 'asc',
            RoundOffSortIcon: "none",
            NetAmtSortorder: 'desc',
            NetAmtSortIcon: "asc"
          })
        }

      }
      else if (SortOrder === "desc") {
        const Sorted = [...this.state.Data].sort((a, b) => {
          if (typeof (a[col]) == "string") {
            return a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
          } else {
            return a[col] < b[col] ? 1 : -1
          }
        })
        this.setState({ Data: Sorted })

        if (col === "invoice_date") {
          this.setState({ DateSortorder: 'default', DateSortIcon: "desc" })
        }
        else if (col === "taxable_amount") {
          this.setState({ AmountSortorder: 'default', AmountSortIcon: "desc" })
        }
        else if (col === "cgst_amount") {
          this.setState({ CGSTSortorder: 'default', CGSTSortIcon: "desc" })
        }
        else if (col === "sgst_amount") {
          this.setState({ SGSTSortorder: 'default', SGSTSortIcon: "desc" })
        }
        else if (col === "general_discount") {
          this.setState({ DisAmtSortorder: 'default', DisAmtSortIcon: "desc" })
        }
        else if (col === "round_off") {
          this.setState({ RoundOffSortorder: 'default', RoundOffSortIcon: "desc" })
        }
        else if (col === "grand_total") {
          this.setState({ NetAmtSortorder: 'default', NetAmtSortIcon: "desc" })
        }
      }
      else if (SortOrder === "default") {
        this.setState({
          DateSortorder: 'asc',
          DateSortIcon: "none",
          AmountSortorder: 'asc',
          AmountSortIcon: "none",
          CGSTSortorder: 'asc',
          CGSTSortIcon: "none",
          SGSTSortorder: 'asc',
          SGSTSortIcon: "none",
          DisAmtSortorder: 'asc',
          DisAmtSortIcon: "none",
          RoundOffSortorder: 'asc',
          RoundOffSortIcon: "none",
          NetAmtSortorder: 'asc',
          NetAmtSortIcon: "none",
          Data: DataArray
        })
      }
    }
    // Sorting Function   ==== ends

    //  Sorting Icons Change func === starts 
    const SortIcon = (sortType) => {
      if (sortType === "asc") {
        return <Tooltip disableInteractive placement='top' title={t("InAscendingOrder")}><KeyboardArrowUpIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
      }
      else if (sortType === 'desc') {
        return <Tooltip disableInteractive placement='top' title={t("InDecendingOrder")}><KeyboardArrowDownIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
      }
      else return <Tooltip disableInteractive placement='top' title={t("Sorting")}><FilterListIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
    }

    //  Sorting Icons Change func === ends 

    const handleDate = () => {
      var today = DateTime.now().toFormat('yyyy-MM-dd')
      var yesterday = DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd')
      var startweek = DateTime.now().minus({ days: 7 }).toFormat('yyyy-MM-dd')
      var startmonth = DateTime.now().minus({ days: 30 }).toFormat('yyyy-MM-dd')

      if (this.state.datefilter === 0) {
        this.setState({
          QueryFrmDte: "",
          QueryToDte: "",
        }, () => {
          this.getSalesOverview()
        })
      }
      else if (this.state.datefilter === 1) {
        this.setState({
          QueryFrmDte: today,
          QueryToDte: today,
        }, () => {
          this.getSalesOverview()
        })

      }
      else if (this.state.datefilter === 2) {
        this.setState({
          QueryFrmDte: yesterday,
          QueryToDte: yesterday,
        }
          , () => {
            this.getSalesOverview()
          })
      }
      else if (this.state.datefilter === 3) {
        this.setState({
          QueryFrmDte: startweek,
          QueryToDte: yesterday,
        },
          () => {
            this.getSalesOverview()
          })
      }
      else if (this.state.datefilter === 4) {
        this.setState({
          QueryFrmDte: startmonth,
          QueryToDte: yesterday,
        },
          () => {
            this.getSalesOverview()
          })
      }
      else if (this.state.datefilter === 5) {
        this.setState({ datePickerPop: true })
      }

    }


    const handleChange = (event) => {
      this.setState({ datefilter: event.target.value }, () => handleDate());
    };
    return (
      <div>
        <div className="eMed_page_header">
          <div id='eMed_name_totalcard'>
            <div id="eMed_table_name" style={{ color: Colors.TextColor }}>
              {t(this.props.menuName)}
            </div>
            {
              this.state.CardOpen ?
                <div onClick={() => { this.setState({ CardOpen: !this.state.CardOpen }) }} id="eMed_tbl_paymentcard" >
                  <PaymentCard Color={Colors.blue} Icons={<CurrencyRupeeIcon />} Name={t("Amount")} Amount={this.state.TotalAmt} />
                  <PaymentCard Color={Colors.orange} Icons={<AccountBalanceIcon />} Name={t("CGST")} Amount={this.state.CGSTAmt} />
                  <PaymentCard Color={Colors.liteGreen} Icons={<AccountBalanceIcon />} Name={t("SGST")} Amount={this.state.SGSTAmt} />
                  <PaymentCard Color={Colors.liteViolet} Icons={<CreditCardIcon />} Name={t("DisAmt")} Amount={this.state.DisAmt} />
                  <PaymentCard Color={Colors.brown} Icons={<CurrencyRupeeIcon />} Name={t("NetAmount")} Amount={this.state.NetAmt} />
                </div> :
                <div id="eMed_tbl_paymentcard" onClick={() => { this.setState({ CardOpen: !this.state.CardOpen, SearchFieldOpen: false }) }} style={{ display: this.state.CardOpen ? "none" : "inline" }}>
                  <PaymentCard Color={Colors.defaulttheme} Icons={<SearchIcon />} Name={"Total"} Amount={this.state.TotalAmt} />
                </div>
            }
          </div>
          <div id='eMed_tbl_filters'>
            <div>
              <FormControl id="eMed_tbl_datefilter">
                <InputLabel sx={{ color: Colors.TextColor }}>{t("SelectDate")}</InputLabel>
                <Select
                  sx={{ color: Colors.TextColor }}
                  size='small'
                  value={this.state.datefilter}
                  label={t("SelectDate")}
                  onChange={handleChange}
                >
                  <MenuItem value={0}>{t("All")}</MenuItem>
                  <MenuItem value={1}>{t("Today")}</MenuItem>
                  <MenuItem value={2}>{t("Yesterday")}</MenuItem>
                  <MenuItem value={3}>{t("Lastweek")}</MenuItem>
                  <MenuItem value={4}>{t("LastMonth")}</MenuItem>
                  <MenuItem value={5} onClick={() => this.setState({ datePickerPop: true })}>{t("Custom")}</MenuItem>
                </Select>
                <FormHelperText id="eMed_form_helpertext">{this.DateHelperText()}</FormHelperText>
              </FormControl>
            </div>
            {this.state.SearchFieldOpen ?
              <div id="eMed_tbl_searchbox" >
                <TextField
                  sx={{ input: { color: Colors.TextColor } }}
                  size='small'
                  focused
                  onChange={(e) => this.setState({ SearchText: e.target.value })}
                  value={this.state.SearchText}
                  label={t("SearchHere")}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <Tooltip disableInteractive title={t("Clear")} placement='top'><CloseIcon sx={{ color: Colors.ThemeColor }} onClick={() => { this.setState({ SearchText: '' }) }} /></Tooltip>
                    ),
                  }}
                />
              </div> : null}
            {/* <div> */}
            <Tooltip disableInteractive placement="top-start" title={this.state.SearchFieldOpen ? t("CloseSearch") : t("OpenSearch")}>
              <Button id="eMed_tbl_searchicon" variant='contained' style={{ backgroundColor: Colors.ThemeColor }} onClick={() => this.setState({ SearchFieldOpen: !this.state.SearchFieldOpen, CardOpen: false, SearchText: '' })} >
                {this.state.SearchFieldOpen ? <CloseIcon /> : <SearchIcon />}
              </Button>
            </Tooltip>
            {/* </div> */}
          </div>
        </div>
        <div>
          <Card className='eMed_tbl_card' sx={{ bgcolor: Colors.TableCard }}>
            <TableContainer className='eMed_tbl_container'>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className="eMed_sticky_start" align='center' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>
                        {t("SNo")}
                      </div>
                    </TableCell>
                    <TableCell id="eMed_sticky_col2_head" align='center' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div className='eMed_sortable_center'>
                        <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("Date")}</div>
                        <div id="eMed-SortIcons" onClick={() => { sorting("invoice_date", this.state.DateSortorder) }}>{SortIcon(this.state.DateSortIcon)}</div>
                      </div>
                    </TableCell>
                    <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" align='center' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div style={{ color: Colors.TableHeaderTextColor }} className='eMed_sortable_center' id='eMed_tbl_headtext'>
                        {t("BillNo")}
                      </div>
                    </TableCell>
                    <TableCell className='eMed_tbl_patient_name' id="eMed_nonsticky_head" align='left' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>
                        {t("PatientName")}
                      </div>
                    </TableCell>
                    <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" align='center' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div className=' eMed_sortable_number'>
                        <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("Amount")}(₹)</div>
                        <div id="eMed-SortIcons" onClick={() => { sorting("taxable_amount", this.state.AmountSortorder) }}>{SortIcon(this.state.AmountSortIcon)}</div>
                      </div>
                    </TableCell>
                    <TableCell className='eMed_tbl_headwidth_small' align='center' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div className=' eMed_sortable_number'>
                        <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'> {t("CGST")}(₹)</div>
                        <div id="eMed-SortIcons" onClick={() => { sorting("cgst_amount", this.state.CGSTSortorder) }}>{SortIcon(this.state.CGSTSortIcon)}</div>
                      </div>
                    </TableCell>
                    <TableCell className='eMed_tbl_headwidth_small' align='center' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div className=' eMed_sortable_number'>
                        <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'> {t("SGST")}(₹)</div>
                        <div id="eMed-SortIcons" onClick={() => { sorting("sgst_amount", this.state.SGSTSortorder) }}>{SortIcon(this.state.SGSTSortIcon)}</div>
                      </div>
                    </TableCell>
                    <TableCell className='eMed_tbl_headwidth_large' align='center' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div className=' eMed_sortable_number'>
                        <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("DisAmt")}(₹)</div>
                        <div id="eMed-SortIcons" onClick={() => { sorting("general_discount", this.state.DisAmtSortorder) }}>{SortIcon(this.state.DisAmtSortIcon)}</div>
                      </div>
                    </TableCell>
                    <TableCell className='eMed_tbl_headwidth_large' align='center' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div className=' eMed_sortable_number'>
                        <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'> {t("RoundOff")}(₹)</div>
                        <div id="eMed-SortIcons" onClick={() => { sorting("round_off", this.state.RoundOffSortorder) }}>{SortIcon(this.state.RoundOffSortIcon)}</div>
                      </div>
                    </TableCell>
                    <TableCell className='eMed_sticky_end eMed_tbl_headwidth_large' align='right' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div className='eMed_sortable_number'>
                        <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("NetAmount")}(₹)</div>
                        <div id="eMed-SortIcons" onClick={() => { sorting("grand_total", this.state.NetAmtSortorder) }}>{SortIcon(this.state.NetAmtSortIcon)}</div>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {filtereddata.length > 0 ? filtereddata.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((data, index) => (
                  <TableBody key={index}>
                    <TableRow>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='center' id='eMed_tbl_bodytext' className="eMed_sticky_startbody">
                        {index + 1}
                      </TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='center' id="eMed_sticky_col2_body">
                        <div id='eMed_tbl_bodytext'>{data.invoice_date}</div>
                      </TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='center' id="eMed_tbl_bodytext" >
                        {data.invoice_number}</TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='left' id="eMed_tbl_bodytext">
                        {data.billing_customer_name.length > 12 ?
                          <Tooltip placement="top" title={data.billing_customer_name} disableInteractive>
                            <div id='eMed_tbl_bodytext'>{data.billing_customer_name.slice(0, 12) + '...'}</div>
                          </Tooltip> : <div id='eMed_tbl_bodytext'>{data.billing_customer_name}</div>}
                      </TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='right' id="eMed_tbl_bodytext">{AmountFormat(data.taxable_amount)}</TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='right' id="eMed_tbl_bodytext">{AmountFormat(data.cgst_amount)}</TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='right' id="eMed_tbl_bodytext">{AmountFormat(data.sgst_amount)}</TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='right' id="eMed_tbl_bodytext">{AmountFormat(data.general_discount)}</TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='right' id="eMed_tbl_bodytext">{AmountFormat(data.round_off)}</TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='right' id="eMed_tbl_bodytext" className='eMed_sticky_endbody'>{AmountFormat(data.grand_total)}</TableCell>
                    </TableRow>
                  </TableBody>
                )) :
                  <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_Norecords'>
                    {t("NoRecords")}
                  </div>}
              </Table>
            </TableContainer>
            <TablePagination
              component='div'
              id="eMed_tbl_pagination"
              sx={{
                color: Colors.PagiantionTxtColor
              }}
              rowsPerPageOptions={[5, 10, 15]}
              count={filtereddata.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </Card>
          {this.state.datePickerPop ? <DatePicker PopClose={this.PopClose} CustomDates={this.CustomDates} /> : null}
          {this.state.dialogBox ?
            <Dialog
              type={"Notification"}
              DialogClose={this.dialogCloseFunction}
              header={"Message"}
              content={this.state.ErrorMsg}
              icon={<img className="eMed_dilboxAnimations" alt="Warning" src={Failed} />}
              secondary={"Close"}
              closeButton={this.dialogCloseFunction}
            /> : null}
        </div>
      </div>
    )
  }
}
export default withTranslation()(SalesOverview)
