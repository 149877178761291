import React, { Component } from 'react';
import './PatientTagCard.css';
import AccessibleIcon from '@mui/icons-material/Accessible';
import DirectionsBikeIcon from '@mui/icons-material/DirectionsBike';
import HikingIcon from '@mui/icons-material/Hiking';
import { Stack } from '@mui/material';

export const PatientTagCard = (props) => {

    const PatientTag = (tag) => {
        if (tag === "wheelchair") {
            return <div style={{ backgroundColor: 'rgb(33, 29, 245)' }} className='eMed_Tag'><AccessibleIcon id='eMed_TagIcon' /></div>
        }
        else if (tag === "vip") {
            return <div style={{ backgroundColor: 'rgb(242, 113, 15)' }} className='eMed_Tag'><DirectionsBikeIcon id='eMed_TagIcon' /></div>
        }
        else if (tag === "navy") {
            return <div style={{ backgroundColor: 'rgb(66, 182, 245)' }} className='eMed_Tag'><HikingIcon id='eMed_TagIcon' /></div>
        }
        else if (tag === "isro") {
            return <div style={{ backgroundColor: 'rgb(97, 242, 29)' }} className='eMed_Tag'><DirectionsBikeIcon id='eMed_TagIcon' /></div>
        }
        else if (tag === "army") {
            return <div style={{ backgroundColor: 'rgb(242, 29, 182)' }} className='eMed_Tag'><DirectionsBikeIcon id='eMed_TagIcon' /></div>
        }
        else if (tag === "Staff") {
            return <div style={{ backgroundColor: 'rgb(247, 16, 12)' }} className='eMed_Tag'><DirectionsBikeIcon id='eMed_TagIcon' /></div>
        }
    }
    return (
        <div id='eMed_PatientTagContainer' >
            {props.data.map((data) => (
                <Stack direction='row' id='eMed_PatientTagWrapper'>
                    {PatientTag(data.tag)}
                    <div className='eMed_TagBody'>
                        <div className='eMed_Tag_Name'>{data.tag}</div>
                        <div className='eMed_Tag_Count'>{data.PatientCount}</div>
                    </div>
                </Stack>
            ))}
        </div>
    )
}
