import React, { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';
import { Serviceurls } from '../../Utility/API/Serviceurls';
import RestAPIService from '../../Utility/Services/RestAPIService';
import './Settings.css'
import { localSetItem, localGetItem } from '../../Utility/Services/CacheProviderService'
import { Divider, Typography } from '@mui/material';
import { Colors } from '../../Styles/Colors';
import { Trans, withTranslation } from 'react-i18next';
import ErrorDialog from '../../Components/Dialog'
import Failed from '../../Assets/Animation/Failed.gif';

class Settings extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: true,
            close: false,
            ClinicList: [],
            PharmacyList: [],
            LabList: [],
            ClinicID: localGetItem("Clinic_Id"),
            PharmacyID: localGetItem("Pharmacy_Id"),
            LabID: localGetItem("Lab_Id"),
        }
    }

    componentDidMount() {
        this.getModulesList()
    }
    getModulesList = () => {
        try {
            RestAPIService.getAll(Serviceurls.GET_MODULES_LIST)
                .then(response => {
                    this.setState({
                        ClinicList: response.data.data.clinic,
                        PharmacyList: response.data.data.pharmacy,
                        LabList: response.data.data.laboratory
                    })
                })
                .catch(e => {
                    this.setState({ ErrorPop: true, ErrorMsg: e.message });
                    setTimeout(() => {
                        this.setState({
                            dialogBox: false
                        })
                    }, 3000)
                });

        }
        catch (e) {
            this.setState({ ErrorPop: true, ErrorMsg: e.message });
            setTimeout(() => {
                this.setState({
                    dialogBox: false
                })
            }, 3000)
        }
    }

    SetID = () => {
        if (this.state.ClinicList.length !== 0) {
            localSetItem("Clinic_Id", this.state.ClinicID)
        }
        if (this.state.PharmacyList.length !== 0) {
            localSetItem("Pharmacy_Id", this.state.PharmacyID)
        }
        if (this.state.LabList.length !== 0) {
            localSetItem("Lab_Id", this.state.LabID)
        }
        this.props.DialogClose()
        this.props.Success()
    }


    DialogClose = () => {
        this.props.DialogClose()
    }

    render() {
        const { t } = this.props;
        const ClinicIDChange = (event) => {
            this.setState({ ClinicID: event.target.value });
        }
        const PharmaIDChange = (event) => {
            this.setState({ PharmacyID: event.target.value });
        }
        const LabIDChange = (event) => {
            this.setState({ LabID: event.target.value });
        }
        return (
            <div>
                <Dialog open={this.state.open} onClose={this.state.close} >
                    <DialogTitle id='eMed_Dialog_title' sx={{bgcolor:Colors.TableHeaderBarBGColor, color:Colors.TableHeaderTextColor}}>
                        <Typography id="eMed_dialog_title">{t("BranchSelection")}</Typography>
                    </DialogTitle>
                    <Divider />
                    <DialogContent sx={{bgcolor:Colors.PopupBackground}}>
                        <Box component="form" id='eMed_DropDown_Box' >
                            {this.state.ClinicList.length !== 0 ?
                                <div id='eMed_individual_div'>
                                    <div>
                                        <Typography id="eMed_dialog_text" sx={{ color: Colors.ThemeColor }}>{t("Clinic")}</Typography>
                                    </div>
                                    <div>
                                        <FormControl id="eMed_dropDown_formcontrol">
                                            <Select
                                                size='small'
                                                value={this.state.ClinicID}
                                                onChange={ClinicIDChange}
                                            >
                                                {this.state.ClinicList.map((lists) => (
                                                    <MenuItem key={lists.clinic_id} value={lists.clinic_id}>{lists.clinic_name}</MenuItem>
                                                ))}

                                            </Select>
                                        </FormControl>
                                    </div>
                                </div> : null}
                            {this.state.PharmacyList.length !== 0 ?
                                <div id='eMed_individual_div'>
                                    <div>
                                        <Typography id="eMed_dialog_text" sx={{ color: Colors.ThemeColor }}>{t("Pharmacy")}</Typography>
                                    </div>
                                    <div>
                                        <FormControl id="eMed_dropDown_formcontrol">
                                            <Select
                                                size='small'
                                                value={this.state.PharmacyID}
                                                onChange={PharmaIDChange}
                                            >
                                                {this.state.PharmacyList.map((lists) => (
                                                    <MenuItem key={lists.pharmacy_id} value={lists.pharmacy_id}>{lists.pharmacy_name}</MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div> : null}
                            {this.state.LabList.length !== 0 ? <div id='eMed_individual_div'>
                                <div>
                                    <Typography id="eMed_dialog_text" sx={{ color: Colors.ThemeColor }}>{t("Lab")}</Typography>
                                </div>
                                <div>
                                    <FormControl id="eMed_dropDown_formcontrol">
                                        <Select
                                            size='small'
                                            value={this.state.LabID}
                                            onChange={LabIDChange}
                                        >
                                            {this.state.LabList.map((lists) => (
                                                <MenuItem key={lists.laboratory_id} value={lists.laboratory_id}>{lists.laboratory_name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div> : null}
                        </Box>
                    </DialogContent>
                    <DialogActions id="eMed_dialog_button_div" sx={{bgcolor:Colors.PopupBackground}}>
                        <Button id='eMed_dialog_button'
                            onClick={this.DialogClose}
                        >{t("Cancel")}</Button>
                        <Button id='eMed_dialog_button' color='primary' variant='contained' onClick={this.SetID}>{t("Ok")}</Button>
                    </DialogActions>
                </Dialog>
                {this.state.ErrorPop ?
                    <ErrorDialog
                        type={"Notification"}
                        DialogClose={this.DialogClose}
                        header={"Message"}
                        content={this.state.ErrorMsg}
                        icon={<img className="eMed_dilboxAnimations" alt="Warning" src={Failed} />}
                        secondary={"Close"}
                        closeButton={this.DialogClose}
                    /> : null}

            </div>
        )
    }
}
export default withTranslation()(Settings)