const setCachevalue = (value, key) => {
    sessionStorage.setItem(key,value);
    console.log("Set cache", value, key);
}
const getCachevalue = (key) => {
    let value = sessionStorage.getItem(key);
    console.log("get cache", key);
    return value;
}
const clearCachevalue = (key) => {
    sessionStorage.removeItem(key);
    console.log("Clear cache", key);
}
const clearAllCachevalue = () => {
    sessionStorage.clear();
    console.log("Cache cleared");
}
const localSetItem = (key, value) =>{
    localStorage.setItem(key, value);
}
const localGetItem = (key) =>{
    return localStorage.getItem(key);
}
const localClearItem = (key) => {
    console.log("Logout");
    return localStorage.clearItem(key);
}
const localClearAll = () =>{
    localStorage.clear();
}
export { setCachevalue, getCachevalue, clearCachevalue, clearAllCachevalue,localSetItem,localGetItem,localClearItem,localClearAll }