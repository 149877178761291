import React, { Component } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Avatar from '@mui/material/Avatar';
import "../../Styles/CommonStyles.css"
import { Colors } from '../../Styles/Colors'
import Typography from '@mui/material/Typography';
import TableData from "./MockData.json"
import AccessibleIcon from '@mui/icons-material/Accessible';
import Tooltip from '@mui/material/Tooltip';
import AnchorIcon from '@mui/icons-material/Anchor';
import PersonIcon from '@mui/icons-material/Person';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { TablePagination } from '@mui/material';
import Card from '@mui/material/Card';
import Switch from '@mui/material/Switch';
import ManIcon from '@mui/icons-material/Man';
import WomanIcon from '@mui/icons-material/Woman';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import { Trans, withTranslation } from 'react-i18next';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import "./PatientProfile.css"

class Patient_Profile extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loggedInuserInfo: false,
      page: 0,
      rowsPerPage: 5,
      data: TableData,
      NameorderBySort: 'asc',
      DateorderBySort: 'asc',
      NameSortingIcon: 'none',
      DateSortingIcon: 'none',
      SearchFieldOpen: false,
      SearchText: ""
    }
  }

  //  Mui table Pagination Functions --- starts

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 });
  }

  //  Mui table Pagination Functions --- ends

  SearchFunction = (event) => {
    this.setState({
      SearchText: event.target.value
    });
  };


  render() {

    const { t } = this.props

    // Returns the Suitable Icon based On patients Tag -- starts
    const PatientTag = (tag) => {
      if (tag === "wheelchair") {
        return <Tooltip placement="top" title={tag}><Avatar id='eMed_Icons_Avatar' sx={{ bgcolor: Colors.IconCircleBgColor }} ><AccessibleIcon id='eMed_Icons' sx={{ color: Colors.ThemeColor }} /></Avatar></Tooltip>
      }
      else if (tag === "navy") {
        return <Tooltip placement="top" title={tag}><Avatar id='eMed_Icons_Avatar' sx={{ bgcolor: Colors.IconCircleBgColor }} ><AnchorIcon id='eMed_Icons' sx={{ color: Colors.ThemeColor }} /></Avatar></Tooltip>
      }
      else if (tag === "vip") {
        return <Tooltip placement="top" title={tag}><Avatar id='eMed_Icons_Avatar' sx={{ bgcolor: Colors.IconCircleBgColor }} ><AccessibleIcon id='eMed_Icons' sx={{ color: Colors.ThemeColor }} /></Avatar></Tooltip>
      }
      else if (tag === "isro") {
        return <Tooltip placement="top" title={tag}><Avatar id='eMed_Icons_Avatar' sx={{ bgcolor: Colors.IconCircleBgColor }} ><AccessibleIcon id='eMed_Icons' sx={{ color: Colors.ThemeColor }} /></Avatar></Tooltip>
      }
      else if (tag === "army") {
        return <Tooltip placement="top" title={tag}><Avatar id='eMed_Icons_Avatar' sx={{ bgcolor: Colors.IconCircleBgColor }} ><AccessibleIcon id='eMed_Icons' sx={{ color: Colors.ThemeColor }} /></Avatar></Tooltip>
      }
      else if (tag === "hospitalStaff") {
        return <Tooltip placement="top" title={tag}><Avatar id='eMed_Icons_Avatar' sx={{ bgcolor: Colors.IconCircleBgColor }} ><AccessibleIcon id='eMed_Icons' sx={{ color: Colors.ThemeColor }} /></Avatar></Tooltip>
      }
    }

    // Returns the Suitable Icon based On patients Tag -- ends


    // Setting the Patient profile avatar Color, Icons based on Patient type -- starts
    const PatientAvatar = (type, gender) => {
      if (type === "new") {
        return <Avatar id='eMed_profile_avatar' sx={{ backgroundColor: "#F56B5E" }}>{gender === "M" ? <ManIcon fontSize='medium' /> : gender === "F" ? <WomanIcon fontSize='large' /> : gender === 'Baby' ? <ChildFriendlyIcon /> : <PersonIcon fontSize='large' />}</Avatar>
      }
      else if (type === "review") {
        return <Avatar id='eMed_profile_avatar' sx={{ backgroundColor: "#5BB4F0" }}>{gender === "M" ? <ManIcon fontSize='medium' /> : gender === "F" ? <WomanIcon fontSize='large' /> : gender === 'Baby' ? <ChildFriendlyIcon /> : <PersonIcon fontSize='large' />}</Avatar>
      }
      else if (type === "established") {
        return <Avatar id='eMed_profile_avatar' sx={{ backgroundColor: "#52B4B0" }}>{gender === "M" ? <ManIcon fontSize='medium' /> : gender === "F" ? <WomanIcon fontSize='large' /> : gender === 'Baby' ? <ChildFriendlyIcon /> : <PersonIcon fontSize='large' />}</Avatar>
      }
    }

    // Setting the Patient profile avatar Color, Icons based on Patient type -- starts

    // Sorting Function  ==== starts
    const sorting = (col, sortOrder) => {
      if (sortOrder === "asc") {
        const Sorted = [...this.state.data].sort((a, b) =>
          a[col] > b[col] ? 1 : -1
        )
        this.setState({ data: Sorted })

        if (col === "first_name") {
          this.setState({ NameorderBySort: 'desc', DateorderBySort: 'asc', NameSortingIcon: "asc", DateSortingIcon: "none" })
        }
        else if (col === "lastVisitDate") {
          this.setState({ DateorderBySort: 'desc', NameorderBySort: 'asc', DateSortingIcon: 'asc', NameSortingIcon: 'none' })
        }
      }
      else if (sortOrder === "desc") {
        const Sorted = [...this.state.data].sort((a, b) =>
          a[col] < b[col] ? 1 : -1
        )
        this.setState({ data: Sorted })

        if (col === "first_name") {
          this.setState({ NameorderBySort: 'default', NameSortingIcon: "desc" })
        }
        else if (col === "lastVisitDate") {
          this.setState({ DateorderBySort: 'default', DateSortingIcon: 'desc' })
        }
      }
      else if (sortOrder === "default") {
        this.setState({
          NameorderBySort: 'asc',
          DateorderBySort: 'asc',
          NameSortingIcon: 'none',
          DateSortingIcon: 'none',
          data: TableData
        })
      }
    }
    // Sorting Function  ==== ends



    //  Sorting Icons Change func === starts 
    const SortIcon = (sortType) => {
      if (sortType === "asc") {
        return <Tooltip disableInteractive placement='top' title={t("InAscendingOrder")}><KeyboardArrowUpIcon sx={{ color: Colors.ThemeColor }} id='eMed_sorting_icon' /></Tooltip>
      }
      else if (sortType === 'desc') {
        return <Tooltip disableInteractive placement='top' title={t("InDecendingOrder")}><KeyboardArrowDownIcon sx={{ color: Colors.ThemeColor }} id='eMed_sorting_icon' /></Tooltip>
      }
      else return <Tooltip disableInteractive placement='top' title={t("Sorting")}><FilterListIcon sx={{ color: Colors.ThemeColor }} id='eMed_sorting_icon' /></Tooltip>
    }

    //  Sorting Icons Change func === ends 

    //  filter table data based on search Text
    const filtereddata = this.state.data.filter((data) => {
      return (
        data.first_name.toLowerCase().includes(this.state.SearchText.toLowerCase()) ||
        data.mobile.includes(this.state.SearchText) ||
        data.AccNo.toLowerCase().includes(this.state.SearchText.toLowerCase()) ||
        data.age === parseInt(this.state.SearchText) ||
        data.gender.toLowerCase().includes(this.state.SearchText.toLowerCase()) ||
        data.purpose.toLowerCase().includes(this.state.SearchText.toLowerCase()) ||
        data.consultant.toLowerCase().includes(this.state.SearchText.toLowerCase()) ||
        data.lastVisitDate.toLowerCase().includes(this.state.SearchText.toLowerCase())
      );
    });

    // Search Icon OnClick Function == ends


    return (
      <div>
        <div className='eMed_page_top_div'>
          <Typography id="eMed_table_name">{t("PatientProfile")}</Typography>
          <div className='eMed_page_top_div_icons'>
            <Tooltip disableInteractive title={t("Filter")} placement='bottom'><Avatar id='eMed_IconAvatar' sx={{ bgcolor: Colors.IconCircleBgColor }}><FilterAltIcon id='eMed_Header_Icons' sx={{ color: Colors.ThemeColor }} /></Avatar></Tooltip>
            <Tooltip disableInteractive title={t("Download")} placement='bottom'><Avatar id='eMed_IconAvatar' sx={{ bgcolor: Colors.IconCircleBgColor }}><SimCardDownloadIcon id='eMed_Header_Icons' sx={{ color: Colors.ThemeColor }} /></Avatar></Tooltip>
          </div>
        </div>
        <div className="eMed_page_header">
          <div id="eMed_table_name">
          </div>
          <div id='eMed_tbl_filters'>
            {this.state.SearchFieldOpen ?
              <div id="eMed_tbl_searchbox" >
                <TextField
                  size='small'
                  focused
                  onChange={(e) => this.setState({ SearchText: e.target.value })}
                  value={this.state.SearchText}
                  label={t("SearchHere")}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <Tooltip disableInteractive title={t("Clear")} placement='top'><CloseIcon sx={{ color: Colors.ThemeColor }} onClick={() => { this.setState({ SearchText: '' }) }} /></Tooltip>
                    ),
                  }}
                />
              </div> : null}
            <Tooltip disableInteractive placement="top-start" title={this.state.SearchFieldOpen ? t("CloseSearch") : t("OpenSearch")}>
              <Button id="eMed_tbl_searchicon" variant='contained' style={{ backgroundColor: Colors.ThemeColor }} onClick={() => this.setState({ SearchFieldOpen: !this.state.SearchFieldOpen, CardOpen: false, SearchText: '' })} >
                {this.state.SearchFieldOpen ? <CloseIcon /> : <SearchIcon />}
              </Button>
            </Tooltip>
          </div>
        </div>
        <Card className='eMed_tbl_card'>
          <TableContainer className='eMed_tbl_container'>
            <Table stickyHeader aria-label="sticky table" size='small' >
              <TableHead>
                <TableRow>
                  <TableCell className="eMed_sticky_start" align='center' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                    <div id='eMed_tbl_headtext'>
                      {t("SNo")}
                    </div>
                  </TableCell>
                  <TableCell className='eMed_sticky_start_head2' id='eMed_patient_details_head' align="center" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                    <div className='eMed_sortable_center'>
                      <div id='eMed_tbl_headtext'> {t('PatientProfile')}</div>
                      <span onClick={() => { sorting("first_name", this.state.NameorderBySort) }}>{SortIcon(this.state.NameSortingIcon)}</span>

                    </div> </TableCell>
                  <TableCell id="eMed_nonsticky_head" align="left" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                    <div id='eMed_tbl_headtext'>{t('Age')}</div>
                  </TableCell>
                  <TableCell id="eMed_nonsticky_head" align="left" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                    <div id='eMed_tbl_headtext'> {t('Tag')}</div>
                  </TableCell>
                  <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" align="center" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                    <div id='eMed_tbl_headtext'> {t("PurposeofVisit")}</div>
                  </TableCell>
                  <TableCell id="eMed_nonsticky_head" align="left" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                    <div id='eMed_tbl_headtext'> {t("Consultant")}</div>
                  </TableCell>
                  <TableCell id="eMed_nonsticky_head" align="center" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                    <div className='eMed_sortable_center'>
                      <div>{t("LastVisitDate")}</div>
                      <span onClick={() => { sorting("lastVisitDate", this.state.DateorderBySort) }}>{SortIcon(this.state.DateSortingIcon)}</span>
                    </div>
                  </TableCell>
                  <TableCell id="eMed_nonsticky_head" align="left" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>{t("Status")}</TableCell>
                  <TableCell className="eMed_sticky_end" align="center" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                    <div className='eMed_sortable_center'>
                      <div>{t("Action")}</div>
                    </div>
                  </TableCell>

                </TableRow>
              </TableHead>

              {filtereddata.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                .map((data, index) => {
                  return (
                    <TableBody key={index}>
                      <TableRow>
                        <TableCell align='center' className="eMed_sticky_startbody">{index + 1}</TableCell>
                        <TableCell align="center" className='eMed_sticky_colbody2'>
                          <div id='eMed_patient_details_body'>
                            <div>
                              {PatientAvatar(data.type, data.gender)}
                            </div>
                            <div id='eMed_patient_details_Text' >
                              <Typography sx={{ color: Colors.TableProfilDetails }} textAlign="left">
                                <div id='eMed_PatientName'>
                                  {data.first_name.length > 15 ?
                                    <Tooltip placement="top" title={data.first_name} disableInteractive>
                                      <div>{data.first_name.slice(0, 15) + '...'}</div>
                                    </Tooltip> : `${data.first_name}`}
                                </div>
                              </Typography>
                              <Typography id='eMed_PatientAcc' sx={{ color: Colors.TableProfilDetails }} textAlign="left">
                                <div>{data.AccNo}</div>
                              </Typography>
                              <Typography id='eMed_PatientMobile' sx={{ color: Colors.TableProfilDetails }} textAlign="left">
                                <div> {data.mobile}</div>
                              </Typography>
                            </div>
                          </div>
                        </TableCell>

                        <TableCell align="left" >
                          <div id='eMed_nonsticky_head'>
                            {data.age}<span>/</span> {data.gender}
                          </div>
                        </TableCell>

                        <TableCell align="left" >
                          <div id='eMed_nonsticky_head'>
                            {PatientTag(data.tag)}
                          </div>
                        </TableCell>


                        <TableCell align="center" sx={{ color: Colors.TableProfilDetails }} >
                          <div id='eMed_nonsticky_head'  >
                            {data.purpose.length > 20 ?
                              <Tooltip placement="top" title={data.purpose} disableInteractive>
                                <div>{data.purpose.slice(0, 20) + '...'}</div>
                              </Tooltip> : data.purpose}
                          </div>
                        </TableCell>

                        <TableCell align="center" sx={{ color: Colors.TableProfilDetails }} >
                          <div id='eMed_nonsticky_head'>
                            {data.consultant}
                          </div>
                        </TableCell>

                        <TableCell align="center" sx={{ color: Colors.TableProfilDetails }} >
                          <div id='eMed_nonsticky_head' >
                            {data.lastVisitDate}
                          </div>
                        </TableCell>

                        <TableCell align="center">
                          <div id='eMed_nonsticky_head'>
                            <Switch sx={{ fontSize: '1vw' }} size='small' checked={data.active} />
                          </div>
                        </TableCell>

                        <TableCell align="center" className='eMed_sticky_endbody'>
                          <div id='eMed_actions_column_body' >
                            <Tooltip placement="top" title={this.state.loggedInuserInfo ? t("Edit") : t("NoAccess")} disableInteractive>
                              <span style={{ cursor: this.state.loggedInuserInfo ? 'pointer' : 'no-drop' }}>
                                <IconButton disabled={!this.state.loggedInuserInfo} >
                                  <Avatar id='eMed_Icons_Avatar' sx={{ bgcolor: Colors.IconCircleBgColor }}>
                                    <EditIcon id='eMed_Icons' sx={{ color: this.state.loggedInuserInfo ? Colors.ThemeColor : "#BFBFBF" }} />
                                  </Avatar>
                                </IconButton>
                              </span>
                            </Tooltip>

                            <Tooltip placement="top" title={this.state.loggedInuserInfo ? t("Delete") : t("NoAccess")} disableInteractive>
                              <span style={{ cursor: this.state.loggedInuserInfo ? 'pointer' : 'no-drop' }}>
                                <IconButton disabled={!this.state.loggedInuserInfo} >
                                  <Avatar id='eMed_Icons_Avatar' sx={{ bgcolor: Colors.IconCircleBgColor }}>
                                    <DeleteIcon id='eMed_Icons' sx={{ color: this.state.loggedInuserInfo ? Colors.ThemeColor : "#BFBFBF" }} />
                                  </Avatar>
                                </IconButton>
                              </span>
                            </Tooltip>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )
                })}
            </Table>
            {filtereddata.length === 0 ?
              <div id='eMed_Norecords_div'>
                {t("NoRecords")}
              </div> : null}
          </TableContainer>
          <TablePagination
            component='div'
            id="eMed_Table_Pagination"
            sx={{
              ".MuiTablePagination-displayedRows": {
                color: Colors.ThemeColor,
                fontSize: "1vw"
              },
              ".MuiTablePagination-selectLabel": {
                color: Colors.ThemeColor,
                fontSize: "1vw"
              },
              "MuiTablePagination-actions": {
                color: Colors.ThemeColor,
              }
            }}
            rowsPerPageOptions={[5, 10, 15]}
            count={this.state.data.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />

        </Card>
      </div>
    )
  }
}
export default withTranslation()(Patient_Profile);