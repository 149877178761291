import React from 'react';
import './OPDashboard.css';
import DoctorBasedPatientChart from './../../Components/DoctorBasedPatientChart';
import PatientsStatusCard from './../../Components/PatientStatusCard/PatientsStatusCard';
import {PatientsTypeCard} from './../../Components/PatientsTypeCard/PatientsTypeCard';
import {PatientTagCard} from './../../Components/PatientTagCard/PatientTagCard';
import RevenueBarChart from './../../Components/RevenueBarChart';
import RevenuePieChart from './../../Components/RevenuePieChart';
import { Button, Paper, Stack } from '@mui/material';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import PieChart from '../Charts/PieChart';
import BarChart from '../Charts/BarChart';
import LineChart from '../Charts/LineChart';
import Skeleton from '@mui/material/Skeleton';
import MaximizeDialogBox from '../../Components/MaximizeDialogBox';

export class OPDashboard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: 0,
            PatientsTypeCard: false,
            RevenueBarChart: false,
            PatientTagCard: false,
            RevenuePieChart: false,
            DoctorBasedPatientChart: false,
            PatientsStatusCard: false
        }
    }
    handleClose = () => {
        this.setState({
            PatientsTypeCard: false,
            RevenueBarChart: false,
            PatientTagCard: false,
            RevenuePieChart: false,
            DoctorBasedPatientChart: false,
            PatientsStatusCard: false
        })
    }
    render() {
        const PatientsTagCard =   [
            {
                PatientCount:3,
                tag:"wheelchair"
            },
            {
                PatientCount:4, 
                tag:"vip"
            },
            {
                PatientCount:5,
                tag:"navy"
            },
            {
                PatientCount:6,
                tag:"isro"
            },
            {
                PatientCount:6,
                tag:"army"
            },
            {
                PatientCount:6,
                tag:"Staff"
            }
        ]

        const PatientTypeCard = [
            {
                icon: "New",
                type: "New Patient",
                PatientCount: 3,
                color: "rgb(247, 16, 12)"
            },
            {
                icon: "Review",
                type: "Review Patient",
                PatientCount: 4,
                color: 'rgb(42, 222, 204)'
            },
            {
                icon: "Established",
                type: "Established",
                PatientCount: 5,
                color: 'rgb(66, 182, 245)'
            },
            {
                icon: "Total",
                type: "Total Patient",
                PatientCount: 6,
                color: 'rgb(245, 39, 145)'
            }
        ]

        const PatientStatusCard = [
            {
              "status": "Booked",
              "PatientCount": "3",
              "color": "rgb(247, 16, 12)"
            },
            {
              "status": "Arrived",
              "PatientCount": "4",
              "color": "rgb(42, 222, 204)"
            },
            {
              "status": "withdoctor",
              "PatientCount": "5",
              "color":"rgb(66, 182, 245)"
            },
            {
              "status": "complete",
              "PatientCount": "6",
              "color":"rgb(245, 39, 145)"
            },
            {
              "status": "cancelled",
              "PatientCount": "7",
              "color":"rgb(247, 16, 12)"
            }
          ]

        return (
            <div>
                <div id='eMed_selectDate'>
                    <div className='eMed_today Date'> Today </div>
                    <div className='eMed_yesterday Date'>Yesterday</div>
                    <div className='eMed_lastWeek Date'>Last Week</div>
                    <div className='eMed_lastMonth Date'>Last Month</div>
                    <div className='eMed_custom Date'>Custom</div>
                </div>
                <div>
                    <div className='eMed_grid_container'>
                        <Paper className='eMed_grid grid-1'>
                            <div style={{ marginLeft: 'auto' }}>
                                <Button onClick={() => this.setState({ PatientsTypeCard: true })} >
                                    <ZoomOutMapIcon id='eMed_ZoomIcon'/>
                                </Button>
                            </div>
                            {this.state.PatientsTypeCard ? <MaximizeDialogBox DialogClose={this.handleClose}
                            header={'PATIENT TYPE'}
                             component={<div className='eMed_PatientType_Component'><PatientsTypeCard data={PatientTypeCard}/></div>} /> : null}
                            {this.state.value === 0 ?
                                <div className='eMed_card card_1'><PatientsTypeCard data={PatientTypeCard}/></div>
                                :
                                <Stack spacing={1}>
                                    <Skeleton variant="text" />
                                    <Skeleton variant="circular" width={40} height={40} />
                                    <Skeleton variant="rectangular" width={510} height={80} />
                                </Stack>
                            }
                        </Paper>
                        <Paper className='eMed_grid grid-2'>
                            <div style={{ marginLeft: 'auto' }}>
                                <Button onClick={() => this.setState({ RevenueBarChart: true })}>
                                    <ZoomOutMapIcon id='eMed_ZoomIcon'/>
                                </Button>
                            </div>
                            {this.state.RevenueBarChart ? <MaximizeDialogBox DialogClose={this.handleClose} header={'DOCTOR BASED REVENUE'} component={<LineChart />} /> : null}
                            {this.state.value === 0 ?
                                <div className='eMed_card card_2'><RevenueBarChart /></div>
                                :
                                <Stack spacing={1}>
                                    <Skeleton variant="text" />
                                    <Skeleton variant="circular" width={40} height={40} />
                                    <Skeleton variant="rectangular" width={220} height={80} />
                                </Stack>
                            }
                        </Paper>
                        <Paper className='eMed_grid grid-3'>
                            <div style={{ marginLeft: 'auto' }}>
                                <Button onClick={() => this.setState({ PatientTagCard: true })}>
                                    <ZoomOutMapIcon id='eMed_ZoomIcon'/>
                                </Button>
                            </div>
                            {this.state.PatientTagCard ? <MaximizeDialogBox DialogClose={this.handleClose}
                            header={'PATIENT TAG'}
                            component={<div className='eMed_PatientTag_Component'><PatientTagCard data={PatientsTagCard}/></div>} /> : null}
                            {this.state.value === 0 ?
                                <div className='eMed_card card_3'><PatientTagCard data={PatientsTagCard} /></div>
                                :
                                <Stack spacing={1}>
                                    <Skeleton variant="text" />
                                    <Skeleton variant="circular" width={40} height={40} />
                                    <Skeleton variant="rectangular" width={220} height={80} />
                                </Stack>
                            }
                        </Paper>
                        <Paper className='eMed_grid grid-4'>
                            <div style={{ marginLeft: 'auto' }}>
                                <Button onClick={() => this.setState({ RevenuePieChart: true })} >
                                    <ZoomOutMapIcon id='eMed_ZoomIcon'/>
                                </Button>
                            </div>
                            {this.state.RevenuePieChart ? <MaximizeDialogBox DialogClose={this.handleClose}
                            header={'REVENUE DETAILS'}
                             component={<div className='eMed_PieChart_Component'><PieChart /></div>} /> : null}
                            {this.state.value === 0 ?
                                <div className='eMed_card card_4'><RevenuePieChart /></div>
                                :
                                <Stack spacing={1}>
                                    <Skeleton variant="text" />
                                    <Skeleton variant="circular" width={40} height={40} />
                                    <Skeleton variant="rectangular" width={220} height={80} />
                                </Stack>
                            }
                        </Paper>
                        <Paper className='eMed_grid grid-5'>
                            <div style={{ marginLeft: 'auto' }}>
                                <Button onClick={() => this.setState({ DoctorBasedPatientChart: true })} >
                                    <ZoomOutMapIcon id='eMed_ZoomIcon'/>
                                </Button>
                            </div>
                            {this.state.DoctorBasedPatientChart ? <MaximizeDialogBox DialogClose={this.handleClose} header={'DOCTOR BASED PATIENTS'} component={<BarChart />} /> : null}
                            {this.state.value === 0 ?
                                <div className='eMed_card card_5'><DoctorBasedPatientChart /></div>
                                :
                                <Stack spacing={1}>
                                    <Skeleton variant="text" />
                                    <Skeleton variant="circular" width={40} height={40} />
                                    <Skeleton variant="rectangular" width={515} height={80} />
                                </Stack>
                            }
                        </Paper>
                        <Paper className='eMed_grid grid-6'>
                            <div style={{ marginLeft: 'auto' }}>
                                <Button onClick={() => this.setState({ PatientsStatusCard: true })} >
                                    <ZoomOutMapIcon id='eMed_ZoomIcon'/>
                                </Button>
                            </div>
                            {this.state.PatientsStatusCard ? <MaximizeDialogBox DialogClose={this.handleClose}
                            header={'PATIENT STATUS'}
                             component={<div id='eMed_StatusCard_Component'><PatientsStatusCard data={PatientStatusCard}/></div>} /> : null}
                            {this.state.value === 0 ?
                                <div className='eMed_card card_5'><PatientsStatusCard data={PatientStatusCard} /></div>
                                :
                                <Stack spacing={1}>
                                    <Skeleton variant="text" />
                                    <Skeleton variant="circular" width={40} height={40} />
                                    <Skeleton variant="rectangular" width={220} height={80} />
                                </Stack>
                            }
                        </Paper>
                    </div>
                </div>
            </div>
        );
    };
};