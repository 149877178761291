import * as React from 'react';
import ComingSoon from '../../Upcomingpages/ComingSoon';

class VendorManagementHome extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <ComingSoon />
        )
    }

}

export default VendorManagementHome;