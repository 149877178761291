import React from 'react';
import Soon from '../../Assets/Animation/Soon.gif';

class ComingSoon extends React.Component {
    render() {
        return (
            <h1 className='soonpage'>
                <img alt='Coming Soon...' style={{ height: "40vh" }} src={Soon} />
            </h1>
        )
    }
}
export default ComingSoon;