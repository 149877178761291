import React, { Component } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import "../StockAdjustment/indexTable.css";
import { Colors } from '../../../../Styles/Colors';
import EditIcon from '@mui/icons-material/Edit';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Avatar from '@mui/material/Avatar';
import { IconButton, TablePagination } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import { Trans, withTranslation } from 'react-i18next';
import History from './History';
import ModelDialogBox from '../../../../Components/ModelDialogBox/ModelDialogBox';
import StockAdjustmentForm from './StockAdjustmentForm';
import { Button } from '@mui/material';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import Dialog from '../../../../Components/Dialog'
import Failed from '../../../../Assets/Animation/Failed.gif';
import { AmountFormat, DosageType } from '../../../../Components/CommonFunctions/CommonFunctions';
import { localGetItem } from '../../../../Utility/Services/CacheProviderService';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';


class StockAdjustment extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Data: [],
      DataDefault: [],
      page: 0,
      rowsPerPage: 5,
      BrandNameSortOrder: 'asc',
      GenericNameSortOrder: 'asc',
      ExpiryDateSortOrder: 'asc',
      QuantitySortOrder: 'asc',
      BrandNameSortIcon: 'none',
      ExpiryDateSortIcon: 'none',
      GenericNameSortIcon: 'none',
      QuantitySortIcon: 'none',
      SearchFieldOpen: false,
      SearchText: "",
      StockAdjustmentHistory: false,
      StockAdjustmentForm: false,
      StockAdjustmentFormClose: false,
      StockAdjustmentEditdtails: "",
      pharmacyID: 0,
      dialogBox: false,
      historydata: '',
      filterDataList: {},
      EditAccess: false,
      ViewAccess: false

    }
  }

  componentDidMount() {
    this.getStockList()
    this.GetRoleName()
  }

  componentDidUpdate(prvProps) {
    if (this.props.filterData != prvProps.filterData) {
      this.setState({
        filterDataList: this.props.filterData
      }, () => this.getFilterData())
    }
  }

  /*Get Table API data Function start */
  getStockList = () => {
    try {
      RestAPIService.getAll(Serviceurls.GET_STOCK_ADJUST_LIST + `?pharmacy_id=${localGetItem("Pharmacy_Id")}`)
        .then(response => {
          this.setState({
            Data: response.data.data.stock_list_items,
            DataDefault: response.data.data.stock_list_items,
            pharmacyID: response.data.data.pharmacy_id,
            historyList: response.data.data
          })

        })
        .catch(e => {
          this.setState({ dialogBox: true, ErrorMsg: e.message });
          setTimeout(() => {
            this.setState({
              dialogBox: false
            })
          }, 3000)
        });

    }
    catch (e) {
      this.setState({ dialogBox: true, ErrorMsg: e.message });
      setTimeout(() => {
        this.setState({
          dialogBox: false
        })
      }, 3000)
    }
  }
  /*Get Table API data Function end */
  // Get Selected Dosage Type List
  filterDosageType = () => {
    let dosagetypeList = [];
    if (this.state.filterDataList.dosageType.length > 0) {
      this.state.filterDataList.dosageType.forEach(element => {
        dosagetypeList.push(element.dosage_type);
      });
    } else {

    }
    return dosagetypeList;
  }

  /* Filter Table data API Call start */
  getFilterData = () => {
    try {
      let filterData = {
        "dosage_type": this.filterDosageType(),
        "batch_no": this.state.filterDataList.batchNumber,
        "from_qty": parseInt(this.state.filterDataList.fromQuantity),
        "to_qty": parseInt(this.state.filterDataList.toQuantity),
        "from_mrp": parseInt(this.state.filterDataList.fromMRPunit),
        "to_mrp": parseInt(this.state.filterDataList.toMRPunit),
        "from_price": parseInt(this.state.filterDataList.fromBuyingprice),
        "to_price": parseInt(this.state.filterDataList.toBuyingprice),
        "from_date": this.state.filterDataList.QueryFromDate,
        "to_date": this.state.filterDataList.QueryToDate
      }
      if (filterData["dosage_type"].length < 0) {
        delete filterData["dosage_type"];
      }
      if (filterData["batch_no"] === "") {
        delete filterData["batch_no"];
      }
      if (filterData["from_qty"] === 0 && filterData["to_qty"] === 0) {
        delete filterData["from_qty"];
        delete filterData["to_qty"];
      }
      if (filterData["from_mrp"] === 0 && filterData["to_mrp"] === 0) {
        delete filterData["from_mrp"];
        delete filterData["to_mrp"];
      }
      if (filterData["from_price"] === 0 && filterData["to_price"] === 0) {
        delete filterData["from_price"];
        delete filterData["to_price"];
      }
      if (filterData["from_date"] === "" && filterData["to_date"] === "") {
        delete filterData["from_date"];
        delete filterData["to_date"];
      }
      RestAPIService.getListbyData(`?pharmacy_id=${localGetItem("Pharmacy_Id")}`, {
        params: filterData
      }, Serviceurls.GET_STOCK_ADJUST_LIST)
        .then(response => {
          this.setState({
            Data: response.data.data.stock_list_items
          })
        })
        .catch(e => {
          this.setState({ dialogBox: true, ErrorMsg: e.message });
          setTimeout(() => {
            this.setState({
              dialogBox: false
            })
          }, 3000)
        });
    }
    catch (e) {
      this.setState({ dialogBox: true, ErrorMsg: e.message });
      setTimeout(() => {
        this.setState({
          dialogBox: false
        })
      }, 3000)
    }

  }
  /* Filter Table data API Call end */

  handleClose = () => {
    this.setState({
      StockAdjustmentHistory: false,
      StockAdjustmentForm: false
    })
  }

  dialogCloseFunction = () => {
    this.setState({ dialogBox: false })
  }

  //  Mui table Pagination Functions --- starts

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 });
  }

  //  Mui table Pagination Functions --- ends

  closeEditForm = (isClosed) => {
    this.setState({
      StockAdjustmentForm: isClosed
    })
    this.getStockList()
  }

  // Role Based Access for Action Buttons Function ---- Starts
  GetRoleName = () => {
    var GetUserData = localGetItem("loggedInUserInfo")
    var UserInfo = JSON.parse(GetUserData)
    var RoleName = UserInfo.login_user_role_name

    if (RoleName === "SITE_SUPER_ADMIN") {
      this.setState({
        EditAccess: true,
        ViewAccess: true
      })
    }
    else if (RoleName === "SITE_ADMIN") {
      this.setState({
        EditAccess: true,
        ViewAccess: true
      })
    }
    else if (RoleName === "ADMIN") {
      this.setState({
        EditAccess: false,
        ViewAccess: true
      })
    }
  }
  // Role Based Access for Action Buttons Function ---- Starts

  render() {

    const { t } = this.props
    const DataArray = this.state.Data
    // Sorting Function   ==== starts
    const sorting = (col, SortOrder) => {
      if (SortOrder === "asc") {
        const Sorted = [...this.state.Data].sort((a, b) => {
          if (typeof (a[col]) == "string") {
            return a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
          } else {
            return a[col] > b[col] ? 1 : -1
          }
        }
        )
        this.setState({ Data: Sorted })

        if (col === "drug_name") {
          this.setState({ BrandNameSortOrder: 'desc', ExpiryDateSortOrder: 'asc', QuantitySortOrder: 'asc', GenericNameSortOrder: 'asc', BrandNameSortIcon: "asc", ExpiryDateSortIcon: "none", GenericNameSortIcon: 'none', QuantitySortIcon: 'none', })
        }
        else if (col === "generic_name") {
          this.setState({ GenericNameSortOrder: 'desc', ExpiryDateSortOrder: 'asc', QuantitySortOrder: 'asc', BrandNameSortOrder: 'asc', GenericNameSortIcon: "asc", ExpiryDateSortIcon: "none", BrandNameSortIcon: 'none', QuantitySortIcon: 'none', })
        }
        else if (col === "expiry_date") {
          this.setState({ ExpiryDateSortOrder: 'desc', GenericNameSortOrder: 'asc', QuantitySortOrder: 'asc', BrandNameSortOrder: 'asc', ExpiryDateSortIcon: "asc", GenericNameSortIcon: "none", BrandNameSortIcon: 'none', QuantitySortIcon: 'none', })
        }
        else if (col === "stock_quantity") {
          this.setState({ QuantitySortOrder: 'desc', GenericNameSortOrder: 'asc', ExpiryDateSortOrder: 'asc', BrandNameSortOrder: 'asc', QuantitySortIcon: "asc", GenericNameSortIcon: "none", BrandNameSortIcon: 'none', ExpiryDateSortIcon: 'none', })
        }

      }
      else if (SortOrder === "desc") {
        const Sorted = [...this.state.Data].sort((a, b) => {
          if (typeof (a[col]) == "string") {
            return a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
          } else {
            return a[col] < b[col] ? 1 : -1
          }
        }
        )
        this.setState({ Data: Sorted })

        if (col === "drug_name") {
          this.setState({ BrandNameSortOrder: 'default', BrandNameSortIcon: "desc" })
        }
        else if (col === "generic_name") {
          this.setState({ GenericNameSortOrder: 'default', GenericNameSortIcon: "desc" })
        }
        else if (col === "expiry_date") {
          this.setState({ ExpiryDateSortOrder: 'default', ExpiryDateSortIcon: "desc" })
        }
        else if (col === "stock_quantity") {
          this.setState({ QuantitySortOrder: 'default', QuantitySortIcon: "desc" })
        }
      }
      else if (SortOrder === "default") {
        this.setState({
          BrandNameSortOrder: 'asc',
          GenericNameSortOrder: 'asc',
          ExpiryDateSortOrder: 'asc',
          QuantitySortOrder: 'asc',
          BrandNameSortIcon: 'none',
          ExpiryDateSortIcon: 'none',
          GenericNameSortIcon: 'none',
          QuantitySortIcon: 'none',
          Data: DataArray
        })

      }
    }
    // Sorting Function   ==== ends

    //  Sorting Icons Change func === starts 
    const SortIcon = (sortType) => {

      if (sortType === "asc") {
        return <Tooltip disableInteractive placement='top' title={t("InAscendingOrder")}><KeyboardArrowUpIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
      }
      else if (sortType === 'desc') {
        return <Tooltip disableInteractive placement='top' title={t("InDecendingOrder")}><KeyboardArrowDownIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
      }
      else return <Tooltip disableInteractive placement='top' title={t("Sorting")}><FilterListIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
    }

    //  Sorting Icons Change func === ends 


    //  filter table data based on search Text
    const filtereddata = this.state.Data.filter((data) => {
      return (
        (data.drug_name === null ? null : data.drug_name.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
        (data.generic_name === null ? null : data.generic_name.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
        (data.dosage_type === null ? null : data.dosage_type.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
        (data.drug_strength === null ? null : data.drug_strength.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
        (data.schedule === null ? null : data.schedule.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
        (data.batch_no === null ? null : data.batch_no.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
        (data.expiry_date === null ? null : data.expiry_date.includes(this.state.SearchText)) ||
        (data.stock_quantity === null ? null : data.stock_quantity === parseInt(this.state.SearchText)) ||
        (data.free_quantity === null ? null : data.free_quantity === parseInt(this.state.SearchText)) ||
        (data.mrp_per_quantity === null ? null : Math.round(data.mrp_per_quantity * 100) === parseInt(Math.round(this.state.SearchText * 100))) ||
        (data.buying_price === null ? null : Math.round(data.buying_price * 100) === parseInt(Math.round(this.state.SearchText * 100)))
      );
    });

    // Search Icon OnClick Function == ends



    return (

      <div>

        <div className="eMed_page_header">
          <div id="eMed_table_name" style={{ color: Colors.TextColor }}>
            {t("StockAdjustment")}
          </div>
          <div id='eMed_tbl_filters'>
            {this.state.SearchFieldOpen ?
              <div id="eMed_tbl_searchbox" >
                <TextField
                  sx={{ input: { color: Colors.TextColor } }}
                  size='small'
                  focused
                  onChange={(e) => this.setState({ SearchText: e.target.value })}
                  value={this.state.SearchText}
                  label={t("SearchHere")}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <Tooltip disableInteractive title={t("Clear")} placement='top'><CloseIcon sx={{ color: Colors.ThemeColor }} onClick={() => { this.setState({ SearchText: '' }) }} /></Tooltip>
                    ),
                  }}
                />
              </div> : null}
            <Tooltip disableInteractive placement="top-start" title={this.state.SearchFieldOpen ? t("CloseSearch") : t("OpenSearch")}>
              <Button id="eMed_tbl_searchicon" variant='contained' style={{ backgroundColor: Colors.ThemeColor }} onClick={() => this.setState({ SearchFieldOpen: !this.state.SearchFieldOpen, CardOpen: false, SearchText: '' })} >
                {this.state.SearchFieldOpen ? <CloseIcon /> : <SearchIcon />}
              </Button>
            </Tooltip>
          </div>
        </div>


        <div>
          <Card className='eMed_tbl_card' sx={{ bgcolor: Colors.TableCard }} >
            <TableContainer className='eMed_tbl_container'>
              <Table stickyHeader aria-label="sticky table" size='small' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>
                <TableHead>
                  <TableRow>
                    <TableCell className='eMed_sticky_start eMed_tbl_headwidth_small' align='center' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div id='eMed_tbl_headtext' className='eMed_sortable_center' style={{ color: Colors.TableHeaderTextColor }}>
                        {t("SNo")}
                      </div>
                    </TableCell>
                    <TableCell className='eMed_tbl_headwidth_large eMed_sticky_start_head2' align='center' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div className='eMed_sortable_name'>
                        <div id='eMed_tbl_headtext' style={{ color: Colors.TableHeaderTextColor }} >{t("BrandName")}</div>
                        <div id="eMed_sorting_icon" onClick={() => { sorting("drug_name", this.state.BrandNameSortOrder) }}>{SortIcon(this.state.BrandNameSortIcon)}</div>
                      </div>
                    </TableCell>
                    <TableCell className='eMed_tbl_headwidth_large' id='eMed_nonsticky_head' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div className='eMed_sortable_name'>
                        <div id='eMed_tbl_headtext' style={{ color: Colors.TableHeaderTextColor }}>{t("GenericName")} </div>
                        <div id="eMed_sorting_icon" onClick={() => { sorting("generic_name", this.state.GenericNameSortOrder) }}>{SortIcon(this.state.GenericNameSortIcon)}</div>
                      </div>
                    </TableCell>
                    <TableCell className='eMed_tbl_headwidth_large' id='eMed_nonsticky_head' align='right' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div className='eMed_sortable_center' id='eMed_tbl_headtext' style={{ color: Colors.TableHeaderTextColor }}>{t("DosageType")}</div>
                    </TableCell>
                    <TableCell className='eMed_tbl_headwidth_small' id='eMed_nonsticky_head' align='center' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div className='eMed_sortable_center' id='eMed_tbl_headtext' style={{ color: Colors.TableHeaderTextColor }}>{t("Strength")}</div>
                    </TableCell>
                    <TableCell className='eMed_tbl_headwidth_small' id='eMed_nonsticky_head' align='center' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div className='eMed_sortable_center' id='eMed_tbl_headtext' style={{ color: Colors.TableHeaderTextColor }}> {t("Schedule")}</div>
                    </TableCell>
                    <TableCell className='eMed_tbl_headwidth_small' id='eMed_nonsticky_head' align='center' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div className='eMed_sortable_center' id='eMed_tbl_headtext' style={{ color: Colors.TableHeaderTextColor }}> {t("BatchNo")}</div>
                    </TableCell>
                    <TableCell className='eMed_tbl_headwidth_large' id='eMed_nonsticky_head' align='center' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div className='eMed_sortable_center'>
                        <div id='eMed_tbl_headtext' style={{ color: Colors.TableHeaderTextColor }}>{t("ExpiryDate")}</div>
                        <div id="eMed_sorting_icon" onClick={() => { sorting("expiry_date", this.state.ExpiryDateSortOrder) }}>{SortIcon(this.state.ExpiryDateSortIcon)}</div>
                      </div>
                    </TableCell>
                    <TableCell className='eMed_tbl_headwidth_large' id='eMed_nonsticky_head' align='center' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div className='eMed_sortable_number'>
                        <div id='eMed_tbl_headtext' style={{ color: Colors.TableHeaderTextColor }}>{t("AvailQuantity")}</div>
                        <div id="eMed_sorting_icon" onClick={() => { sorting("stock_quantity", this.state.QuantitySortOrder) }}>{SortIcon(this.state.QuantitySortIcon)}</div>
                      </div>
                    </TableCell>
                    <TableCell className='eMed_tbl_headwidth_small' id='eMed_nonsticky_head' align='right' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div className='eMed_sortable_number' id='eMed_tbl_headtext' style={{ color: Colors.TableHeaderTextColor }}> {t("FreeQty")}</div>
                    </TableCell>
                    <TableCell className='eMed_tbl_headwidth_small' id='eMed_nonsticky_head' align='right' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div id='eMed_tbl_headtext' style={{ color: Colors.TableHeaderTextColor }}>{t("MRP/Qty")}(₹)</div>
                    </TableCell>
                    <TableCell className='eMed_tbl_headwidth_large' id='eMed_nonsticky_head' align='right' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div className='eMed_sortable_number' id='eMed_tbl_headtext' style={{ color: Colors.TableHeaderTextColor }}> {t("BuyingPrice")}</div>
                    </TableCell>
                    <TableCell className='eMed_tbl_headwidth_large eMed_sticky_end' align='center' sx={{ bgcolor: Colors.TableHeaderBarBGColor, fontSize: '1vw' }}>
                      <div className='eMed_sortable_center' id='eMed_tbl_headtext' style={{ color: Colors.TableHeaderTextColor }}>
                        {t("Action")}
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {filtereddata.length > 0 ? filtereddata.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((data, index) => (
                  <TableBody>
                    <TableRow>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='center' id='eMed_tbl_bodytext' className="eMed_sticky_startbody eMed_sNo_cell">{index + 1}</TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='left' className='eMed_sticky_startbody_body2'>
                        {data.drug_name.length > 15 ?
                          <Tooltip placement="top" title={data.drug_name} disableInteractive>
                            <div id='eMed_tbl_bodytext'>{data.drug_name.slice(0, 15) + '...'}</div>
                          </Tooltip> : <div id='eMed_tbl_bodytext'> {data.drug_name}</div>}
                      </TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='left'>
                        {data.generic_name.length > 15 ?
                          <Tooltip placement="top" title={data.generic_name} disableInteractive>
                            <div id='eMed_tbl_bodytext'>{data.generic_name.slice(0, 15) + '...'}</div>
                          </Tooltip> : <div id='eMed_tbl_bodytext'>{data.generic_name}</div>}
                      </TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='center' id='eMed_tbl_bodytext'>{DosageType(data.dosage_type)} </TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='center' id='eMed_tbl_bodytext'>{data.drug_strength}</TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='center' id='eMed_tbl_bodytext'>
                        {data.schedule === "" ? "-" : data.schedule}
                      </TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='center' id='eMed_tbl_bodytext'>{data.batch_no}</TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='center' id='eMed_tbl_bodytext'>{data.expiry_date}</TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='right' id='eMed_tbl_bodytext'>{data.stock_quantity}</TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='right' id='eMed_tbl_bodytext'>{data.free_quantity}</TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='right' id='eMed_tbl_bodytext'>{AmountFormat(data.mrp_per_quantity)}</TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='right' id='eMed_tbl_bodytext'> {AmountFormat(data.buying_price)}</TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='center' id='eMed_tbl_bodytext' className='eMed_sticky_endbody'>
                        <div id='eMed_actions_column_body'>
                          <Tooltip disableInteractive title={this.state.EditAccess ? t("EditStock") : t("NoAccess")} placement='top'>
                            <span style={{ cursor: this.state.EditAccess ? 'pointer' : 'no-drop' }}>
                              <IconButton onClick={() => this.setState({ StockAdjustmentForm: true, StockAdjustmentEditdtails: data })} disabled={!this.state.EditAccess}>
                                <Avatar id='eMed_Icons_Avatar' sx={{ bgcolor: Colors.IconCircleBgColor, cursor: this.state.EditAccess ? 'pointer' : 'no-drop' }}>
                                  <EditIcon sx={{ color: this.state.EditAccess ? Colors.ThemeColor : '#BFBFBF' }} />
                                </Avatar>
                              </IconButton>
                            </span>
                          </Tooltip>
                          <Tooltip disableInteractive title={this.state.ViewAccess ? t("ViewHistory") : t("NoAccess")} placement='top'>
                            <span style={{ cursor: this.state.ViewAccess ? 'pointer' : 'no-drop' }}>
                              <IconButton onClick={() => this.setState({ StockAdjustmentHistory: true, historydata: data })} disabled={!this.state.ViewAccess}>
                                <Avatar id='eMed_Icons_Avatar' sx={{ bgcolor: Colors.IconCircleBgColor, cursor: this.state.ViewAccess ? 'pointer' : 'no-drop' }}>
                                  <RemoveRedEyeIcon sx={{ color: this.state.ViewAccess ? Colors.ThemeColor : '#BFBFBF' }} />
                                </Avatar>
                              </IconButton>
                            </span>
                          </Tooltip>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )) :
                  <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_Norecords'>
                    {t("NoRecords")}
                  </div>}
              </Table>
            </TableContainer>
            {this.state.StockAdjustmentForm ? <ModelDialogBox DialogClose={this.handleClose} header={t('StockAdjustment')} component={<StockAdjustmentForm editClose={this.closeEditForm} Editdetails={this.state.StockAdjustmentEditdtails} pharmacyId={this.state.pharmacyID} />} /> : null}
            {this.state.StockAdjustmentHistory ? <ModelDialogBox DialogClose={this.handleClose} header={t('History')} history={this.state.historydata} component={<History pharmacyId={this.state.pharmacyID} history={this.state.historydata} />} /> : null}
            <TablePagination
              component='div'
              id="eMed_Table_Pagination"
              sx={{
                color: Colors.PagiantionTxtColor
              }}
              rowsPerPageOptions={[5, 10, 15]}
              count={filtereddata.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </Card>
          {this.state.dialogBox ?
            <Dialog
              type={"Notification"}
              DialogClose={this.dialogCloseFunction}
              header={"Message"}
              content={this.state.ErrorMsg}
              icon={<img className="eMed_dilboxAnimations" alt="Warning" src={Failed} />}
              secondary={"Close"}
            /> : null}
        </div>
      </div>
    )
  }
}
export default withTranslation()(StockAdjustment);