import { localGetItem } from "../Utility/Services/CacheProviderService";

const setTableHeaderColor = () => {
    if (localGetItem("theme") === "default") {
        return "#EDFFFE"
    }
    else if (localGetItem("theme") === "light") {
        return "#E8E8E8 "
    }
    else if (localGetItem("theme") === "dark") {
        return  "#17202A"
    }
}

const setThemeColor = () => {
    if (localGetItem("theme") === "default") {
        return "#04B7B1"
    }
    else if (localGetItem("theme") === "light") {
        return "#B6B6B6"
    }
    else if (localGetItem("theme") === "dark") {
        return "#000000"
    }
}

const setTableHeadTextColor = () => {
    if (localGetItem("theme") === "default") {
        return "#1a1917"
    }
    else if (localGetItem("theme") === "light") {
        return "#1a1917"
    }
    else if (localGetItem("theme") === "dark") {
        return "#ffffff"
    }
}

const setTableDataTextColor = () => {
    if (localGetItem("theme") === "default") {
        return "#2F2A53"
    }
    else if (localGetItem("theme") === "light") {
        return "#1a1917"
    }
    else if (localGetItem("theme") === "dark") {
        return "#ffffff"
    }
}
const setConstrastColor = () => {

    if (localGetItem("theme") === "default") {
        return "#ffffff"
    }
    else if (localGetItem("theme") === "light") {
        return "#1a1917"
    }
    else if (localGetItem("theme") === "dark") {
        return "#ffffff"
    }
}
const setHoverColor = () => {

    if (localGetItem("theme") === "default") {
        return "#E9FCF9 "
    }
    else if (localGetItem("theme") === "light") {
        return "#E5DEDE"
    }
    else if (localGetItem("theme") === "dark") {
        return "#504F4F"
    }
}
const setBgColor = () => {

    if (localGetItem("theme") === "default") {
        return "#ffffff"
    }
    else if (localGetItem("theme") === "light") {
        return "#ffffff"
    }
    else if (localGetItem("theme") === "dark") {
        return "#212F3D"
    }
}
const setListItemsColor = () => {

    if (localGetItem("theme") === "default") {
        return "#1a1917"
    }
    else if (localGetItem("theme") === "light") {
        return "#1a1917"
    }
    else if (localGetItem("theme") === "dark") {
        return "#ffffff"
    }
}
const setListIconColor = () => {

    if (localGetItem("theme") === "default") {
        return "#04B7B1"
    }
    else if (localGetItem("theme") === "light") {
        return "#E8E8E8 "
    }
    else if (localGetItem("theme") === "dark") {
        return "#ffffff"
    }
}
const setIconBgIcon = () => {

    if (localGetItem("theme") === "default") {
        return "#ffffff"
    }
    else if (localGetItem("theme") === "light") {
        return "#ffffff"
    }
    else if (localGetItem("theme") === "dark") {
        return "#ffffff"
    }
}

const setTableBody = () => {

    if (localGetItem("theme") === "default") {
        return "#ffffff"
    }
    else if (localGetItem("theme") === "light") {
        return "#ffffff"
    }
    else if (localGetItem("theme") === "dark") {
        return "#121212"
    }
}
const setTextColor = () => {

    if (localGetItem("theme") === "default") {
        return "#000000"
    }
    else if (localGetItem("theme") === "light") {
        return "#000000"
    }
    else if (localGetItem("theme") === "dark") {
        return "#ffffff"
    }
}
const setHighlightColor = () => {

    if (localGetItem("theme") === "default") {
        return "#04B7B1"
    }
    else if (localGetItem("theme") === "light") {
        return "#B6B6B6"
    }
    else if (localGetItem("theme") === "dark") {
        return  '#04B7B1'
    }
}
const settabsColor = () => {

    if (localGetItem("theme") === "default") {
        return "#000000"
    }
    else if (localGetItem("theme") === "light") {
        return "#000000"
    }
    else if (localGetItem("theme") === "dark") {
        return  '#ffffff'
    }
}
const setTopDivbackgound = () => {

    if (localGetItem("theme") === "default") {
        return "#ffffff"
    }
    else if (localGetItem("theme") === "light") {
        return "#ffffff"
    }
    else if (localGetItem("theme") === "dark") {
        return  "#17202A"
    }
}
const setPagiantionTxtColor = () => {

    if (localGetItem("theme") === "default") {
        return  "#04B7B1"
    }
    else if (localGetItem("theme") === "light") {
        return "#B6B6B6"
    }
    else if (localGetItem("theme") === "dark") {
        return  "#ffffff"
    }
}
const setSortingIconColor = () => {

    if (localGetItem("theme") === "default") {
        return "#04B7B1"
    }
    else if (localGetItem("theme") === "light") {
        return "#B6B6B6"
    }
    else if (localGetItem("theme") === "dark") {
        return  "#ffffff"
    }
}
const setTableCard = () => {

    if (localGetItem("theme") === "default") {
        return "#ffffff"
    }
    else if (localGetItem("theme") === "light") {
        return "#ffffff"
    }
    else if (localGetItem("theme") === "dark") {
        return  "#17202A"
    }
}
const setSecondaryColor = () => {

    if (localGetItem("theme") === "default") {
        return "#04B7B1"
    }
    else if (localGetItem("theme") === "light") {
        return "#B6B6B6"
    }
    else if (localGetItem("theme") === "dark") {
        return  "#ffffff"
    }
}
const setPopupBackground = () => {

    if (localGetItem("theme") === "default") {
        return "#ffffff"
    }
    else if (localGetItem("theme") === "light") {
        return "#ffffff"
    }
    else if (localGetItem("theme") === "dark") {
        return  "#566573"
    }
}
const setIconColor = () => {

    if (localGetItem("theme") === "default") {
        return "#04B7B1"
    }
    else if (localGetItem("theme") === "light") {
        return "#B6B6B6"
    }
    else if (localGetItem("theme") === "dark") {
        return  "#ffffff"
    }
}

const setHelperText = () => {

    if (localGetItem("theme") === "default") {
        return "#000000"
    }
    else if (localGetItem("theme") === "light") {
        return "#000000"
    }
    else if (localGetItem("theme") === "dark") {
        return "#ffffff"
    }
}




export const Colors = {

    // Choose Colors Based On Theme

    TableHeaderBarBGColor: setTableHeaderColor(),
    ThemeColor: setThemeColor(),
    SecondaryColor: setSecondaryColor(),
    TableHeaderTextColor: setTableHeadTextColor(),
    TableProfilDetails: setTableDataTextColor(),
    HoverColor: setHoverColor(),
    BgColor: setBgColor(),
    ListItems: setListItemsColor(),
    ListIcons: setListIconColor(),
    IconCircleBgColor: setIconBgIcon(),
    TableBody: setTableBody(),
    ContrastText: setConstrastColor(),
    HelperText: setHelperText(),
    TextColor: setTextColor(),
    HighlightColor: setHighlightColor(),
    TabsColor: settabsColor(),
    pageTopBackground: setTopDivbackgound(),
    PagiantionTxtColor: setPagiantionTxtColor(),
    SortIconColor: setSortingIconColor(),
    TableCard: setTableCard(),
    PopupBackground: setPopupBackground(),
    IconColor: setIconColor(),

    // ============================



    SideMenuAndMainHeadingText: "#2F2A53",
    Tabs: "#1A1A1A",
    DateFilter: "#696969",
    CardMAinHeading: "#04B7B1",
    SideMenuBranchHeading: "#2F2A53",
    DashBoardCardContents: "#696969",
    SelectedItemsBGColor: "#04B7B1",
    SelectedItemsBGText: "#FFFFFF",
    TableContentsTextColor: "#2E2E2E",
    HeaderBarBGColor: "#EDFFFE",
    HeaderBarTextColor: "#1A1A1A",
    defaulttheme: '#04B7B1',


    black: "#1a1917",
    themeBlue: "#262941",
    themePink: "#F25364",
    white: "#ffffff",
    red: "#F1584A",
    themeOpthamology: "#262941",
    lightGray: "#ccccb3",
    lightBlue: "#29B6F6",
    darkViolet: "#9400D3",
    unselectedSectionTitle: "#D9D9D9",
    drNotesUnSelectedTitle: "#1a1917",
    notesTableTitle: "#848484",
    notesTableData: "#2F2F2F",
    sectionItemLine: "#E0E0E0",
    appointmentTableHead: "#EEEEEE",
    appointmentTableHeadText: "#4F5A69",
    darkBlack: "#292929",
    textBoxBorderColor: "#CDD1D5",
    // side menu Unselected Color
    themeUnselectIcon: "#B3D9FE",
    rightPanelInputBorder: "#B3D9FE",
    // blue theme
    //themeDark------ 2F92F5
    themeDark: "#04b7b1",
    themeBackgroud: "#04b7b1",
    themeShade: "#F8FCFF", // DR Right side view back ground
    themeShadeBackground: "#F3F8FD",        // DR notes  right side button background
    rightPanelLabel: "#04b7b1",
    //search text
    textcolor: "#363636",
    subtextcolor: "#898989",
    placeholder: "#898989",
    boxColor: "#898989",
    themeBackgroudMessage: "B3D9FE",
    searchBarBackground: "#E8ECF1",
    rightPanelLabel: "#04b7b1",
    disableComponentColor: '#EAEAEA',
    disableLabelColor: '#A9A9A9',
    // new screen Section Dark Background
    sectionHeadingDarkColor: "#E2E2E2",
    sectionHeadingLiteColor: "#F0F0F0",
    sectionHeadingTextColor: "#B6BBC0",
    noButtonColor: "#F28D99",
    applicationBackgroundColor: "#EFEFEF",
    sectionBackgroundColor: "#FFFFFF",
    newPencilColor: "#000249",
    newTrashColor: "#DD1717",
    grayShade: "#888888",
    // PaymentCard Colors
    blue: "#0963FF",
    orange: "#FF7723",
    liteViolet: "#8E30FF",
    liteGreen: "#B8D336",
    brown: "#813919"
};