import React, { Component } from 'react';
import { Button, Card, FormControl, FormHelperText, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { Colors } from '../../../../../Styles/Colors';
import RestAPIService from '../../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../../Utility/API/Serviceurls';
import { localGetItem } from '../../../../../Utility/Services/CacheProviderService';
import { AmountFormat } from '../../../../../Components/CommonFunctions/CommonFunctions';
import Dialog from '../../../../../Components/Dialog';
import Failed from "../../../../../Assets/Animation/Failed.gif";
import DatePicker from "../../../../../Components/DatePicker/DatePicker";
import { DateTime } from "luxon";
import StockData from "../StockData.json"
import { DosageType } from '../../../../../Components/CommonFunctions/CommonFunctions';
import { Trans, withTranslation } from 'react-i18next';

class AdjustmentReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: 5,
      BrandNameSortOrder: 'asc',
      GenericNameSortOrder: 'asc',
      ExpiryDateSortOrder: 'asc',
      AdjustedDateSortOrder: 'asc',
      MrpUnitSortOrder: 'asc',
      BrandNameSortIcon: 'none',
      ExpiryDateSortIcon: 'none',
      AdjustedDateSortIcon: 'none',
      GenericNameSortIcon: 'none',
      MrpUnitSortIcon: 'none',
      SearchFieldOpen: false,
      SearchText: "",
      loggedInuserInfo: true,
      datePickerPop: false,
      dateFilter: 0,
      QueryFrmDte: "",
      QueryEndDte: "",
      StockAdjustment: StockData.data.result,
      DefaultStockAdjustment: [],
      Selectdate: "",
      SelectdatePop: false,
      FrmDate: new Date(),
      dialogBox: false
    }
  }

  // componentDidMount() {
  //   this.getAdjustmentReport();
  // }

  // getAdjustmentReport = () => {
  //   try {
  //     RestAPIService.getAll(Serviceurls.PHARMACY_REPORT_SlowMovement_Stock + `?pharmacy_id=${this.state.pharmacyId}&from_date=${this.state.QueryFrmDte}&to_date=${this.state.QueryToDte}`)
  //       .then(response => {
  //         this.setState({
  //           StockAdjustment: response.data.data,
  //           DefaultStockAdjustment: response.data.data
  //         })
  //         console.log(this.state.StockAdjustment)
  //       })
  //       .catch(e => {
  //         this.setState({ dialogBox: true, ErrorMsg: e.message });
  //         setTimeout(() => {
  //           this.setState({
  //             dialogBox: false
  //           })
  //         }, 3000)
  //       });
  //   }
  //   catch (e) {
  //     this.setState({ dialogBox: true, ErrorMsg: e.message });
  //     setTimeout(() => {
  //       this.setState({
  //         dialogBox: false
  //       })
  //     }, 3000)
  //   }
  // }

  //  Mui table Pagination Functions --- starts

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 });
  }

  //  Mui table Pagination Functions --- ends

  PopClose = () => {
    this.setState({
      datePickerPop: false,
      dateFilter: 0,
      QueryFrmDte: "",
      QueryToDte: "",
    })
  }

  CustomDates = (FromDate, ToDate) => {
    this.setState({
      QueryFrmDte: FromDate,
      QueryToDte: ToDate,
      datePickerPop: false
    })
  }

  dialogCloseFunction = () => {
    this.setState({
      dialogBox: false
    })
  }

  DateHelperText = () => {
    if (this.state.dateFilter === 0) {
      return " "
    }
    else if (this.state.dateFilter === 1) {
      return DateTime.now().toFormat('dd-MM-yyyy')
    }
    else if (this.state.dateFilter === 2) {
      return DateTime.now().minus({ days: 1 }).toFormat('dd-MM-yyyy')
    }
    else if (this.state.dateFilter === 3) {
      return `${DateTime.now().minus({ days: 7 }).toFormat('dd-MM-yyyy')}  to  ${DateTime.now().toFormat('dd-MM-yyyy')}`
    }
    else if (this.state.dateFilter === 4) {
      return `${DateTime.now().minus({ days: 30 }).toFormat('dd-MM-yyyy')}  to  ${DateTime.now().toFormat('dd-MM-yyyy')}`
    }
    else if (this.state.dateFilter === 5) {
      return this.state.QueryToDte === undefined ? null : `${this.state.QueryFrmDte.split("-").reverse().join("-")}  to  ${this.state.QueryToDte.split("-").reverse().join("-")}`
    }
  }

  render() {
    const { t } = this.props

    // Sorting Function   ==== starts
    const sorting = (col, SortOrder) => {
      if (SortOrder === "asc") {
        const Sorted = [...this.state.StockAdjustment].sort((a, b) => {
          if (typeof (a[col]) == "string") {
            return a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
          } else {
            return a[col] > b[col] ? 1 : -1
          }
        })
        this.setState({ StockAdjustment: Sorted })

        if (col === "brand_name") {
          this.setState({ BrandNameSortOrder: 'desc', AdjustedDateSortOrder: 'asc', ExpiryDateSortOrder: 'asc', MrpUnitSortOrder: 'asc', GenericNameSortOrder: 'asc', BrandNameSortIcon: "asc", AdjustedDateSortIcon: 'none', ExpiryDateSortIcon: "none", GenericNameSortIcon: 'none', MrpUnitSortIcon: 'none', })
        }
        else if (col === "generic_name") {
          this.setState({ GenericNameSortOrder: 'desc', AdjustedDateSortOrder: 'asc', ExpiryDateSortOrder: 'asc', MrpUnitSortOrder: 'asc', BrandNameSortOrder: 'asc', GenericNameSortIcon: "asc", AdjustedDateSortIcon: 'none', ExpiryDateSortIcon: "none", BrandNameSortIcon: 'none', MrpUnitSortIcon: 'none', })
        }
        else if (col === "adjustment_date") {
          this.setState({ AdjustedDateSortOrder: 'desc', MrpUnitSortOrder: 'asc', GenericNameSortOrder: 'asc', ExpiryDateSortOrder: 'asc', BrandNameSortOrder: 'asc', MrpUnitSortIcon: "asc", AdjustedDateSortIcon: 'asc', GenericNameSortIcon: "none", BrandNameSortIcon: 'none', ExpiryDateSortIcon: 'none', })
        }
        else if (col === "expiry_date") {
          this.setState({ ExpiryDateSortOrder: 'desc', AdjustedDateSortOrder: 'asc', GenericNameSortOrder: 'asc', MrpUnitSortOrder: 'asc', BrandNameSortOrder: 'asc', ExpiryDateSortIcon: "asc", AdjustedDateSortIcon: 'none', GenericNameSortIcon: "none", BrandNameSortIcon: 'none', MrpUnitSortIcon: 'none', })
        }
        else if (col === "price") {
          this.setState({ MrpUnitSortOrder: 'desc', AdjustedDateSortOrder: 'asc', GenericNameSortOrder: 'asc', ExpiryDateSortOrder: 'asc', BrandNameSortOrder: 'asc', MrpUnitSortIcon: "asc", AdjustedDateSortIcon: 'none', GenericNameSortIcon: "none", BrandNameSortIcon: 'none', ExpiryDateSortIcon: 'none', })
        }

      }
      else if (SortOrder === "desc") {
        const Sorted = [...this.state.StockAdjustment].sort((a, b) => {
          if (typeof (a[col]) == "string") {
            return a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
          } else {
            return a[col] < b[col] ? 1 : -1
          }
        })
        this.setState({ StockAdjustment: Sorted })

        if (col === "brand_name") {
          this.setState({ BrandNameSortOrder: 'default', BrandNameSortIcon: "desc" })
        }
        else if (col === "generic_name") {
          this.setState({ GenericNameSortOrder: 'default', GenericNameSortIcon: "desc" })
        }
        else if (col === "adjustment_date") {
          this.setState({ AdjustedDateSortOrder: 'default', AdjustedDateSortIcon: 'desc' })
        }
        else if (col === "expiry_date") {
          this.setState({ ExpiryDateSortOrder: 'default', ExpiryDateSortIcon: "desc" })
        }
        else if (col === "price") {
          this.setState({ MrpUnitSortOrder: 'default', MrpUnitSortIcon: "desc" })
        }
      }
      else if (SortOrder === "default") {

        this.setState({
          BrandNameSortIcon: 'none',
          ExpiryDateSortIcon: 'none',
          GenericNameSortIcon: 'none',
          MrpUnitSortIcon: 'none',
          AdjustedDateSortIcon: 'none',
          StockAdjustment: StockData.data.result,
          AdjustedDateSortOrder: 'asc',
          MrpUnitSortOrder: 'asc',
          ExpiryDateSortOrder: 'asc',
          GenericNameSortOrder: 'asc',
          BrandNameSortOrder: 'asc'
        })
      }
    }

    // Sorting Function   ==== ends


    //  Sorting Icons Change func === starts 
    const SortIcon = (sortType) => {
      if (sortType === "asc") {
        return <Tooltip disableInteractive placement='top' title={t("InAscendingOrder")}><KeyboardArrowUpIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
      }
      else if (sortType === 'desc') {
        return <Tooltip disableInteractive placement='top' title={t("InDecendingOrder")}><KeyboardArrowDownIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
      }
      else return <Tooltip disableInteractive placement='top' title={t("Sorting")}><FilterListIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
    }

    //  Sorting Icons Change func === ends 


    //  filter table data based on search Text
    const filtereddata = this.state.StockAdjustment.filter((data) => {
      return (
        (data.brand_name === null ? null : data.brand_name.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
        (data.generic_name === null ? null : data.generic_name.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
        (data.batch_no === null ? null : data.batch_no.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
        (data.dosage_strength === null ? null : data.dosage_strength.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
        (data.adjustment_date === null ? null : data.adjustment_date.includes(this.state.SearchText)) ||
        (data.expiry_date === null ? null : data.expiry_date.includes(this.state.SearchText)) ||
        (data.mrp === null ? null : Math.round(data.mrp * 100) === parseInt(this.state.SearchText * 100)) ||
        (data.mrp_per_quantity === null ? null : data.mrp_per_quantity === parseInt(this.state.SearchText)) ||
        (data.price === null ? null : Math.round(data.price * 100) === parseInt(Math.round(this.state.SearchText * 100))) ||
        (data.reason === null ? null : data.reason.toLowerCase().includes(this.state.SearchText.toLowerCase()))
      );
    });

    // Search Icon OnClick Function == ends

    const handleDate = () => {
      var today = DateTime.now().toFormat('yyyy-MM-dd')
      var yesterday = DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd')
      var startweek = DateTime.now().minus({ days: 7 }).toFormat('yyyy-MM-dd')
      var startmonth = DateTime.now().minus({ days: 30 }).toFormat('yyyy-MM-dd')

      if (this.state.dateFilter === 0) {
        this.setState({
          QueryFrmDte: "",
          QueryToDte: "",
        })
      }
      else if (this.state.dateFilter === 1) {
        this.setState({
          QueryFrmDte: today,
          QueryToDte: today,
        })

      }
      else if (this.state.dateFilter === 2) {
        this.setState({
          QueryFrmDte: yesterday,
          QueryToDte: yesterday,
        })
      }
      else if (this.state.dateFilter === 3) {
        this.setState({
          QueryFrmDte: startweek,
          QueryToDte: yesterday,
        })
      }
      else if (this.state.dateFilter === 4) {
        this.setState({
          QueryFrmDte: startmonth,
          QueryToDte: yesterday,
        })
      }
      else if (this.state.dateFilter === 5) {
        this.setState({ datePickerPop: true })
      }
    }

    const handleChange = (event) => {
      this.setState({ dateFilter: event.target.value }, () => handleDate());
    };

    return (
      <div>
        <div className='eMed_page_header'>
          <div id='eMed_table_name' style={{ color: Colors.TextColor }}>{t(this.props.menuName)}</div>
          <div id='eMed_tbl_filters'>
            <div>
              <FormControl id="eMed_tbl_datefilter">
                <InputLabel sx={{ color: Colors.TextColor }}>{t("SelectDate")}</InputLabel>
                <Select
                  sx={{ color: Colors.TextColor }}
                  size='small'
                  value={this.state.dateFilter}
                  label={t("SelectDate")}
                  onChange={handleChange}
                >
                  <MenuItem value={0}>{t("All")}</MenuItem>
                  <MenuItem value={1}>{t("Today")}</MenuItem>
                  <MenuItem value={2}>{t("Yesterday")}</MenuItem>
                  <MenuItem value={3}>{t("Lastweek")}</MenuItem>
                  <MenuItem value={4}>{t("LastMonth")}</MenuItem>
                  <MenuItem value={5} onClick={() => this.setState({ datePickerPop: true })}>{t("Custom")}</MenuItem>
                </Select>
                <FormHelperText sx={{ color: Colors.HelperText }} id="eMed_form_helpertext">{this.DateHelperText()}</FormHelperText>
              </FormControl>
            </div>
            {this.state.SearchFieldOpen ?
              <div id="eMed_tbl_searchbox" >
                <TextField
                  size='small'
                  focused
                  onChange={(e) => this.setState({ SearchText: e.target.value })}
                  value={this.state.SearchText}
                  label={t("SearchHere")}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <Tooltip disableInteractive title={t("Clear")} placement='top'><CloseIcon sx={{ color: Colors.ThemeColor, cursor: "pointer" }} onClick={() => { this.setState({ SearchText: '' }) }} /></Tooltip>
                    ),
                  }}
                />
              </div> : null}
            <Tooltip disableInteractive placement="top-start" title={this.state.SearchFieldOpen ? t("CloseSearch") : t("OpenSearch")}>
              <Button id="eMed_tbl_searchicon" variant='contained' style={{ backgroundColor: Colors.ThemeColor }} onClick={() => this.setState({ SearchFieldOpen: !this.state.SearchFieldOpen, CardOpen: false, SearchText: '' })} >
                {this.state.SearchFieldOpen ? <CloseIcon /> : <SearchIcon />}
              </Button>
            </Tooltip>
          </div>
        </div>
        <Card className='eMed_tbl_card' sx={{ bgcolor: Colors.TableCard }}>
          <TableContainer className='eMed_tbl_container'>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell className='eMed_tbl_headwidth_small eMed_sticky_start' sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}><div className='eMed_sortable_center' id="eMed_tbl_headtext">{t("S.No")}</div></TableCell>
                  <TableCell className='eMed_tbl_headwidth_large eMed_Sticky_Col2' sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}>
                    <div className=' eMed_sortable_name'>
                      <div id="eMed_tbl_headtext">{t("BrandName")}</div>
                      <div onClick={() => { sorting("brand_name", this.state.BrandNameSortOrder) }}>{SortIcon(this.state.BrandNameSortIcon)}</div>
                    </div>
                  </TableCell>
                  <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}>
                    <div className=' eMed_sortable_name'>
                      <div id="eMed_tbl_headtext">{t("GenericName")} </div>
                      <div onClick={() => { sorting("generic_name", this.state.GenericNameSortOrder) }}>{SortIcon(this.state.GenericNameSortIcon)}</div>

                    </div>
                  </TableCell>
                  <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}><div id="eMed_tbl_headtext" className='eMed_sortable_center'>{t("DosageType")}</div></TableCell>
                  <TableCell className='eMed_tbl_headwidth_small' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}><div id="eMed_tbl_headtext" className='eMed_sortable_center'>{t("Strength")}</div></TableCell>
                  <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}><div id="eMed_tbl_headtext" className='eMed_sortable_center'>{t("BatchNo")}</div></TableCell>
                  <TableCell className='eMed_tbl_headwidth_extralarge' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}>
                    <div className=' eMed_sortable_center'>
                      <div id="eMed_tbl_headtext">{t("StockAdjustedDate")}</div>
                      <div onClick={() => { sorting("adjustment_date", this.state.AdjustedDateSortOrder) }}>{SortIcon(this.state.AdjustedDateSortIcon)}</div>
                    </div>
                  </TableCell>
                  <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}>
                    <div className=' eMed_sortable_center'>
                      <div id="eMed_tbl_headtext">{t("ExpiryDate")}</div>
                      <div onClick={() => { sorting("expiry_date", this.state.ExpiryDateSortOrder) }}>{SortIcon(this.state.ExpiryDateSortIcon)}</div>
                    </div>
                  </TableCell>
                  <TableCell className='eMed_tbl_headwidth_small' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}><div className=' eMed_sortable_number' id="eMed_tbl_headtext">{t("Quantity")}</div></TableCell>
                  <TableCell className='eMed_tbl_headwidth_small' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}><div className=' eMed_sortable_number' id="eMed_tbl_headtext">{t("FreeQty")}</div></TableCell>
                  <TableCell className='eMed_tbl_headwidth_small' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}>
                    <div className=' eMed_sortable_number'>
                      <div id="eMed_tbl_headtext">{t("MRP/Unit(₹)")}</div>
                      <div onClick={() => { sorting("price", this.state.MrpUnitSortOrder) }}>{SortIcon(this.state.MrpUnitSortIcon)}</div>
                    </div>
                  </TableCell>
                  <TableCell className='eMed_tbl_headwidth_large eMed_sticky_end' sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}>
                    <div className=' eMed_sortable_center'>
                      <div id="eMed_tbl_headtext">{t("Reason")}</div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              {filtereddata.length > 0 ? filtereddata.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                .map((data, index) => {
                  return (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" id='eMed_tbl_bodytext' className='eMed_sticky_startbody' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>{index + 1}</TableCell>
                        <TableCell align='left' id='eMed_tbl_bodytext' className='eMed_Sticky_Body2' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>
                          {data.brand_name.length > 12 ?
                            <Tooltip placement="top" title={data.brand_name} disableInteractive>
                              <div>{data.brand_name.slice(0, 10) + '...'}</div>
                            </Tooltip> : `${data.brand_name}`}
                        </TableCell>
                        <TableCell align='left' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>
                          {data.generic_name.length > 12 ?
                            <Tooltip placement="top" title={data.generic_name} disableInteractive>
                              <div>{data.generic_name.slice(0, 10) + '...'}</div>
                            </Tooltip> : `${data.generic_name}`}
                        </TableCell>
                        <TableCell align='center' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>{DosageType(data.dosage_type)} </TableCell>
                        <TableCell align='center' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>{data.dosage_strength}</TableCell>
                        <TableCell align='center' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>{data.batch_no}</TableCell>
                        <TableCell align='center' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>{data.adjustment_date}</TableCell>
                        <TableCell align='center' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>{data.expiry_date}</TableCell>
                        <TableCell align='right' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>
                          <div id='eMed_tbl_bodytext'>{data.mrp_per_quantity}</div>
                        </TableCell>
                        <TableCell align='right' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>{data.mrp}</TableCell>
                        <TableCell align='right' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>
                          {data.price > 10000000 ?
                            <Tooltip placement="top" title={AmountFormat(data.price)} disableInteractive>
                              <div id='eMed_tbl_bodytext'>{AmountFormat(data.price).slice(0, 12) + '...'}</div>
                            </Tooltip> : <div id='eMed_tbl_bodytext'>{AmountFormat(data.price)}</div>}
                        </TableCell>
                        <TableCell align='center' id='eMed_tbl_bodytext' className='eMed_sticky_endbody' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>
                          {data.reason}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )
                }) :
                <div id='eMed_Norecords' style={{ color: Colors.TableHeaderTextColor }}>
                  {t("NoRecords")}
                </div>}
            </Table>

          </TableContainer>
          <TablePagination
            component='div'
            id="eMed_Table_Pagination"
            sx={{
              color: Colors.Pagination
            }}
            rowsPerPageOptions={[5, 10, 15]}
            count={filtereddata.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
          {this.state.datePickerPop ? <DatePicker PopClose={this.PopClose} CustomDates={this.CustomDates} /> : null}
        </Card>
        {
          this.state.dialogBox ?
            <Dialog
              type={"Notification"}
              DialogClose={this.dialogCloseFunction}
              header={"Message"}
              content={this.state.ErrorMsg}
              icon={<img className="eMed_dilboxAnimations" alt="Warning" src={Failed} />}
              secondary={"Close"}
              closeButton={this.dialogCloseFunction}
            /> : null
        }
      </div>
    )
  }
}
export default withTranslation()(AdjustmentReport);