import React from "react";
import './PurchaseOverview.css';
import {
    Card,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    Tooltip,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    FormHelperText,
    Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Colors } from '../../../../../Styles/Colors';
import SearchIcon from '@mui/icons-material/Search';
import { Trans, withTranslation } from 'react-i18next';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FilterListIcon from '@mui/icons-material/FilterList';
import PaymentCard from '../../../../../Components/PaymentCard/PaymentCard';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ArticleIcon from '@mui/icons-material/Article';
import DiscountIcon from '@mui/icons-material/Discount';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import Dialog from '../../../../../Components/Dialog';
import Failed from '../../../../../Assets/Animation/Failed.gif';
import DatePicker from '../../../../../Components/DatePicker/DatePicker';
import { DateTime } from "luxon";
import { AmountFormat } from "../../../../../Components/CommonFunctions/CommonFunctions";
import { localGetItem } from '../../../../../Utility/Services/CacheProviderService';
import { Serviceurls } from '../../../../../Utility/API/Serviceurls';
import RestAPIService from '../../../../../Utility/Services/RestAPIService';

class PurchaseOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            apiData: [],
            page: 0,
            rowsPerPage: 5,
            searchText: "",
            searchFieldShow: false,
            dateFilter: 0,
            datePickerPop: false,
            dateSortOrder: "asc",
            dateSortIcon: 'none',
            gstSortOrder: "asc",
            gstSortIcon: 'none',
            amountSortOrder: "asc",
            amountSortIcon: 'none',
            gstAmountSortOrder: "asc",
            gstAmountSortIcon: 'none',
            disAmountSortOrder: "asc",
            disAmountSortIcon: 'none',
            netAmountSortOrder: "asc",
            netAmountSortIcon: 'none',
            cardOpen: false,
            failedDlgOpen: false,
            QueryFromDate: "",
            QueryToDate: "",
            defaultData: [],
            dialogBox: false,
            pharmacyID: localGetItem("Pharmacy_Id")
        }
    }

    componentDidMount() {
        this.getPurchaseOverview()
    }

    /* Table Get API Funtion start */
    getPurchaseOverview = () => {
        try {
            let QueryParams = `?pharmacy_id=${this.state.pharmacyID}&page=&page_size=all&from_date=${this.state.QueryFromDate}&to_date=${this.state.QueryToDate}`
            RestAPIService.getAll(Serviceurls.GET_PURCHASE_OVERVIEW + QueryParams)
                .then(response => {
                    this.setState({
                        apiData: response.data.data.result,
                        defaultData: response.data.data.result,
                        cardTotalAmt: response.data.data.total_amount,
                        cardGSTAmt: response.data.data.total_gst_amount,
                        cardDisAmt: response.data.data.total_discount_amount,
                        cardNetAmt: response.data.data.total_net_total
                    })
                })
                .catch(e => {
                    this.setState({ dialogBox: true, ErrorMsg: e.message });
                    setTimeout(() => {
                        this.setState({
                            dialogBox: false
                        })
                    }, 3000)
                });
        }
        catch (e) {
            this.setState({ dialogBox: true, ErrorMsg: e.message });
            setTimeout(() => {
                this.setState({
                    dialogBox: false
                })
            }, 3000)
        }
    }
    /* Table Get API Funtion end */

    /* Failed Dialogbox function end */
    dialogCloseFunction = () => {
        this.setState({
            dialogBox: false
        })
    }
    /* Failed Dialogbox function start */

    /* Table Get API Funtion start */

    /* pagechenge function start*/
    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    }
    /* pagechenge function end*/

    /* Rowpagechenge function start*/
    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value, page: 0 });
    }
    /* Rowpagechenge function end*/

    /* customdate popup function start*/
    PopClose = () => {
        this.setState({
            datePickerPop: false,
            QueryFromDate: "",
            QueryToDate: "",
            dateFilter: 0,
        }, () => this.getPurchaseOverview())
    }
    /* customdate popup function end*/

    /* customdate select function start*/
    CustomDates = (FromDate, ToDate) => {
        this.setState({
            QueryFromDate: FromDate,
            QueryToDate: ToDate,
            datePickerPop: false
        }, () => this.getPurchaseOverview())
    }
    /* customdate select function end*/

    /* customdate label function start*/
    DateHelperText = () => {
        if (this.state.dateFilter === 0) {
            return ""
        }
        else if (this.state.dateFilter === 1) {
            return DateTime.now().toFormat('dd-MM-yyyy')
        }
        else if (this.state.dateFilter === 2) {
            return DateTime.now().minus({ days: 1 }).toFormat('dd-MM-yyyy')
        }
        else if (this.state.dateFilter === 3) {
            return `${DateTime.now().minus({ days: 7 }).toFormat('dd-MM-yyyy')}  to  ${DateTime.now().toFormat('dd-MM-yyyy')}`
        }
        else if (this.state.dateFilter === 4) {
            return `${DateTime.now().minus({ days: 30 }).toFormat('dd-MM-yyyy')}  to  ${DateTime.now().toFormat('dd-MM-yyyy')}`
        }
        else if (this.state.dateFilter === 5) {
            return `${this.state.QueryFromDate.split("-").reverse().join("-")}  to  ${this.state.QueryToDate.split("-").reverse().join("-")}`
        }
    }
    /* customdate label function end*/

    render() {
        const { t } = this.props
        const filtereddata = this.state.apiData.filter((data) => {
            return (
                (data.bill_date === null ? null : data.bill_date.toLowerCase().includes(this.state.searchText.toLowerCase())) ||
                (data.invoice_number === null ? null : data.invoice_number.toLowerCase().includes(this.state.searchText.toLowerCase())) ||
                (data.vendor_name === null ? null : data.vendor_name.toLowerCase().includes(this.state.searchText.toLowerCase())) ||
                (data.vendor_gstin_number === null ? null : data.vendor_gstin_number.toLowerCase().includes(this.state.searchText.toLowerCase())) ||
                (data.amount === null ? null : Math.round(data.amount * 100) === parseInt(Math.round(this.state.searchText * 100))) ||
                (data.gst_amount === null ? null : Math.round(data.gst_amount * 100) === parseInt(Math.round(this.state.searchText * 100))) ||
                (data.discount_amount === null ? null : Math.round(data.discount_amount * 100) === parseInt(Math.round(this.state.searchText * 100))) ||
                (data.total_buying_price === null ? null : Math.round(data.total_buying_price * 100) === parseInt(Math.round(this.state.searchText * 100)))
            );
        });
        // Sorting Function   ==== starts
        const sorting = (col, SortOrder) => {
            if (SortOrder === "asc") {
                const Sorted = [...this.state.apiData].sort((a, b) => {
                    if (typeof (a[col]) == "string") {
                        return a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
                    } else {
                        return a[col] > b[col] ? 1 : -1
                    }
                })
                this.setState({ apiData: Sorted })

                if (col === "bill_date") {
                    this.setState({
                        dateSortOrder: 'desc',
                        dateSortIcon: 'asc',
                        gstSortOrder: "asc",
                        gstSortIcon: 'none',
                        amountSortOrder: "asc",
                        amountSortIcon: 'none',
                        gstAmountSortOrder: "asc",
                        gstAmountSortIcon: 'none',
                        disAmountSortOrder: "asc",
                        disAmountSortIcon: 'none',
                        netAmountSortOrder: "asc",
                        netAmountSortIcon: 'none',
                    })
                }
                else if (col === "vendor_gstin_number") {
                    this.setState({
                        dateSortOrder: 'asc',
                        dateSortIcon: 'none',
                        gstSortOrder: "desc",
                        gstSortIcon: 'asc',
                        amountSortOrder: "asc",
                        amountSortIcon: 'none',
                        gstAmountSortOrder: "asc",
                        gstAmountSortIcon: 'none',
                        disAmountSortOrder: "asc",
                        disAmountSortIcon: 'none',
                        netAmountSortOrder: "asc",
                        netAmountSortIcon: 'none',
                    })
                }
                else if (col === "amount") {
                    this.setState({
                        dateSortOrder: 'asc',
                        dateSortIcon: 'none',
                        gstSortOrder: "asc",
                        gstSortIcon: 'none',
                        amountSortOrder: "desc",
                        amountSortIcon: 'asc',
                        gstAmountSortOrder: "asc",
                        gstAmountSortIcon: 'none',
                        disAmountSortOrder: "asc",
                        disAmountSortIcon: 'none',
                        netAmountSortOrder: "asc",
                        netAmountSortIcon: 'none',
                    })
                }
                else if (col === "gst_amount") {
                    this.setState({
                        dateSortOrder: 'asc',
                        dateSortIcon: 'none',
                        gstSortOrder: "asc",
                        gstSortIcon: 'none',
                        amountSortOrder: "asc",
                        amountSortIcon: 'none',
                        gstAmountSortOrder: "desc",
                        gstAmountSortIcon: 'asc',
                        disAmountSortOrder: "asc",
                        disAmountSortIcon: 'none',
                        netAmountSortOrder: "asc",
                        netAmountSortIcon: 'none',
                    })
                } else if (col === "discount_amount") {
                    this.setState({
                        dateSortOrder: 'asc',
                        dateSortIcon: 'none',
                        gstSortOrder: "asc",
                        gstSortIcon: 'none',
                        amountSortOrder: "asc",
                        amountSortIcon: 'none',
                        gstAmountSortOrder: "asc",
                        gstAmountSortIcon: 'none',
                        disAmountSortOrder: "desc",
                        disAmountSortIcon: 'asc',
                        netAmountSortOrder: "asc",
                        netAmountSortIcon: 'none',
                    })
                } else if (col === "total_buying_price") {
                    this.setState({
                        dateSortOrder: 'asc',
                        dateSortIcon: 'none',
                        gstSortOrder: "asc",
                        gstSortIcon: 'none',
                        amountSortOrder: "asc",
                        amountSortIcon: 'none',
                        gstAmountSortOrder: "asc",
                        gstAmountSortIcon: 'none',
                        disAmountSortOrder: "asc",
                        disAmountSortIcon: 'none',
                        netAmountSortOrder: "desc",
                        netAmountSortIcon: 'asc',
                    })
                }

            }
            else if (SortOrder === "desc") {
                const Sorted = [...this.state.apiData].sort((a, b) => {
                    if (typeof (a[col]) == "string") {
                        return a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
                    } else {
                        return a[col] < b[col] ? 1 : -1
                    }
                })
                this.setState({ apiData: Sorted })

                if (col === "bill_date") {
                    this.setState({ dateSortOrder: 'default', dateSortIcon: "desc", })
                } else if (col === "vendor_gstin_number") {
                    this.setState({ gstSortOrder: 'default', gstSortIcon: "desc" })
                } else if (col === "amount") {
                    this.setState({ amountSortOrder: 'default', amountSortIcon: "desc" })
                } else if (col === "gst_amount") {
                    this.setState({ gstAmountSortOrder: 'default', gstAmountSortIcon: "desc" })
                } else if (col === "discount_amount") {
                    this.setState({ disAmountSortOrder: 'default', disAmountSortIcon: "desc" })
                } else if (col === "total_buying_price") {
                    this.setState({ netAmountSortOrder: 'default', netAmountSortIcon: "desc" })
                }
            } else if (SortOrder === "default") {
                this.setState({
                    dateSortOrder: 'asc',
                    dateSortIcon: 'none',
                    gstSortOrder: 'asc',
                    gstSortIcon: 'none',
                    amountSortOrder: 'asc',
                    amountSortIcon: 'none',
                    gstAmountSortOrder: 'asc',
                    gstAmountSortIcon: 'none',
                    disAmountSortOrder: 'asc',
                    disAmountSortIcon: 'none',
                    netAmountSortOrder: 'asc',
                    netAmountSortIcon: 'none',
                    apiData: this.state.defaultData

                })
            }
        }
        // Sorting Function   ==== ends


        //  Sorting Icons Change func === starts 
        const SortIcon = (sortType) => {
            if (sortType === "asc") {
                return <Tooltip disableInteractive placement='top' title={t("InAscendingOrder")}><KeyboardArrowUpIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
            }
            else if (sortType === 'desc') {
                return <Tooltip disableInteractive placement='top' title={t("InDecendingOrder")}><KeyboardArrowDownIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
            }
            else return <Tooltip disableInteractive placement='top' title={t("Sorting")}><FilterListIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
        }

        //  Sorting Icons Change func === ends  

        const handleDate = () => {
            var today = DateTime.now().toFormat('yyyy-MM-dd')
            var yesterday = DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd')
            var startweek = DateTime.now().minus({ days: 7 }).toFormat('yyyy-MM-dd')
            var startmonth = DateTime.now().minus({ days: 30 }).toFormat('yyyy-MM-dd')

            if (this.state.dateFilter === 0) {
                this.setState({
                    QueryFromDate: "",
                    QueryToDate: "",
                }, () => {
                    this.getPurchaseOverview()
                })
            }
            else if (this.state.dateFilter === 1) {
                this.setState({
                    QueryFromDate: today,
                    QueryToDate: today,
                }, () => {
                    this.getPurchaseOverview()
                })

            }
            else if (this.state.dateFilter === 2) {
                this.setState({
                    QueryFromDate: yesterday,
                    QueryToDate: yesterday,
                }
                    , () => {
                        this.getPurchaseOverview()
                    })
            }
            else if (this.state.dateFilter === 3) {
                this.setState({
                    QueryFromDate: startweek,
                    QueryToDate: yesterday,
                },
                    () => {
                        this.getPurchaseOverview()
                    })
            }
            else if (this.state.dateFilter === 4) {
                this.setState({
                    QueryFromDate: startmonth,
                    QueryToDate: yesterday,
                },
                    () => {
                        this.getPurchaseOverview()
                    })
            }
            else if (this.state.dateFilter === 5) {
                this.setState({ datePickerPop: true })
            }

        }
        return (
            <div>
                <div className="eMed_page_header">
                    <div className="eMed_puroverview_tblandcard">
                        <div id="eMed_table_name" style={{ color: Colors.TextColor }}>
                            {t(this.props.menuName)}
                        </div>
                        <div id="eMed_tbl_paymentcard">
                            <div onClick={() => this.setState({ cardOpen: !this.state.cardOpen, searchFieldShow: false })} style={{ display: this.state.cardOpen ? "none" : "inline" }}>
                                <PaymentCard Color={Colors.blue} Icons={<CurrencyRupeeIcon />} Name={t("Total")} Amount={this.state.cardNetAmt} />
                            </div>
                            {this.state.cardOpen ?
                                <div id="eMed_tbl_paymentcard" onClick={() => this.setState({ cardOpen: !this.state.cardOpen, searchFieldShow: false })}>
                                    <PaymentCard Color={Colors.blue} Icons={<CurrencyRupeeIcon />} Name={t("Amount")} Amount={this.state.cardTotalAmt} />
                                    <PaymentCard Color={Colors.orange} Icons={<AssessmentIcon />} Name={t("CGST")} Amount={this.state.cardGSTAmt} />
                                    <PaymentCard Color={Colors.liteViolet} Icons={<ArticleIcon />} Name={t("SGST")} Amount={0} />
                                    <PaymentCard Color={Colors.liteGreen} Icons={<DiscountIcon />} Name={t("DisAmount")} Amount={this.state.cardDisAmt} />
                                    <PaymentCard Color={Colors.brown} Icons={<AutoAwesomeMotionIcon />} Name={t("NetAmount")} Amount={this.state.cardNetAmt} />
                                </div>
                                : null}
                        </div>
                    </div>
                    <div id="eMed_tbl_filters">
                        <div>
                            <FormControl id="eMed_tbl_datefilter" >
                                <InputLabel sx={{ color: Colors.TextColor }}>{t("SelectDate")}</InputLabel>
                                <Select
                                    sx={{ color: Colors.TextColor }}
                                    size='small'
                                    onChange={(event) => { this.setState({ dateFilter: event.target.value }, () => handleDate()) }}
                                    value={this.state.dateFilter}
                                    labelId="demo-simple-select-required-label"
                                    label={t("SelectDate")}
                                    id="emed_demo-simple-select-required"
                                >
                                    <MenuItem value={0}>All</MenuItem>
                                    <MenuItem value={1}>Today</MenuItem>
                                    <MenuItem value={2}>Yesterday</MenuItem>
                                    <MenuItem value={3}>Last Week</MenuItem>
                                    <MenuItem value={4}>Last Month</MenuItem>
                                    <MenuItem value={5} onClick={() => { this.setState({ datePickerPop: true }) }} >Custom</MenuItem>
                                </Select>
                                <FormHelperText sx={{ color: Colors.HelperText }} id="eMed_form_helpertext">{this.DateHelperText()}</FormHelperText>
                            </FormControl>
                        </div>
                        {this.state.datePickerPop ? <DatePicker PopClose={this.PopClose} CustomDates={this.CustomDates} /> : null}

                        {this.state.searchFieldShow ?
                            <div id='eMed_tbl_searchbox'>
                                <TextField
                                    sx={{ input: { color: Colors.TextColor } }}
                                    size='small'
                                    focused
                                    onChange={(e) => this.setState({ searchText: e.target.value })}
                                    value={this.state.searchText}
                                    label={t("SearchHere")}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip disableInteractive title={t("Clear")} placement='top'><CloseIcon sx={{ color: Colors.ThemeColor, cursor: 'pointer' }} onClick={() => { this.setState({ searchText: '' }) }} /></Tooltip>
                                        ),
                                    }}
                                />
                            </div> : null}


                        <div className="eMed_tbl_searchicon">
                            <Tooltip disableInteractive placement='top' title={this.state.searchFieldShow ? t("CloseSearch") : t("OpenSearch")}>
                                <Button id="eMed_tbl_searchicon" variant='contained' style={{ backgroundColor: Colors.ThemeColor }} onClick={() => this.setState({ searchFieldShow: !this.state.searchFieldShow, searchText: '', cardOpen: false })} >
                                    {this.state.searchFieldShow ? <CloseIcon /> : <SearchIcon />}
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div>
                    <Card sx={{ bgcolor: Colors.TableCard }} className="eMed_tbl_card" >
                        <TableContainer className="eMed_tbl_container" >
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="eMed_sticky_start eMed_tbl_headwidth_small" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div style={{ color: Colors.TableHeaderTextColor }} className="eMed_sortable_center" id='eMed_tbl_headtext'>{t("S.No")}</div>
                                        </TableCell>
                                        <TableCell className='eMed_tbl_headwidth_large' align="left" id="eMed_sickyHeadtwo" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("VendorName")}</div>
                                        </TableCell>
                                        <TableCell className='eMed_tbl_headwidth_large' align="right" id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div style={{ color: Colors.TableHeaderTextColor }} className="eMed_sortable_center" id='eMed_tbl_headtext'>{t("InvoiceNo")}</div>
                                        </TableCell>
                                        <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className="eMed_sortable_center">
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("Date")}</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("bill_date", this.state.dateSortOrder) }}>{SortIcon(this.state.dateSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className="eMed_sortable_center">
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("GSTIN")}</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("vendor_gstin_number", this.state.gstSortOrder) }}>
                                                    {SortIcon(this.state.gstSortIcon)}
                                                </div>
                                            </div>
                                        </TableCell>
                                        <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className="eMed_sortable_number">
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("Amount")}(₹)</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("amount", this.state.amountSortOrder) }}>{SortIcon(this.state.amountSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className="eMed_sortable_number">
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("GSTAmount")}(₹)</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("gst_amount", this.state.gstAmountSortOrder) }}>{SortIcon(this.state.gstAmountSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell className='eMed_tbl_headwidth_extralarge' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className="eMed_sortable_number">
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("DiscountAmount")}(₹)</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("discount_amount", this.state.disAmountSortOrder) }}>{SortIcon(this.state.disAmountSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell className="eMed_sticky_end eMed_tbl_headwidth_large" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className="eMed_sortable_number">
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("NetAmount")}(₹)</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("total_buying_price", this.state.netAmountSortOrder) }}>{SortIcon(this.state.netAmountSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {filtereddata.length > 0 ? filtereddata
                                    .slice(this.state.page * this.state.rowsPerPage,
                                        this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                    .map((data, index) => {
                                        return (
                                            <TableBody key={index}>
                                                <TableRow>
                                                    <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="center" className="eMed_sticky_startbody">{index + 1}</TableCell>
                                                    <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} align="left" id="eMed_stickyBodyTwo">
                                                        <Tooltip title={data.vendor_name}>
                                                            <div id="eMed_tbl_bodytext">
                                                                {data.vendor_name.length > 10 ? data.vendor_name.slice(0, 10) + ".." : data.vendor_name}
                                                            </div>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="center">{data.invoice_number}</TableCell>
                                                    <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="center">{data.bill_date}</TableCell>
                                                    <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="center">{data.vendor_gstin_number}</TableCell>
                                                    <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="right">{AmountFormat(data.amount)}</TableCell>
                                                    <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="right">{AmountFormat(data.gst_amount)}</TableCell>
                                                    <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="right">{AmountFormat(data.discount_amount)}</TableCell>
                                                    <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="right" className="eMed_sticky_endbody">{AmountFormat(data.total_buying_price)}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )
                                    }) : <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_Norecords'>
                                    {t("NoRecords")}
                                </div>}
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component='div'
                            id="eMed_tbl_pagination"
                            sx={{ color: Colors.PagiantionTxtColor }}
                            rowsPerPageOptions={[5, 10, 15]}
                            count={filtereddata.length}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </Card>
                    {this.state.dialogBox ?
                        <Dialog
                            type={"Notification"}
                            DialogClose={this.dialogCloseFunction}
                            header={"Message"}
                            content={this.state.ErrorMsg}
                            icon={<img className="eMed_dilboxAnimations" alt="Warning" src={Failed} />}
                            secondary={"Close"}
                            closeButton={this.dialogCloseFunction}
                        /> : null}
                </div>

            </div>
        )
    }
}
export default withTranslation()(PurchaseOverview);