import React, { Component } from 'react';
import { Card, Table, TableCell, TableContainer, TableHead, TableRow, TableBody, Tooltip, TablePagination, Button } from '@mui/material';
import { Trans, withTranslation } from 'react-i18next';
import { Colors } from '../../../../../Styles/Colors';
import { DosageType } from '../../../../../Components/CommonFunctions/CommonFunctions';
import './MISReport.css'
import StockData from "../StockData.json";
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import PaymentCard from '../../../../../Components/PaymentCard/PaymentCard';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import MISModelBox from './MISModelBox';

class MISReport extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: 5,
      BrandNameSortOrder: 'asc',
      GenericNameSortOrder: 'asc',
      BrandNameSortIcon: 'none',
      GenericNameSortIcon: 'none',
      SearchFieldOpen: false,
      SearchText: "",
      MISreport: StockData.data.result,
      Modalpop: false,
      dialogBox: false,
      MISreports: "",
      defaultMISreport: ""
    }
  }

  dialogCloseFunction = () => {
    this.setState({ Modalpop: false })
  }


  //  Mui table Pagination Functions --- starts

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 });
  }

  //  Mui table Pagination Functions --- ends

  CloseFunction = () => {
    this.setState({ dialogBox: false })
  }

  render() {
    const { t } = this.props

    // Sorting Function   ==== starts
    const sorting = (col, SortOrder) => {
      if (SortOrder === "asc") {
        const Sorted = [...this.state.MISreport].sort((a, b) => {
          if (typeof (a[col]) == "string") {
            return a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
          } else {
            return a[col] > b[col] ? 1 : -1
          }
        })
        this.setState({ MISreport: Sorted })

        if (col === "brand_name") {
          this.setState({ BrandNameSortOrder: 'desc', GenericNameSortOrder: 'asc', BrandNameSortIcon: "asc", GenericNameSortIcon: 'none' })
        }
        else if (col === "generic_name") {
          this.setState({ GenericNameSortOrder: 'desc', BrandNameSortOrder: 'asc', GenericNameSortIcon: "asc", BrandNameSortIcon: 'none' })
        }

      }
      else if (SortOrder === "desc") {
        const Sorted = [...this.state.MISreport].sort((a, b) => {
          if (typeof (a[col]) == "string") {
            return a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
          } else {
            return a[col] < b[col] ? 1 : -1
          }
        })
        this.setState({ MISreport: Sorted })

        if (col === "brand_name") {
          this.setState({ BrandNameSortOrder: 'default', BrandNameSortIcon: "desc" })
        }
        else if (col === "generic_name") {
          this.setState({ GenericNameSortOrder: 'default', GenericNameSortIcon: "desc" })
        }
      }
      else if (SortOrder === "default") {
        this.setState({
          BrandNameSortIcon: 'none',
          GenericNameSortIcon: 'none',
          BrandNameSortOrder: 'asc',
          GenericNameSortOrder: 'asc',
          MISreport: StockData.data.result
        })
      }
    }
    // Sorting Function   ==== ends

    //  Sorting Icons Change func === starts 
    const SortIcon = (sortType) => {
      if (sortType === "asc") {
        return <Tooltip disableInteractive placement='top' title={t("InAscendingOrder")}><KeyboardArrowUpIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
      }
      else if (sortType === 'desc') {
        return <Tooltip disableInteractive placement='top' title={t("InDecendingOrder")}><KeyboardArrowDownIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
      }
      else return <Tooltip disableInteractive placement='top' title={t("Sorting")}><FilterListIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
    }

    //  Sorting Icons Change func === ends 

    //  filter table data based on search Text
    const filtereddata = this.state.MISreport.filter((data) => {
      return (
        (data.brand_name === null ? null : data.brand_name.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
        (data.generic_name === null ? null : data.generic_name.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
        (data.dosage_strength === null ? null : data.dosage_strength.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
        (data.vendor_1 === null ? null : Math.round(data.vendor_1 * 100) === parseInt(Math.round(this.state.SearchText * 100))) ||
        (data.vendor_2 === null ? null : Math.round(data.vendor_2 * 100) === parseInt(Math.round(this.state.SearchText * 100))) ||
        (data.vendor_3 === null ? null : Math.round(data.vendor_3 * 100) === parseInt(Math.round(this.state.SearchText * 100)))
      );
    });

    // Search Icon OnClick Function == ends


    return (
      <div>
        <div className="eMed_page_header">

          <div className='eMed_header_div'>
            <div id='eMed_table_name' style={{ color: Colors.TextColor }}>{t(this.props.menuName)}</div>
            <div id="eMed_tbl_paymentcard">
              <PaymentCard Color={Colors.blue} Icons={<CurrencyRupeeIcon />} Name={t("Vendor 1")} Amount={565230.00} />
              <PaymentCard Color={Colors.orange} Icons={<CreditCardIcon />} Name={t("Vendor 2")} Amount={2660.00} />
              <PaymentCard Color={Colors.liteGreen} Icons={<AccountBalanceIcon />} Name={t("Vendor 3")} Amount={400.00} />
            </div>
          </div>

          <div className='eMed_header_search_div'>
            <div>

              <Button variant="contained" onClick={() => { this.setState({ Modalpop: true }) }}>
                {t("VendorSearch")}
              </Button>
              {this.state.Modalpop ? <MISModelBox DialogClose={this.dialogCloseFunction} /> : null}
            </div>
            {this.state.SearchFieldOpen ?
              <div id="eMed_tbl_searchbox" >
                <TextField
                  size='small'
                  focused
                  onChange={(e) => this.setState({ SearchText: e.target.value })}
                  value={this.state.SearchText}
                  label={t("SearchHere")}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <Tooltip disableInteractive title={t("Clear")} placement='top'>
                        <CloseIcon sx={{ color: Colors.ThemeColor }} onClick={() => { this.setState({ SearchText: '' }) }} />
                      </Tooltip>
                    ),
                  }}
                />
              </div> : null}
            <Tooltip disableInteractive placement="top-start" title={this.state.SearchFieldOpen ? t("CloseSearch") : t("OpenSearch")}>
              <Button id="eMed_tbl_searchicon" variant='contained' style={{ backgroundColor: Colors.ThemeColor }} onClick={() => this.setState({ SearchFieldOpen: !this.state.SearchFieldOpen, CardOpen: false, SearchText: '' })} >
                {this.state.SearchFieldOpen ? <CloseIcon /> : <SearchIcon />}
              </Button>
            </Tooltip>
          </div>

        </div>
        <Card className='eMed_tbl_card' sx={{ bgcolor: Colors.TableCard }}>
          <TableContainer className='eMed_tbl_container'>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className='eMed_tbl_headwidth_small' sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}><div className='eMed_sortable_center' id="eMed_tbl_headtext">{t("S.No")}</div></TableCell>
                  <TableCell className='eMed_tbl_headwidth_small eMed_Sticky_Col2' sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}>
                    <div className=' eMed_sortable_name'>
                      <div id="eMed_tbl_headtext">{t("BrandName")}</div>
                      <div onClick={() => { sorting("brand_name", this.state.BrandNameSortOrder) }}>{SortIcon(this.state.BrandNameSortIcon)}</div>
                    </div>
                  </TableCell>
                  <TableCell className='eMed_tbl_headwidth_small' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}>
                    <div className=' eMed_sortable_name'>
                      <div id="eMed_tbl_headtext">{t("GenericName")} </div>
                      <div onClick={() => { sorting("generic_name", this.state.GenericNameSortOrder) }}>{SortIcon(this.state.GenericNameSortIcon)}</div>
                    </div>
                  </TableCell>
                  <TableCell className='eMed_tbl_headwidth_small' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}>
                    <div className='eMed_sortable_center' id="eMed_tbl_headtext">{t("DosageType")}</div>
                  </TableCell>
                  <TableCell className='eMed_tbl_headwidth_small' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}>
                    <div className='eMed_sortable_center' id="eMed_tbl_headtext">{t("Strength")}</div>
                  </TableCell>
                  <TableCell className='eMed_tbl_headwidth_small' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}>
                    <div className='eMed_sortable_center' id="eMed_tbl_headtext">{t("Vendor 1")}</div>
                  </TableCell>
                  <TableCell className='eMed_tbl_headwidth_small' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}>
                    <div className='eMed_sortable_name' id="eMed_tbl_headtext">{t("Vendor 2")}</div>
                  </TableCell>
                  <TableCell className='eMed_tbl_headwidth_small' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}>
                    <div className='eMed_sortable_name' id="eMed_tbl_headtext">{t("Vendor 3")}</div>
                  </TableCell>
                </TableRow>
              </TableHead>
              {filtereddata.length > 0 ? filtereddata.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                .map((data, index) => (
                  <TableBody>
                    <TableRow>
                      <TableCell align='center' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>{index + 1}</TableCell>
                      <TableCell align='left' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>
                        {data.brand_name.length > 12 ?
                          <Tooltip placement="top" title={data.brand_name} disableInteractive>
                            <div id='eMed_tbl_bodytext'>{data.brand_name.slice(0, 12) + '...'}</div>
                          </Tooltip> : `${data.brand_name}`}
                      </TableCell>
                      <TableCell align='left' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>
                        {data.generic_name.length > 12 ?
                          <Tooltip placement="top" title={data.generic_name} disableInteractive>
                            <div id='eMed_tbl_bodytext'>{data.generic_name.slice(0, 12) + '...'}</div>
                          </Tooltip> : `${data.generic_name}`}
                      </TableCell >
                      <TableCell align='center' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>{DosageType(data.dosage_type)} </TableCell>
                      <TableCell align='center' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>{data.dosage_strength}</TableCell>
                      <TableCell align='left' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>
                        {data.vendor_1.length > 12 ?
                          <Tooltip placement="top" title={data.vendor_1} disableInteractive>
                            <div id='eMed_tbl_bodytext'>{data.vendor_1.slice(0, 12) + '...'}</div>
                          </Tooltip> : `${data.vendor_1}`}
                      </TableCell>
                      <TableCell align='left' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>
                        {data.vendor_2.length > 12 ?
                          <Tooltip placement="top" title={data.vendor_2} disableInteractive>
                            <div id='eMed_tbl_bodytext'>{data.vendor_2.slice(0, 12) + '...'}</div>
                          </Tooltip> : `${data.vendor_2}`}
                      </TableCell>
                      <TableCell align='left' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>
                        {data.vendor_3.length > 12 ?
                          <Tooltip placement="top" title={data.vendor_3} disableInteractive>
                            <div id='eMed_tbl_bodytext'>{data.vendor_3.slice(0, 12) + '...'}</div>
                          </Tooltip> : `${data.vendor_3}`}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )) : <div id='eMed_Norecords' style={{ color: Colors.TableHeaderTextColor }}>
                {t("NoRecords")}
              </div>}
            </Table>
          </TableContainer>
          <TablePagination
            component='div'
            id="eMed_Table_Pagination"
            sx={{
              color: Colors.Pagination
            }}
            rowsPerPageOptions={[5, 10, 15]}
            count={filtereddata.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Card>
      </div>
    )
  }
}
export default withTranslation()(MISReport);