import React from "react";
import './FilterModel.css';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Button,
    Divider,
    Tabs,
    Tab,
    Box,
    Autocomplete,
    TextField,
    FormControl,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
    Slider,
    FormHelperText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FilterTabs } from '../FilterModel/Tablist';
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import { withTranslation } from 'react-i18next';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import DatePicker from "../FutureDatepicker/FutureDatePicker";
import { Colors } from "../../Styles/Colors";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { Serviceurls } from '../../Utility/API/Serviceurls';
import RestAPIService from '../../Utility/Services/RestAPIService';
import { localGetItem, localSetItem } from '../../Utility/Services/CacheProviderService';
import DialogBox from '../Dialog';
import Failed from '../../Assets/Animation/Failed.gif';

class FilterModel extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openFilter: true,
            tabID: "0",
            searchData: {
                dosageType: this.props.returndata.dosageType != undefined ? this.props.returndata.dosageType : [],
                batchNumber: this.props.returndata.batchNumber != undefined ? this.props.returndata.batchNumber : "",
                fromMRPunit: this.props.returndata.fromMRPunit != undefined ? this.props.returndata.fromMRPunit : 0,
                toMRPunit: this.props.returndata.toMRPunit != undefined ? this.props.returndata.toMRPunit : 0,
                fromBuyingprice: this.props.returndata.fromBuyingprice != undefined ? this.props.returndata.fromBuyingprice : 0,
                toBuyingprice: this.props.returndata.toBuyingprice != undefined ? this.props.returndata.toBuyingprice : 0,
                fromQuantity: this.props.returndata.fromQuantity != undefined ? this.props.returndata.fromQuantity : 0,
                toQuantity: this.props.returndata.toQuantity != undefined ? this.props.returndata.toQuantity : 0,
                QueryFromDate: this.props.returndata.QueryFromDate != undefined ? this.props.returndata.QueryFromDate : "",
                QueryToDate: this.props.returndata.QueryToDate != undefined ? this.props.returndata.QueryToDate : "",
            },
            dateRange: false,
            fromsliderMaxValue: 200,
            tosliderMaxValue: 200,
            toQuantityhelperText: "",
            toQuantityerror: "",
            toMRPunithelperText: "",
            toMRPuniterror: "",
            toBuyingpricehelperText: "",
            toBuyingpriceerror: "",
            dosageType: JSON.parse(localGetItem("DosageType"))
        }
    }

    handleClose = () => {
        this.props.filterClose()
    }

    /*Clear All Filter value Function start */
    clearAll = () => {
        this.setState({
            toQuantityhelperText: "",
            toQuantityerror: "",
            toMRPunithelperText: "",
            toMRPuniterror: "",
            toBuyingpricehelperText: "",
            toBuyingpriceerror: "",
            searchData: {
                dosageType: [],
                batchNumber: "",
                fromMRPunit: 0,
                toMRPunit: 0,
                fromBuyingprice: 0,
                toBuyingprice: 0,
                fromQuantity: 0,
                toQuantity: 0,
                QueryFromDate: "",
                QueryToDate: "",
            },
        }, () => this.props.clearSearchdata(this.state.searchData))
    }
    /*Clear All Filter value Function end */
    handleChange = (event, newValue) => {
        this.setState({ tabID: newValue })
    }

    /*Batchnumber Textbox function start */
    textInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        var { searchData } = this.state
        searchData['batchNumber'] = value
        this.setState({
            [name]: searchData,
        });
    };
    /*Batchnumber Textbox function end */

    /* MRP Enter Text Box Function start */
    decimalNumInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;

        const re = /^(\d*)((\.(\d{0,2})?)?)$/;
        if ((value === "" || re.test(value))) {
            var { searchData } = this.state
            searchData[name] = value
            this.setState({
                searchData
            });
        }

    };
    /* MRP Enter Text Box Function end */

    /* customdate popup function start*/
    PopClose = () => {
        this.setState({
            dateRange: false,
        })
    }
    /* customdate popup function end*/

    /* customdate select function start*/
    CustomDates = (FromDate, ToDate) => {
        var { searchData } = this.state
        searchData['QueryFromDate'] = FromDate
        searchData['QueryToDate'] = ToDate
        this.setState({
            searchData,
            dateRange: false
        })
    }
    /* customdate select function end*/

    /*Quantity Filed Function start */
    numInput = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        var { searchData } = this.state
        searchData[name] = value
        const re = /^[0-9\b]+$/;
        if (value === "" || re.test(value)) {
            this.setState({
                searchData,
            }, () => {
                let frommaxValue = parseInt(this.state.searchData.fromQuantity) + 200
                let tomaxValue = parseInt(this.state.searchData.toQuantity) + 200
                this.setState({
                    fromsliderMaxValue: frommaxValue,
                    tosliderMaxValue: tomaxValue
                })
            });

        }

    };

    fromDecreaseQuantity = () => {
        var fromQty = parseInt(this.state.searchData.fromQuantity)
        var { searchData } = this.state
        searchData['fromQuantity'] = parseInt(this.state.searchData.fromQuantity) === 0 ? 0 : fromQty - 1
        this.setState({
            searchData
        })
    }
    fromIncreaseQuantity = () => {
        var fromQty = parseInt(this.state.searchData.fromQuantity)
        var { searchData } = this.state
        searchData['fromQuantity'] = fromQty + 1
        this.setState({
            searchData
        })
    }
    toDecreaseQuantity = () => {
        var toQty = parseInt(this.state.searchData.toQuantity)
        var { searchData } = this.state
        searchData['toQuantity'] = parseInt(this.state.searchData.toQuantity) === 0 ? 0 : toQty - 1
        this.setState({
            searchData
        })
    }
    toIncreaseQuantity = () => {
        var toQty = parseInt(this.state.searchData.toQuantity)
        var { searchData } = this.state
        searchData['toQuantity'] = toQty + 1
        this.setState({
            searchData
        })
    }
    /*Quantity Filed Function end */

    /*Quantity Siled Function start */
    fromSliderChange = (event, newValue) => {
        var { searchData } = this.state
        searchData['fromQuantity'] = newValue
        this.setState({ searchData })
    }
    toSliderChange = (event, newValue) => {
        var { searchData } = this.state
        searchData['toQuantity'] = newValue
        this.setState({ searchData })
    }
    /*Quantity slide Function end */

    /*DialogBox Close Functon start */
    dialogCloseFunction = () => {
        this.setState({ failedDialogBox: false })
    }
    /*DialogBox Close Functon end */

    submitData = () => {
        const { t } = this.props

        if ((this.state.searchData.fromQuantity != 0 ? parseInt(this.state.searchData.fromQuantity) <= parseInt(this.state.searchData.toQuantity) : true) &&
            (this.state.searchData.fromMRPunit != 0 ? parseInt(this.state.searchData.fromMRPunit) <= parseInt(this.state.searchData.toMRPunit) : true) &&
            (this.state.searchData.fromBuyingprice != 0 ? parseInt(this.state.searchData.fromBuyingprice) <= parseInt(this.state.searchData.toBuyingprice) : true)
        ) {
            this.handleClose()
            this.props.searchData(this.state.searchData)
        } else {
            if (parseInt(this.state.searchData.fromQuantity) > parseInt(this.state.searchData.toQuantity)) {
                this.setState({
                    toQuantityerror: true,
                    toQuantityhelperText: t("ToQuantityGreaterthenFromQuantity")
                })
            } else {
                this.setState({
                    toQuantityerror: false,
                    toQuantityhelperText: ""
                })
            } if (parseInt(this.state.searchData.fromMRPunit) > parseInt(this.state.searchData.toMRPunit)) {
                this.setState({
                    toMRPuniterror: true,
                    toMRPunithelperText: t("ToMRPGreaterthenFromMRP")
                })
            } else {
                this.setState({
                    toMRPuniterror: false,
                    toMRPunithelperText: ""
                })
            } if (parseInt(this.state.searchData.fromBuyingprice) > parseInt(this.state.searchData.toBuyingprice)) {
                this.setState({
                    toBuyingpriceerror: true,
                    toBuyingpricehelperText: t("ToBuyingPriceGreaterthenFromBuyingPrice")
                })
            } else {
                this.setState({
                    toBuyingpriceerror: false,
                    toBuyingpricehelperText: ""
                })
            }
        }

    }

    render() {
        const { t } = this.props

        const quantitySlide = [
            { value: 0, scaledValue: 0, label: "0" },
            { value: 100, scaledValue: 100, label: "100" },
            { value: 200, scaledValue: 200, label: "200" },
            { value: 300, scaledValue: 300, label: "300" },
            { value: 400, scaledValue: 400, label: "400" },
        ]


        return (
            <div>
                <Dialog open={this.state.openFilter} >
                    <DialogTitle sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }} >
                        <div className="eMed_filterHead">
                            <div>
                                {t('Filter')}
                            </div>
                            <div>
                                <CloseIcon onClick={this.handleClose} id="eMed_Dialog_CloseButton" />
                            </div>
                        </div>
                    </DialogTitle>

                    <DialogContent sx={{ bgcolor: Colors.PopupBackground }}>
                        <div>
                            <TabContext value={this.state.tabID}>
                                <div className="eMed_filterTabs">
                                    <div>
                                        <TabList id="eMed_filterTabList" sx={{ borderRight: 1, borderColor: 'divider' }} orientation="vertical" onChange={this.handleChange}>
                                            {FilterTabs.Tabs.map((data) => (
                                                <Tab value={data.id} label={t(data.TabName)} />
                                            )
                                            )}

                                        </TabList>
                                    </div>
                                    <div>
                                        <TabPanel value={"0"}>
                                            <div className="eMed_filterDosageType">
                                                <Autocomplete
                                                    multiple
                                                    id="tags-outlined"
                                                    options={this.state.dosageType}
                                                    getOptionLabel={(option) => option.dosage_type}
                                                    value={this.state.searchData.dosageType}
                                                    onChange={(event, newValue) => {
                                                        var { searchData } = this.state
                                                        searchData["dosageType"] = newValue
                                                        this.setState({
                                                            searchData
                                                        })
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label={t("SelectDosageType")}
                                                            placeholder={t("DosageType")}
                                                        />
                                                    )}
                                                />
                                            </div>
                                        </TabPanel>
                                        <TabPanel value={"1"}>
                                            <div className="eMed_filterBatchNo">
                                                <FormControl className="eMed_filterBatchNobox">
                                                    <InputLabel>{t('BatchNumber')}</InputLabel>
                                                    <OutlinedInput
                                                        value={this.state.searchData.batchNumber}
                                                        onChange={this.textInput}
                                                        name="batchNumber"
                                                        label={t('BatchNumber')}
                                                    />
                                                </FormControl>
                                            </div>
                                        </TabPanel>
                                        <TabPanel value={"2"}>
                                            <div>
                                                <p>{t('From')}</p>
                                                <div className="eMed_filterQuantity">
                                                    <div className='eMed_filterquantityFiled'>

                                                        <div><IconButton id='eMed_filterquantityLBtn' disabled={this.state.searchData.fromQuantity === 0 ? true : false} onClick={this.fromDecreaseQuantity}><RemoveOutlinedIcon sx={{ color: this.state.searchData.fromQuantity !== 0 ? Colors.ThemeColor : '#BFBFBF' }} /></IconButton></div>
                                                        <TextField
                                                            id="eMed_filterquantityTextbox"
                                                            onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4) }}
                                                            label={t('Qty')}
                                                            size='small'
                                                            name='fromQuantity'
                                                            value={this.state.searchData.fromQuantity}
                                                            onChange={this.numInput}
                                                        />
                                                        <div><IconButton id='eMed_filterquantityRBtn' onClick={this.fromIncreaseQuantity}><AddOutlinedIcon style={{ color: Colors.ThemeColor }} /></IconButton></div>
                                                    </div>
                                                </div>
                                                <div className="eMed_filterBatchNo">
                                                    <Box sx={{ width: 300 }}>
                                                        <Slider
                                                            style={{ maxWidth: 500 }}
                                                            value={this.state.searchData.fromQuantity}
                                                            min={0}
                                                            step={1}
                                                            max={this.state.fromsliderMaxValue}
                                                            onChange={this.fromSliderChange}
                                                            valueLabelDisplay="on"
                                                            aria-labelledby="non-linear-slider"
                                                        />
                                                    </Box>
                                                </div>
                                                <p>{t('To')}</p>
                                                <div className="eMed_filterQuantity">
                                                    <div className='eMed_filterquantityFiled'>
                                                        <div><IconButton id='eMed_filterquantityLBtn' disabled={this.state.searchData.toQuantity === 0 ? true : false} onClick={this.toDecreaseQuantity}><RemoveOutlinedIcon sx={{ color: this.state.searchData.toQuantity !== 0 ? Colors.ThemeColor : '#BFBFBF' }} /></IconButton></div>
                                                        <TextField
                                                            id="eMed_filterquantityTextbox"
                                                            onInput={(e) => { e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4) }}
                                                            label={t('Qty')}
                                                            size='small'
                                                            name='toQuantity'
                                                            value={this.state.searchData.toQuantity}
                                                            onChange={this.numInput}
                                                            error={this.state.toQuantityerror}
                                                        />
                                                        <div><IconButton id='eMed_filterquantityRBtn' onClick={this.toIncreaseQuantity}><AddOutlinedIcon style={{ color: Colors.ThemeColor }} /></IconButton></div>
                                                    </div>
                                                </div>
                                                <div className="eMed_filterBatchNo">
                                                    <Box sx={{ width: 300 }}>
                                                        <Slider
                                                            style={{ maxWidth: 500 }}
                                                            value={this.state.searchData.toQuantity}
                                                            min={0}
                                                            step={1}
                                                            max={this.state.tosliderMaxValue}
                                                            onChange={this.toSliderChange}
                                                            valueLabelDisplay="on"
                                                            aria-labelledby="non-linear-slider"
                                                        />
                                                    </Box>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel value={"3"}>
                                            <div className="eMed_filterBatchNo">
                                                <FormControl className="eMed_filterBatchNobox">
                                                    <InputLabel>{t('From')}</InputLabel>
                                                    <OutlinedInput
                                                        value={this.state.searchData.fromMRPunit}
                                                        onChange={this.decimalNumInput}
                                                        name="fromMRPunit"
                                                        startAdornment={<InputAdornment position="start"><CurrencyRupeeIcon /></InputAdornment>}
                                                        label={t('From')}
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className="eMed_filterBatchNo">
                                                <FormControl className="eMed_filterBatchNobox">
                                                    <InputLabel>{t('To')}</InputLabel>
                                                    <OutlinedInput
                                                        value={this.state.searchData.toMRPunit}
                                                        onChange={this.decimalNumInput}
                                                        name="toMRPunit"
                                                        startAdornment={<InputAdornment position="start"><CurrencyRupeeIcon /></InputAdornment>}
                                                        label={t('To')}
                                                        error={this.state.toMRPuniterror}
                                                    />
                                                </FormControl>
                                            </div>
                                        </TabPanel>
                                        <TabPanel value={"4"}>
                                            <div className="eMed_filterBatchNo">
                                                <FormControl className="eMed_filterBatchNobox">
                                                    <InputLabel>{t('From')}</InputLabel>
                                                    <OutlinedInput
                                                        value={this.state.searchData.fromBuyingprice}
                                                        onChange={this.decimalNumInput}
                                                        name="fromBuyingprice"
                                                        startAdornment={<InputAdornment position="start"><CurrencyRupeeIcon /></InputAdornment>}
                                                        label={t('From')}
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className="eMed_filterBatchNo">
                                                <FormControl className="eMed_filterBatchNobox">
                                                    <InputLabel>{t('To')}</InputLabel>
                                                    <OutlinedInput
                                                        value={this.state.searchData.toBuyingprice}
                                                        onChange={this.decimalNumInput}
                                                        name="toBuyingprice"
                                                        startAdornment={<InputAdornment position="start"><CurrencyRupeeIcon /></InputAdornment>}
                                                        label={t('To')}
                                                        error={this.state.toBuyingpriceerror}

                                                    />
                                                </FormControl>
                                            </div>
                                        </TabPanel>
                                        <TabPanel value={"5"}>
                                            <div className="eMed_filterBatchNo">
                                                <FormControl className="eMed_filterBatchNobox">
                                                    <InputLabel>{t('From')}</InputLabel>
                                                    <OutlinedInput
                                                        onClick={() => this.setState({ dateRange: true })}
                                                        name="dateRange"
                                                        label={t('From')}
                                                        value={`${this.state.searchData.QueryFromDate === undefined ? null : this.state.searchData.QueryFromDate.split("-").reverse().join("-")}`}
                                                    />
                                                </FormControl>
                                            </div>
                                            <div className="eMed_filterBatchNo">
                                                <FormControl className="eMed_filterBatchNobox">
                                                    <InputLabel>{t('To')}</InputLabel>
                                                    <OutlinedInput
                                                        onClick={() => this.setState({ dateRange: true })}
                                                        name="dateRange"
                                                        label={t('To')}
                                                        value={`${this.state.searchData.QueryToDate === undefined ? null : this.state.searchData.QueryToDate.split("-").reverse().join("-")}`}
                                                    />
                                                </FormControl>
                                            </div>
                                            {this.state.dateRange ? <DatePicker PopClose={this.PopClose} CustomDates={this.CustomDates} /> : null}
                                        </TabPanel>
                                    </div>
                                </div>
                            </TabContext>
                        </div>
                    </DialogContent >
                    <Divider />
                    <DialogActions id='eMed_filterBtn' sx={{ bgcolor: Colors.PopupBackground }}>
                        <Button variant="contained" onClick={this.submitData}>{t('Search')}</Button>
                        <Button variant="outlined" onClick={this.clearAll}>
                            {t("ClearAll")}
                        </Button>
                    </DialogActions>
                    <div>
                        <FormHelperText id="eMed_filterHelperText">{this.state.toQuantityhelperText}</FormHelperText>
                        <FormHelperText id="eMed_filterHelperText">{this.state.toMRPunithelperText}</FormHelperText>
                        <FormHelperText id="eMed_filterHelperText">{this.state.toBuyingpricehelperText}</FormHelperText>
                    </div>
                </Dialog >
            </div >
        )
    }
}
export default withTranslation()(FilterModel);