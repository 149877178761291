import { Divider, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import { Colors } from '../../../../../Styles/Colors';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button } from '@mui/material';
import { AmountFormat } from '../../../../../Components/CommonFunctions/CommonFunctions';
import { localGetItem } from '../../../../../Utility/Services/CacheProviderService'
import { Serviceurls } from '../../../../../Utility/API/Serviceurls';
import RestAPIService from '../../../../../Utility/Services/RestAPIService';
import Dialog from '../../../../../Components/Dialog';
import Failed from '../../../../../Assets/Animation/Failed.gif';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

class GstSplitUp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            Data: [],
            DataFilter: [],
            SearchFieldOpen: false,
            SearchText: "",
            page: 0,
            rowsPerPage: 5,
            datePickerPop: false,
            customDropDown: 0,
            PurchaseSortOrder: 'asc',
            PurchaseSortIcon: 'none',
            SalesSortOrder: 'asc',
            SalesSortIcon: 'none',
            ProfitSortOrder: 'asc',
            ProfitSortIcon: 'none',
            QueryFrmDte: "",
            QueryToDte: "",
            PharmacyID: localGetItem("Pharmacy_Id"),
            ConsolidateSplitUp: false,
            SelectedMonth: '',
            stockMenuPop: null,
        }
    }

    componentDidMount() {
        this.getSalesConsolidate()
    }

    getSalesConsolidate = () => {
        try {
            let QueryParams = `?pharmacy_id=${this.state.PharmacyID}&invoice_date=${this.props.month}`
            RestAPIService.getAll(Serviceurls.GET_SALES_GST_SPLITUP + QueryParams)
                .then(response => {
                    this.setState({
                        Data: response.data.data,
                        DataFilter: response.data.data,

                    })
                })
                .catch(e => {
                    this.setState({ dialogBox: true, ErrorMsg: e.message });
                    setTimeout(() => {
                        this.setState({
                            dialogBox: false
                        })
                    }, 3000)
                });

        }
        catch (e) {
            this.setState({ dialogBox: true, ErrorMsg: e.message });
            setTimeout(() => {
                this.setState({
                    dialogBox: false
                })
            }, 3000)
        }
    }

    handleClose = () => {
        this.setState({
            ConsolidateSplitUp: false
        })
    }

    dialogCloseFunction = () => {
        this.setState({
            dialogBox: false
        })
    }

    render() {
        const { t } = this.props;
        // Sorting Function   ==== starts
        const sorting = (col, SortOrder) => {
            if (SortOrder === "asc") {
                const Sorted = [...this.state.Data].sort((a, b) => {
                    if (typeof (a[col]) == "string") {
                        return a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
                    } else {
                        return a[col] > b[col] ? 1 : -1
                    }
                })
                this.setState({ Data: Sorted })

                if (col === "purchase_amount") {
                    this.setState({
                        PurchaseSortOrder: 'desc',
                        PurchaseSortIcon: 'asc',
                        SalesSortOrder: 'asc',
                        SalesSortIcon: 'none',
                        ProfitSortOrder: 'asc',
                        ProfitSortIcon: 'none',
                    })
                }
                else if (col === "sales_amount") {
                    this.setState({
                        PurchaseSortOrder: 'asc',
                        PurchaseSortIcon: 'none',
                        SalesSortOrder: 'desc',
                        SalesSortIcon: 'asc',
                        ProfitSortOrder: 'asc',
                        ProfitSortIcon: 'none',
                    })
                }
                else if (col === "profit_amount") {
                    this.setState({
                        PurchaseSortOrder: 'asc',
                        PurchaseSortIcon: 'none',
                        SalesSortOrder: 'asc',
                        SalesSortIcon: 'none',
                        ProfitSortOrder: 'desc',
                        ProfitSortIcon: 'asc',
                    })
                }


            }
            else if (SortOrder === "desc") {
                const Sorted = [...this.state.Data].sort((a, b) => {
                    if (typeof (a[col]) == "string") {
                        return a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
                    } else {
                        return a[col] < b[col] ? 1 : -1
                    }
                })
                this.setState({ Data: Sorted })

                if (col === "purchase_amount") {
                    this.setState({ PurchaseSortOrder: 'default', PurchaseSortIcon: "desc" })
                }
                else if (col === "sales_amount") {
                    this.setState({ SalesSortOrder: 'default', SalesSortIcon: "desc" })
                }
                else if (col === "profit_amount") {
                    this.setState({ ProfitSortOrder: 'default', ProfitSortIcon: "desc" })
                }
            }
            else if (SortOrder === "default") {
                this.setState({
                    PurchaseSortOrder: 'asc',
                    PurchaseSortIcon: 'none',
                    SalesSortOrder: 'asc',
                    SalesSortIcon: 'none',
                    ProfitSortOrder: 'asc',
                    ProfitSortIcon: 'none',
                    Data: this.state.DataFilter
                })
            }
        }
        // Sorting Function   ==== ends

        const ExportPop = (event) => {
            this.setState({ stockMenuPop: event.currentTarget })
        };
        const ExportPopClose = () => {
            this.setState({ stockMenuPop: null })
        };
        const ExportPopBoolean = Boolean(this.state.stockMenuPop);
        return (
            <div>
                <div className="eMed_page_header">
                    <div id='eMed_name_totalcard'>
                        <div id="eMed_table_name" style={{ color: Colors.ThemeColor }}>
                            {t(this.props.month)}
                        </div>
                    </div>
                    <div id='eMed_tbl_filters'>
                        <Tooltip disableInteractive title={t("Export")} placement='bottom'>
                            <Button onClick={ExportPop}>
                                <SimCardDownloadIcon id='eMed_Header_Icons' sx={{ color: Colors.ThemeColor }} />
                            </Button>
                        </Tooltip>
                        <Menu
                            id="basic-menu"
                            anchorEl={this.state.stockMenuPop}
                            open={ExportPopBoolean}
                            onClose={ExportPopClose}
                        >
                            <MenuItem ><ListAltIcon fontSize='small' id='eMed_menuList_icons' sx={{ color: Colors.blue }} /> {t("ExportAsExcel")}</MenuItem>
                            <Divider />
                            <MenuItem ><PictureAsPdfIcon fontSize='small' id='eMed_menuList_icons' sx={{ color: Colors.red }} /> {t("ExportAsPdf")}</MenuItem>
                        </Menu>

                    </div>
                </div>
                <div>
                    <Paper elevation={0} className='eMed_splitup_card' sx={{ bgcolor: Colors.TableCard }}>
                        <TableContainer className='eMed_splitup_container'>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='center' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div style={{ color: Colors.TableHeaderTextColor }} className='eMed_sortable_center' id='eMed_tbl_headtext' >
                                                {t("SNo")}
                                            </div>
                                        </TableCell>
                                        <TableCell align='center' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div style={{ color: Colors.TableHeaderTextColor }} className='eMed_sortable_center' id='eMed_tbl_headtext'>
                                                {t("GST")}(%)
                                            </div>
                                        </TableCell>
                                        <TableCell align='center' className='eMed_amount_column' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div style={{ color: Colors.TableHeaderTextColor }} className='eMed_sortable_number' id='eMed_tbl_headtext'>
                                                {t("CGSTAmount")}(₹)
                                            </div>
                                        </TableCell>
                                        <TableCell align='center' className='eMed_amount_column' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div style={{ color: Colors.TableHeaderTextColor }} className='eMed_sortable_number' id='eMed_tbl_headtext'>
                                                {t("SGSTAmount")}(₹)
                                            </div>
                                        </TableCell>
                                        <TableCell align='right' className='eMed_amount_column' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div style={{ color: Colors.TableHeaderTextColor }} className='eMed_sortable_number' id='eMed_tbl_headtext'>
                                                {t("GSTAmount")}(₹)
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {this.state.Data.length > 0 ? this.state.Data.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((data, index) => (
                                    <TableBody key={index} h>
                                        <TableRow>
                                            <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} style={{ color: Colors.TableHeaderTextColor }} align='center'>
                                                <div id='eMed_tbl_bodytext'> {index + 1}</div>
                                            </TableCell>
                                            <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} style={{ color: Colors.TableHeaderTextColor }} align='center'>
                                                <div id='eMed_tbl_bodytext'>{data.gst_percentage}{typeof (data.gst_percentage) == 'string' ? null : " (" + data.gst_percentage / 2 + " + " + data.gst_percentage / 2 + ")"}</div>
                                            </TableCell>
                                            <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} style={{ color: Colors.TableHeaderTextColor }} align='right'>
                                                <div id='eMed_tbl_bodytext'>{AmountFormat(data.cgst_amount)}</div>
                                            </TableCell>
                                            <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} style={{ color: Colors.TableHeaderTextColor }} align='right'>
                                                <div id='eMed_tbl_bodytext'>{AmountFormat(data.sgst_amount)}</div>
                                            </TableCell>
                                            <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} style={{ color: Colors.TableHeaderTextColor }} align='right'>
                                                <div id='eMed_tbl_bodytext'>{AmountFormat(data.taxable_amount)}</div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )) :
                                    <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_Norecords'>
                                        {t("NoRecords")}
                                    </div>}
                            </Table>
                        </TableContainer>
                    </Paper>
                    {this.state.dialogBox ?
                        <Dialog
                            type={"Notification"}
                            DialogClose={this.dialogCloseFunction}
                            header={"Message"}
                            content={this.state.ErrorMsg}
                            icon={<img className="eMed_dilboxAnimations" alt="Warning" src={Failed} />}
                            secondary={"Close"}
                            closeButton={this.dialogCloseFunction}
                        /> : null}
                </div>
            </div>
        )
    }
}
export default withTranslation()(GstSplitUp)
