import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import { Colors } from '../../../../../Styles/Colors';
import Tooltip from '@mui/material/Tooltip';
import { TablePagination } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PaymentCard from '../../../../../Components/PaymentCard/PaymentCard'
import { Button, FormHelperText } from '@mui/material';
import { AmountFormat } from '../../../../../Components/CommonFunctions/CommonFunctions';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import './SalesGST.css';
import { localGetItem } from '../../../../../Utility/Services/CacheProviderService'
import { Serviceurls } from '../../../../../Utility/API/Serviceurls';
import RestAPIService from '../../../../../Utility/Services/RestAPIService';
import Dialog from '../../../../../Components/Dialog';
import Failed from '../../../../../Assets/Animation/Failed.gif';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ModelDialogBox from '../../../../../Components/ModelDialogBox/ModelDialogBox';
import GstSplitUp from './GstSplitUp';


class SalesGST extends Component {
    constructor(props) {
        super(props)
        var date = new Date();
        var year = date.getFullYear();
        this.state = {
            Data: [],
            DataFilter: [],
            SearchFieldOpen: false,
            SearchText: "",
            page: 0,
            rowsPerPage: 5,
            datePickerPop: false,
            datefilter: 0,
            DateSortOrder: 'asc',
            DateSortIcon: 'none',
            CGSTSortOrder: 'asc',
            CGSTSortIcon: 'none',
            SGSTSortOrder: 'asc',
            SGSTSortIcon: 'none',
            GSTSortOrder: 'asc',
            GSTSortIcon: 'none',
            PharmacyID: localGetItem("Pharmacy_Id"),
            YearQuery: year,
            GSTSplitUp: false,
            isDatePickerOpen: false
        }
    }

    componentDidMount() {
        this.getSalesGST()
    }

    getSalesGST = () => {
        try {
            let QueryParams = `?pharmacy_id=${this.state.PharmacyID}&page=&page_size=all&year=${this.state.YearQuery}`
            RestAPIService.getAll(Serviceurls.GET_SALES_GST + QueryParams)
                .then(response => {
                    this.setState({
                        Data: response.data.data.result,
                        TotalGST: response.data.data.total_gst_amount,
                        CGST: response.data.data.total_cgst_amount,
                        SGST: response.data.data.total_sgst_amount
                    })
                    this.CalculateAmount()
                })
                .catch(e => {
                    this.setState({ dialogBox: true, ErrorMsg: e.message });
                    setTimeout(() => {
                        this.setState({
                            dialogBox: false
                        })
                    }, 3000)
                });
        }

        catch (e) {
            this.setState({ dialogBox: true, ErrorMsg: e.message });
            setTimeout(() => {
                this.setState({
                    dialogBox: false
                })
            }, 3000)
        }
    }

    CalculateAmount() {
        let FinalarrRes = [];
        var result = this.finalResult(this.state.Data);
        let keys = Object.keys(result);
        keys.forEach(ele => {
            FinalarrRes.push(result[ele])
        })
        this.setState({ FinalData: FinalarrRes, FinalDataFilter: FinalarrRes })
    }

    finalResult(data) {
        var result = [];
        return data.reduce((res, value) => {
            if (!res[value.month]) {
                res[value.month] = { month: value.month, cgst_amount: 0, sgst_amount: 0, total_amount: 0 };
                result.push(res[value.month])
            }
            res[value.month].cgst_amount += value.cgst_amount;
            res[value.month].sgst_amount += value.sgst_amount;
            res[value.month].total_amount += value.total_amount;
            return res;
        }, {});
    }


    //  Mui table Pagination Functions --- starts

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value, page: 0 });
    }

    //  Mui table Pagination Functions --- ends

    dialogCloseFunction = () => {
        this.setState({
            dialogBox: false
        })
    }

    SetYear = () => {
        this.setState({
            YearQuery: this.state.value != null ? this.state.value.getFullYear() : ""
        }, () => this.getSalesGST())
    }

    handleClose = () => {
        this.setState({
            GSTSplitUp: false
        })
    }


    render() {
        const { t } = this.props;
        //  filter table data based on search Text
        const filtereddata = this.state.FinalData !== undefined ? this.state.FinalData.filter((data) => {
            return (
                (data.month === null ? null : data.month.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
                (data.cgst_amount === null ? null : Math.round(data.cgst_amount.toFixed(2) * 100) === parseInt(Math.round(this.state.SearchText * 100))) ||
                (data.sgst_amount === null ? null : Math.round(data.sgst_amount.toFixed(2) * 100) === parseInt(Math.round(this.state.SearchText * 100))) ||
                (data.total_amount === null ? null : Math.round(data.total_amount.toFixed(2) * 100) === parseInt(Math.round(this.state.SearchText * 100)))
            );

        }) : []

        // Sorting Function   ==== starts
        const sorting = (col, SortOrder) => {
            if (SortOrder === "asc") {
                const Sorted = [...this.state.FinalData].sort((a, b) => {
                    if (typeof (a[col]) == "string") {
                        return a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
                    } else {
                        return a[col] > b[col] ? 1 : -1
                    }
                })
                this.setState({ FinalData: Sorted })

                if (col === "month") {
                    this.setState({
                        DateSortOrder: 'desc',
                        DateSortIcon: 'asc',
                        CGSTSortOrder: 'asc',
                        CGSTSortIcon: 'none',
                        SGSTSortOrder: 'asc',
                        SGSTSortIcon: 'none',
                        GSTSortOrder: 'asc',
                        GSTSortIcon: 'none'
                    })
                }
                else if (col === "cgst_amount") {
                    this.setState({
                        DateSortOrder: 'asc',
                        DateSortIcon: 'none',
                        CGSTSortOrder: 'desc',
                        CGSTSortIcon: 'asc',
                        SGSTSortOrder: 'asc',
                        SGSTSortIcon: 'none',
                        GSTSortOrder: 'asc',
                        GSTSortIcon: 'none'
                    })
                }
                else if (col === "sgst_amount") {
                    this.setState({
                        DateSortOrder: 'asc',
                        DateSortIcon: 'none',
                        CGSTSortOrder: 'asc',
                        CGSTSortIcon: 'none',
                        SGSTSortOrder: 'desc',
                        SGSTSortIcon: 'asc',
                        GSTSortOrder: 'asc',
                        GSTSortIcon: 'none'
                    })
                }
                else if (col === "total_amount") {
                    this.setState({
                        DateSortOrder: 'asc',
                        DateSortIcon: 'none',
                        CGSTSortOrder: 'asc',
                        CGSTSortIcon: 'none',
                        SGSTSortOrder: 'asc',
                        SGSTSortIcon: 'none',
                        GSTSortOrder: 'desc',
                        GSTSortIcon: 'asc'
                    })
                }
            }
            else if (SortOrder === "desc") {
                const Sorted = [...this.state.FinalData].sort((a, b) => {
                    if (typeof (a[col]) == "string") {
                        return a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
                    } else {
                        return a[col] < b[col] ? 1 : -1
                    }
                })
                this.setState({ FinalData: Sorted })

                if (col === "month") {
                    this.setState({ DateSortOrder: 'default', DateSortIcon: "desc" })
                }
                else if (col === "cgst_amount") {
                    this.setState({ CGSTSortOrder: 'default', CGSTSortIcon: "desc" })
                }
                else if (col === "sgst_amount") {
                    this.setState({ SGSTSortOrder: 'default', SGSTSortIcon: "desc" })
                }
                else if (col === "total_amount") {
                    this.setState({ GSTSortOrder: 'default', GSTSortIcon: "desc" })
                }
            }
            else if (SortOrder === "default") {
                this.setState({
                    DateSortOrder: 'asc',
                    DateSortIcon: 'none',
                    CGSTSortOrder: 'asc',
                    CGSTSortIcon: 'none',
                    SGSTSortOrder: 'asc',
                    SGSTSortIcon: 'none',
                    GSTSortOrder: 'asc',
                    GSTSortIcon: 'none',
                    FinalData: this.state.FinalDataFilter
                })
            }
        }
        // Sorting Function   ==== ends

        //  Sorting Icons Change func === starts 
        const SortIcon = (sortType) => {
            if (sortType === "asc") {
                return <Tooltip disableInteractive placement='top' title={t("InAscendingOrder")}><KeyboardArrowUpIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
            }
            else if (sortType === 'desc') {
                return <Tooltip disableInteractive placement='top' title={t("InDecendingOrder")}><KeyboardArrowDownIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
            }
            else return <Tooltip disableInteractive placement='top' title={t("Sorting")}><FilterListIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
        }

        //  Sorting Icons Change func === ends 

        return (
            <div>
                <div className="eMed_page_header">
                    <div id='eMed_name_totalcard'>
                        <div id="eMed_table_name" style={{ color: Colors.TextColor }}>
                            {t(this.props.menuName)}
                        </div>
                        <div id="eMed_tbl_paymentcard">
                            <PaymentCard Color={Colors.blue} Icons={<CurrencyRupeeIcon />} Name={t("TotalGST")} Amount={this.state.TotalGST} />
                            <PaymentCard Color={Colors.orange} Icons={<AccountBalanceIcon />} Name={t("CSGT")} Amount={this.state.CGST} />
                            <PaymentCard Color={Colors.liteViolet} Icons={<AccountBalanceIcon />} Name={t("SGST")} Amount={this.state.SGST} />
                        </div>
                    </div>
                    <div id='eMed_tbl_filters'>
                        <div>
                            <LocalizationProvider dateAdapter={AdapterDateFns} >
                                <DatePicker
                                    open={this.state.isDatePickerOpen}
                                    onOpen={() => this.setState({ isDatePickerOpen: true })}
                                    onClose={() => this.setState({ isDatePickerOpen: false })}
                                    views={['year']}
                                    label={t("SelectYear")}
                                    minDate={new Date('2015-01-01')}
                                    maxDate={new Date(new Date().setFullYear(new Date().getFullYear()))}
                                    value={this.state.value}
                                    onChange={(newValue) => {
                                        this.setState({
                                            value: newValue,
                                            isDatePickerOpen: false
                                        })
                                    }}
                                    onAccept={(values) => {
                                        this.setState({
                                            value: values
                                        }, () => {
                                            this.SetYear()
                                        })

                                    }}
                                    renderInput={(params) => <TextField 
                                     sx={{ input: { color: Colors.TextColor } }} onClick={(e) => this.setState({ isDatePickerOpen: true })} id='eMed_year_filter' size='small' {...params} helperText={null} />}
                                />
                            </LocalizationProvider>

                        </div>
                        {this.state.SearchFieldOpen ?
                            <div id="eMed_tbl_searchbox" >
                                <TextField
                                    sx={{ input: { color: Colors.TextColor } }}
                                    size='small'
                                    focused
                                    onChange={(e) => this.setState({ SearchText: e.target.value })}
                                    value={this.state.SearchText}
                                    label={t("SearchHere")}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip disableInteractive title={t("Clear")} placement='top'><CloseIcon sx={{ color: Colors.ThemeColor }} onClick={() => { this.setState({ SearchText: '' }) }} /></Tooltip>
                                        ),
                                    }}
                                />
                            </div> : null}
                        <Tooltip disableInteractive placement="top-start" title={this.state.SearchFieldOpen ? t("CloseSearch") : t("OpenSearch")}>
                            <Button id="eMed_tbl_searchicon" variant='contained' style={{ backgroundColor: Colors.ThemeColor }} onClick={() => this.setState({ SearchFieldOpen: !this.state.SearchFieldOpen, CardOpen: false, SearchText: '' })} >
                                {this.state.SearchFieldOpen ? <CloseIcon /> : <SearchIcon />}
                            </Button>
                        </Tooltip>
                    </div>
                </div>
                <div>
                    <Card className='eMed_tbl_card' sx={{ bgcolor: Colors.TableCard }}>
                        <TableContainer className='eMed_tbl_container'>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='center' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>

                                            <div className="eMed_sortable_center">
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("SNo")}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell align='center' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>

                                            <div className="eMed_sortable_center">
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("Month")}</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("month", this.state.DateSortOrder) }}>{SortIcon(this.state.DateSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell align='center' className='eMed_amount_column' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>

                                            <div className="eMed_sortable_number">
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("CGST")}(₹)</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("cgst_amount", this.state.CGSTSortOrder) }}>{SortIcon(this.state.CGSTSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell align='center' className='eMed_amount_column' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>

                                            <div className="eMed_sortable_number">
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'> {t("SGST")}(₹)</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("sgst_amount", this.state.SGSTSortOrder) }}>{SortIcon(this.state.SGSTSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell align='right' className='eMed_amount_column' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>

                                            <div className="eMed_sortable_number">
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'> {t("GSTAmount")}(₹)</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("total_amount", this.state.GSTSortOrder) }}>{SortIcon(this.state.GSTSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell sx={{ bgcolor: Colors.TableHeaderBarBGColor }}></TableCell>
                                    </TableRow>
                                </TableHead>
                                {filtereddata.length > 0 ? filtereddata.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((data, index) => (
                                    <TableBody key={index}>
                                        <TableRow id='eMed_clickable_tbl_row' hover onClick={() => { this.setState({ GSTSplitUp: true, SelectedMonth: data.month }) }}>
                                            <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='center'>
                                                <div id='eMed_tbl_bodytext' > {index + 1}</div>
                                            </TableCell>
                                            <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='center'>
                                                <div> {data.month}</div>
                                            </TableCell>
                                            <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='right'>
                                                <div id='eMed_tbl_bodytext'> {AmountFormat(data.cgst_amount.toFixed(2))}</div>
                                            </TableCell>
                                            <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='right'>
                                                <div id='eMed_tbl_bodytext'>{AmountFormat(data.sgst_amount.toFixed(2))}</div>
                                            </TableCell>
                                            <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='right'>
                                                <div id='eMed_tbl_bodytext'>{AmountFormat(data.total_amount.toFixed(2))}</div>
                                            </TableCell>
                                            <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}></TableCell>
                                        </TableRow>
                                    </TableBody>
                                )) :
                                    <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_Norecords'>
                                        {t("NoRecords")}
                                    </div>}
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component='div'
                            id="eMed_tbl_pagination"
                            sx={{
                                color: Colors.PagiantionTxtColor
                            }}
                            rowsPerPageOptions={[5, 10, 15]}
                            count={filtereddata.length}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </Card>
                    {this.state.GSTSplitUp ? <ModelDialogBox DialogClose={this.handleClose} header={t('SalesGST-SplitUp')} component={<GstSplitUp month={this.state.SelectedMonth} year={this.state.YearQuery} />} /> : null}
                    {this.state.dialogBox ?
                        <Dialog
                            type={"Notification"}
                            DialogClose={this.dialogCloseFunction}
                            header={"Message"}
                            content={this.state.ErrorMsg}
                            icon={<img className="eMed_dilboxAnimations" alt="Warning" src={Failed} />}
                            secondary={"Close"}
                            closeButton={this.dialogCloseFunction}
                        /> : null}
                </div>
            </div>
        )
    }
}
export default withTranslation()(SalesGST)