import React from 'react';
import './Settings.css';
import { Box, InputLabel, MenuItem, FormControl } from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import { Trans, withTranslation } from 'react-i18next';
import i18n from '../../Utility/i18n/locales/config';
import { localSetItem, localGetItem } from "../../Utility/Services/CacheProviderService";
import '../../Styles/CommonStyles.css'
import {Colors} from '../../Styles/Colors'

class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            theme: localGetItem("theme"),
            language: localGetItem("language")
        }
    }


    handleChangeTheme = (event) => {
        this.setState({
            theme: event.target.value
        }, () => {



        })
    }
    handleChangeLanguage = (event) => {


        this.setState({
            language: event.target.value
        })
    }
    render() {
        const { t } = this.props
        return (
            <div>
                <div>

                    <h1 style={{color:Colors.TextColor}}><Trans>{t('Settings')}</Trans></h1>

                </div>
                <div className='eMed_selectItem'>
                    <div>
                        <Box sx={{ minWidth: '15vw' }}>
                            <FormControl fullWidth size='small'>
                                <InputLabel sx={{color:Colors.TextColor}}>{t('Theme')}</InputLabel>
                                <Select
                                    sx={{color:Colors.TextColor}}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.theme}
                                    label={t('Theme')}
                                    onChange={this.handleChangeTheme}
                                    className="select-options-navbar-main-color"
                                >
                                    <MenuItem value="default">{t('Default')}</MenuItem>
                                    <MenuItem value="light">{t('Light')}</MenuItem>
                                    <MenuItem value="dark">{t('Dark')}</MenuItem>
                                </Select>

                            </FormControl>

                        </Box>
                    </div>
                    <div>
                        <Box sx={{ minWidth: '15vw' }}>
                            <FormControl fullWidth size='small'>
                                <InputLabel sx={{color:Colors.TextColor}}>{t('Languages')}</InputLabel>
                                <Select
                                 sx={{color:Colors.TextColor}}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.language}
                                    label={t('Languages')}
                                    onChange={this.handleChangeLanguage}
                                    className="select-options-navbar-main-color"
                                >
                                    <MenuItem value="en">{t('English')}</MenuItem>
                                    <MenuItem value="tm">{t('Tamil')}</MenuItem>
                                    <MenuItem value="arab">{t('Arabic')}</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                    </div>

                    {/* <div>
                        <Button variant="contained" color="primary" onClick={() => { localSetItem("theme", "default"); window.location.reload(false) }}>Set Default</Button>
                    </div> */}
                </div>
                <div className='eMed_settingsbtn'>
                    <Button onClick={() => {
                        // i18n.changeLanguage(localGetItem("language")); 
                        localSetItem("theme", this.state.theme);
                        localSetItem("language", this.state.language);
                        window.location.reload(false)
                    }} variant="contained">{t('Apply')}</Button>
                </div>

            </div>

        )
    }
}
export default withTranslation()(Settings);