import React, { Component } from 'react';
import { Paper, TableContainer, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Colors } from '../../../../Styles/Colors';
import { TablePagination } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Trans, withTranslation } from 'react-i18next';
import './History.css';
import Dialog from '../../../../Components/Dialog';
import Failed from '../../../../Assets/Animation/Failed.gif'
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Time_convert } from "../../../../Components/CommonFunctions/CommonFunctions"

class HistoryTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            rowsPerPage: 5,
            modifiedSortIcon: 'none',
            modifiedSortOrder: 'asc',
            modifiedbySortOrder: 'asc',
            modifiedbySortIcon: 'none',
            availableQuantSortIcon: 'none',
            availableQuantSortOrder: 'asc',
            history: '',
            dialogBox: false,
            pharmacyID: this.props.pharmacyId,
            historydata: this.props.history,
            Defaulthistory: ''
        }
    }

    componentDidMount() {
        this.getHistory();
    }

    getHistory = () => {
        try {
            let data = `?pharmacy_id=${this.state.pharmacyID}&drug_id=${this.state.historydata.drug_id}&batch_no=${this.state.historydata.batch_no}`
            RestAPIService.getAll(Serviceurls.GET_STOCK_ADJUST_HISTORY_LIST + data)
                .then(response => {
                    this.setState({
                        history: response.data.data,
                        Defaulthistory: response.data.data
                    })
                })
                .catch(e => {
                    this.setState({ dialogBox: true, ErrorMsg: e.message });
                    setTimeout(() => {
                        this.setState({
                            dialogBox: false
                        })
                    }, 3000)
                });
        }
        catch (e) {
            this.setState({ dialogBox: true, ErrorMsg: e.message });
            setTimeout(() => {
                this.setState({
                    dialogBox: false
                })
            }, 3000)
        }
    }

    dialogCloseFunction = () => {
        this.setState({ dialogBox: false })
    }

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value, page: 0 });
    }

    render() {

        const { t } = this.props

        // Sorting Function   ==== starts
        const sorting = (col, SortOrder) => {
            if (SortOrder === "asc") {
                const Sorted = [...this.state.history].sort((a, b) => {
                    if (typeof (a[col]) == "string") {
                        return a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
                    } else {
                        return a[col] > b[col] ? 1 : -1
                    }
                })
                this.setState({ history: Sorted })

                if (col === "modified_date") {
                    this.setState({ modifiedSortOrder: 'desc', availableQuantSortOrder: 'asc', modifiedbySortOrder: 'asc', modifiedSortIcon: "asc", availableQuantSortIcon: "none", modifiedbySortIcon: 'none' })
                }
                else if (col === "modified_by") {
                    this.setState({ modifiedbySortOrder: 'desc', availableQuantSortOrder: 'asc', modifiedSortOrder: 'asc', modifiedbySortIcon: "asc", availableQuantSortIcon: "none", modifiedSortIcon: 'none' })
                }
                else if (col === "to_quantity") {
                    this.setState({ availableQuantSortOrder: 'desc', modifiedbySortOrder: 'asc', modifiedSortOrder: 'asc', availableQuantSortIcon: "asc", modifiedbySortIcon: "none", modifiedSortIcon: 'none' })
                }

            }
            else if (SortOrder === "desc") {
                const Sorted = [...this.state.history].sort((a, b) => {
                    if (typeof (a[col]) == "string") {
                        return a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
                    } else {
                        return a[col] < b[col] ? 1 : -1
                    }
                })
                this.setState({ history: Sorted })

                if (col === "modified_date") {
                    this.setState({ modifiedSortOrder: 'default', modifiedSortIcon: "desc" })
                }
                else if (col === "modified_by") {
                    this.setState({ modifiedbySortOrder: 'default', modifiedbySortIcon: "desc" })
                }
                else if (col === "to_quantity") {
                    this.setState({ availableQuantSortOrder: 'default', availableQuantSortIcon: "desc" })
                }
            }
            else if (SortOrder === "default") {
                this.setState({
                    modifiedSortIcon: 'none',
                    availableQuantSortIcon: 'none',
                    modifiedbySortIcon: 'none',
                    modifiedSortOrder: 'asc',
                    modifiedbySortOrder: 'asc',
                    availableQuantSortOrder: 'asc',
                    history: this.state.Defaulthistory
                })
            }
        }
        // Sorting Function   ==== ends

        const reason = (value) => {
            if (value === 1) {
                return <div>Breakage</div>
            } else if (value === 2) {
                return <span>Loss</span>
            } else if (value === 3) {
                return <span>Expired</span>
            } else if (value === 4) {
                return <span>Miscellaneous</span>
            }
        }

        //  Sorting Icons Change func === starts 

        const SortIcon = (sortType) => {
            if (sortType === "asc") {
                return <Tooltip disableInteractive placement='top' title={t("InAscendingOrder")}><KeyboardArrowUpIcon sx={{ color: Colors.ThemeColor }} id='eMed_sorting_icon' /></Tooltip>
            }
            else if (sortType === 'desc') {
                return <Tooltip disableInteractive placement='top' title={t("InDecendingOrder")}><KeyboardArrowDownIcon sx={{ color: Colors.ThemeColor }} id='eMed_sorting_icon' /></Tooltip>
            }
            else return <Tooltip disableInteractive placement='top' title={t("Sorting")}><FilterListIcon sx={{ color: Colors.ThemeColor }} id='eMed_sorting_icon' /></Tooltip>
        }

        //  Sorting Icons Change func === ends

        return (
            <div>
                <Paper className="eMed_history_TablePaper" elevation={0} sx={{ bgcolor: Colors.TableCard }}>
                    <TableContainer id="eMed_History_TableContainer">
                        <Table stickyHeader>
                            <TableHead>
                                <TableRow>
                                    <TableCell className='eMed_tbl_headwidth_large' sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}>
                                        <div className=' eMed_sortable_center'>
                                            {t('LastUpdatedDate').length > 20 ?
                                                <Tooltip placement="top" title={t('LastUpdatedDate')} disableInteractive>
                                                    <div id="eMed_tbl_headtext">{t('LastUpdatedDate').slice(0, 20) + '...'}</div>
                                                </Tooltip> : <div id="eMed_tbl_headtext">{t('LastUpdatedDate')}</div>}
                                            <div onClick={() => { sorting("modified_date", this.state.modifiedSortOrder) }}>{SortIcon(this.state.modifiedSortIcon)}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell className='eMed_tbl_headwidth_small' sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}>
                                        <div className=' eMed_sortable_center'>
                                            <div id="eMed_tbl_headtext">{t('UpdatedBy')}</div>
                                            <div id="eMed_sorting_icon" onClick={() => { sorting("modified_by", this.state.modifiedbySortOrder) }}>{SortIcon(this.state.modifiedbySortIcon)}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell className='eMed_tbl_headwidth_small' sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}>
                                        <div className=' eMed_sortable_number'>
                                            <div id="eMed_tbl_headtext">{t('PreviousQuantity')}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell className='eMed_tbl_headwidth_large' sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}>
                                        <div className=' eMed_sortable_number'>
                                            <div id="eMed_tbl_headtext">{t('AdjustedQuantity')}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell className='eMed_tbl_headwidth_small' sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}>
                                        <div className=' eMed_sortable_number'>
                                            <div id="eMed_tbl_headtext">{t('AvailQuantity')}</div>
                                            <div id="eMed_sorting_icon" onClick={() => { sorting("to_quantity", this.state.availableQuantSortOrder) }}>{SortIcon(this.state.availableQuantSortIcon)}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell className='eMed_tbl_headwidth_small' sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}>
                                        <div className=' eMed_sortable_center'>
                                            <div id="eMed_tbl_headtext">{t('Reason')}</div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            {this.state.history.length > 0 ? this.state.history.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                .map((data) => {
                                    return (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell sx={{ bgcolor: Colors.TableBody }} align='center' id='eMed_tbl_bodytext' >
                                                    <div style={{ color: Colors.TableProfilDetails }}>
                                                        <div className="eMed_History_Lastdate">{data.modified_date.split("T")[0]}</div>
                                                        <div className="eMed_History_Time">{Time_convert(data.modified_date)}</div>
                                                    </div>
                                                </TableCell>
                                                <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='center' id='eMed_tbl_bodytext'>
                                                    {data.modified_by.length > 15 ?
                                                        <Tooltip placement="top" title={data.modified_by} disableInteractive>
                                                            <div id='eMed_tbl_bodytext'> {data.modified_by.slice(0, 15) + '...'}</div>
                                                        </Tooltip> : `${data.modified_by}`}
                                                </TableCell>
                                                <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='right' id='eMed_tbl_bodytext'>{data.from_quantity}</TableCell>
                                                <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='right' id='eMed_tbl_bodytext'>{data.adjusted_quantity}</TableCell>
                                                <TableCell align='right' sx={{ color: data.from_quantity <= data.to_quantity ? "green" : "red", bgcolor: Colors.TableBody }} id='eMed_tbl_bodytext'>{data.to_quantity}</TableCell>
                                                <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='center' id='eMed_tbl_bodytext'>
                                                    <Tooltip disableInteractive placement='top' title={t(data.comments)}>
                                                        <div>{reason(data.reason)}</div>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )
                                }) :
                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_Norecords'>
                                    {t("NoRecords")}
                                </div>}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component='div'
                        id="eMed_Table_Pagination"
                        sx={{
                            color: Colors.PagiantionTxtColor
                        }}
                        rowsPerPageOptions={[5, 10, 15]}
                        count={this.state.history.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </Paper>
                {this.state.dialogBox ?
                    <Dialog
                        type={"Notification"}
                        DialogClose={this.dialogCloseFunction}
                        header={"Message"}
                        content={this.state.ErrorMsg}
                        icon={<img className="eMed_dilboxAnimations" alt="Warning" src={Failed} />}
                        secondary={"Close"}
                    /> : null}
            </div>
        )
    }
}
export default withTranslation()(HistoryTable);