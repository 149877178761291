import React, { Component } from 'react';
import BarChart from '../Modules/Charts/BarChart';

export default class DoctorBasedPatientChart extends Component {
  render() {
    return (
      <div style={{width:'23.5vw'}}><BarChart/></div>
    )
  }
}
