import VaccinesIcon from '@mui/icons-material/Vaccines';
import MedicationIcon from '@mui/icons-material/Medication';
import { Colors } from '../../Styles/Colors';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Trans, withTranslation } from 'react-i18next';

// Amount Format Change To Indian Standard Format (With comma) ---- Starts
const AmountFormat = (InputAmount) => {
    const DecimalAmount = (Useramount) => {
        var amount = String(Useramount).split(".")[0];
        var AfterDecimal = String(Useramount).split(".")[1]
        amount = amount.toString();
        var lastThree = amount.substring(amount.length - 3);
        var otherNumbers = amount.substring(0, amount.length - 3);
        if (otherNumbers != '')
            lastThree = ',' + lastThree;
        return "₹ " + otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree + "." + String(AfterDecimal).slice(0, 2)
    }
    const WholeAmount = (Useramount) => {
        var amount = Useramount;
        amount = amount.toString();
        var lastThree = amount.substring(amount.length - 3);
        var otherNumbers = amount.substring(0, amount.length - 3);
        if (otherNumbers != '')
            lastThree = ',' + lastThree;
        return "₹ " + otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ",") + lastThree
    }
    if (typeof (InputAmount) == "string") {
        if (InputAmount.includes(".")) {
            return DecimalAmount(InputAmount)
        }
        else {
            return WholeAmount(InputAmount)
        }
    }
    else if (typeof (InputAmount) == "number") {
        if (InputAmount % 1 != 0) {
            return DecimalAmount(InputAmount)
        }
        else if (InputAmount % 1 == 0) {
            return WholeAmount(InputAmount)
        }
    }
}
// Amount Format Change To Indian Standard Format (With comma) ---- ends

// Time Converter Change 24 hours formate into 12 hours formate ---- Starts
const Time_convert = (Time) => {
    var gettime = Time.split("T")[1]
    var splittime = gettime.split(".")[0]
    var slicetime = splittime.slice(0, 5)
    var time = slicetime.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [slicetime];

    if (time.length > 1) {
        time = time.slice(1);
        time[5] = +time[0] < 12 ? ' AM' : ' PM';
        time[0] = +time[0] % 12 || 12;
    } else {
        return "Wrong Time Formate"
    }
    return time
}
// Time Converter Change 24 hours formate into 12 hours formate ---- ends

// Returns the Suitable Icon based On Dosage Type -- starts
const DosageType = (type) => {

    if (type === "Tablet" || type === "Tablets") {
        return <Tooltip title={type} placement='top' disableInteractive><MedicationIcon id='eMed_icons' sx={{ color: Colors.IconColor }} /></Tooltip>
    }
    else if (type === "Injection") {
        return <Tooltip title={type} placement='top' disableInteractive><VaccinesIcon id='eMed_icons' sx={{ color: Colors.IconColor }} /></Tooltip>
    }
    else if (type === "Capsule" || type === "Capsules") {
        return <Tooltip title={type} placement='top' disableInteractive><VaccinesIcon id='eMed_icons' sx={{ color: Colors.IconColor }} /></Tooltip>
    }
    else if (type === "Syrup" || type === "Syrups") {
        return <Tooltip title={type} placement='top' disableInteractive><VaccinesIcon id='eMed_icons' sx={{ color: Colors.IconColor }} /></Tooltip>
    }
    else if (type === "Solution") {
        return <Tooltip title={type} placement='top' disableInteractive><VaccinesIcon id='eMed_icons' sx={{ color: Colors.IconColor }} /></Tooltip>
    }
}

// Returns the Suitable Icon based On Dosage Type -- ends

//  Sorting Icons Change func === starts 
const SortIcon = (sortType) => {

    const { t } = this.props
    if (sortType === "asc") {
        return <Tooltip disableInteractive placement='top' title={t("InAscendingOrder")}><KeyboardArrowUpIcon sx={{ color: Colors.ThemeColor }} id='eMed_sorting_icon' /></Tooltip>
    }
    else if (sortType === 'desc') {
        return <Tooltip disableInteractive placement='top' title={t("InDecendingOrder")}><KeyboardArrowDownIcon sx={{ color: Colors.ThemeColor }} id='eMed_sorting_icon' /></Tooltip>
    }
    else return <Tooltip disableInteractive placement='top' title={t("Sorting")}><FilterListIcon sx={{ color: Colors.ThemeColor }} id='eMed_sorting_icon' /></Tooltip>
}

//  Sorting Icons Change func === ends 

export { AmountFormat, Time_convert, DosageType, SortIcon }