import * as React from 'react';
import Box from '@mui/material/Box';
import ComingSoon from "../Upcomingpages/ComingSoon";
import { localSetItem } from "../../Utility/Services/CacheProviderService";
import RestAPIService from "../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../Utility/API/Serviceurls";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    this.getModuleList()
    this.getDosageType()
  }
  getModuleList = () => {
    RestAPIService.getAll(Serviceurls.GET_MODULES_LIST)
      .then(response => {
        if (response.data.data.clinic.length !== 0) {
          localSetItem("Clinic_Id", response.data.data.clinic[0].clinic_id)
        }
        if (response.data.data.pharmacy.length !== 0) {
          localSetItem("Pharmacy_Id", response.data.data.pharmacy[0].pharmacy_id)
        }
        if (response.data.data.laboratory.length !== 0) {
          localSetItem("Lab_Id", response.data.data.laboratory[0].laboratory_id)
        }

      })
      .catch(e => {
        this.setState({ ErrorPop: true, ErrorMsg: e.message });
        setTimeout(() => {
          this.setState({
            dialogBox: false
          })
        }, 3000)
      });
  }
  /* Get Dosage Type API Start */
  getDosageType = () => {
    try {
      RestAPIService.getAll(Serviceurls.GET_DRUG_LIST)
        .then(response => {
          localSetItem("DosageType", JSON.stringify(response.data.data))
        })
        .catch(e => {
          this.setState({ failedDialogBox: true, ErrorMsg: e.message })
          setTimeout(() => {
            this.setState({
              failedDialogBox: false
            })
          }, 3000)
        })
    }
    catch (e) {
      this.setState({ failedDialogBox: true, ErrorMsg: e.message })
      setTimeout(() => {
        this.setState({
          failedDialogBox: false
        })
      }, 3000)
    }
  }
  /* Get Dosage Type API Start */
  render() {
    return (
      <ComingSoon></ComingSoon>
    )
  }

}

export default Dashboard;