import React, { Component } from 'react'
import ComingSoon from '../../../Upcomingpages/ComingSoon';
import { Trans, withTranslation } from 'react-i18next';

class PurchaseOrder extends Component {
  render() {
    const { t } = this.props
    return (
      <div className='eMed_Tab_Box'>
        <div  id='eMed_table_name'>
          {t("PurchaseOrder")}
        </div>
        <ComingSoon /></div>
    )
  }
}
export default withTranslation()(PurchaseOrder)