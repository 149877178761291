import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import { Colors } from '../../../../../Styles/Colors';
import Tooltip from '@mui/material/Tooltip';
import { TablePagination } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DatePicker from '../../../../../Components/DatePicker/DatePicker';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PaymentCard from '../../../../../Components/PaymentCard/PaymentCard'
import { Button, FormHelperText } from '@mui/material';
import { DateTime } from "luxon";
import { AmountFormat } from '../../../../../Components/CommonFunctions/CommonFunctions';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import './SalesReturn.css';
import { localGetItem } from '../../../../../Utility/Services/CacheProviderService'
import { Serviceurls } from '../../../../../Utility/API/Serviceurls';
import RestAPIService from '../../../../../Utility/Services/RestAPIService';
import Dialog from '../../../../../Components/Dialog';
import Failed from '../../../../../Assets/Animation/Failed.gif';
import { DosageType } from '../../../../../Components/CommonFunctions/CommonFunctions'

class SalesReturn extends Component {
  constructor(props) {
    super(props)
    this.state = {
      Data: [],
      DataFilter: [],
      SearchFieldOpen: false,
      SearchText: "",
      page: 0,
      rowsPerPage: 5,
      datePickerPop: false,
      datefilter: 0,
      DateSortorder: 'asc',
      DateSortIcon: "none",
      BrandSortorder: 'asc',
      BrandSortIcon: "none",
      GenericSortorder: 'asc',
      GenericSortIcon: "none",
      QtySortorder: 'asc',
      QtySortIcon: "none",
      RtnQtySortorder: 'asc',
      RtnQtySortIcon: "none",
      RtnAmtSortorder: 'asc',
      RtnAmtSortIcon: "none",
      QueryFrmDte: "",
      QueryToDte: "",
      PharmacyID: localGetItem("Pharmacy_Id")
    }
  }

  componentDidMount() {
    this.getSalesReturn()
  }

  getSalesReturn = () => {
    try {
      let QueryParams = `?pharmacy_id=${this.state.PharmacyID}&page=&page_size=all&from_date=${this.state.QueryFrmDte}&to_date=${this.state.QueryToDte}&start_time=&end_time=`
      RestAPIService.getAll(Serviceurls.GET_SALES_RETURN + QueryParams)
        .then(response => {
          this.setState({
            Data: response.data.data.result.length > 0 ? response.data.data.result : [],
            DataFilter: response.data.data.result,
            ReturnedAmt: response.data.data.total_returned_amount
          })
        })
        .catch(e => {
          this.setState({ dialogBox: true, ErrorMsg: e.message });
          setTimeout(() => {
            this.setState({
              dialogBox: false
            })
          }, 3000)
        });

    }
    catch (e) {
      this.setState({ dialogBox: true, ErrorMsg: e.message });
      setTimeout(() => {
        this.setState({
          dialogBox: false
        })
      }, 3000)
    }
  }

  //  Mui table Pagination Functions --- starts

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 });
  }

  //  Mui table Pagination Functions --- ends
  dialogCloseFunction = () => {
    this.setState({
      dialogBox: false
    })
  }


  PopClose = () => {
    this.setState({
      datePickerPop: false,
      datefilter: 0,
      QueryFrmDte: "",
      QueryToDte: "",
    }, () => this.getSalesReturn())
  }

  CustomDates = (FromDate, ToDate) => {
    this.setState({
      QueryFrmDte: FromDate,
      QueryToDte: ToDate,
      datePickerPop: false
    }, () => this.getSalesReturn())
  }


  DateHelperText = () => {
    if (this.state.datefilter === 0) {
      return " "
    }
    else if (this.state.datefilter === 1) {
      return DateTime.now().toFormat('dd-MM-yyyy')
    }
    else if (this.state.datefilter === 2) {
      return DateTime.now().minus({ days: 1 }).toFormat('dd-MM-yyyy')
    }
    else if (this.state.datefilter === 3) {
      return `${DateTime.now().minus({ days: 7 }).toFormat('dd-MM-yyyy')}  to  ${DateTime.now().toFormat('dd-MM-yyyy')}`
    }
    else if (this.state.datefilter === 4) {
      return `${DateTime.now().minus({ days: 30 }).toFormat('dd-MM-yyyy')}  to  ${DateTime.now().toFormat('dd-MM-yyyy')}`
    }
    else if (this.state.datefilter === 5) {
      return this.state.QueryToDte === undefined ? null : `${this.state.QueryFrmDte.split("-").reverse().join("-")}  to  ${this.state.QueryToDte.split("-").reverse().join("-")}`
    }
  }


  render() {

    const { t } = this.props
    const DataArray = this.state.DataFilter

    //  filter table data based on search Text
    const filtereddata = this.state.Data.filter((data) => {
      return (
        (data.invoice_date === null ? null : data.invoice_date.includes(this.state.SearchText)) ||
        (data.invoice_number === null ? null : data.invoice_number.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
        (data.batch_no === null ? null : data.batch_no.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
        (data.brand_name === null ? null : data.brand_name.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
        (data.generic_name === null ? null : data.generic_name.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
        (data.dosage_type === null ? null : data.dosage_type.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
        (data.dosage_strength === null ? null : data.dosage_strength.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
        (data.quantity_bought === null ? null : Math.round(data.quantity_bought * 100) === parseInt(Math.round(this.state.SearchText * 100))) ||
        (data.returned_quantity === null ? null : Math.round(data.returned_quantity * 100) === parseInt(Math.round(this.state.SearchText * 100))) ||
        (data.returned_amount === null ? null : Math.round(data.returned_amount * 100) === parseInt(Math.round(this.state.SearchText * 100)))
      );
    });

    // Search Icon OnClick Function == ends

    const handleDate = () => {
      var today = DateTime.now().toFormat('yyyy-MM-dd')
      var yesterday = DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd')
      var startweek = DateTime.now().minus({ days: 7 }).toFormat('yyyy-MM-dd')
      var startmonth = DateTime.now().minus({ days: 30 }).toFormat('yyyy-MM-dd')

      if (this.state.datefilter === 0) {
        this.setState({
          QueryFrmDte: "",
          QueryToDte: "",
        }, () => {
          this.getSalesReturn()
        })
      }
      else if (this.state.datefilter === 1) {
        this.setState({
          QueryFrmDte: today,
          QueryToDte: today,
        }, () => {
          this.getSalesReturn()
        })

      }
      else if (this.state.datefilter === 2) {
        this.setState({
          QueryFrmDte: yesterday,
          QueryToDte: yesterday,
        }
          , () => {
            this.getSalesReturn()
          })
      }
      else if (this.state.datefilter === 3) {
        this.setState({
          QueryFrmDte: startweek,
          QueryToDte: yesterday,
        },
          () => {
            this.getSalesReturn()
          })
      }
      else if (this.state.datefilter === 4) {
        this.setState({
          QueryFrmDte: startmonth,
          QueryToDte: yesterday,
        },
          () => {
            this.getSalesReturn()
          })
      }
      else if (this.state.datefilter === 5) {
        this.setState({ datePickerPop: true })
      }

    }

    // Sorting Function   ==== starts
    const sorting = (col, SortOrder) => {
      if (SortOrder === "asc") {
        const Sorted = [...this.state.Data].sort((a, b) => {
          if (typeof (a[col]) == "string") {
            return a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
          } else {
            return a[col] > b[col] ? 1 : -1
          }
        })
        this.setState({ Data: Sorted })

        if (col === "invoice_date") {
          this.setState({
            DateSortorder: 'desc',
            DateSortIcon: "asc",
            BrandSortorder: 'asc',
            BrandSortIcon: "none",
            GenericSortorder: 'asc',
            GenericSortIcon: "none",
            QtySortorder: 'asc',
            QtySortIcon: "none",
            RtnQtySortorder: 'asc',
            RtnQtySortIcon: "none",
            RtnAmtSortorder: 'asc',
            RtnAmtSortIcon: "none",
          })
        }
        else if (col === "brand_name") {
          this.setState({
            DateSortorder: 'asc',
            DateSortIcon: "none",
            BrandSortorder: 'desc',
            BrandSortIcon: "asc",
            GenericSortorder: 'asc',
            GenericSortIcon: "none",
            QtySortorder: 'asc',
            QtySortIcon: "none",
            RtnQtySortorder: 'asc',
            RtnQtySortIcon: "none",
            RtnAmtSortorder: 'asc',
            RtnAmtSortIcon: "none",
          })
        }
        else if (col === "generic_name") {
          this.setState({
            DateSortorder: 'asc',
            DateSortIcon: "none",
            BrandSortorder: 'asc',
            BrandSortIcon: "none",
            GenericSortorder: 'desc',
            GenericSortIcon: "asc",
            QtySortorder: 'asc',
            QtySortIcon: "none",
            RtnQtySortorder: 'asc',
            RtnQtySortIcon: "none",
            RtnAmtSortorder: 'asc',
            RtnAmtSortIcon: "none",
          })
        }
        else if (col === "quantity_bought") {
          this.setState({
            DateSortorder: 'asc',
            DateSortIcon: "none",
            BrandSortorder: 'asc',
            BrandSortIcon: "none",
            GenericSortorder: 'asc',
            GenericSortIcon: "none",
            QtySortorder: 'desc',
            QtySortIcon: "asc",
            RtnQtySortorder: 'asc',
            RtnQtySortIcon: "none",
            RtnAmtSortorder: 'asc',
            RtnAmtSortIcon: "none",
          })
        }
        else if (col === "returned_quantity") {
          this.setState({
            DateSortorder: 'asc',
            DateSortIcon: "none",
            BrandSortorder: 'asc',
            BrandSortIcon: "none",
            GenericSortorder: 'asc',
            GenericSortIcon: "none",
            QtySortorder: 'asc',
            QtySortIcon: "none",
            RtnQtySortorder: 'desc',
            RtnQtySortIcon: "asc",
            RtnAmtSortorder: 'asc',
            RtnAmtSortIcon: "none",
          })
        }
        else if (col === "returned_amount") {
          this.setState({
            DateSortorder: 'asc',
            DateSortIcon: "none",
            BrandSortorder: 'asc',
            BrandSortIcon: "none",
            GenericSortorder: 'asc',
            GenericSortIcon: "none",
            QtySortorder: 'asc',
            QtySortIcon: "none",
            RtnQtySortorder: 'asc',
            RtnQtySortIcon: "none",
            RtnAmtSortorder: 'desc',
            RtnAmtSortIcon: "asc",
          })
        }

      }
      else if (SortOrder === "desc") {
        const Sorted = [...this.state.Data].sort((a, b) => {
          if (typeof (a[col]) == "string") {
            return a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
          } else {
            return a[col] < b[col] ? 1 : -1
          }
        })
        this.setState({ Data: Sorted })

        if (col === "invoice_date") {
          this.setState({ DateSortorder: 'default', DateSortIcon: "desc" })
        }
        else if (col === "brand_name") {
          this.setState({ BrandSortorder: 'default', BrandSortIcon: "desc" })
        }
        else if (col === "generic_name") {
          this.setState({ GenericSortorder: 'default', GenericSortIcon: "desc" })
        }
        else if (col === "quantity_bought") {
          this.setState({ QtySortorder: 'default', QtySortIcon: "desc" })
        }
        else if (col === "returned_quantity") {
          this.setState({ RtnQtySortorder: 'default', RtnQtySortIcon: "desc" })
        }
        else if (col === "returned_amount") {
          this.setState({ RtnAmtSortorder: 'default', RtnAmtSortIcon: "desc" })
        }
      }
      else if (SortOrder === "default") {
        this.setState({
          DateSortorder: 'asc',
          DateSortIcon: "none",
          BrandSortorder: 'asc',
          BrandSortIcon: "none",
          GenericSortorder: 'asc',
          GenericSortIcon: "none",
          QtySortorder: 'asc',
          QtySortIcon: "none",
          RtnQtySortorder: 'asc',
          RtnQtySortIcon: "none",
          RtnAmtSortorder: 'asc',
          RtnAmtSortIcon: "none",
          Data: DataArray
        })
      }
    }
    // Sorting Function   ==== ends

    //  Sorting Icons Change func === starts 
    const SortIcon = (sortType) => {
      if (sortType === "asc") {
        return <Tooltip disableInteractive placement='top' title={t("InAscendingOrder")}><KeyboardArrowUpIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
      }
      else if (sortType === 'desc') {
        return <Tooltip disableInteractive placement='top' title={t("InDecendingOrder")}><KeyboardArrowDownIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
      }
      else return <Tooltip disableInteractive placement='top' title={t("Sorting")}><FilterListIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
    }

    //  Sorting Icons Change func === ends 


    const handleChange = (event) => {
      this.setState({ datefilter: event.target.value }, () => handleDate());
    };

    return (
      <div>
        <div className="eMed_page_header">
          <div id='eMed_name_totalcard'>
            <div id="eMed_table_name" style={{ color: Colors.TextColor }}>
              {t(this.props.menuName)}
            </div>
            <div id="eMed_tbl_paymentcard">
              <PaymentCard Color={Colors.blue} Icons={<CurrencyRupeeIcon />} Name={t("ReturnedAmount")} Amount={this.state.ReturnedAmt} />
            </div>
          </div>
          <div id='eMed_tbl_filters'>
            <div>
              <FormControl id="eMed_tbl_datefilter">
                <InputLabel sx={{ color: Colors.TextColor }}>{t("SelectDate")}</InputLabel>
                <Select
                  sx={{ color: Colors.TextColor }}
                  size='small'
                  value={this.state.datefilter}
                  label={t("SelectDate")}
                  onChange={handleChange}
                >
                  <MenuItem value={0}>{t("All")}</MenuItem>
                  <MenuItem value={1}>{t("Today")}</MenuItem>
                  <MenuItem value={2}>{t("Yesterday")}</MenuItem>
                  <MenuItem value={3}>{t("Lastweek")}</MenuItem>
                  <MenuItem value={4}>{t("LastMonth")}</MenuItem>
                  <MenuItem value={5} onClick={() => this.setState({ datePickerPop: true })}>{t("Custom")}</MenuItem>
                </Select>
                <FormHelperText id="eMed_form_helpertext">{this.DateHelperText()}</FormHelperText>
              </FormControl>
            </div>
            {this.state.SearchFieldOpen ?
              <div id="eMed_tbl_searchbox" >
                <TextField
                  sx={{ input: { color: Colors.TextColor } }}
                  size='small'
                  focused
                  onChange={(e) => this.setState({ SearchText: e.target.value })}
                  value={this.state.SearchText}
                  label={t("SearchHere")}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <Tooltip disableInteractive title={t("Clear")} placement='top'><CloseIcon sx={{ color: Colors.ThemeColor }} onClick={() => { this.setState({ SearchText: '' }) }} /></Tooltip>
                    ),
                  }}
                />
              </div> : null}
            <Tooltip disableInteractive placement="top-start" title={this.state.SearchFieldOpen ? t("CloseSearch") : t("OpenSearch")}>
              <Button id="eMed_tbl_searchicon" variant='contained' style={{ backgroundColor: Colors.ThemeColor }} onClick={() => this.setState({ SearchFieldOpen: !this.state.SearchFieldOpen, CardOpen: false, SearchText: '' })} >
                {this.state.SearchFieldOpen ? <CloseIcon /> : <SearchIcon />}
              </Button>
            </Tooltip>
          </div>
        </div>
        <div>
          <Card className='eMed_tbl_card' sx={{ bgcolor: Colors.TableCard }}>
            <TableContainer className='eMed_tbl_container'>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className="eMed_sticky_start" align='center' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>
                        {t("SNo")}
                      </div>
                    </TableCell>
                    <TableCell id="eMed_sticky_col2_head" className='eMed_tbl_headwidth_large' align='center' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div className='eMed_sortable_center'>
                        <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("ReturnDate")}</div>
                        <div id="eMed-SortIcons" onClick={() => { sorting("invoice_date", this.state.DateSortorder) }}>{SortIcon(this.state.DateSortIcon)}</div>
                      </div>
                    </TableCell>
                    <TableCell id="eMed_nonsticky_head" className='eMed_tbl_headwidth_small' align='right' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_Table_HeadText'>
                        {t("BillNo")}
                      </div>
                    </TableCell>
                    <TableCell id="eMed_nonsticky_head" className='eMed_tbl_headwidth_small' align='right' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_Table_HeadText'>
                        {t("BatchNo")}
                      </div>
                    </TableCell>
                    <TableCell id="eMed_nonsticky_head" className='eMed_tbl_headwidth_large' align='left' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div className=' eMed_sortable_name'>
                        <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_Table_HeadText'>{t("BrandName")}</div>
                        <div id="eMed-SortIcons" onClick={() => { sorting("brand_name", this.state.BrandSortorder) }}>{SortIcon(this.state.BrandSortIcon)}</div>
                      </div>
                    </TableCell>
                    <TableCell align='left' className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div className=' eMed_sortable_name'>
                        <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_Table_HeadText'> {t("GenericName")}</div>
                        <div id="eMed-SortIcons" onClick={() => { sorting("generic_name", this.state.GenericSortorder) }}>{SortIcon(this.state.GenericSortIcon)}</div>
                      </div>
                    </TableCell>
                    <TableCell align='center' className='eMed_tbl_headwidth_small' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div style={{ color: Colors.TableHeaderTextColor }} className=' eMed_sortable_center' id='eMed_Table_HeadText'> {t("DosageType")}</div>
                    </TableCell>
                    <TableCell align='center' className='eMed_tbl_headwidth_small' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div style={{ color: Colors.TableHeaderTextColor }} className=' eMed_sortable_center' id='eMed_Table_HeadText'>{t("Strength")}</div>
                    </TableCell>
                    <TableCell align='center' className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div className=' eMed_sortable_number'>
                        <div style={{ color: Colors.TableHeaderTextColor }} className=' eMed_sortable_number' id='eMed_Table_HeadText'> {t("Quantity")}(₹)</div>
                        <div id="eMed-SortIcons" onClick={() => { sorting("quantity_bought", this.state.QtySortorder) }}>{SortIcon(this.state.QtySortIcon)}</div>
                      </div>
                    </TableCell>
                    <TableCell align='right' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div className='eMed_tbl_headwidth_small eMed_sortable_number'>
                        <div style={{ color: Colors.TableHeaderTextColor }} className=' eMed_sortable_number' id='eMed_Table_HeadText'> {t("RtdQty")}(₹)</div>
                        <div id="eMed-SortIcons" onClick={() => { sorting("returned_quantity", this.state.RtnQtySortorder) }}>{SortIcon(this.state.RtnQtySortIcon)}</div>
                      </div>
                    </TableCell>
                    <TableCell align='right' className='eMed_tbl_headwidth_large  eMed_sticky_end' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                      <div className='eMed_sortable_number'>
                        <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_Table_HeadText'>{t("RtdAmount")}(₹)</div>
                        <div id="eMed-SortIcons" onClick={() => { sorting("returned_amount", this.state.RtnAmtSortorder) }}>{SortIcon(this.state.RtnAmtSortIcon)}</div>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {filtereddata.length > 0 ? filtereddata.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((data, index) => (
                  <TableBody key={index}>
                    <TableRow>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='center' className="eMed_sticky_startbody">
                        <div id='eMed_tbl_bodytext'> {index + 1}</div>
                      </TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='center' id="eMed_sticky_col2_body">
                        <div id='eMed_tbl_bodytext'> {data.invoice_date}</div>
                      </TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='right' id="eMed_nonsticky_head" >
                        <div id='eMed_tbl_bodytext'>  {data.invoice_number}</div>
                      </TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='right' id="eMed_nonsticky_head" >
                        <div id='eMed_tbl_bodytext'> {data.batch_no}</div>
                      </TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='left' id="eMed_nonsticky_head">
                        {data.brand_name.length > 12 ?
                          <Tooltip placement="top" title={data.brand_name} disableInteractive>
                            <div id='eMed_tbl_bodytext'>{data.brand_name.slice(0, 12) + '...'}</div>
                          </Tooltip> : <div id='eMed_tbl_bodytext'>{data.brand_name}</div>}
                      </TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='left' id="eMed_nonsticky_head">
                        {data.generic_name.length > 12 ?
                          <Tooltip placement="top" title={data.generic_name} disableInteractive>
                            <div id='eMed_tbl_bodytext'>{data.generic_name.slice(0, 12) + '...'}</div>
                          </Tooltip> : <div id='eMed_tbl_bodytext'> {data.generic_name}</div>}
                      </TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='center' id="eMed_nonsticky_head">
                        {DosageType(data.dosage_type)}
                      </TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='center' id="eMed_nonsticky_head">
                        <div id='eMed_tbl_bodytext'>  {data.dosage_strength}</div>
                      </TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='right' id="eMed_nonsticky_head">
                        <div id='eMed_tbl_bodytext'> {data.quantity_bought}</div>
                      </TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='right' id="eMed_nonsticky_head">
                        <div id='eMed_tbl_bodytext'>  {data.returned_quantity}</div>
                      </TableCell>
                      <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='right' id='eMed_tbl_bodytext' className='eMed_sticky_endbody'>
                        {AmountFormat(data.returned_amount)}</TableCell>
                    </TableRow>
                  </TableBody>
                )) :
                  <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_Norecords'>
                    {t("NoRecords")}
                  </div>}
              </Table>
            </TableContainer>
            <TablePagination
              component='div'
              id="eMed_tbl_pagination"
              sx={{
                color: Colors.PagiantionTxtColor
              }}
              rowsPerPageOptions={[5, 10, 15]}
              count={filtereddata.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </Card>
          {this.state.datePickerPop ? <DatePicker PopClose={this.PopClose} CustomDates={this.CustomDates} /> : null}
          {this.state.dialogBox ?
            <Dialog
              type={"Notification"}
              DialogClose={this.dialogCloseFunction}
              header={"Message"}
              content={this.state.ErrorMsg}
              icon={<img className="eMed_dilboxAnimations" alt="Warning" src={Failed} />}
              secondary={"Close"}
              closeButton={this.dialogCloseFunction}
            /> : null}
        </div >
      </div >
    )
  }
}
export default withTranslation()(SalesReturn)


