import React, { Component } from 'react';
import {Line} from 'react-chartjs-2';
import {Chart as ChartJS, Title, Tooltip, LineElement, Legend, CategoryScale, LinearScale, PointElement} from 'chart.js';
ChartJS.register(Title, Tooltip, LineElement, Legend, CategoryScale, LinearScale, PointElement)

class LineChart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data:
            {
                labels: ['Jan','Feb','Mar','Apr','May','Jun','July','Aug'],
                datasets: [
                    {
                        label:'RevenueLineChart',
                        data: [10, 20, 30, 42, 51, 82, 31, 59],
                        backgroundColor: ['yellow']
                    },
                ],
            }
        }
    };
    render() {
        return (
            <div>
                <Line data={this.state.data} 
                options={{
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                            labels: {
                                usePointStyle: true,
                                boxWidth: 10,
                            },
                        }
                    }
                }} />
            </div>
        )
    }
}
export default LineChart;