import React from "react";
import CloseIcon from '@mui/icons-material/Close';
import './ModelDialogBox.css'
import { Button, Modal, Paper } from '@mui/material';
import { Colors } from '../../Styles/Colors'


class ModelDialogBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            history: this.props.history
        }
    }

    handleClose = () => {
        this.props.DialogClose()
    }

    render() {
        return (
            <div>
                <Modal open={this.state.open}>
                    <Paper className="eMed_Dialog_Paper" sx={{ bgcolor: Colors.PopupBackground }}>
                        <div className="eMed_Dialog_Wrapper">
                            <div style={{ color: Colors.ThemeColor }} className="eMed_Dialog_Title">{this.props.header}{this.state.history ? "-" + this.state.history.drug_name : ""}</div>
                            <div>
                                <Button variant="contained" onClick={this.handleClose}>
                                    <CloseIcon sx={{ color: Colors.ContrastText }} id="eMed_Dialog_CloseButton" />
                                </Button>
                            </div>
                        </div>
                        <div id="eMed_Dialog_Component">
                            {this.props.component}
                        </div>
                    </Paper>
                </Modal>
            </div>
        )
    }
}

export default ModelDialogBox;