import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { Colors } from "../../../Styles/Colors";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { Button, Divider, Tooltip } from '@mui/material';
import { Trans, withTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import { MenuLists } from '../../PharmacyManagement/MenuLists'
import '../Reports/index.css';
import { localSetItem, localGetItem } from "../../../Utility/Services/CacheProviderService"
import ListAltIcon from '@mui/icons-material/ListAlt';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import HomeRepairServiceIcon from '@mui/icons-material/HomeRepairService';
import StoreIcon from '@mui/icons-material/Store';

class ReportsHome extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            salesMenuPop: null,
            purchaseMunePop: null,
            stockMenuPop: null,
            open: true
        }
    }

    render() {
        if (localGetItem("PharmaReportsID") === null) {
            localSetItem("PharmaReportsID", 1.1)
        }
        const SelectedID = localGetItem("PharmaReportsID");

        const { t } = this.props

        const saleshandleClick = (event) => {
            this.setState({ salesMenuPop: event.currentTarget })
        };
        const purchasehandleClick = (event) => {
            this.setState({ purchaseMunePop: event.currentTarget })
        };
        const stockhandleClick = (event) => {
            this.setState({ stockMenuPop: event.currentTarget })
        };
        const saleshandleClose = () => {
            this.setState({ salesMenuPop: null })
        };
        const PurchasehandleClose = () => {
            this.setState({ purchaseMunePop: null })
        };
        const StockhandleClose = () => {
            this.setState({ stockMenuPop: null })
        };
        const ExportPop = (event) => {
            this.setState({ exportMenuPop: event.currentTarget })
        };
        const ExportPopClose = () => {
            this.setState({ exportMenuPop: null })
        };
        const salesopen = Boolean(this.state.salesMenuPop);
        const purchaseopen = Boolean(this.state.purchaseMunePop);
        const stockopen = Boolean(this.state.stockMenuPop);
        const ExportPopBoolean = Boolean(this.state.exportMenuPop);

        return (
            <Box className='eMed_MainBox'>
                <div className='eMed_Page_Header' style={{backgroundColor:Colors.pageTopBackground}} >
                    <div style={{ display: 'flex' }}>

                        <Button startIcon={<StoreIcon id="eMed_icons" />} sx={{ marginLeft: "3vw", color: SelectedID < 2 ? Colors.HighlightColor : Colors.TextColor }} onClick={saleshandleClick}>{t("SalesReports")}</Button>
                        <Menu
                            anchorEl={this.state.salesMenuPop}
                            open={salesopen}
                            onClose={saleshandleClose}
                        >
                            {MenuLists.salesReports.map((list) => (
                                <MenuItem onClick={() => { this.setState({ tableDisplay: list.component, salesMenuPop: null }); localSetItem("PharmaReportsID", list.id) }}><span id='eMed_menuList_icons' style={{ color: Colors.ThemeColor }}>{list.icon}</span> <span style={{ color: localGetItem("PharmaReportsID") == list.id ? Colors.HighlightColor : "black" }}>{t(list.menuName)}</span></MenuItem>
                            ))}
                        </Menu>
                        <Button startIcon={<ShoppingCartIcon id="eMed_icons" />} sx={{ marginLeft: "3vw", color: (SelectedID > 2 && SelectedID < 3) ? Colors.HighlightColor : Colors.TextColor }} onClick={purchasehandleClick}>{t("PurchaseReports")}</Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={this.state.purchaseMunePop}
                            open={purchaseopen}
                            onClose={PurchasehandleClose}
                        >
                            {MenuLists.PurchaseReports.map((list) => (
                                <MenuItem onClick={() => { this.setState({ tableDisplay: list.component, purchaseMunePop: null }); localSetItem("PharmaReportsID", list.id) }}><span id='eMed_menuList_icons' style={{ color: Colors.ThemeColor }}>{list.icon}</span>  <span style={{ color: localGetItem("PharmaReportsID") == list.id ? Colors.HighlightColor : "black" }}> {t(list.menuName)}</span></MenuItem>
                            ))}
                        </Menu>
                        <Button startIcon={<HomeRepairServiceIcon id="eMed_icons" />} sx={{ marginLeft: "3vw", color: (SelectedID > 3 && SelectedID < 4) ? Colors.HighlightColor : Colors.TextColor }} onClick={stockhandleClick}>{t("StockReports")}</Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={this.state.stockMenuPop}
                            open={stockopen}
                            onClose={StockhandleClose}
                        >
                            {MenuLists.StockReports.map((list) => (
                                <MenuItem onClick={() => { this.setState({ tableDisplay: list.component, stockMenuPop: null }); localSetItem("PharmaReportsID", list.id) }}><span id='eMed_menuList_icons' style={{ color: Colors.ThemeColor }}>{list.icon}</span>  <span style={{ color: localGetItem("PharmaReportsID") == list.id ? Colors.HighlightColor : "black" }}>{t(list.menuName)}</span></MenuItem>
                            ))}
                        </Menu>
                    </div>
                    <div className='eMed_PageHeader_icons_div'>
                        <Tooltip disableInteractive title={t("Export")} placement='bottom'><Avatar id='eMed_IconAvatar' sx={{ bgcolor: Colors.IconCircleBgColor, boxShadow: 3 }} onClick={ExportPop}><SimCardDownloadIcon sx={{ color: Colors.ThemeColor }} /></Avatar></Tooltip>
                        <Menu
                            anchorEl={this.state.exportMenuPop}
                            open={ExportPopBoolean}
                            onClose={ExportPopClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <MenuItem ><ListAltIcon fontSize='small' id='eMed_menuList_icons' sx={{ color: Colors.blue }} /> {t("ExportAsExcel")}</MenuItem>
                            <Divider />
                            <MenuItem ><PictureAsPdfIcon fontSize='small' id='eMed_menuList_icons' sx={{ color: Colors.red }} /> {t("ExportAsPdf")}</MenuItem>
                        </Menu>
                    </div>

                </div>
                <div id="eMed_Table_Div">
                    {MenuLists.salesReports.map((items) => (
                        items.id == SelectedID ? items.component : null
                    ))}
                    {MenuLists.PurchaseReports.map((items) => (
                        items.id == SelectedID ? items.component : null
                    ))}
                    {MenuLists.StockReports.map((items) => (
                        items.id == SelectedID ? items.component : null
                    ))}
                </div>

            </Box>
        )
    }

}
export default withTranslation()(ReportsHome)