import React from "react";
import eMedHub from "../Assets/Logo/eMedHub.jpeg";
import LockIcon from "@mui/icons-material/Lock";
import { TextField } from "@mui/material";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Button from "@material-ui/core/Button";
import Logo from "../Assets/Logo/logo.png";
import './Signin.css';
import './Forgotpassword.css';
import { localSetItem } from "../Utility/Services/CacheProviderService";
import RestAPIService from "../Utility/Services/RestAPIService";
import { Serviceurls } from "../Utility/API/Serviceurls";
import Dialog from '../Components/Dialog';
import Loader from '../Components/Loader';
import Failed from '../Assets/Animation/Failed.gif'
import Success from '../Assets/Animation/Success.gif';
import { Trans, withTranslation } from 'react-i18next';

class Signin extends React.Component {
  constructor(props) {

    super(props);
    this.state = {
      username: "",
      password: "",
      showPassword: false,
      usernameError: '',
      usernameHelperText: '',
      passwordError: '',
      passwordHelperText: '',
      isLoader: false,
      loginSuccess: false,
      openFailed: false,
      login_details: {},
    };
  }
  componentDidMount() {
  }
  Visibility = () => {
    this.setState({
      showPassword: this.state.showPassword ? false : true,
    });
  };
  validate = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      [event.target.name]: value,
    });
  };

  Submit = () => {
    try {
      if (this.state.username && this.state.password) {
        this.setState({
          isLoader: true,
          login_details: { username: this.state.username, password: this.state.password }
        })
        RestAPIService.create(this.state, Serviceurls.API_SIGN_IN)
          .then(response => {
            if (response.data.status == "Success") {
              localSetItem("isLoggedIn", true);
              localSetItem("AccessToken", response.data.login_user_info.token);
              var loginUser = JSON.stringify(response.data.login_user_info);
              localSetItem("loggedInUserInfo", loginUser);

              this.setState({
                isLoader: false,
                loginSuccess: true
              });
              setTimeout(() => {
                this.props.history.push('/Dashboard')
                window.location.reload()
                this.setState({
                  loginSuccess: false,
                  isLoader: false,
                })
              }, 2000)
            } else {
              this.setState({
                isLoader: false,
                openFailed: true
              });
            }

          })
          .catch(e => {
            if (e.response.data.message == "Invalid username") {
              this.setState({
                isLoader: false,
                openFailed: false,
                usernameError: 'error',
                usernameHelperText: e.response.data.message
              })
            } else if (e.response.data.message == "Invalid password") {
              this.setState({
                isLoader: false,
                openFailed: false,
                passwordError: 'error',
                passwordHelperText: e.response.data.message
              })
            } else {
              this.setState({
                isLoader: false,
                openFailed: true,
                usernameError: 'error',
                usernameHelperText: e.response.data.message
              })
            }

          });

      } else {
        localSetItem("isLoggedin", false);
        if (this.state.username == "") {
          this.setState({
            usernameError: 'error',
            usernameHelperText: 'Enter Valid username',
          })
        }
        else {
          this.setState({
            usernameError: "",
            usernameHelperText: "",
          })
        }
        if (this.state.password == "") {
          this.setState({
            passwordError: 'error',
            passwordHelperText: 'Enter valid password',
          })
        }
        else {
          this.setState({
            passwordError: "",
            passwordHelperText: "",
          })
        }
      }
    }
    catch (e) {
      if (e.response.data.message == "Invalid username") {
        this.setState({
          isLoader: false,
          openFailed: false,
          usernameError: 'error',
          usernameHelperText: e.response.data.message
        })
      } else if (e.response.data.message == "Invalid password") {
        this.setState({
          isLoader: false,
          openFailed: false,
          passwordError: 'error',
          passwordHelperText: e.response.data.message
        })
      } else {
        this.setState({
          isLoader: false,
          openFailed: true,
          usernameError: 'error',
          usernameHelperText: e.response.data.message
        })
      }
    }
  };
  dialogCloseFunction = () => {
    this.props.history.push(window.location.reload(), '/Dashboard')
    this.setState({
      loginSuccess: false,
      failedDialogBox: false
    })
  }
  failesDialogClose = () => {
    this.setState({
      openFailed: false,
    })
  }
  render() {
    const { history } = this.props
    const { t } = this.props
    return (
      <div class="total">
        <Loader loaderOpen={this.state.isLoader} />
        <div class="left">
          <img src={eMedHub} width="100%" height="100%" />
        </div>
        <img src={Logo} class="logo_img" />
        <div class="right">
          <form>
            <div>
              <h1> {t("Welcome to eMedHub")}</h1>
            </div>
            <div class="login_input">
              <div>

                <TextField
                  error={this.state.usernameError}
                  helperText={this.state.usernameHelperText}
                  name="username"
                  value={this.state.username}
                  onChange={this.validate}
                  className="eMed_textFiled"
                  placeholder={t("userName")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                />

              </div>
              <div className="eMed_passwordTextbox">
                <TextField
                  error={this.state.passwordError}
                  helperText={this.state.passwordHelperText}
                  value={this.state.password}
                  name="password" onChange={this.validate}
                  className="eMed_textFiled"
                  placeholder={t("password")}
                  type={this.state.showPassword ? "text" : "password"}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end" className="eMed_visibleIcon">
                        <VisibilityOffIcon onClick={this.Visibility} />
                      </InputAdornment>
                    )
                  }} />
              </div>
            </div>
            <div
              className="eMed_login_checkbox"
            >
              <label>
                <input type="checkbox"></input> {t("RememberMe")}
              </label>
              <label class="label_link" onClick={() => history.push('/Forgotpassword')}>{t("ForgotPassword")}? </label>
            </div>
            <div class="butt">
              <Button class="Button" onClick={this.Submit}>
                {t("Signin")}
              </Button>
              {this.state.loginSuccess ? <Dialog
                type={"loginsucess"}
                DialogClose={this.dialogCloseFunction}
                header={"Message"}
                content={"Login Successfully!"}
                icon={<img className="eMed_dilboxAnimations" alt="Sucess" src={Success} />}
                secondary={"Close"}
                closeButton={this.homeScreen} /> : null}
              {this.state.openFailed ? <Dialog
                type={"Notification"}
                DialogClose={this.failesDialogClose}
                header={"Message"}
                content={this.state.usernameHelperText}
                icon={<img className="eMed_dilboxAnimations" alt="Failed" src={Failed} />}
                secondary={"Close"}
                closeButton={this.dialogCloseFunction} /> : null}
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default withTranslation()(Signin);