import React, { Component } from 'react';
import "./PaymentCard.css";
import { Card, IconButton, Tooltip, Typography } from '@mui/material';
import { AmountFormat } from '../CommonFunctions/CommonFunctions';
import { Trans, withTranslation } from 'react-i18next';
import { Colors } from '../../Styles/Colors'

class Paymentcard extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        const { t } = this.props
        const Amount = (this.props.Amount)
        return (
            <div className='eMed_Paymentcard_Container'>
                <Card id='eMed_Paymentcard_Card' sx={{ bgcolor: Colors.TableBody }}>
                    <div className='eMed_Paymentcard_Wrapper'>
                        <div className='eMed_PaymentCard_Icon' style={{ backgroundColor: this.props.Color }}>
                            <IconButton id='eMed_PaymentCard_IconButton'>{this.props.Icons}</IconButton>
                        </div>
                        <div className='eMed_Paymentcard_Right'>
                            <div className='eMed_PaymentCard_Name'>
                                {String(this.props.Name).length > 13 ?
                                    <Tooltip placement="top" title={t(this.props.Name)} disableInteractive>
                                        <div className='eMed_PaymentCard_Name' style={{ color: Colors.TextColor }}>{this.props.Name.slice(0, 10) + '...'}</div>
                                    </Tooltip> : <div className='eMed_PaymentCard_Name' style={{ color: Colors.TextColor }}>{t(this.props.Name)}</div>}

                            </div>
                            {Amount !== undefined ?
                                String(Amount).split(".")[0].length >= 8 ?
                                    <Tooltip placement="top" title={AmountFormat(Amount)} disableInteractive>
                                        <div className='eMed_PaymentCard_Amount' style={{ color: Colors.TextColor }}>{String(AmountFormat(Amount)).slice(0, 12) + '...'}</div>
                                    </Tooltip> :
                                    <div className='eMed_PaymentCard_Amount' style={{ color: Colors.TextColor }}>{AmountFormat(Amount)}</div>
                                : <div className='eMed_PaymentCard_Amount' style={{ color: Colors.TextColor }}>-</div>}

                        </div>
                    </div>
                </Card>
            </div>
        )
    }
}
export default withTranslation()(Paymentcard)