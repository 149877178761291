import React, { Component } from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJs, Tooltip, Title, ArcElement, Legend } from 'chart.js';
ChartJs.register(Tooltip, Title, ArcElement, Legend);

class PieChart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data:
            {
                datasets: [
                    {
                        data: [100, 100, 100, 300, 300],
                        backgroundColor: [
                            'rgb(255, 99, 132)',
                            'rgb(54, 162, 235)',
                            'rgb(255, 205, 86)',
                            'rgb(204, 255, 153)',
                            'rgb(97, 207, 126)',
                            'rgb(168, 50, 155)'
                        ],
                        hoverOffset: 4
                    },
                ],
                labels: [
                    'Cash',
                    'Card',
                    'UPI',
                    'Insurance',
                    'Bank Transfer',
                    'Cash & Card'
                ],
            }
        }
    };
    render() {
    return(
        <div>
            <div>
                <Pie width={400}
                    data={this.state.data}
                    options={{
                        plugins: {
                            legend: {
                                position: 'right',
                                labels: {
                                    usePointStyle: true,
                                    boxWidth: 10,
                                    padding: 10,
                                },
                            }
                        }
                    }}
                />
            </div>
        </div >
    )
}
}

export default PieChart;