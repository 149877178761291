import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import Card from '@mui/material/Card';
import { Colors } from '../../../../../Styles/Colors';
import Tooltip from '@mui/material/Tooltip';
import { TablePagination } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Button, FormHelperText } from '@mui/material';
import { AmountFormat } from '../../../../../Components/CommonFunctions/CommonFunctions';
import './Consolidate.css';
import { localGetItem } from '../../../../../Utility/Services/CacheProviderService'
import { Serviceurls } from '../../../../../Utility/API/Serviceurls';
import RestAPIService from '../../../../../Utility/Services/RestAPIService';
import Dialog from '../../../../../Components/Dialog';
import Failed from '../../../../../Assets/Animation/Failed.gif';
import ModelDialogBox from '../../../../../Components/ModelDialogBox/ModelDialogBox';
import ConsSplitUp from './ConsSplitUp';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';



class ConsolidatedSales extends Component {
    constructor(props) {
        super(props)
        var date = new Date();
        var year = date.getFullYear();
        this.state = {
            Data: [],
            DataFilter: [],
            SearchFieldOpen: false,
            SearchText: "",
            page: 0,
            rowsPerPage: 5,
            datePickerPop: false,
            customDropDown: 0,
            PurchaseSortOrder: 'asc',
            PurchaseSortIcon: 'none',
            SalesSortOrder: 'asc',
            SalesSortIcon: 'none',
            ProfitSortOrder: 'asc',
            ProfitSortIcon: 'none',
            QueryFrmDte: "",
            QueryToDte: "",
            PharmacyID: localGetItem("Pharmacy_Id"),
            ConsolidateSplitUp: false,
            SelectedMonth: '',
            YearQuery: year,
        }
    }

    componentDidMount() {
        this.getSalesConsolidate()
    }

    getSalesConsolidate = () => {

        try {
            let QueryParams = `?pharmacy_id=${this.state.PharmacyID}&invoice_year=${this.state.YearQuery}`
            RestAPIService.getAll(Serviceurls.GET_SALES_CONSOLIDATE + QueryParams)
                .then(response => {
                    this.setState({
                        Data: response.data.data.result,
                        DataFilter: response.data.data.result,
                    })
                })
                .catch(e => {
                    this.setState({ dialogBox: true, ErrorMsg: e.message });
                    setTimeout(() => {
                        this.setState({
                            dialogBox: false
                        })
                    }, 3000)
                });

        }
        catch (e) {
            this.setState({ dialogBox: true, ErrorMsg: e.message });
            setTimeout(() => {
                this.setState({
                    dialogBox: false
                })
            }, 3000)
        }
    }

    //  Mui table Pagination Functions --- starts

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value, page: 0 });
    }

    //  Mui table Pagination Functions --- ends
    handleClose = () => {
        this.setState({
            ConsolidateSplitUp: false
        })
    }

    dialogCloseFunction = () => {
        this.setState({
            dialogBox: false
        })
    }

    SetYear = () => {
        this.setState({
            YearQuery: this.state.value != null ? this.state.value.getFullYear() : null
        }, () => this.getSalesConsolidate())
    }

    render() {
        const { t } = this.props

        //  filter table data based on search Text
        const filtereddata = this.state.Data.filter((data) => {
            return (
                (data.date === null ? null : data.date.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
                (data.profit_amount === null ? null : Math.round(data.profit_amount * 100) === parseInt(Math.round(this.state.SearchText * 100))) ||
                (data.purchase_amount === null ? null : Math.round(data.purchase_amount * 100) === parseInt(Math.round(this.state.SearchText * 100))) ||
                (data.sales_amount === null ? null : Math.round(data.sales_amount * 100) === parseInt(Math.round(this.state.SearchText * 100)))
            );
        });

        // Search Icon OnClick Function == ends

        // Sorting Function   ==== starts
        const sorting = (col, SortOrder) => {
            if (SortOrder === "asc") {
                const Sorted = [...this.state.Data].sort((a, b) => {
                    if (typeof (a[col]) == "string") {
                        return a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
                    } else {
                        return a[col] > b[col] ? 1 : -1
                    }
                })
                this.setState({ Data: Sorted })

                if (col === "purchase_amount") {
                    this.setState({
                        PurchaseSortOrder: 'desc',
                        PurchaseSortIcon: 'asc',
                        SalesSortOrder: 'asc',
                        SalesSortIcon: 'none',
                        ProfitSortOrder: 'asc',
                        ProfitSortIcon: 'none',
                    })
                }
                else if (col === "sales_amount") {
                    this.setState({
                        PurchaseSortOrder: 'asc',
                        PurchaseSortIcon: 'none',
                        SalesSortOrder: 'desc',
                        SalesSortIcon: 'asc',
                        ProfitSortOrder: 'asc',
                        ProfitSortIcon: 'none',
                    })
                }
                else if (col === "profit_amount") {
                    this.setState({
                        PurchaseSortOrder: 'asc',
                        PurchaseSortIcon: 'none',
                        SalesSortOrder: 'asc',
                        SalesSortIcon: 'none',
                        ProfitSortOrder: 'desc',
                        ProfitSortIcon: 'asc',
                    })
                }


            }
            else if (SortOrder === "desc") {
                const Sorted = [...this.state.Data].sort((a, b) => {
                    if (typeof (a[col]) == "string") {
                        return a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
                    } else {
                        return a[col] < b[col] ? 1 : -1
                    }
                })
                this.setState({ Data: Sorted })

                if (col === "purchase_amount") {
                    this.setState({ PurchaseSortOrder: 'default', PurchaseSortIcon: "desc" })
                }
                else if (col === "sales_amount") {
                    this.setState({ SalesSortOrder: 'default', SalesSortIcon: "desc" })
                }
                else if (col === "profit_amount") {
                    this.setState({ ProfitSortOrder: 'default', ProfitSortIcon: "desc" })
                }
            }
            else if (SortOrder === "default") {
                this.setState({
                    PurchaseSortOrder: 'asc',
                    PurchaseSortIcon: 'none',
                    SalesSortOrder: 'asc',
                    SalesSortIcon: 'none',
                    ProfitSortOrder: 'asc',
                    ProfitSortIcon: 'none',
                    Data: this.state.DataFilter
                })
            }
        }
        // Sorting Function   ==== ends

        //  Sorting Icons Change func === starts 
        const SortIcon = (sortType) => {
            if (sortType === "asc") {
                return <Tooltip disableInteractive placement='top' title={t("InAscendingOrder")}><KeyboardArrowUpIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
            }
            else if (sortType === 'desc') {
                return <Tooltip disableInteractive placement='top' title={t("InDecendingOrder")}><KeyboardArrowDownIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
            }
            else return <Tooltip disableInteractive placement='top' title={t("Sorting")}><FilterListIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
        }

        //  Sorting Icons Change func === ends 

        return (
            <div>
                <div className="eMed_page_header">
                    <div id='eMed_name_totalcard'>
                        <div id="eMed_table_name" style={{ color: Colors.TextColor }}>
                            {t(this.props.menuName)}
                        </div>
                    </div>
                    <div id='eMed_tbl_filters'>
                        <div>
                            <LocalizationProvider dateAdapter={AdapterDateFns} >
                                <DatePicker
                                    color='secondary'
                                    views={['year']}
                                    label={t("SelectYear")}
                                    minDate={new Date('2015-01-01')}
                                    maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 10))}
                                    value={this.state.value}
                                    onChange={(newValue) => {
                                        this.setState({
                                            value: newValue
                                        }, () => this.SetYear())
                                    }}
                                    renderInput={(params) => <TextField sx={{ input: { color: Colors.TextColor } }} id='eMed_year_filter' size='small' {...params} helperText={null} />}
                                />
                            </LocalizationProvider>
                        </div>
                        {this.state.SearchFieldOpen ?
                            <div id="eMed_tbl_searchbox" >
                                <TextField
                                    sx={{ input: { color: Colors.TextColor } }}
                                    size='small'
                                    focused
                                    onChange={(e) => this.setState({ SearchText: e.target.value })}
                                    value={this.state.SearchText}
                                    label={t("SearchHere")}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip disableInteractive title={t("Clear")} placement='top'><CloseIcon sx={{ color: Colors.ThemeColor }} onClick={() => { this.setState({ SearchText: '' }) }} /></Tooltip>
                                        ),
                                    }}
                                />
                            </div> : null}
                        <Tooltip disableInteractive placement="top-start" title={this.state.SearchFieldOpen ? t("CloseSearch") : t("OpenSearch")}>
                            <Button id="eMed_tbl_searchicon" variant='contained' style={{ backgroundColor: Colors.ThemeColor }} onClick={() => this.setState({ SearchFieldOpen: !this.state.SearchFieldOpen, CardOpen: false, SearchText: '' })} >
                                {this.state.SearchFieldOpen ? <CloseIcon /> : <SearchIcon />}
                            </Button>
                        </Tooltip>
                    </div>
                </div>
                <div>
                    <Card className='eMed_tbl_card' sx={{ bgcolor: Colors.TableCard }}>
                        <TableContainer className='eMed_tbl_container'>
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align='center' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div style={{ color: Colors.TableHeaderTextColor }} className='eMed_sortable_center' id='eMed_tbl_headtext' >
                                                {t("SNo")}
                                            </div>
                                        </TableCell>
                                        <TableCell align='center' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div style={{ color: Colors.TableHeaderTextColor }} className='eMed_sortable_center' id='eMed_tbl_headtext'>
                                                {t("Month")}
                                            </div>
                                        </TableCell>
                                        <TableCell align='center' className='eMed_amount_column' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className='eMed_sortable_number'>
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'> {t("PurchaseAmount")}(₹)</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("purchase_amount", this.state.PurchaseSortOrder) }}>{SortIcon(this.state.PurchaseSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell align='center' className='eMed_amount_column' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className='eMed_sortable_number'>
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("SalesAmount")}(₹)</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("sales_amount", this.state.SalesSortOrder) }}>{SortIcon(this.state.SalesSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell align='right' className='eMed_amount_column' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className='eMed_sortable_number'>
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("ProfitAmount")}(₹)</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("profit_amount", this.state.ProfitSortOrder) }}>{SortIcon(this.state.ProfitSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {filtereddata.length > 0 ? filtereddata.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage).map((data, index) => (
                                    <TableBody key={index} h>
                                        <TableRow id='eMed_clickable_tbl_row' hover onClick={() => { this.setState({ ConsolidateSplitUp: true, SelectedMonth: data.date }) }}>
                                            <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='center'>
                                                <div id='eMed_tbl_bodytext'> {index + 1}</div>
                                            </TableCell>
                                            <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='center'>
                                                <div id='eMed_tbl_bodytext'> {data.date}</div>
                                            </TableCell>
                                            <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='right'>
                                                <div id='eMed_tbl_bodytext'> {AmountFormat(data.purchase_amount)}</div>
                                            </TableCell>
                                            <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='right'>
                                                <div id='eMed_tbl_bodytext'>{AmountFormat(data.sales_amount)}</div>
                                            </TableCell>
                                            <TableCell sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }} align='right'>
                                                <div id='eMed_tbl_bodytext'>{AmountFormat(data.profit_amount)}</div>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )) :
                                    <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_Norecords'>
                                        {t("NoRecords")}
                                    </div>}
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component='div'
                            id="eMed_tbl_pagination"
                            sx={{
                                color: Colors.PagiantionTxtColor
                            }}
                            rowsPerPageOptions={[5, 10, 15]}
                            count={filtereddata.length}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </Card>
                    {this.state.ConsolidateSplitUp ? <ModelDialogBox DialogClose={this.handleClose} header={t('ConsolidateSales-SplitUp')} component={<ConsSplitUp month={this.state.SelectedMonth} year={this.state.YearQuery} />} /> : null}
                    {this.state.dialogBox ?
                        <Dialog
                            type={"Notification"}
                            DialogClose={this.dialogCloseFunction}
                            header={"Message"}
                            content={this.state.ErrorMsg}
                            icon={<img className="eMed_dilboxAnimations" alt="Warning" src={Failed} />}
                            secondary={"Close"}
                            closeButton={this.dialogCloseFunction}
                        /> : null}
                </div>
            </div>
        )
    }
}
export default withTranslation()(ConsolidatedSales)
