import React, { Component } from 'react';
import LineChart from '../Modules/Charts/LineChart';

export default class RevenueBarChart extends Component {
  render() {
    return (
      <div style={{width:'20vw'}}><LineChart/></div>
    )
  }
}
