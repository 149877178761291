import * as React from 'react';
import { styled, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { withRouter } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment, Typography } from '@mui/material';
import { OutlinedInput } from '@mui/material';
import BasicModal from '../Shell/BasicModal';
import RouterComponent from '../Utility/RouterComponent';
import './NavbarApp.css';
import Avatar from '@mui/material/Avatar';
import logo from '../Assets/Logo/logo.png';
import Fulllogo from '../Assets/Logo/Fulllogo.png';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { localGetItem, localClearAll, clearAllCachevalue } from '../Utility/Services/CacheProviderService';
import Dialog from '@mui/material/Dialog';
import { SideMenuList } from '../Utility/SideMenuList';
import ComingSoon from '../Modules/Upcomingpages/ComingSoon';
import Collapse from '@mui/material/Collapse';
import TextField from '@mui/material/TextField';
import ClearIcon from "@mui/icons-material/Clear";
import { Trans, withTranslation } from 'react-i18next';
import { Colors } from "../Styles/Colors";
import Loader from '../Components/Loader';
import { AppBarMenus } from './AppBarMenus';
import Settings from '../Shell/MenuComponents/Settings';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { Modal, Paper } from '@mui/material';
import Success from '../Assets/Animation/Success.gif'
import DialogPop from '../Components/Dialog'


const openedMixin = (theme) => ({
  width: '20vw',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: '20vw',
    width: `calc(100% - 20vw)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: '20vw',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

class MiniDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawer_Open: false,
      anchorElUser: false,
      loggedInuserInfo: JSON.parse(localGetItem("loggedInUserInfo")),
      checked: false,
      globalSearch: '',
      menuId: null,
      selectedMenuId: null,
      selectedSubMenuId: null,
      isLoader: false
    }
  }
  /* Login UserName Api Function start  */
  componentDidMount() {
    if (localGetItem("isLoggedIn")) {
      var ProfileName = localGetItem("loggedInUserInfo")
      var myUser = JSON.parse(ProfileName)
      this.setState({
        loginName: myUser.login_user_name,
        loginType: myUser.login_user_role_name,
      })
    }
  }
  /* Login UserName Api Function end  */

  resetState = () => {
    this.setState({
      drawer_Open: false,
      anchorElUser: false,
      loggedInuserInfo: "",
      checked: false,
      globalSearch: '',
      menuId: null,
      selectedMenuId: null,
      selectedSubMenuId: null,
      isLoader: false
    })
  }
  Menuitem = (id) => {
    this.setState({
      meunId: id
    })
  }
  handleChange = () => {
    this.setState({
      checked: true
    })
  };

  handleDrawerOpen = () => {
    this.setState({
      drawer_Open: true,

    })
  };
  handleDrawerClose = () => {
    this.setState({
      drawer_Open: false,
    })
  };
  handleOpenUserMenu = () => {
    this.setState({
      anchorElUser: true,
    })
  };
  handleCloseUserMenu = (event) => {
    this.setState({
      anchorElUser: false
    })
  };
  logout = () => {
    this.resetState();
    localClearAll();
    clearAllCachevalue();
  }

  /*Routing and Loader Functions Start*/
  onClick = () => {
    this.setState({
      isLoader: true
    })
    setTimeout(() => {
      this.setState({
        isLoader: false,
      })
    }, 1000)
    if (this.state.selectedMenuId == 3) {
      var open = this.state.checked
      this.setState({
        checked: !open
      })
    } else if (this.state.selectedMenuId == 4) {
      var open = this.state.checked
      this.setState({
        checked: !open
      })
    } else if (this.state.selectedMenuId == 5) {
      var open = this.state.checked
      this.setState({
        checked: !open
      })
    }
  }
  /*Routing and Loader Functions End*/

  search = (event) => {
    this.setState({
      globalSearch: event.target.value,
    });
  };
  clearSearch = () => {
    this.setState({
      globalSearch: ''
    })
  }
  ModuleDialogClose = () => {
    this.setState({ menuComp: false })
  }
  dialogCloseFunction = () => {
    this.setState({ ModuleSucessPop: false })
  }

  ModuleSuccess = () => {
    this.setState({ ModuleSucessPop: true })
    setTimeout(() => {
      this.setState({ ModuleSucessPop: false })
    }, 2000)
  }
  render() {
    const { t } = this.props;
    const theme = this.props;
    const { history } = this.props;
    const menuList = this.state.loggedInuserInfo && this.state.loggedInuserInfo.is_fo_admin ? SideMenuList.SuperAdmin : this.state.loggedInuserInfo && this.state.loggedInuserInfo.isAdmin ? SideMenuList.Admin : SideMenuList.User
    if (localGetItem("isLoggedIn")) {
      return (
        <div>

          <Box className='eMed_muiDrawer' >

            <CssBaseline />
            <AppBar position="fixed" open={this.state.drawer_Open}>
              <Toolbar>
                <div className='eMed_headContent'>
                  <div className='emed_headerLogo'>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={this.handleDrawerOpen}
                      edge='start'
                      sx={{
                        ...(this.state.drawer_Open && { display: 'none', height: '3px' }),
                      }}>
                      <img className='img_logo' src={logo} alt="logo" />
                    </IconButton>
                  </div>
                  <div>
                    <TextField
                      placeholder={t('SearchHere')}
                      className="eMed_searchbox"
                      color='primary'
                      size='small'
                      value={this.state.globalSearch}
                      onChange={this.search}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon className="eMed_searchbarIcon" style={{ color: Colors.ThemeColor }} />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end>">
                            <ClearIcon className="eMed_searchbarIcon" style={{ color: Colors.ThemeColor }} onClick={this.clearSearch} />
                            {/* <BasicModal /> */}
                          </InputAdornment>
                        ),
                      }}
                    />

                  </div>
                </div>
                <div className='eMed_appbar_menu'>
                  {/* <AppBarMenus DialogClose={this.DialogClose}/> */}
                  {AppBarMenus.Menus.map((menu) => (
                    <div >
                      <Tooltip title={t(menu.menuName)} placement='top'>
                        {/* <IconButton id="eMed_menu_icons" onClick={() => this.setState({ menuComp: true })} sx={{ color: Colors.ContrastText }} > */}

                        <Avatar id='eMed_IconAvatar' sx={{ bgcolor: Colors.IconCircleBgColor, boxShadow: 3 }} onClick={() => this.setState({ menuComp: true })}>
                          {menu.icon}
                        </Avatar>
                        {/* </IconButton> */}
                      </Tooltip>
                      {this.state.menuComp ? <Settings DialogClose={this.ModuleDialogClose} Success={this.ModuleSuccess} /> : null}
                    </div>
                  ))}
                </div>
                <div className='fieldHeaderfordoctor'>
                  <div className='eMed_profile'>
                    <div className='eMed_user'>
                      <label className='eMed_loginuser'>{this.state.loginName}</label>
                    </div>
                    <div className='eMed_servicetype'>
                      <label className='eMed_LoginType'>{this.state.loginType}</label>
                    </div>
                  </div>
                  <div className='eMed_Avatar'>
                    <Box >
                      <Tooltip title="Profile">
                        <IconButton onClick={this.handleOpenUserMenu}>
                          <Avatar sx={{ bgcolor: Colors.IconCircleBgColor, boxShadow: 3, color: Colors.ThemeColor }} id="eMed_Icons_Avatar" />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        id="eMed_profileMenu"
                        anchorEl={this.state.anchorElUser}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        open={this.state.anchorElUser}
                        onClose={this.handleCloseUserMenu}
                      >

                        <div className='eMed_AvatarMenu'>
                          <MenuItem onClick={this.handleCloseUserMenu}>
                            <AccountBoxIcon sx={{ color: Colors.ThemeColor }} />
                            {t('Profile')}
                          </MenuItem>
                          <MenuItem onClick={() => { this.logout(); history.push('/') }}>
                            <ExitToAppIcon sx={{ color: Colors.ThemeColor }} />
                            {t('Logout')}
                          </MenuItem>
                        </div>

                      </Menu>
                    </Box>
                  </div>
                </div>
              </Toolbar>
            </AppBar>
            <Drawer className='eMed_Drawer'
              variant="permanent" open={this.state.drawer_Open} >
              <DrawerHeader className='eMed_drawerlogo' sx={{ backgroundColor: Colors.pageTopBackground }}>
                <img className='img_logo1' src={Fulllogo} alt="logo" />
                <IconButton onClick={this.handleDrawerClose}>
                  {theme.direction === 'rtl' ? <ChevronRightIcon sx={{ color: Colors.ListIcons }} /> : <ChevronLeftIcon sx={{ color: Colors.ListIcons }} />}
                </IconButton>
              </DrawerHeader>
              <List id="eMed_side_list">

                {menuList.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <ListItem
                        className='eMed_menuItems'
                        sx={{
                          color: window.location.href.toLowerCase().includes(item.menuName.replace(/\s/g, '').toLowerCase()) ? Colors.ContrastText : Colors.ListItems,
                          backgroundColor: window.location.href.toLowerCase().includes(item.menuName.replace(/\s/g, '').toLowerCase()) ? Colors.ThemeColor : Colors.pageTopBackground,
                          borderRadius: this.state.drawer_Open ? "5%" : "20%"
                        }}
                        // button 
                        key={item.menuName}
                        checked={this.state.checked}
                        onClick={() => {
                          this.setState({
                            selectedMenuId: item.id,
                            selectedSubMenuId: null
                          }, () => this.onClick(this.props.history.push(`/${item.menuName.replace(/\s/g, '')}`),))
                        }}>
                        <Tooltip placement='top' title={t(item.menuName)} disableInteractive>
                          <ListItemIcon className='eMed_menuIcon' sx={{ color: localGetItem("theme") === "dark" ? Colors.ContrastText : window.location.href.toLowerCase().includes(item.menuName.replace(/\s/g, '').toLowerCase()) ? Colors.ContrastText : Colors.ThemeColor }}>
                            {item.icon}
                          </ListItemIcon></Tooltip>
                        <ListItemText primary={t(item.menuName)} className='eMed_menuText' />
                      </ListItem>

                      <Box className='eMed_subMenus'>
                        <Collapse in={this.state.checked}>
                          {
                            (this.state.selectedMenuId == item.id) && Array.isArray(item.subMenu) ? (
                              <List disablePadding dense >
                                {item.subMenu.map((subItem) => {
                                  return (

                                    <ListItem id='eMed_menuHieghlight' style={{
                                      color: window.location.href.toLowerCase().includes(subItem.subMenuName.replace(/\s/g, '').toLowerCase()) ? Colors.ContrastText : Colors.ListItems,
                                      backgroundColor: window.location.href.toLowerCase().includes(subItem.subMenuName.replace(/\s/g, '').toLowerCase()) ? Colors.ThemeColor : Colors.pageTopBackground,
                                    }}
                                      key={subItem.name} button
                                      onClick={() => {
                                        this.setState({
                                          isLoader: true
                                        })
                                        setTimeout(() => {
                                          this.setState({
                                            isLoader: false,
                                          })
                                        }, 2000)
                                        this.setState({
                                          selectedSubMenuId: subItem.id
                                        }, () => {
                                          this.props.history.push(`/${item.menuName.replace(/\s/g, '')}/${subItem.subMenuName.replace(/\s/g, '')}`)
                                        })
                                      }
                                      } >
                                      <Tooltip placement='top' title={t(subItem.subMenuName)} disableInteractive>
                                        <ListItemIcon sx={{ color: localGetItem("theme") === "dark" ? Colors.ContrastText : window.location.href.toLowerCase().includes(subItem.subMenuName.replace(/\s/g, '').toLowerCase()) ? Colors.ContrastText : Colors.ThemeColor }}>
                                          {subItem.icon}
                                        </ListItemIcon>
                                      </Tooltip>
                                      <ListItemText>
                                        {t(subItem.subMenuName)}
                                      </ListItemText>
                                    </ListItem>
                                  )
                                })}

                              </List>
                            ) : null
                          }
                        </Collapse>
                      </Box>
                    </React.Fragment>
                  )
                })}

              </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, minWidth: '10vw', pl: 2, pr: 2 }} >
              <RouterComponent />

            </Box>
          </Box>
          <Loader loaderOpen={this.state.isLoader} />
          {this.state.ModuleSucessPop ?
            <DialogPop
              type={"Notification"}
              DialogClose={this.dialogCloseFunction}
              header={"Message"}
              content={"Primary Modules Updated Successfully"}
              icon={<img className="eMed_dilboxAnimations" alt="Warning" src={Success} />}
              secondary={"Close"}
              closeButton={this.dialogCloseFunction} />
            : null}
        </div>
      )
    } else {
      return (<RouterComponent />)
    }
  }
}
export default withRouter(withTranslation()(MiniDrawer));