import React from "react";
import {
    Card,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    Tooltip,
    Button
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Colors } from '../../../../../Styles/Colors';
import SearchIcon from '@mui/icons-material/Search';
import { withTranslation } from 'react-i18next';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FilterListIcon from '@mui/icons-material/FilterList';
import PaymentCard from '../../../../../Components/PaymentCard/PaymentCard';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import Dialog from '../../../../../Components/Dialog';
import Failed from '../../../../../Assets/Animation/Failed.gif';
import { AmountFormat } from "../../../../../Components/CommonFunctions/CommonFunctions";
import { localGetItem } from '../../../../../Utility/Services/CacheProviderService';
import { Serviceurls } from '../../../../../Utility/API/Serviceurls';
import RestAPIService from '../../../../../Utility/Services/RestAPIService';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

class PurchaseMonthly extends React.Component {
    constructor(props) {
        super(props);
        var date = new Date()
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        this.state = {
            purchaseMonthlyData: [],
            page: 0,
            rowsPerPage: 5,
            searchText: "",
            searchFieldShow: false,
            defaultTblData: [],
            dialogBox: false,
            pharmacyID: localGetItem("Pharmacy_Id"),
            dateSortOrder: 'asc',
            dateSortIcon: 'none',
            vendornameSortOrder: 'asc',
            vendornameSortIcon: 'none',
            billnoSortOrder: 'asc',
            billnoSortIcon: 'none',
            purordernoSortOrder: 'asc',
            purordernoSortIcon: 'none',
            totalamountSortOrder: 'asc',
            totalamountSortIcon: 'none',
            selectDate: date,
            selectMonth: month,
            selectYear: year,
            isDatePickerOpen: false


        }
    }

    componentDidMount() {
        this.getPurchaseMonthly()
    }

    /* Table Get API Funtion start */
    getPurchaseMonthly = () => {
        try {
            let QueryParams = `?pharmacy_id=${this.state.pharmacyID}&month=${this.state.selectMonth}&year=${this.state.selectYear}&page=&page_size=100000`
            RestAPIService.getAll(Serviceurls.GET_PURCHASE_MONTHLY + QueryParams)
                .then(response => {
                    this.setState({
                        purchaseMonthlyData: response.data.data.bill_details.length > 0 ? response.data.data.bill_details : [],
                        defaultTblData: response.data.data.bill_details,
                        cardTotalAmt: response.data.data.total_amount
                    })
                })
                .catch(e => {
                    this.setState({ dialogBox: true, ErrorMsg: e.message });
                    setTimeout(() => {
                        this.setState({
                            dialogBox: false
                        })
                    }, 3000)
                });
        }
        catch (e) {
            this.setState({ dialogBox: true, ErrorMsg: e.message });
            setTimeout(() => {
                this.setState({
                    dialogBox: false
                })
            }, 3000)
        }
    }
    /* Table Get API Funtion end */

    /* Failed Dialogbox function end */
    dialogCloseFunction = () => {
        this.setState({
            dialogBox: false
        })
    }
    /* Failed Dialogbox function start */

    /* Month and Year function start */
    picMonthandYear = () => {
        this.setState({
            selectMonth: this.state.monthandYear != null ? this.state.monthandYear.getMonth() + 1 : null,
            selectYear: this.state.monthandYear != null ? this.state.monthandYear.getFullYear() : null
        }, () => this.getPurchaseMonthly())
    }
    /* Month and Year function end */

    /* pagechenge function start*/
    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    }
    /* pagechenge function end*/

    /* Rowpagechenge function start*/
    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value, page: 0 });
    }
    /* Rowpagechenge function end*/

    render() {
        const { t } = this.props
        const filtereddata = this.state.purchaseMonthlyData.filter((data) => {
            return (
                (data.date === null ? null : data.date.toLowerCase().includes(this.state.searchText.toLowerCase())) ||
                (data.vendor_name === null ? null : data.vendor_name.toLowerCase().includes(this.state.searchText.toLowerCase())) ||
                (data.bill_no === null ? null : data.bill_no.toLowerCase().includes(this.state.searchText.toLowerCase())) ||
                (data.purchase_order_number === null ? null : Math.round(data.purchase_order_number * 100) === parseInt(Math.round(this.state.searchText * 100))) ||
                (data.amount === null ? null : Math.round(data.amount * 100) === parseInt(Math.round(this.state.searchText * 100)))
            );
        });
        // Sorting Function   ==== starts
        const sorting = (col, SortOrder) => {
            if (SortOrder === "asc") {
                const Sorted = [...this.state.purchaseMonthlyData].sort((a, b) => {
                    if (typeof (a[col]) == "string") {
                        return a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
                    } else {
                        return a[col] > b[col] ? 1 : -1
                    }
                })
                this.setState({ purchaseMonthlyData: Sorted })

                if (col === "date") {
                    this.setState({
                        dateSortOrder: 'desc',
                        dateSortIcon: 'asc',
                        vendornameSortOrder: "asc",
                        vendornameSortIcon: 'none',
                        billnoSortOrder: "asc",
                        billnoSortIcon: 'none',
                        purordernoSortOrder: "asc",
                        purordernoSortIcon: 'none',
                        totalamountSortOrder: "asc",
                        totalamountSortIcon: 'none',
                    })
                }
                else if (col === "vendor_name") {
                    this.setState({
                        dateSortOrder: 'asc',
                        dateSortIcon: 'none',
                        vendornameSortOrder: "desc",
                        vendornameSortIcon: 'asc',
                        billnoSortOrder: "asc",
                        billnoSortIcon: 'none',
                        purordernoSortOrder: "asc",
                        purordernoSortIcon: 'none',
                        totalamountSortOrder: "asc",
                        totalamountSortIcon: 'none',
                    })
                }
                else if (col === "bill_no") {
                    this.setState({
                        dateSortOrder: 'asc',
                        dateSortIcon: 'none',
                        vendornameSortOrder: 'asc',
                        vendornameSortIcon: 'none',
                        billnoSortOrder: "desc",
                        billnoSortIcon: 'asc',
                        purordernoSortOrder: "asc",
                        purordernoSortIcon: 'none',
                        totalamountSortOrder: "asc",
                        totalamountSortIcon: 'none',
                    })
                }
                else if (col === "purchase_order_number") {
                    this.setState({
                        dateSortOrder: 'asc',
                        dateSortIcon: 'none',
                        vendornameSortOrder: 'asc',
                        vendornameSortIcon: 'none',
                        billnoSortOrder: "asc",
                        billnoSortIcon: 'none',
                        purordernoSortOrder: "desc",
                        purordernoSortIcon: 'asc',
                        totalamountSortOrder: "asc",
                        totalamountSortIcon: 'none',
                    })
                } else if (col === "amount") {
                    this.setState({
                        dateSortOrder: 'asc',
                        dateSortIcon: 'none',
                        vendornameSortOrder: 'asc',
                        vendornameSortIcon: 'none',
                        billnoSortOrder: "asc",
                        billnoSortIcon: 'none',
                        purordernoSortOrder: "asc",
                        purordernoSortIcon: 'none',
                        totalamountSortOrder: "desc",
                        totalamountSortIcon: 'asc',
                    })
                }

            }
            else if (SortOrder === "desc") {
                const Sorted = [...this.state.purchaseMonthlyData].sort((a, b) => {
                    if (typeof (a[col]) == "string") {
                        return a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
                    } else {
                        return a[col] < b[col] ? 1 : -1
                    }
                })
                this.setState({ purchaseMonthlyData: Sorted })

                if (col === "date") {
                    this.setState({ dateSortOrder: 'default', dateSortIcon: "desc", })
                } else if (col === "vendor_name") {
                    this.setState({ vendornameSortOrder: 'default', vendornameSortIcon: "desc" })
                } else if (col === "bill_no") {
                    this.setState({ billnoSortOrder: 'default', billnoSortIcon: "desc" })
                } else if (col === "purchase_order_number") {
                    this.setState({ purordernoSortOrder: 'default', purordernoSortIcon: "desc" })
                } else if (col === "amount") {
                    this.setState({ totalamountSortOrder: 'default', totalamountSortIcon: "desc" })
                }
            } else if (SortOrder === "default") {
                this.setState({
                    dateSortOrder: 'asc',
                    dateSortIcon: 'none',
                    vendornameSortOrder: 'asc',
                    vendornameSortIcon: 'none',
                    billnoSortOrder: 'asc',
                    billnoSortIcon: 'none',
                    purordernoSortOrder: 'asc',
                    purordernoSortIcon: 'none',
                    totalamountSortOrder: 'asc',
                    totalamountSortIcon: 'none',
                    purchaseMonthlyData: this.state.defaultTblData

                })
            }
        }
        // Sorting Function   ==== ends


        //  Sorting Icons Change func === starts 
        const SortIcon = (sortType) => {
            if (sortType === "asc") {
                return <Tooltip disableInteractive placement='top' title={t("InAscendingOrder")}><KeyboardArrowUpIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
            }
            else if (sortType === 'desc') {
                return <Tooltip disableInteractive placement='top' title={t("InDecendingOrder")}><KeyboardArrowDownIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
            }
            else return <Tooltip disableInteractive placement='top' title={t("Sorting")}><FilterListIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
        }

        //  Sorting Icons Change func === ends  
        return (
            <div>
                <div className="eMed_page_header">
                    <div className="eMed_purreturn_tblandcard">
                        <div id="eMed_table_name" style={{ color: Colors.TextColor }}>
                            {t(this.props.menuName)}
                        </div>
                        <div id="eMed_tbl_paymentcard">
                            <PaymentCard Color={Colors.blue} Icons={<CurrencyRupeeIcon />} Name={t("Total")} Amount={this.state.cardTotalAmt} />
                        </div>
                    </div>
                    <div id="eMed_tbl_filters">
                        <div>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DatePicker
                                    open={this.state.isDatePickerOpen}
                                    onOpen={() => this.setState({ isDatePickerOpen: true })}
                                    onClose={() => this.setState({ isDatePickerOpen: false })}
                                    views={['year', 'month']}
                                    label={t("YearandMonth")}
                                    minDate={new Date('2012-03-01')}
                                    maxDate={new Date()}
                                    value={this.state.monthandYear}
                                    onChange={(newValue) => {
                                        this.setState({
                                            monthandYear: newValue
                                        })
                                    }}
                                    onAccept={(values) => {
                                        this.setState({
                                            value: values
                                        }, () => {
                                            this.picMonthandYear()
                                        })

                                    }}
                                    renderInput={(params) => <TextField sx={{ input: { color: Colors.TextColor } }} onClick={(e) => this.setState({ isDatePickerOpen: true })} id="eMed_tbl_yearandmonth" size='small' {...params} helperText={null} />}
                                />
                            </LocalizationProvider>
                        </div>

                        {this.state.searchFieldShow ?
                            <div id='eMed_tbl_searchbox'>
                                <TextField
                                    sx={{ input: { color: Colors.TextColor } }}
                                    size='small'
                                    focused
                                    onChange={(e) => this.setState({ searchText: e.target.value })}
                                    value={this.state.searchText}
                                    label={t("SearchHere")}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip disableInteractive title={t("Clear")} placement='top'><CloseIcon sx={{ color: Colors.ThemeColor, cursor: 'pointer' }} onClick={() => { this.setState({ searchText: '' }) }} /></Tooltip>
                                        ),
                                    }}
                                />
                            </div> : null}
                        <div className="eMed_tbl_searchicon">
                            <Tooltip disableInteractive placement='top' title={this.state.searchFieldShow ? t("CloseSearch") : t("OpenSearch")}>
                                <Button id="eMed_tbl_searchicon" variant='contained' style={{ backgroundColor: Colors.ThemeColor }} onClick={() => this.setState({ searchFieldShow: !this.state.searchFieldShow, searchText: '', cardOpen: false })} >
                                    {this.state.searchFieldShow ? <CloseIcon /> : <SearchIcon />}
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div>
                    <Card sx={{ bgcolor: Colors.TableCard }} className="eMed_tbl_card" >
                        <TableContainer className="eMed_tbl_container" >
                            <Table stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell className="eMed_tbl_headwidth_small" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div style={{ color: Colors.TableHeaderTextColor }} className="eMed_sortable_center" id='eMed_tbl_headtext'>{t("S.No")}</div>
                                        </TableCell>
                                        <TableCell className='eMed_tbl_headwidth_large' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className="eMed_sortable_center">
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("Date")}</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("date", this.state.dateSortOrder) }}>{SortIcon(this.state.dateSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell className='eMed_tbl_headwidth_large' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className="eMed_sortable_name">
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("VendorName")}</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("vendor_name", this.state.vendornameSortOrder) }}>{SortIcon(this.state.vendornameSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell className='eMed_tbl_headwidth_large' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className="eMed_sortable_center">
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("BillNo")}</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("bill_no", this.state.billnoSortOrder) }}>{SortIcon(this.state.billnoSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell className='eMed_tbl_headwidth_large' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className="eMed_sortable_number">
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("PurchaseOrderNo")}</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("purchase_order_number", this.state.purordernoSortOrder) }}>{SortIcon(this.state.purordernoSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                        <TableCell className="eMed_tbl_headwidth_large" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                            <div className="eMed_sortable_number">
                                                <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("TotalAmount")}(₹)</div>
                                                <div id="eMed-SortIcons" onClick={() => { sorting("amount", this.state.totalamountSortOrder) }}>{SortIcon(this.state.totalamountSortIcon)}</div>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {filtereddata.length > 0 ? filtereddata
                                    .slice(this.state.page * this.state.rowsPerPage,
                                        this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                    .map((data, index) => {
                                        return (
                                            <TableBody key={index}>
                                                <TableRow>
                                                    <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="center">{index + 1}</TableCell>
                                                    <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="center">{data.date}</TableCell>
                                                    <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} align="left">
                                                        <Tooltip title={data.vendor_name}>
                                                            <div id="eMed_tbl_bodytext">
                                                                {data.vendor_name.length > 10 ? data.vendor_name.slice(0, 10) + ".." : data.vendor_name}
                                                            </div>
                                                        </Tooltip>
                                                    </TableCell>
                                                    <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="center">{data.bill_no}</TableCell>
                                                    <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="right">{data.purchase_order_number}</TableCell>
                                                    <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="right">{AmountFormat(data.amount)}</TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )
                                    }) : <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_Norecords'>
                                    {t("NoRecords")}
                                </div>}
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component='div'
                            id="eMed_tbl_pagination"
                            sx={{ color: Colors.PagiantionTxtColor }}
                            rowsPerPageOptions={[5, 10, 15]}
                            count={filtereddata.length}
                            rowsPerPage={this.state.rowsPerPage}
                            page={this.state.page}
                            onPageChange={this.handleChangePage}
                            onRowsPerPageChange={this.handleChangeRowsPerPage}
                        />
                    </Card>
                    {this.state.dialogBox ?
                        <Dialog
                            type={"Notification"}
                            DialogClose={this.dialogCloseFunction}
                            header={"Message"}
                            content={this.state.ErrorMsg}
                            icon={<img className="eMed_dilboxAnimations" alt="Warning" src={Failed} />}
                            secondary={"Close"}
                            closeButton={this.dialogCloseFunction}
                        /> : null}
                </div>

            </div>
        )
    }
}
export default withTranslation()(PurchaseMonthly);