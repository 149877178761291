export class Constants {
    static SaveSuccess = "Record saved successfully!";
    static CancelRequest = "Canceled successfully!";
    static Deleted = "Deleted successfully!";
    static WarningToast = "Warning";
    static Failed = "Failed to load";
}
export const StockAdjustment_Reason = [
    { id: 1, name: "Breakage" },
    { id: 2, name: "Loss" },
    { id: 3, name: "Expired" },
    { id: 4, name: "Miscellaneous" }
]
