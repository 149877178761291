import React from "react";
import Button from '@mui/material/Button';
import Dialog from '../../Components/Dialog';
import { Constants } from "../../Utility/Constants";
import Warning from '../../Assets/Animation/Warning.gif';
import Success from '../../Assets/Animation/Success.gif';
import Cancel from "../../Assets/Animation/Cancel.gif";
import Loader from '../../Components/Loader';
import Deleted from '../../Assets/Animation/Deleted.gif';
import Failed from '../../Assets/Animation/Failed.gif';
import { Trans, withTranslation } from 'react-i18next';


class BillingTransaction extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dilbox_OpenSave: false,
      dilbox_OpenDelete: false,
      dilbox_OpenSuccess: false,
      dilbox_OpenWarning: false,
      dilbox_OpenCancel: false,
      dilbox_DeletedSuccess: false,
      dilbox_OpenFailed: false,
      isLoader: false
    }
  }

  /* DialogBox Close Function Start */
  dialogCloseFunction = () => {
    this.setState({
      dilbox_OpenSave: false,
      dilbox_OpenDelete: false,
      dilbox_OpenSuccess: false,
      dilbox_OpenWarning: false,
      dilbox_OpenCancel: false,
      dilbox_DeletedSuccess: false,
      dilbox_OpenFailed: false,

    })
  }
  /* DialogBox Close Function End */

  /* Open Save DialogBox Function Start */
  yesButtondata = () => {
    this.setState({
      isLoader: true
    })
    setTimeout(() => {
      this.setState({
        isLoader: false,
        dilbox_OpenSuccess: true
      })
    }, 2000)
  }
  /* Open Save DialogBox Function End */

  /* Open Delete DialogBox Function Start */
  closeButtondata = () => {
    this.setState({
      isLoader: true
    })
    setTimeout(() => {
      this.setState({
        isLoader: false,
        dilbox_DeletedSuccess: true
      })
    }, 2000)
  }
  /* Open Delete DialogBox Function End */

  /* Close Notification DialogBox Function Start */
  closeDialog = () => {
    this.setState({
      dilbox_OpenSuccess: false,
      dilbox_OpenWarning: false,
      dilbox_OpenCancel: false,
      dilbox_DeletedSuccess: false,
      dilbox_OpenFailed: false,
    })
  }
  /* Close Notification DialogBox Function End */

  render() {
    const { t } = this.props
    return (
      <div>
        <Loader loaderOpen={this.state.isLoader} />
        <div>
          <h1>{t('Confirmation')}</h1>
          <div className="eMed_confirm">
            <Button variant="contained" onClick={() => this.setState({ dilbox_OpenSave: true })}>
              {t("Save")}
            </Button>
            {this.state.dilbox_OpenSave ? <Dialog
              type={"Confirmation"}
              DialogClose={this.dialogCloseFunction}
              header={"Confirm"}
              content={"Do you want to save the record?"}
              primary={"Yes"}
              secondary={"No"}
              yesButton={this.yesButtondata} /> : null}
            <Button variant="contained" onClick={() => { this.setState({ dilbox_OpenDelete: true }) }}>
              {t("Delete")}
            </Button>
            {this.state.dilbox_OpenDelete ? <Dialog
              type={"Confirmation"}
              DialogClose={this.dialogCloseFunction}
              header={"Confirm"}
              content={"Do you want to delete the record?"}
              primary={"Yes"}
              secondary={"No"}
              yesButton={this.closeButtondata} /> : null}
            {this.state.dilbox_DeletedSuccess ? <Dialog
              type={"Notification"}
              DialogClose={this.dialogCloseFunction}
              header={"Message"}
              content={Constants.Deleted}
              icon={<img className="eMed_dilboxAnimations" alt="Warning" src={Deleted} />}
              secondary={"Close"}
              closeButton={this.closeDialog} /> : null}
          </div>
        </div>
        <div>
          <h1>{t('Notification')}</h1>
          <div className="eMed_notify">
            <Button variant="contained" onClick={() => this.setState({ dilbox_OpenSuccess: true })}>
              {t("Success")}
            </Button>
            {this.state.dilbox_OpenSuccess ? <Dialog
              type={"Notification"}
              DialogClose={this.dialogCloseFunction}
              header={"Message"}
              content={Constants.SaveSuccess}
              icon={<img className="eMed_dilboxAnimations" alt="Warning" src={Success} />}
              secondary={"Close"} /> : null}
            <Button variant="contained" onClick={() => this.setState({ dilbox_OpenWarning: true })}>
              {t("Warning")}
            </Button>
            {this.state.dilbox_OpenWarning ? <Dialog
              type={"Notification"}
              DialogClose={this.dialogCloseFunction}
              header={"Message"}
              content={Constants.WarningToast}
              icon={<img className="eMed_dilboxAnimations" alt="Warning" src={Warning} />}
              secondary={"Close"} /> : null}
            <Button variant="contained" onClick={() => this.setState({ dilbox_OpenCancel: true })}>
              {t('Cancel')}
            </Button>
            {this.state.dilbox_OpenCancel ? <Dialog
              type={"Notification"}
              DialogClose={this.dialogCloseFunction}
              header={"Message"}
              content={Constants.CancelRequest}
              icon={<img className="eMed_dilboxAnimations" alt="Warning" src={Cancel} />}
              secondary={"Close"} /> : null}
            <Button variant="contained" onClick={() => this.setState({ dilbox_OpenFailed: true })}>
              {t('Failed')}
            </Button>
            {this.state.dilbox_OpenFailed ? <Dialog
              type={"Notification"}
              DialogClose={this.dialogCloseFunction}
              header={"Message"}
              content={Constants.Failed}
              icon={<img className="eMed_dilboxAnimations" alt="Warning" src={Failed} />}
              secondary={"Close"} /> : null}
          </div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(BillingTransaction);