import PurchaseOverview from './Reports/PurchaseReports/PurchaseOverview/PurchaseOverview';
import PurchaseDetails from './Reports/PurchaseReports/PurchaseDetails/PurchaseDetails';
import PurchaseGST from './Reports/PurchaseReports/PurchaseGST/PurchaseGST';
import PurchaseReturn from './Reports/PurchaseReports/PurchaseReturn/PurchaseReturn';
import PurchaseMonthly from './Reports/PurchaseReports/PurchseMonthly/PurchaseMonthly';
import InvoiceAdjReport from './Reports/PurchaseReports/InvoiceAdjustmentReport/InvoiceAdjReport';
import VendorReport from './Reports/PurchaseReports/VendorReport/VendorReport';
import SalesOverview from './Reports/SalesReports/SalesOverview/SalesOverview';
import DayEndOverview from './Reports/SalesReports/DayEndReports/DayEndOverview';
import ConsolidatedSales from './Reports/SalesReports/ConsolidatedSales/ConsolidatedSales';
import SalesDetailed from './Reports/SalesReports/SalesDetailed/SalesDetailed';
import SalesGST from './Reports/SalesReports/SalesGST/SalesGST';
import SalesReturn from './Reports/SalesReports/SalesReturn/SalesReturn';
import SalesMonthly from './Reports/SalesReports/SalesMonthly/SalesMonthly';
import StockOverview from './Reports/StockReports/StockOverview/StockOverview';
import MISReport from './Reports/StockReports/MIS Report/MISReport';
import ExpiryStock from './Reports/StockReports/ExpiryStock/ExpiryStock';
import LowStockDrugs from './Reports/StockReports/LowStockDrugs/LowStockDrugs';
import SlowMovementDrugs from './Reports/StockReports/SlowMovementDrugs/SlowMovementDrugs';
import NoMovementDrugs from './Reports/StockReports/NoMovementDrugs/NoMovementDrugs';
import AdjustmentReport from './Reports/StockReports/AdjustmentReport/AdjustmentReport';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';


export class MenuLists {
    static salesReports = [
        { id: 1.1, menuName: 'Day End Report', icon: <ReceiptLongIcon />, component: <DayEndOverview menuName="Day End Report" /> },
        { id: 1.2, menuName: 'Sales Overview', icon: <ReceiptLongIcon />, component: <SalesOverview menuName="Sales Overview" /> },
        { id: 1.3, menuName: 'Sales Detailed', icon: <ReceiptLongIcon />, component: <SalesDetailed menuName="Sales Detailed" /> },
        { id: 1.4, menuName: 'Sales Return', icon: <ReceiptLongIcon />, component: <SalesReturn menuName="Sales Return" /> },
        { id: 1.5, menuName: 'Sales Monthly', icon: <ReceiptLongIcon />, component: <SalesMonthly menuName="Sales Monthly" /> },
        { id: 1.6, menuName: 'Sales GST', icon: <ReceiptLongIcon />, component: <SalesGST menuName="Sales GST" /> },
        { id: 1.7, menuName: 'Consolidated Sales', icon: <ReceiptLongIcon />, component: <ConsolidatedSales menuName="Consolidated Sales" /> },
    ];

    static PurchaseReports = [
        { id: 2.1, menuName: 'Purchase Overview', icon: <ReceiptLongIcon />, component: <PurchaseOverview menuName="Purchase Overview" /> },
        { id: 2.2, menuName: 'Purchase Detailed', icon: <ReceiptLongIcon />, component: <PurchaseDetails menuName="Purchase Detailed" /> },
        { id: 2.3, menuName: 'Purchase Return', icon: <ReceiptLongIcon />, component: <PurchaseReturn menuName="Purchase Return" /> },
        { id: 2.4, menuName: 'Purchase Monthly', icon: <ReceiptLongIcon />, component: <PurchaseMonthly menuName="Purchase Monthly" /> },
        { id: 2.5, menuName: 'Purchase GST', icon: <ReceiptLongIcon />, component: <PurchaseGST menuName="Purchase GST" /> },
        { id: 2.6, menuName: 'Invoice Adjustment Reports', icon: <ReceiptLongIcon />, component: <InvoiceAdjReport menuName="Invoice Adjustment Report" /> },
        { id: 2.7, menuName: 'Vendor Report', icon: <ReceiptLongIcon />, component: <VendorReport menuName="Vendor Report" /> }
    ];

    static StockReports = [
        { id: 3.1, menuName: 'Stock Overview', icon: <ReceiptLongIcon />, component: <StockOverview menuName="Stock Overview" /> },
        { id: 3.2, menuName: 'Adjustment Reports of Stocks', icon: <ReceiptLongIcon />, component: <AdjustmentReport menuName="Stock Adjustment Reports" /> },
        { id: 3.3, menuName: 'Expiry Stock', icon: <ReceiptLongIcon />, component: <ExpiryStock menuName="Expiry Stock" /> },
        { id: 3.4, menuName: 'Low Stock Drugs', icon: <ReceiptLongIcon />, component: <LowStockDrugs menuName="Low Stock Drugs" /> },
        { id: 3.5, menuName: 'Slow Movement Drugs', icon: <ReceiptLongIcon />, component: <SlowMovementDrugs menuName="Slow Movement Drugs" /> },
        { id: 3.6, menuName: 'No Movement Drugs', icon: <ReceiptLongIcon />, component: <NoMovementDrugs menuName="No Movement Drugs" /> },
        { id: 3.7, menuName: 'MIS Report', icon: <ReceiptLongIcon />, component: <MISReport menuName="MIS Report" /> }

    ];


} 