import React, { Component } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import "./StockOverview.css";
import "../../../../../Styles/CommonTableStyles.css"
import { Colors } from "../../../../../Styles/Colors";
import { Button, FormControl, InputLabel, MenuItem, Select, TablePagination, FormHelperText, IconButton, Popover } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import TextField from '@mui/material/TextField';
import { Trans, withTranslation } from 'react-i18next';
import DatePicker from "../../../../../Components/DatePicker/DatePicker";
import { DateTime } from "luxon";
import { Serviceurls } from '../../../../../Utility/API/Serviceurls';
import RestAPIService from '../../../../../Utility/Services/RestAPIService';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { AmountFormat } from '../../../../../Components/CommonFunctions/CommonFunctions';
import { localGetItem } from '../../../../../Utility/Services/CacheProviderService';
import { DosageType } from '../../../../../Components/CommonFunctions/CommonFunctions';
import Dialog from '../../../../../Components/Dialog';
import Failed from "../../../../../Assets/Animation/Failed.gif";

class StockOverview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            rowsPerPage: 5,
            BrandNameSortOrder: 'asc',
            GenericNameSortOrder: 'asc',
            ExpiryDateSortOrder: 'asc',
            QuantitySortOrder: 'asc',
            BrandNameSortIcon: 'none',
            ExpiryDateSortIcon: 'none',
            GenericNameSortIcon: 'none',
            QuantitySortIcon: 'none',
            SearchFieldShow: false,
            SearchText: "",
            loggedInuserInfo: true,
            datePickerPop: false,
            datePicker: 0,
            QueryFrmDte: "",
            QueryEndDte: "",
            StockOverview: [],
            DefaultStockOverview: [],
            Selectdate: "",
            SelectdatePop: false,
            FrmDate: new Date(),
            dialogBox: false
        }
    }

    componentDidMount() {
        this.getStockOverview();
    }

    getStockOverview = () => {
        try {
            RestAPIService.getAll(Serviceurls.PHARMACY_REPORT_STOCK_REPORT + `?pharmacy_id=${localGetItem("Pharmacy_Id")}&page=&page_size=all&delivery_date=${this.state.Selectdate}`)
                .then(response => {
                    this.setState({
                        StockOverview: response.data.data.result,
                        DefaultStockOverview: response.data.data.result
                    })
                })
                .catch(e => {
                    this.setState({ dialogBox: true, ErrorMsg: e.message });
                    setTimeout(() => {
                        this.setState({
                            dialogBox: false
                        })
                    }, 3000)
                });
        }
        catch (e) {
            this.setState({ dialogBox: true, ErrorMsg: e.message });
            setTimeout(() => {
                this.setState({
                    dialogBox: false
                })
            }, 3000)
        }
    }

    CustomDates = (FromDate) => {
        this.setState({
            Selectdate: FromDate,
            datePickerPop: false
        }, () => { this.getStockOverview() })
    }

    PopClose = () => {
        this.setState({
            datePickerPop: false,
            SelectdatePop: false,
            datePicker: 0,
            Selectdate: ''

        }, () => { this.getStockOverview() })
    }

    //  Select Date HelperText Funtion --- starts

    DateHelperText = () => {
        if (this.state.datePicker == 0) {
            return " "
        }
        else if (this.state.datePicker === 1) {
            return DateTime.now().toFormat('dd-MM-yyyy')
        }
        else if (this.state.datePicker === 2) {
            return DateTime.now().minus({ days: 1 }).toFormat('dd-MM-yyyy')
        }
        else if (this.state.datePicker === 3) {
            return `${DateTime.now().minus({ days: 7 }).toFormat('dd-MM-yyyy')}`
        }
        else if (this.state.datePicker === 4) {
            return `${DateTime.now().minus({ days: 30 }).toFormat('dd-MM-yyyy')}`
        }
        else if (this.state.datePicker === 5) {
            return `${this.state.Selectdate.split("-").reverse().join("-")}`
        }
        else if (this.state.datePicker === 6) {
            return `${this.state.Selectdate.split("-").reverse().join("-")}`
        }
    }

    //  Select Date HelperText Funtion --- end

    //  Select Date Funtion --- start

    handledate = () => {
        const today = DateTime.now().toFormat('yyyy-MM-dd');
        const yesterday = DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd');
        const startweek = DateTime.now().minus({ days: 7 }).toFormat('yyyy-MM-dd');
        const startmonth = DateTime.now().minus({ days: 30 }).toFormat('yyyy-MM-dd');

        if (this.state.datePicker === 0) {
            this.setState({ Selectdate: "" }, () => { this.getStockOverview() })
        }
        else if (this.state.datePicker === 1) {
            this.setState({ Selectdate: today }, () => { this.getStockOverview() })
        }
        else if (this.state.datePicker === 2) {
            this.setState({ Selectdate: yesterday }, () => { this.getStockOverview() })
        }
        else if (this.state.datePicker === 3) {
            this.setState({ Selectdate: startweek }, () => { this.getStockOverview() })
        }
        else if (this.state.datePicker === 4) {
            this.setState({ Selectdate: startmonth }, () => { this.getStockOverview() })
        }
    }

    //  Select Date Funtion --- end

    HandleFromDate = (newvalue) => {
        this.setState({
            FrmDate: newvalue,
        });
    };

    handleSelectDate = () => {
        this.setState({
            Selectdate: DateTime.fromJSDate(this.state.FrmDate).toFormat('yyyy-MM-dd'),
            SelectdatePop: false
        }, () => { this.getStockOverview() })
    }

    //  Mui table Pagination Functions --- starts

    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    }

    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value, page: 0 });
    }

    //  Mui table Pagination Functions --- ends

    dialogCloseFunction = () => {
        this.setState({
            dialogBox: false
        })
    }

    render() {
        const { t } = this.props

        // Sorting Function   ==== starts
        const sorting = (col, SortOrder) => {
            if (SortOrder === "asc") {
                const Sorted = [...this.state.StockOverview].sort((a, b) => {
                    if (typeof (a[col]) == "string") {
                        return a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
                    } else {
                        return a[col] > b[col] ? 1 : -1
                    }
                })
                this.setState({ StockOverview: Sorted })

                if (col === "brand_name") {
                    this.setState({ BrandNameSortOrder: 'desc', ExpiryDateSortOrder: 'asc', QuantitySortOrder: 'asc', GenericNameSortOrder: 'asc', BrandNameSortIcon: "asc", ExpiryDateSortIcon: "none", GenericNameSortIcon: 'none', QuantitySortIcon: 'none', })
                }
                else if (col === "generic_name") {
                    this.setState({ GenericNameSortOrder: 'desc', ExpiryDateSortOrder: 'asc', QuantitySortOrder: 'asc', BrandNameSortOrder: 'asc', GenericNameSortIcon: "asc", ExpiryDateSortIcon: "none", BrandNameSortIcon: 'none', QuantitySortIcon: 'none', })
                }
                else if (col === "expiry_date") {
                    this.setState({ ExpiryDateSortOrder: 'desc', GenericNameSortOrder: 'asc', QuantitySortOrder: 'asc', BrandNameSortOrder: 'asc', ExpiryDateSortIcon: "asc", GenericNameSortIcon: "none", BrandNameSortIcon: 'none', QuantitySortIcon: 'none', })
                }
                else if (col === "mrp") {
                    this.setState({ QuantitySortOrder: 'desc', GenericNameSortOrder: 'asc', ExpiryDateSortOrder: 'asc', BrandNameSortOrder: 'asc', QuantitySortIcon: "asc", GenericNameSortIcon: "none", BrandNameSortIcon: 'none', ExpiryDateSortIcon: 'none', })
                }

            }
            else if (SortOrder === "desc") {
                const Sorted = [...this.state.StockOverview].sort((a, b) => {
                    if (typeof (a[col]) == "string") {
                        return a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
                    } else {
                        return a[col] < b[col] ? 1 : -1
                    }
                })
                this.setState({ StockOverview: Sorted })

                if (col === "brand_name") {
                    this.setState({ BrandNameSortOrder: 'default', BrandNameSortIcon: "desc" })
                }
                else if (col === "generic_name") {
                    this.setState({ GenericNameSortOrder: 'default', GenericNameSortIcon: "desc" })
                }
                else if (col === "expiry_date") {
                    this.setState({ ExpiryDateSortOrder: 'default', ExpiryDateSortIcon: "desc" })
                }
                else if (col === "mrp") {
                    this.setState({ QuantitySortOrder: 'default', QuantitySortIcon: "desc" })
                }
            }
            else if (SortOrder === "default") {
                this.setState({
                    BrandNameSortIcon: 'none',
                    ExpiryDateSortIcon: 'none',
                    GenericNameSortIcon: 'none',
                    QuantitySortIcon: 'none',
                    BrandNameSortOrder: 'asc',
                    GenericNameSortOrder: 'asc',
                    ExpiryDateSortOrder: 'asc',
                    QuantitySortOrder: 'asc',
                    StockOverview: this.state.DefaultStockOverview
                })
            }
        }
        // Sorting Function   ==== ends


        //  Sorting Icons Change func === starts 
        const SortIcon = (sortType) => {
            if (sortType === "asc") {
                return <Tooltip disableInteractive placement='top' title={t("InAscendingOrder")}><KeyboardArrowUpIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
            }
            else if (sortType === 'desc') {
                return <Tooltip disableInteractive placement='top' title={t("InDecendingOrder")}><KeyboardArrowDownIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
            }
            else return <Tooltip disableInteractive placement='top' title={t("Sorting")}><FilterListIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
        }

        //  Sorting Icons Change func === ends 


        //  filter table data based on search Text
        const filtereddata = this.state.StockOverview.filter((data) => {
            return (
                (data.brand_name === null ? null : data.brand_name.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
                (data.generic_name === null ? null : data.generic_name.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
                (data.batch_no === null ? null : data.batch_no.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
                (data.schedule === null ? null : data.schedule.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
                (data.dosage_strength === null ? null : data.dosage_strength.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
                (data.expiry_date === null ? null : data.expiry_date.includes(this.state.SearchText)) ||
                (data.mrp === null ? null : Math.round(data.mrp * 100) === parseInt(this.state.SearchText * 100)) ||
                (data.group === null ? null : data.group.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
                (data.available_stock === null ? null : Math.round(data.available_stock * 100) === parseInt(this.state.SearchText * 100)) ||
                (data.price === null ? null : Math.round(data.price * 100) === parseInt(this.state.SearchText * 100)) ||
                (data.physical_box_no === null ? null : Math.round(data.physical_box_no * 100) === parseInt(this.state.SearchText * 100))
            );
        });

        // Search Icon OnClick Function == ends

        return (
            <div>
                <div className='eMed_page_header'>
                    <div id='eMed_table_name' style={{ color: Colors.TextColor }}>{t(this.props.menuName)}</div>
                    <div id='eMed_tbl_filters'>
                        <div >
                            <FormControl id='eMed_tbl_datefilter' size="small">
                                <InputLabel sx={{ color: Colors.TextColor }}>{t("SelectDate")}</InputLabel>
                                <Select
                                    sx={{ color: Colors.TextColor }}
                                    label={t("SelectDate")}
                                    value={this.state.datePicker}
                                    onChange={(event) => this.setState({ datePicker: event.target.value }
                                        , () => this.handledate())}
                                >
                                    <MenuItem value={0}>{t("All")}</MenuItem>
                                    <MenuItem value={1}>{t("Today")}</MenuItem>
                                    <MenuItem value={2}>{t("Yesterday")}</MenuItem>
                                    <MenuItem value={3}>{t("LastWeek")}</MenuItem>
                                    <MenuItem value={4}>{t("LastMonth")}</MenuItem>
                                    <MenuItem value={6} onClick={() => this.setState({ SelectdatePop: true })}>{t("PickDate")}</MenuItem>
                                    <MenuItem value={5} onClick={() => this.setState({ datePickerPop: true })}>{t("PickDateRange")}</MenuItem>
                                </Select>
                                <FormHelperText sx={{ color: Colors.HelperText }} id="eMed_header_helpertext">{this.DateHelperText()}</FormHelperText>
                            </FormControl>
                        </div>
                        {this.state.SearchFieldShow ?
                            <div id="eMed_tbl_searchbox">
                                <TextField
                                    size='small'
                                    focused
                                    onChange={(e) => this.setState({ SearchText: e.target.value })}
                                    value={this.state.SearchText}
                                    label={t("SearchHere")}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip disableInteractive title={t("Clear")} placement='top'><CloseIcon sx={{ color: Colors.ThemeColor, cursor: 'pointer' }} onClick={() => { this.setState({ SearchText: '' }) }} /></Tooltip>
                                        ),
                                    }}
                                />
                            </div> : null}
                        <div>
                            <Tooltip disableInteractive placement="top-start" title={this.state.SearchFieldShow ? t("CloseSearch") : t("OpenSearch")}>
                                <Button id="eMed_tbl_searchicon" variant='contained' style={{ backgroundColor: Colors.ThemeColor }} onClick={() => this.setState({ SearchFieldShow: !this.state.SearchFieldShow, SearchText: '' })} >
                                    <SearchIcon />
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                </div>

                <Card className='eMed_tbl_card' sx={{ bgcolor: Colors.TableCard }} >
                    <TableContainer className='eMed_tbl_container'>
                        <Table stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <TableCell className='eMed_tbl_headwidth_small eMed_sticky_start' sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}><div className='eMed_sortable_center' id="eMed_tbl_headtext">{t("S.No")}</div></TableCell>
                                    <TableCell className='eMed_tbl_headwidth_large eMed_Sticky_Col2' sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}>
                                        <div className=' eMed_sortable_name'>
                                            <div id="eMed_tbl_headtext">{t("BrandName")}</div>
                                            <div onClick={() => { sorting("brand_name", this.state.BrandNameSortOrder) }}>{SortIcon(this.state.BrandNameSortIcon)}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}>
                                        <div className=' eMed_sortable_name'>
                                            <div id="eMed_tbl_headtext">{t("GenericName")} </div>
                                            <div onClick={() => { sorting("generic_name", this.state.GenericNameSortOrder) }}>{SortIcon(this.state.GenericNameSortIcon)}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}><div className='eMed_sortable_center' id="eMed_tbl_headtext">{t("DosageType")}</div></TableCell>
                                    <TableCell className='eMed_tbl_headwidth_small' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}><div className='eMed_sortable_center' id="eMed_tbl_headtext">{t("Strength")}</div></TableCell>
                                    <TableCell className='eMed_tbl_headwidth_small' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}><div className='eMed_sortable_center' id="eMed_tbl_headtext">{t("Schedule")}</div></TableCell>
                                    <TableCell className='eMed_tbl_headwidth_small' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}><div className='eMed_sortable_center' id="eMed_tbl_headtext">{t("BatchNo")}</div></TableCell>
                                    <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}>
                                        <div className=' eMed_sortable_center'>
                                            <div id="eMed_tbl_headtext">{t("ExpiryDate")}</div>
                                            <div onClick={() => { sorting("expiry_date", this.state.ExpiryDateSortOrder) }}>{SortIcon(this.state.ExpiryDateSortIcon)}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}>
                                        <div className=' eMed_sortable_number'>
                                            <div id="eMed_tbl_headtext">{t("MRP/Qty(₹)")}</div>
                                            <div onClick={() => { sorting("mrp", this.state.QuantitySortOrder) }}>{SortIcon(this.state.QuantitySortIcon)}</div>
                                        </div>
                                    </TableCell>
                                    <TableCell className='eMed_tbl_headwidth_small' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}><div className='eMed_sortable_center' id="eMed_tbl_headtext">{t("Group")}</div></TableCell>
                                    <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}><div className='eMed_sortable_number' id="eMed_tbl_headtext">{t("Purchase Qty")}</div></TableCell>
                                    <TableCell className='eMed_tbl_headwidth_small' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}><div className='eMed_sortable_number' id="eMed_tbl_headtext">{t("Price")}(₹)</div></TableCell>
                                    <TableCell className='eMed_tbl_headwidth_small eMed_sticky_end' sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}>
                                        <div className='eMed_sortable_number'>
                                            <div id="eMed_tbl_headtext">{t("Box No")}</div>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            {filtereddata.length > 0 ? filtereddata.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                .map((data, index) => {
                                    return (
                                        <TableBody>
                                            <TableRow>
                                                <TableCell align='center' id='eMed_tbl_bodytext' className='eMed_sticky_startbody' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>{index + 1}</TableCell>
                                                <TableCell align='left' id='eMed_tbl_bodytext' className='eMed_Sticky_Body2' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>
                                                    {data.brand_name.length > 12 ?
                                                        <Tooltip placement="top" title={data.brand_name} disableInteractive>
                                                            <div id='eMed_tbl_bodytext'>{data.brand_name.slice(0, 12) + '...'}</div>
                                                        </Tooltip> : `${data.brand_name}`}
                                                </TableCell>
                                                <TableCell align='left' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>
                                                    {data.generic_name.length > 12 ?
                                                        <Tooltip placement="top" title={data.generic_name} disableInteractive>
                                                            <div id='eMed_tbl_bodytext'>{data.generic_name.slice(0, 12) + '...'}</div>
                                                        </Tooltip> : `${data.generic_name}`}
                                                </TableCell>
                                                <TableCell align='center' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>{DosageType(data.dosage_type)} </TableCell>
                                                <TableCell align='center' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>{data.dosage_strength}</TableCell>
                                                <TableCell align='center' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>{data.schedule == "" ? "-" : data.schedule}</TableCell>
                                                <TableCell align='center' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>{data.batch_no}</TableCell>
                                                <TableCell align='center' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>{data.expiry_date}</TableCell>
                                                <TableCell align='right' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>
                                                    {data.mrp > 10000000 ?
                                                        <Tooltip placement="top" title={AmountFormat(data.mrp)} disableInteractive>
                                                            <div id='eMed_tbl_bodytext'>{AmountFormat(data.mrp).slice(0, 12) + '...'}</div>
                                                        </Tooltip> : <div id='eMed_tbl_bodytext'>{AmountFormat(data.mrp)}</div>}
                                                </TableCell>
                                                <TableCell align='center' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>{data.group == "" ? "-" : data.group}</TableCell>
                                                <TableCell align='right' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>{data.available_stock}</TableCell>
                                                <TableCell align='right' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>
                                                    {data.price > 10000000 ?
                                                        <Tooltip placement="top" title={AmountFormat(data.price)} disableInteractive>
                                                            <div id='eMed_tbl_bodytext'>{AmountFormat(data.price).slice(0, 12) + '...'}</div>
                                                        </Tooltip> : <div id='eMed_tbl_bodytext'>{AmountFormat(data.price)}</div>}
                                                </TableCell>
                                                <TableCell align='right' id='eMed_tbl_bodytext' className='eMed_sticky_endbody' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>
                                                    {data.physical_box_no}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    )
                                }) :
                                <div id='eMed_Norecords' style={{ color: Colors.TableHeaderTextColor }}>
                                    {t("NoRecords")}
                                </div>}
                        </Table>
                    </TableContainer>
                    <TablePagination
                        component='div'
                        id="eMed_Table_Pagination"
                        sx={{
                            color: Colors.Pagination
                        }}
                        rowsPerPageOptions={[5, 10, 15]}
                        count={filtereddata.length}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                    {this.state.datePickerPop ? <DatePicker PopClose={this.PopClose} CustomDates={this.CustomDates} /> : null}
                </Card>

                {
                    this.state.dialogBox ?
                        <Dialog
                            type={"Notification"}
                            DialogClose={this.dialogCloseFunction}
                            header={"Message"}
                            content={this.state.ErrorMsg}
                            icon={<img className="eMed_dilboxAnimations" alt="Warning" src={Failed} />}
                            secondary={"Close"}
                            closeButton={this.dialogCloseFunction}
                        /> : null
                }

                <Popover
                    open={this.state.SelectdatePop}
                    anchorReference="anchorPosition"
                    anchorPosition={{ top: 205, left: 1120 }}>

                    <div id="eMed_datePick_close">
                        <IconButton onClick={this.PopClose}>
                            <CloseIcon sx={{ color: Colors.ThemeColor }} fontSize='medium' />
                        </IconButton>
                    </div>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <StaticDatePicker
                            displayStaticWrapperAs="desktop"
                            minDate={new Date("2017-01-01")}
                            maxDate={new Date()}
                            onChange={this.HandleFromDate}
                        />
                    </LocalizationProvider>
                    <Button id="emed_Calender_Cancel" onClick={this.PopClose}>{t("Cancel")}</Button>
                    <Button id="emed_Calender_Ok" variant='contained' onClick={this.handleSelectDate}>{t("Ok")}</Button>
                </Popover>
            </div >
        )
    }
}
export default withTranslation()(StockOverview);
