import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import WebcamCapture from './WebcamCapture';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};


class BasicModal extends React.Component {
  constructor() {
    super();
    this.state = {
      show: false
    };
  }
  showModal = () => {
    this.setState({
      show: true
    });

  };
  hideModal = () => {
    this.setState({ show: false });
  };
  render() {


    return (
      <div>
        <Button onClick={this.showModal}><PhotoCameraIcon></PhotoCameraIcon> </Button>

        <Modal>
          <Box sx={{ ...style, width: 650 }}>
            <h2 id="parent-modal-title">Capture photo</h2>
            <p id="parent-modal-description">{this.state.show ? <WebcamCapture /> : null}
            </p>
          </Box>
        </Modal>

      </div>


    );
  }
}
export default BasicModal;