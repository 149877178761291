import React from "react";
import Button from '@mui/material/Button';
import { Dialog, DialogContent, DialogActions, DialogContentText, DialogTitle, Divider } from '@mui/material';
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import '../Components/MaximizeDialogBox.css'


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class MaximizeDialogBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true
        }
    }

    handleClose = () => {
        this.props.DialogClose()
    }
    render() {
        return (
            <div>
                <Dialog TransitionComponent={Transition} open={this.state.open} fullWidth PaperProps={{ sx: {height: '40vw'} }}>
                    <div id="eMed_MaxDialog_Wrapper">
                        <div><DialogTitle id="eMed_MaxDialog_Title">{this.props.header}</DialogTitle></div>
                        <div id="eMed_MaxDialog_Action"><DialogActions>
                            <Button onClick={this.handleClose}><CloseIcon /></Button>
                        </DialogActions></div>
                    </div>
                    <Divider />
                    <DialogContent>
                        <DialogContentText className="eMed_contentText">
                            {this.props.component}
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

export default MaximizeDialogBox;