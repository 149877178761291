import React from "react";
import './InvoiceAdjReport.css'
import InvoiceAdReport from './InvoiceAdReport.json'
import {
    Card,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    Tooltip,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Button,
    FormHelperText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Colors } from '../../../../../Styles/Colors';
import SearchIcon from '@mui/icons-material/Search';
import { Trans, withTranslation } from 'react-i18next';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FilterListIcon from '@mui/icons-material/FilterList';
import PaymentCard from '../../../../../Components/PaymentCard/PaymentCard';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ArticleIcon from '@mui/icons-material/Article';
import DiscountIcon from '@mui/icons-material/Discount';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import DatePicker from '../../../../../Components/DatePicker/DatePicker';
import { DateTime } from "luxon";
import Dialog from '../../../../../Components/Dialog';
import Failed from '../../../../../Assets/Animation/Failed.gif';
import { AmountFormat } from '../../../../../Components/CommonFunctions/CommonFunctions'
import { localGetItem } from '../../../../../Utility/Services/CacheProviderService';
import { Serviceurls } from '../../../../../Utility/API/Serviceurls';
import RestAPIService from '../../../../../Utility/Services/RestAPIService';
import VaccinesIcon from '@mui/icons-material/Vaccines';
import MedicationIcon from '@mui/icons-material/Medication';

class InvoiceAdjReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 5,
            searchText: "",
            searchFieldShow: false,
            dateFilter: 0,
            datePickerPop: false,
            // cardOpen: false,
            QueryFromDate: "",
            QueryToDate: "",
            // pharmacyID: localGetItem("Pharmacy_Id"),
            tblData: [],
            billdateSortOrder: "asc",
            billdateSortIcon: 'none',
            patientnameSortOrder: "asc",
            patientnameSortIcon: 'none',
            billtypeSortOrder: "asc",
            billtypeSortIcon: 'none',
        }
    }

    componentDidMount() {
        this.getInvoiceAdjReport();
    }

    /* Table Get API Funtion start */
    getInvoiceAdjReport = () => {
        try {
            if (InvoiceAdReport && Object.keys(InvoiceAdReport).length > 0 && InvoiceAdReport.data && Object.keys(InvoiceAdReport.data).length > 0 && InvoiceAdReport.data.result && (InvoiceAdReport.data.result).length > 0) {
                this.setState({
                    tblData: InvoiceAdReport.data.result
                })
            }

            // let QueryParams = `?pharmacy_id=${this.state.pharmacyID}&page=&page_size=100000&from_date=${this.state.QueryFromDate}&to_date=${this.state.QueryToDate}`
            // RestAPIService.getAll(Serviceurls.GET_PURCHASE_DETAILS + QueryParams)
            //     .then(response => {
            //         this.setState({
            //             apiData: response.data.data.result,
            //             defaultData: response.data.data.result,
            //             cardTotalAmt: response.data.data.total_amount,
            //             cardCGSTamt: response.data.data.total_cgst_amount,
            //             cardSGSTamt: response.data.data.total_sgst_amount,
            //             cardDisAmt: response.data.data.total_discount_amount,
            //             cardNetAmt: response.data.data.total_net_total
            //         })
            //     })
            //     .catch(e => {
            //         this.setState({ dialogBox: true, ErrorMsg: e.message });
            //         setTimeout(() => {
            //             this.setState({
            //                 dialogBox: false
            //             })
            //         }, 3000)
            //     });
        }

        catch (e) {
            this.setState({ dialogBox: true, ErrorMsg: e.message });
            setTimeout(() => {
                this.setState({
                    dialogBox: false
                })
            }, 3000)
        }

    }
    /* Table Get API Funtion start */

    /* Failed Dialogbox function end */
    dialogCloseFunction = () => {
        this.setState({
            dialogBox: false
        })
    }
    /* Failed Dialogbox function start */

    /* Pagechange funcion start */
    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    }
    /* Pagechange funcion end */

    /* RowPagechange funcion start */
    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value, page: 0 });
    }
    /* RowPagechange funcion end */

    /* customdate popup funcion start */
    PopClose = () => {
        this.setState({
            datePickerPop: false,
            QueryFromDate: "",
            QueryToDate: "",
            dateFilter: 0,
        })
    }
    /* customdate popup funcion end */

    /* customdate select funcion start */
    CustomDates = (FromDate, ToDate) => {
        this.setState({
            QueryFromDate: FromDate,
            QueryToDate: ToDate,
            datePickerPop: false
        })
    }
    /* customdate select funcion end */

    /* date label funcion start */
    DateHelperText = () => {
        if (this.state.dateFilter === 0) {
            return ""
        }
        else if (this.state.dateFilter === 1) {
            return DateTime.now().toFormat('dd-MM-yyyy')
        }
        else if (this.state.dateFilter === 2) {
            return DateTime.now().minus({ days: 1 }).toFormat('dd-MM-yyyy')
        }
        else if (this.state.dateFilter === 3) {
            return `${DateTime.now().minus({ days: 7 }).toFormat('dd-MM-yyyy')}  to  ${DateTime.now().toFormat('dd-MM-yyyy')}`
        }
        else if (this.state.dateFilter === 4) {
            return `${DateTime.now().minus({ days: 30 }).toFormat('dd-MM-yyyy')}  to  ${DateTime.now().toFormat('dd-MM-yyyy')}`
        }
        else if (this.state.dateFilter === 5) {
            return `${this.state.QueryFromDate.split("-").reverse().join("-")}  to  ${this.state.QueryToDate.split("-").reverse().join("-")}`
        }
    }
    /* date label funcion end */

    render() {
        const { t } = this.props
        const filtereddata = this.state.tblData.filter((data) => {
            return (
                (data.bill_date === null ? null : data.bill_date.toLowerCase().includes(this.state.searchText.toLowerCase())) ||
                (data.adjusted_date === null ? null : data.adjusted_date.toLowerCase().includes(this.state.searchText.toLowerCase())) ||
                (data.patient_name === null ? null : data.patient_name.toLowerCase().includes(this.state.searchText.toLowerCase())) ||
                (data.pay_type === null ? null : data.pay_type.toLowerCase().includes(this.state.searchText.toLowerCase())) ||
                (data.patient_mob === null ? null : data.patient_mob === parseInt(this.state.searchText)) ||
                (data.bill_type === null ? null : data.bill_type.toLowerCase().includes(this.state.searchText.toLowerCase())) ||
                (data.amount_collect === null ? null : Math.round(data.amount_collect * 100) === parseInt(Math.round(this.state.searchText * 100)))
            );
        });
        // Sorting Function   ==== starts
        const sorting = (col, SortOrder) => {
            if (SortOrder === "asc") {
                const Sorted = [...this.state.tblData].sort((a, b) => {
                    if (typeof (a[col]) == "string") {
                        return a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
                    } else {
                        return a[col] > b[col] ? 1 : -1
                    }
                }

                )
                this.setState({ tblData: Sorted })

                if (col === "bill_date") {
                    this.setState({
                        billdateSortOrder: "desc",
                        billdateSortIcon: 'asc',
                        patientnameSortOrder: "asc",
                        patientnameSortIcon: 'none',
                        billtypeSortOrder: "asc",
                        billtypeSortIcon: 'none',
                    })
                }
                else if (col === "patient_name") {
                    this.setState({
                        billdateSortOrder: "asc",
                        billdateSortIcon: 'none',
                        patientnameSortOrder: "desc",
                        patientnameSortIcon: 'asc',
                        billtypeSortOrder: "asc",
                        billtypeSortIcon: 'none',
                    })
                }
                else if (col === "bill_type") {
                    this.setState({
                        billdateSortOrder: "asc",
                        billdateSortIcon: 'none',
                        patientnameSortOrder: "asc",
                        patientnameSortIcon: 'none',
                        billtypeSortOrder: "desc",
                        billtypeSortIcon: 'asc',
                    })
                }

            }
            else if (SortOrder === "desc") {
                const Sorted = [...this.state.tblData].sort((a, b) => {
                    if (typeof (a[col]) == "string") {
                        return a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
                    } else {
                        return a[col] < b[col] ? 1 : -1
                    }
                }

                )
                this.setState({ tblData: Sorted })

                if (col === "bill_date") {
                    this.setState({ billdateSortOrder: 'default', billdateSortIcon: "desc", })
                } else if (col === "patient_name") {
                    this.setState({ patientnameSortOrder: 'default', patientnameSortIcon: "desc" })
                } else if (col === "bill_type") {
                    this.setState({ billtypeSortOrder: 'default', billtypeSortIcon: "desc" })
                }
            } else if (SortOrder === "default") {
                this.setState({
                    billdateSortOrder: 'asc',
                    billdateSortIcon: 'none',
                    patientnameSortOrder: 'asc',
                    patientnameSortIcon: 'none',
                    billtypeSortOrder: 'asc',
                    billtypeSortIcon: 'none',
                    tblData: InvoiceAdReport.data.result
                })
            }
        }
        // Sorting Function   ==== ends


        //  Sorting Icons Change func === starts 
        const SortIcon = (sortType) => {
            if (sortType === "asc") {
                return <Tooltip disableInteractive placement='top' title={t("InAscendingOrder")}><KeyboardArrowUpIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
            }
            else if (sortType === 'desc') {
                return <Tooltip disableInteractive placement='top' title={t("InDecendingOrder")}><KeyboardArrowDownIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
            }
            else return <Tooltip disableInteractive placement='top' title={t("Sorting")}><FilterListIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
        }

        //  Sorting Icons Change func === ends 

        const handleDate = () => {
            var today = DateTime.now().toFormat('yyyy-MM-dd')
            var yesterday = DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd')
            var startweek = DateTime.now().minus({ days: 7 }).toFormat('yyyy-MM-dd')
            var startmonth = DateTime.now().minus({ days: 30 }).toFormat('yyyy-MM-dd')

            if (this.state.dateFilter === 0) {
                this.setState({
                    QueryFromDate: "",
                    QueryToDate: "",
                }, () => {
                    this.getPurchaseDetails()
                })
            }
            else if (this.state.dateFilter === 1) {
                this.setState({
                    QueryFromDate: today,
                    QueryToDate: today,
                }, () => {
                    this.getPurchaseDetails()
                })

            }
            else if (this.state.dateFilter === 2) {
                this.setState({
                    QueryFromDate: yesterday,
                    QueryToDate: yesterday,
                }
                    , () => {
                        this.getPurchaseDetails()
                    })
            }
            else if (this.state.dateFilter === 3) {
                this.setState({
                    QueryFromDate: startweek,
                    QueryToDate: yesterday,
                },
                    () => {
                        this.getPurchaseDetails()
                    })
            }
            else if (this.state.dateFilter === 4) {
                this.setState({
                    QueryFromDate: startmonth,
                    QueryToDate: yesterday,
                },
                    () => {
                        this.getPurchaseDetails()
                    })
            }
            else if (this.state.dateFilter === 5) {
                this.setState({ datePickerPop: true })
            }

        }

        return (
            <div>
                <div className="eMed_page_header">
                    <div className="eMed_invoiceadj_tblandcard">
                        <div id="eMed_table_name" style={{ color: Colors.TextColor }}>
                            {t(this.props.menuName)}
                        </div>
                        {/* <div id="eMed_tbl_paymentcard">
                            <div onClick={() => this.setState({ cardOpen: !this.state.cardOpen, searchFieldShow: false })} style={{ display: this.state.cardOpen ? "none" : "inline" }}>
                                <PaymentCard Color={Colors.blue} Icons={<CurrencyRupeeIcon />} Name={t("Total")} Amount={this.state.cardNetAmt} />
                            </div>
                        </div> */}
                    </div>
                    <div id="eMed_tbl_filters">
                        <div>
                            <FormControl id="eMed_tbl_datefilter" >
                                <InputLabel sx={{ color: Colors.TextColor }}>{t("SelectDate")}</InputLabel>
                                <Select
                                    sx={{ color: Colors.TextColor }}
                                    size='small'
                                    onChange={(event) => { this.setState({ dateFilter: event.target.value }) }}
                                    value={this.state.dateFilter}
                                    labelId="demo-simple-select-required-label"
                                    label={t("SelectDate")}
                                    id="emed_demo-simple-select-required"
                                >
                                    <MenuItem value={0}>All</MenuItem>
                                    <MenuItem value={1}>Today</MenuItem>
                                    <MenuItem value={2}>Yesterday</MenuItem>
                                    <MenuItem value={3}>Last Week</MenuItem>
                                    <MenuItem value={4}>Last Month</MenuItem>
                                    <MenuItem value={5} onClick={() => { this.setState({ datePickerPop: true }) }} >Custom</MenuItem>
                                </Select>
                                <FormHelperText sx={{ color: Colors.HelperText }} id="eMed_form_helpertext">{this.DateHelperText()}</FormHelperText>
                            </FormControl>
                        </div>
                        {this.state.datePickerPop ? <DatePicker PopClose={this.PopClose} CustomDates={this.CustomDates} /> : null}

                        {this.state.searchFieldShow ?
                            <div id='eMed_tbl_searchbox'>
                                <TextField
                                    sx={{ input: { color: Colors.TextColor } }}
                                    size='small'
                                    focused
                                    onChange={(e) => this.setState({ searchText: e.target.value })}
                                    value={this.state.searchText}
                                    label={t("SearchHere")}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip disableInteractive title={t("Clear")} placement='top'><CloseIcon sx={{ color: Colors.ThemeColor, cursor: 'pointer' }} onClick={() => { this.setState({ searchText: '' }) }} /></Tooltip>
                                        ),
                                    }}
                                />
                            </div> : null}


                        <div className="eMed_tbl_searchicon">
                            <Tooltip disableInteractive placement='top' title={this.state.searchFieldShow ? t("CloseSearch") : t("OpenSearch")}>
                                <Button id="eMed_tbl_searchicon" variant='contained' style={{ backgroundColor: Colors.ThemeColor }} onClick={() => this.setState({ searchFieldShow: !this.state.searchFieldShow, searchText: '', cardOpen: false })} >
                                    {this.state.searchFieldShow ? <CloseIcon /> : <SearchIcon />}
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <Card sx={{ bgcolor: Colors.TableCard }} className="eMed_tbl_card" >
                            <TableContainer className="eMed_tbl_container" >
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className='eMed_tbl_headwidth_small eMed_sticky_start' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                                <div style={{ color: Colors.TableHeaderTextColor }} className="eMed_sortable_center" id='eMed_tbl_headtext'>{t("S.No")}</div>
                                            </TableCell>
                                            <TableCell className='eMed_tbl_headwidth_large' id="eMed_invoice_stickyHeadtwo" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                                <div className="eMed_sortable_center">
                                                    <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("BillDate")}</div>
                                                    <div id="eMed-SortIcons" onClick={() => { sorting("bill_date", this.state.billdateSortOrder) }}>{SortIcon(this.state.billdateSortIcon)}</div></div>
                                            </TableCell>
                                            <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                                <div style={{ color: Colors.TableHeaderTextColor }} className="eMed_sortable_center" id='eMed_tbl_headtext'>{t("AdjustedDate")}</div>
                                            </TableCell>
                                            <TableCell className='eMed_tbl_headwidth_extralarge' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                                <div style={{ color: Colors.TableHeaderTextColor }} className="eMed_sortable_number" id="eMed_tbl_headtext">
                                                    {t("AmountCollected")}
                                                </div>
                                            </TableCell>
                                            <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                                <div className="eMed_sortable_name">
                                                    <div style={{ color: Colors.TableHeaderTextColor }} id="eMed_tbl_headtext">{t("PatientName")}</div>
                                                    <div id="eMed-SortIcons" onClick={() => { sorting("patient_name", this.state.patientnameSortOrder) }}>{SortIcon(this.state.patientnameSortIcon)}</div></div>
                                            </TableCell>
                                            <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                                <div style={{ color: Colors.TableHeaderTextColor }} className="eMed_sortable_center" id="eMed_tbl_headtext">{t("PatientMobNo")}</div>
                                            </TableCell>
                                            <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                                <div style={{ color: Colors.TableHeaderTextColor }} className="eMed_sortable_center" id="eMed_tbl_headtext">{t("PaymentType")}</div>
                                            </TableCell>
                                            <TableCell className='eMed_sticky_end eMed_tbl_headwidth_large' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                                <div className="eMed_sortable_center">
                                                    <div style={{ color: Colors.TableHeaderTextColor }} id="eMed_tbl_headtext">{t("BillType")}</div>
                                                    <div id="eMed-SortIcons" onClick={() => { sorting("bill_type", this.state.billtypeSortOrder) }}>{SortIcon(this.state.billtypeSortIcon)}</div></div>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {filtereddata.length > 0 ? filtereddata
                                        .slice(this.state.page * this.state.rowsPerPage,
                                            this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                        .map((data, index) => {
                                            return (
                                                <TableBody key={index}>
                                                    <TableRow>
                                                        <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} align="center" id="eMed_tbl_bodytext" className="eMed_sticky_startbody" >{index + 1}</TableCell>
                                                        <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} align="center" id="eMed_invoice_stickycelltwo">{data.bill_date}</TableCell>
                                                        <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} align="center">{data.adjusted_date}</TableCell>
                                                        <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} align="right">{data.amount_collect}</TableCell>
                                                        <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} align="left">
                                                            <Tooltip title={data.patient_name}>
                                                                <div id="eMed_tbl_bodytext">
                                                                    {data.patient_name.length > 10 ? data.patient_name.slice(0, 10) + ".." : data.patient_name}
                                                                </div>
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="center">{data.patient_mob}</TableCell>
                                                        <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="center">{data.pay_type}</TableCell>
                                                        <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="center" className="eMed_sticky_endbody">{data.bill_type}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            )
                                        }) : <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_Norecords'>
                                        {t("NoRecords")}
                                    </div>}
                                </Table>
                            </TableContainer>
                            <TablePagination
                                component='div'
                                id="eMed_tbl_pagination"
                                sx={{ color: Colors.PagiantionTxtColor }}
                                rowsPerPageOptions={[5, 10, 15]}
                                count={filtereddata.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                            />
                        </Card>
                        {this.state.dialogBox ?
                            <Dialog
                                type={"Notification"}
                                DialogClose={this.dialogCloseFunction}
                                header={"Message"}
                                content={this.state.ErrorMsg}
                                icon={<img className="eMed_dilboxAnimations" alt="Warning" src={Failed} />}
                                secondary={"Close"}
                                closeButton={this.dialogCloseFunction}
                            /> : null}
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(InvoiceAdjReport);