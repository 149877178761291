import React, { Component } from 'react';
import { Button, Card, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, Tooltip } from '@mui/material';
import { Colors } from '../../../../../Styles/Colors';
import FilterListIcon from '@mui/icons-material/FilterList';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import RestAPIService from '../../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../../Utility/API/Serviceurls';
import { localGetItem } from '../../../../../Utility/Services/CacheProviderService';
import { DosageType } from '../../../../../Components/CommonFunctions/CommonFunctions';
import Dialog from '../../../../../Components/Dialog';
import Failed from "../../../../../Assets/Animation/Failed.gif";
import { Trans, withTranslation } from 'react-i18next';

class LowStockDrugs extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: 5,
      BrandNameSortOrder: 'asc',
      BrandNameSortIcon: 'none',
      LowStock: [],
      DefaultLowStock: [],
      dialogBox: false,
      SearchText: "",
      PharmacyId: localGetItem("Pharmacy_Id")
    }
  }

  componentDidMount() {
    this.getLowStock();
  }

  getLowStock = () => {
    try {
      RestAPIService.getAll(Serviceurls.PHARMACY_REPORT_Lowstock + `?pharmacy_id=${this.state.PharmacyId}`)
        .then(response => {
          this.setState({
            LowStock: response.data.data,
            DefaultLowStock: response.data.data
          })
        })
        .catch(e => {
          this.setState({ dialogBox: true, ErrorMsg: e.message });
          setTimeout(() => {
            this.setState({
              dialogBox: false
            })
          }, 3000)
        });
    }
    catch (e) {
      this.setState({ dialogBox: true, ErrorMsg: e.message });
      setTimeout(() => {
        this.setState({
          dialogBox: false
        })
      }, 3000)
    }
  }

  //  Mui table Pagination Functions --- starts

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 });
  }

  //  Mui table Pagination Functions --- ends

  dialogCloseFunction = () => {
    this.setState({
      dialogBox: false
    })
  }

  render() {
    const { t } = this.props

    // Sorting Function   ==== starts
    const sorting = (col, SortOrder) => {
      if (SortOrder === "asc") {
        const Sorted = [...this.state.LowStock].sort((a, b) => {
          if (typeof (a[col]) == "string") {
            return a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
          } else {
            return a[col] > b[col] ? 1 : -1
          }
        })
        this.setState({ LowStock: Sorted })

        if (col === "brand_name") {
          this.setState({ BrandNameSortOrder: 'desc', BrandNameSortIcon: "asc" })
        }

      }
      else if (SortOrder === "desc") {
        const Sorted = [...this.state.LowStock].sort((a, b) => {
          if (typeof (a[col]) == "string") {
            return a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
          } else {
            return a[col] < b[col] ? 1 : -1
          }
        })
        this.setState({ LowStock: Sorted })

        if (col === "brand_name") {
          this.setState({ BrandNameSortOrder: 'default', BrandNameSortIcon: "desc" })
        }
      }
      else if (SortOrder === "default") {
        this.setState({
          BrandNameSortIcon: 'none',
          BrandNameSortOrder: 'asc',
          LowStock: this.state.DefaultLowStock
        })
      }
    }
    // Sorting Function   ==== ends


    //  Sorting Icons Change func === starts 
    const SortIcon = (sortType) => {
      if (sortType === "asc") {
        return <Tooltip disableInteractive placement='top' title={t("InAscendingOrder")}><KeyboardArrowUpIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
      }
      else if (sortType === 'desc') {
        return <Tooltip disableInteractive placement='top' title={t("InDecendingOrder")}><KeyboardArrowDownIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
      }
      else return <Tooltip disableInteractive placement='top' title={t("Sorting")}><FilterListIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
    }

    //  Sorting Icons Change func === ends 

    //  filter table data based on search Text
    const filtereddata = this.state.LowStock.filter((data) => {
      return (
        (data.drug_name === null ? null : data.drug_name.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
        (data.dosage_strength === null ? null : data.dosage_strength.toLowerCase().includes(this.state.SearchText.toLowerCase())) ||
        (data.reorder_threshold === null ? null : Math.round(data.reorder_threshold * 100) === parseInt(Math.round(this.state.SearchText * 100))) ||
        (data.available_quantity === null ? null : Math.round(data.available_quantity * 100) === parseInt(Math.round(this.state.SearchText * 100)))
      );
    });

    //  Sorting Icons Change func === ends 

    return (
      <div>
        <div className="eMed_page_header">
          <div id='eMed_table_name' style={{ color: Colors.TextColor }}>{t(this.props.menuName)}</div>
          <div id='eMed_tbl_filters'>
            {this.state.SearchFieldOpen ?
              <div id="eMed_tbl_searchbox" >
                <TextField
                  sx={{ color: Colors.TextColor }}
                  size='small'
                  focused
                  onChange={(e) => this.setState({ SearchText: e.target.value })}
                  value={this.state.SearchText}
                  label={t("SearchHere")}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <Tooltip disableInteractive title={t("Clear")} placement='top'><CloseIcon sx={{ color: Colors.ThemeColor }} onClick={() => { this.setState({ SearchText: '' }) }} /></Tooltip>
                    ),
                  }}
                />
              </div> : null}
            <Tooltip disableInteractive placement="top-start" title={this.state.SearchFieldOpen ? t("CloseSearch") : t("OpenSearch")}>
              <Button id="eMed_tbl_searchicon" variant='contained' style={{ backgroundColor: Colors.ThemeColor }} onClick={() => this.setState({ SearchFieldOpen: !this.state.SearchFieldOpen, CardOpen: false, SearchText: '' })} >
                {this.state.SearchFieldOpen ? <CloseIcon /> : <SearchIcon />}
              </Button>
            </Tooltip>
          </div>
        </div>
        <Card className='eMed_tbl_card' sx={{ bgcolor: Colors.TableCard }}>
          <TableContainer className='eMed_tbl_container'>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className='eMed_tbl_headwidth_small id="eMed_nonsticky_head"' sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}><div className='eMed_sortable_center' id="eMed_tbl_headtext">{t("S.No")}</div></TableCell>
                  <TableCell className='eMed_tbl_headwidth_small' sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}>
                    <div className=' eMed_sortable_name'>
                      <div id="eMed_tbl_headtext">{t("BrandName")}</div>
                      <div onClick={() => { sorting("brand_name", this.state.BrandNameSortOrder) }}>{SortIcon(this.state.BrandNameSortIcon)}</div>
                    </div>
                  </TableCell>
                  <TableCell className='eMed_tbl_headwidth_small' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}><div className='eMed_sortable_center' id="eMed_tbl_headtext">{t("DosageType")}</div></TableCell>
                  <TableCell className='eMed_tbl_headwidth_small' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}><div className='eMed_sortable_center' id="eMed_tbl_headtext">{t("Strength")}</div></TableCell>
                  <TableCell className='eMed_tbl_headwidth_small' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}><div className='eMed_sortable_center' id="eMed_tbl_headtext">{t("ReorderThreshold")}</div></TableCell>
                  <TableCell className='eMed_tbl_headwidth_small' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}>
                    <div className='eMed_sortable_center'>
                      <div id="eMed_tbl_headtext">{t("AvailQuantity")}</div>
                    </div>
                  </TableCell>
                </TableRow>
              </TableHead>
              {filtereddata.length > 0 ? filtereddata.slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                .map((data, index) => {
                  return (
                    <TableBody>
                      <TableRow>
                        <TableCell align='center' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>{index + 1}</TableCell>
                        <TableCell align='left' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>
                          {data.drug_name.length > 12 ?
                            <Tooltip placement="top" title={data.drug_name} disableInteractive>
                              <div id='eMed_tbl_bodytext'>{data.drug_name.slice(0, 12) + '...'}</div>
                            </Tooltip> : `${data.drug_name}`}
                        </TableCell>
                        <TableCell align='center' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>{DosageType(data.dosage_type)} </TableCell>
                        <TableCell align='center' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>{data.dosage_strength}</TableCell>
                        <TableCell align='center' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>{data.reorder_threshold}</TableCell>
                        <TableCell align='center' id='eMed_tbl_bodytext' sx={{ bgcolor: Colors.TableBody, color: Colors.TableProfilDetails }}>
                          <div id='eMed_tbl_bodytext'>{data.available_quantity}</div>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )
                }) :
                <div id='eMed_Norecords' style={{ color: Colors.TableHeaderTextColor }}>
                  {t("NoRecords")}
                </div>}
            </Table>
          </TableContainer>
          <TablePagination
            component='div'
            id="eMed_Table_Pagination"
            sx={{
              color: Colors.Pagination
            }}
            rowsPerPageOptions={[5, 10, 15]}
            count={filtereddata.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Card>
        {
          this.state.dialogBox ?
            <Dialog
              type={"Notification"}
              DialogClose={this.dialogCloseFunction}
              header={"Message"}
              content={this.state.ErrorMsg}
              icon={<img className="eMed_dilboxAnimations" alt="Warning" src={Failed} />}
              secondary={"Close"}
              closeButton={this.dialogCloseFunction}
            /> : null
        }
      </div>
    )
  }
}
export default withTranslation()(LowStockDrugs);