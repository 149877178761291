import React, { Component } from 'react';
import AddTaskIcon from '@mui/icons-material/AddTask';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import MedicalServicesIcon from '@mui/icons-material/MedicalServices';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import './PatientStatusCard.css'

export default class PatientsStatusCard extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    
    const PatientStatus = (status) => {
      if (status === "Booked") {
        return <div style={{ backgroundColor: "rgb(267, 16, 12)" }}><AddTaskIcon id='eMed_PatientStatus_Icon'/></div>
      }
      else if (status === "Arrived") {
        return <div  style={{ backgroundColor: "rgb(42, 222, 204)" }}><PersonPinCircleIcon id='eMed_PatientStatus_Icon'/></div>
      }
      else if (status === "withdoctor") {
        return <div  style={{ backgroundColor: "rgb(66, 182, 245)" }}><MedicalServicesIcon id='eMed_PatientStatus_Icon'/></div>
      }
      else if (status === "complete") {
        return <div  style={{ backgroundColor: "rgb(245, 39, 145)" }}><CheckCircleOutlineIcon id='eMed_PatientStatus_Icon'/></div>
      }
      else if (status === "cancelled") {
        return <div style={{ backgroundColor: "rgb(247, 16, 12)" }}><PersonPinCircleIcon id='eMed_PatientStatus_Icon' /></div>
      }
    }
    return (
      <div className='eMed_PatientStatus_Container'>
        {this.props.data.map((data) => (
          <div className='eMed_PatientStatus_wrapper' style={{backgroundColor:data.color}}>
            <div>{PatientStatus(data.status)}</div>
            <div className='eMed_Status_Name' >{data.status}</div>
            <div className='eMed_Status_Count'>{data.PatientCount}</div>
          </div>
        ))}
      </div>
    )
  }
}
