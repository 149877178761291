import React, { Component } from 'react';
import {Bar} from 'react-chartjs-2';
import {Chart as ChartJS, Title, Tooltip, LineElement, Legend, CategoryScale, LinearScale, BarController, BarElement} from 'chart.js';
ChartJS.register(Title, Tooltip, LineElement, Legend, CategoryScale, LinearScale, BarController, BarElement)

class BarChart extends Component {
    constructor(props) {
        super(props)
        this.state = {
            data:
            {
                labels: ['Jan','Feb','Mar','Apr','May','Jun','July'],
                datasets: [
                    {
                        label:'DoctorBasedPatientChart',
                        data: [10, 20, 30, 42, 51, 82, 31],
                        backgroundColor: [
                            'rgba(255, 99, 132)',
                            'rgba(255, 159, 64)',
                            'rgba(255, 205, 86)',
                            'rgba(75, 192, 192)',
                            'rgba(54, 162, 235)',
                            'rgba(153, 102, 255)',
                            'rgba(201, 203, 207)'
                        ],
                        borderColor: [
                            'rgb(255, 99, 132)',
                            'rgb(255, 159, 64)',
                            'rgb(255, 205, 86)',
                            'rgb(75, 192, 192)',
                            'rgb(54, 162, 235)',
                            'rgb(153, 102, 255)',
                            'rgb(201, 203, 207)'
                        ],
                        barThickness: 25,
                    },
                ],
            }
        }
    };
    render() {
        return (
            <div>
                <Bar data={this.state.data}  
                options={{
                    responsive: true,
                    plugins: {
                        legend: {
                            position: 'top',
                            labels: {
                                usePointStyle: true,
                                boxWidth: 10,
                            },
                        }
                    }
                }} />
            </div>
        )
    }
}
export default BarChart;