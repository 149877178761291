import React from "react";
import './Dialog.css';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContentText from '@mui/material/DialogContentText';
import { Divider } from "@mui/material";
import Slide from '@mui/material/Slide';
import Stack from '@mui/material/Stack';
import { Trans, withTranslation } from 'react-i18next';
import { Colors } from "../Styles/Colors";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class DialogBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dialogboxOpen: true,
            backDrop: false
        }
    }

    handleClose = () => {
        this.props.DialogClose()
    }
    render() {
        const { t } = this.props
        return (
            <div className="eMed_Dialog">
                <Dialog TransitionComponent={Transition} open={this.state.dialogboxOpen}
                    PaperProps={{ sx: { width: '40%' } }}>
                    <DialogTitle sx={{ bgcolor: Colors.TableHeaderBarBGColor, color: Colors.TableHeaderTextColor }}>
                        <div className="eMed_dialogHead"  >
                            <div id="eMed_Dialog_title">
                                {t(this.props.header)}
                            </div>
                            <div className="eMed_cancel">
                                <CloseIcon onClick={this.handleClose} />
                            </div>

                        </div>
                    </DialogTitle>
                    <Divider />
                    <DialogContent sx={{ bgcolor: Colors.PopupBackground }}>
                        <div className='eMed_dialogBody'>
                            <div className="eMed_dialogbodyContent">
                                <DialogContentText>
                                    {this.props.icon}
                                </DialogContentText>
                            </div>
                            <div className="eMed_dialogbodyContent">
                                <DialogContentText>
                                    {t(this.props.content)}
                                </DialogContentText>
                            </div>
                        </div>
                    </DialogContent>

                    <Divider />
                    {this.props.type === "loginsucess" ? null :
                        <DialogActions id="eMed_dlgBtn" sx={{ bgcolor: Colors.PopupBackground }}>
                            {this.props.type === "Notification" ? null :
                                <div>
                                    <Stack spacing={2} direction="row">
                                        <Button variant="contained"
                                            onClick={() => { this.handleClose(); this.props.yesButton(); }}>
                                            {t(this.props.primary)}
                                        </Button>
                                        <Button variant="outlined"
                                            onClick={() => this.handleClose()}>
                                            {t(this.props.secondary)}
                                        </Button>
                                    </Stack>
                                </div>
                            }
                            {this.props.type === "Confirmation" ? null :

                                <Button variant="outlined"
                                    onClick={() => { this.handleClose(); this.props.closeButton(); }}>
                                    {t(this.props.secondary)}
                                </Button>}
                        </DialogActions>
                    }
                </Dialog>
            </div>
        )
    }
}

export default withTranslation()(DialogBox);