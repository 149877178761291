import React, { Component } from 'react'
import ComingSoon from '../../../Upcomingpages/ComingSoon';
import { Trans, withTranslation } from 'react-i18next';

class PoApproval extends Component {
  render() {
    const { t } = this.props
    return (
      <div className='eMed_Tab_Box'>
        <div id='eMed_table_name'>
          {t("DirectPOApproval")}
        </div>
        <ComingSoon />
      </div>
    )
  }
}
export default withTranslation()(PoApproval)