import * as React from 'react';
import { Box, MenuItem, Menu, Tab, Divider, Avatar, Tooltip } from '@mui/material';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import StockAdjustment from './StockAdjustment/index';
import DrugRegistry from './DrugRegistry/index';
import PoApproval from './PoApproval/index';
import PurchaseOrder from './PurchaseOrder/index';
import StockIntake from './StockIntake/index';
import "./MainIndex.css"
import { Colors } from '../../../Styles/Colors'
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import { Trans, withTranslation } from 'react-i18next';
import { localSetItem, localGetItem } from "../../../Utility/Services/CacheProviderService"
import FilterModel from '../../../Components/FilterModel/FilterModel';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

class InventoryHome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Id: localGetItem("PharmaInventoryTabID") === null ? "1" : localGetItem("PharmaInventoryTabID"),
            filterOpen: false,
            filterData: {}
        }

    }
    filterModelColse = () => {
        this.setState({
            filterOpen: false
        })
    }
    handleChange = (event, newValue) => {
        localSetItem("PharmaInventoryTabID", newValue)
        this.setState({ Id: localGetItem("PharmaInventoryTabID") })
    };
    /*FilterDatas get Function start */
    searchData = (searchData) => {
        this.setState({
            filterData: searchData
        })

    }
    /*FilterDatas get Function end */
    /*Clear Filter Data function start */
    clearAllsearchData = (clearData) => {
        this.setState({
            filterData: clearData,
            filterOpen: false
        })
    }
    /*Clear Filter Data function end */
    render() {
        const { t } = this.props;
        const styles = {
            tab: {
                color: Colors.TabsColor
            },
            tabItemContainer: {
                background: 'none'
            }
        }
        const ExportPop = (event) => {
            this.setState({ exportMenuPop: event.currentTarget })
        };
        const ExportPopClose = () => {
            this.setState({ exportMenuPop: null })
        };
        const ExportPopBoolean = Boolean(this.state.exportMenuPop);


        return (

            <Box className='eMed_MainBox'>
                <TabContext value={this.state.Id}>
                    <div className='eMed_Page_Header' style={{ backgroundColor: Colors.pageTopBackground }} >
                        <TabList sx={{ color: Colors.setTableDataTextColor }} onChange={this.handleChange} id="eMed_Tablist">
                            <Tab style={styles.tab} label={t("StockAdjustment")} value="1" />
                            <Tab style={styles.tab} label={t("DrugRegistry")} value="2" />
                            <Tab style={styles.tab} label={t("PurchaseOrder")} value="3" />
                            <Tab style={styles.tab} label={t("StockIntake")} value="4" />
                            <Tab style={styles.tab} label={t("DirectPOApproval")} value="5" />
                        </TabList>
                        <div className='eMed_PageHeader_icons_div'>
                            <Tooltip disableInteractive title={t("Filter")} placement='bottom'>
                                <Avatar id='eMed_IconAvatar' sx={{ bgcolor: Colors.IconCircleBgColor, boxShadow: 3 }} onClick={() => this.setState({ filterOpen: true })}>
                                    <FilterAltIcon id='eMed_Header_Icons' sx={{ color: Colors.ThemeColor }} />
                                </Avatar>
                            </Tooltip>
                            {this.state.filterOpen ?
                                <FilterModel returndata={this.state.filterData} filterClose={this.filterModelColse} clearSearchdata={this.clearAllsearchData} searchData={this.searchData} />
                                : null}
                            <Tooltip disableInteractive title={t("Export")} placement='bottom'>
                                <Avatar id='eMed_IconAvatar' sx={{ bgcolor: Colors.IconCircleBgColor, boxShadow: 3 }} onClick={ExportPop}>
                                    <SimCardDownloadIcon id='eMed_Header_Icons' sx={{ color: Colors.ThemeColor }} />
                                </Avatar>
                            </Tooltip>
                            <Menu
                                anchorEl={this.state.exportMenuPop}
                                open={ExportPopBoolean}
                                onClose={ExportPopClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                            >
                                <MenuItem ><ListAltIcon fontSize='small' id='eMed_menuList_icons' sx={{ color: Colors.blue }} /> {t("ExportAsExcel")}</MenuItem>
                                <Divider />
                                <MenuItem ><PictureAsPdfIcon fontSize='small' id='eMed_menuList_icons' sx={{ color: Colors.red }} /> {t("ExportAsPdf")}</MenuItem>
                            </Menu>
                        </div>
                    </div>
                    <TabPanel value="1"><StockAdjustment filterData={this.state.filterData} /></TabPanel>
                    <TabPanel value="2"><DrugRegistry /></TabPanel>
                    <TabPanel value="3"><PurchaseOrder /></TabPanel>
                    <TabPanel value="4"><StockIntake /></TabPanel>
                    <TabPanel value="5"><PoApproval /></TabPanel>
                </TabContext>
            </Box>
        )
    }
}

export default withTranslation()(InventoryHome)