import React from 'react';
import './PatientTypeCard.css';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAlt1Outlined';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import GroupIcon from '@mui/icons-material/Group';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import { Avatar } from '@mui/material';


export const PatientsTypeCard = (props) => {
    const PatientAvatar = (icon) => {

      if (icon === "New") {
        return <Avatar id='eMed_PatientType_Avatar' sx={{background: "rgb(247, 16, 12)"}}><PersonAddAltOutlinedIcon id='eMed_PatientType_icon' /></Avatar>
      }
      else if (icon === "Review") {
        return <Avatar id='eMed_PatientType_Avatar' sx={{background: "rgb(42, 222, 204)"}}><EscalatorWarningIcon id='eMed_PatientType_icon' /></Avatar>
      }
      else if (icon === "Established") {
        return <Avatar id='eMed_PatientType_Avatar' sx={{background: "rgb(66, 182, 245)" }}><HowToRegIcon id='eMed_PatientType_icon' /></Avatar>
      }
      else if (icon === "Total") {
        return <Avatar id='eMed_PatientType_Avatar' sx={{background: "rgb(245, 39, 145)" }}><GroupIcon id='eMed_PatientType_icon' /></Avatar>
      }
    }
    return (
      <div className='container'>
        {props.data.map((item) => (
          <div className='Wrapper' style={{backgroundColor:item.color}}>
            <div className='Body_container'>
              <div className='icon_container'> {PatientAvatar(item.icon)}</div>
              <div className='Patient_Tag'>{item.type}</div>
              <div className='Patient_Count'>{item.PatientCount}</div>
            </div>
          </div>
        ))}
      </div>
    )
  }