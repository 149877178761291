import React from 'react';
import './Page.css';
import Background from '../../Assets/Logo/Background.jpg';

class PageNotFound extends React.Component{
    render(){
        return(
            <div style={{ backgroundImage: `url(${Background})`, height: '70vh' }} >
            <h1 className='errorpage'>404 Page Not Found</h1>
            
            
            </div>
        )
    }
}
export default PageNotFound;