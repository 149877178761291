import { Modal, Divider, Select, MenuItem, InputLabel, FormControl, Card, Button, Autocomplete, TextField } from '@mui/material';
import React, { Component } from 'react';
import { Trans, withTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { Colors } from '../../../../../Styles/Colors';
import "./MISReport.css";
import { Serviceurls } from '../../../../../Utility/API/Serviceurls';
import RestAPIService from '../../../../../Utility/Services/RestAPIService';
import { localGetItem } from '../../../../../Utility/Services/CacheProviderService';
import Dialog from '../../../../../Components/Dialog';
import Failed from "../../../../../Assets/Animation/Failed.gif"

class MISModelBox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true,
            dialogBox: false,
            brandName: '',
            genericname: '',
            vendor1: '',
            vendor2: '',
            vendor3: '',
            MISreports: [],
            genricDisable: true
        }
    }

    handleClose = () => {
        this.props.DialogClose()
    }

    componentDidMount() {
        this.getMISReport();
    }

    getMISReport = () => {
        try {
            let VendorDetails = `?pharmacy_id=${localGetItem("Pharmacy_Id")}&vendor1=&vendor2=&vendor3=&page=1&page_size=10&brand_name=${this.state.brandName}&generic_name=${this.state.genericname}`
            RestAPIService.getAll(Serviceurls.PHARMACY_REPORT_MIS_REPORT + VendorDetails)
                .then(response => {
                    this.setState({
                        MISreports: response.data.data,
                        defaultMISreport: response.data.data,
                        genricDisable: response.data.data.length != 0 || "" ? false : true
                    })
                })
                .catch(e => {
                    console.log(e)
                    this.setState({ MISreports: [], genricDisable: true })
                });
        }
        catch (e) {
            this.setState({ dialogBox: true, ErrorMsg: e.message });
            setTimeout(() => {
                this.setState({
                    dialogBox: false
                })
            }, 3000)
        }
    }

    render() {
        const defaultdata = [
            { label: 'No options' }
        ]
        const { t } = this.props
        return (
            <div>
                <Modal
                    open={this.state.open}
                    id="eMed_report_modalpop"
                >
                    <div>
                        <Card className='eMed_modal_card'>
                            <div id="eMed-modal-header">
                                <div id="eMed_model_title"> {t("SelectFilter")}</div>
                                <Button onClick={this.handleClose} variant='contained'>
                                    <CloseIcon />
                                </Button>
                            </div>
                            <Divider />
                            <div className='eMed_model_body'>
                                <div className='eMed_model_dropbox'>
                                    <div style={{ color: Colors.ThemeColor }}>{t("BrandName")}</div>
                                    <Autocomplete
                                        className='eMed_model_formcontrol'
                                        options={this.state.MISreports.length != 0 ? this.state.MISreports : defaultdata.map((option) => option.label)}
                                        getOptionDisabled={(option) =>
                                            option === 'No options'
                                        }
                                        renderInput={(params) => <TextField {...params} onChange={(e) => { this.setState({ brandName: e.target.value }, () => { this.getMISReport() }) }} label={t("SelectBrandName")} />}
                                    />
                                </div>
                                <div className='eMed_model_dropbox'>
                                    <div style={{ color: Colors.ThemeColor }}>{t("GenericName")}</div>
                                    <Autocomplete
                                        className='eMed_model_formcontrol'
                                        disabled={this.state.genricDisable}
                                        options={this.state.MISreports.length != 0 ? this.state.MISreports : defaultdata.map((option) => option.label)}
                                        getOptionDisabled={(option) =>
                                            option === 'No options'
                                        }
                                        renderInput={(params) => <TextField {...params} onChange={(e) => { this.setState({ genericname: e.target.value }, () => { this.getMISReport() }) }} label={t("SelectGenericName")} />}
                                    />
                                </div>
                                <div className='eMed_model_dropbox'>
                                    <div style={{ color: Colors.ThemeColor }}>{t("Vendor 1")}</div>
                                    <Autocomplete
                                        className='eMed_model_formcontrol'
                                        disabled={this.state.genricDisable}
                                        options={this.state.MISreports.length != 0 ? this.state.MISreports : defaultdata.map((option) => option.label)}
                                        getOptionDisabled={(option) =>
                                            option === 'No options'
                                        }
                                        renderInput={(params) => <TextField {...params} onChange={(e) => { this.setState({ genericname: e.target.value }, () => { this.getMISReport() }) }} label={t("Vendor 1")} />}
                                    />
                                </div>
                                <div className='eMed_model_dropbox'>
                                    <div style={{ color: Colors.ThemeColor }}>{t("Vendor 2")}</div>
                                    <Autocomplete
                                        className='eMed_model_formcontrol'
                                        disabled={this.state.genricDisable}
                                        options={this.state.MISreports.length != 0 ? this.state.MISreports : defaultdata.map((option) => option.label)}
                                        getOptionDisabled={(option) =>
                                            option === 'No options'
                                        }
                                        renderInput={(params) => <TextField {...params} onChange={(e) => { this.setState({ genericname: e.target.value }, () => { this.getMISReport() }) }} label={t("Vendor 2")} />}
                                    />
                                </div>
                                <div className='eMed_model_dropbox'>
                                    <div style={{ color: Colors.ThemeColor }}>{t("Vendor 3")}</div>
                                    <Autocomplete
                                        className='eMed_model_formcontrol'
                                        disabled={this.state.genricDisable}
                                        options={this.state.MISreports.length != 0 ? this.state.MISreports : defaultdata.map((option) => option.label)}
                                        getOptionDisabled={(option) =>
                                            option === 'No options'
                                        }
                                        renderInput={(params) => <TextField {...params} onChange={(e) => { this.setState({ genericname: e.target.value }, () => { this.getMISReport() }) }} label={t("Vendor 3")} />}
                                    />
                                </div>
                            </div>
                            <Divider />
                            <div id="eMed-modal-footer">
                                <Button variant='contained'>{t("Search")}</Button>
                            </div>
                        </Card>
                        {this.state.dialogBox ?
                            <Dialog
                                type={"Notification"}
                                DialogClose={this.state.dialogBox}
                                header={"Message"}
                                content={this.state.ErrorMsg}
                                icon={<img className="eMed_dilboxAnimations" alt="Warning" src={Failed} />}
                                secondary={"Close"}
                            /> : null}
                    </div>
                </Modal>
            </div>
        )
    }
}
export default withTranslation()(MISModelBox);