import React from "react";
import './PurchaseDetails.css';
import {
    Card,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    TextField,
    Tooltip,
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Button,
    FormHelperText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Colors } from '../../../../../Styles/Colors';
import SearchIcon from '@mui/icons-material/Search';
import { Trans, withTranslation } from 'react-i18next';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import FilterListIcon from '@mui/icons-material/FilterList';
import PaymentCard from '../../../../../Components/PaymentCard/PaymentCard';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ArticleIcon from '@mui/icons-material/Article';
import DiscountIcon from '@mui/icons-material/Discount';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';
import DatePicker from '../../../../../Components/DatePicker/DatePicker';
import { DateTime } from "luxon";
import Dialog from '../../../../../Components/Dialog';
import Failed from '../../../../../Assets/Animation/Failed.gif';
import { AmountFormat, DosageType } from '../../../../../Components/CommonFunctions/CommonFunctions'
import { localGetItem } from '../../../../../Utility/Services/CacheProviderService';
import { Serviceurls } from '../../../../../Utility/API/Serviceurls';
import RestAPIService from '../../../../../Utility/Services/RestAPIService';

class PurchaseDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: 5,
            searchText: "",
            searchFieldShow: false,
            dateFilter: 0,
            datePickerPop: false,
            returnDateSortOrder: "asc",
            returnDateSortIcon: 'none',
            billNoSortOrder: "asc",
            billNoSortIcon: 'none',
            brandNameSortOrder: "asc",
            brandNameSortIcon: 'none',
            genericNameSortOrder: "asc",
            genericNameSortIcon: 'none',
            quantitySortOrder: "asc",
            quantitySortIcon: 'none',
            freeQuantitySortOrder: "asc",
            freeQuantitySortIcon: 'none',
            cardOpen: false,
            QueryFromDate: "",
            QueryToDate: "",
            pharmacyID: localGetItem("Pharmacy_Id"),
            apiData: []

        }
    }

    componentDidMount() {
        this.getPurchaseDetails();
    }

    /* Table Get API Funtion start */
    getPurchaseDetails = () => {
        try {
            let QueryParams = `?pharmacy_id=${this.state.pharmacyID}&page=&page_size=all&from_date=${this.state.QueryFromDate}&to_date=${this.state.QueryToDate}`
            RestAPIService.getAll(Serviceurls.GET_PURCHASE_DETAILS + QueryParams)
                .then(response => {
                    this.setState({
                        apiData: response.data.data.result,
                        defaultData: response.data.data.result,
                        cardTotalAmt: response.data.data.total_amount,
                        cardCGSTamt: response.data.data.total_cgst_amount,
                        cardSGSTamt: response.data.data.total_sgst_amount,
                        cardDisAmt: response.data.data.total_discount_amount,
                        cardNetAmt: response.data.data.total_net_total
                    })
                })
                .catch(e => {
                    this.setState({ dialogBox: true, ErrorMsg: e.message });
                    setTimeout(() => {
                        this.setState({
                            dialogBox: false
                        })
                    }, 3000)
                });
        }

        catch (e) {
            this.setState({ dialogBox: true, ErrorMsg: e.message });
            setTimeout(() => {
                this.setState({
                    dialogBox: false
                })
            }, 3000)
        }

    }
    /* Table Get API Funtion start */

    /* Failed Dialogbox function end */
    dialogCloseFunction = () => {
        this.setState({
            dialogBox: false
        })
    }
    /* Failed Dialogbox function start */

    /* Pagechange funcion start */
    handleChangePage = (event, newPage) => {
        this.setState({ page: newPage });
    }
    /* Pagechange funcion end */

    /* RowPagechange funcion start */
    handleChangeRowsPerPage = (event) => {
        this.setState({ rowsPerPage: +event.target.value, page: 0 });
    }
    /* RowPagechange funcion end */

    /* customdate popup funcion start */
    PopClose = () => {
        this.setState({
            datePickerPop: false,
            QueryFromDate: "",
            QueryToDate: "",
            dateFilter: 0,
        }, () => this.getPurchaseDetails())
    }
    /* customdate popup funcion end */

    /* customdate select funcion start */
    CustomDates = (FromDate, ToDate) => {
        this.setState({
            QueryFromDate: FromDate,
            QueryToDate: ToDate,
            datePickerPop: false
        }, () => this.getPurchaseDetails())
    }
    /* customdate select funcion end */

    /* date label funcion start */
    DateHelperText = () => {
        if (this.state.dateFilter === 0) {
            return ""
        }
        else if (this.state.dateFilter === 1) {
            return DateTime.now().toFormat('dd-MM-yyyy')
        }
        else if (this.state.dateFilter === 2) {
            return DateTime.now().minus({ days: 1 }).toFormat('dd-MM-yyyy')
        }
        else if (this.state.dateFilter === 3) {
            return `${DateTime.now().minus({ days: 7 }).toFormat('dd-MM-yyyy')}  to  ${DateTime.now().toFormat('dd-MM-yyyy')}`
        }
        else if (this.state.dateFilter === 4) {
            return `${DateTime.now().minus({ days: 30 }).toFormat('dd-MM-yyyy')}  to  ${DateTime.now().toFormat('dd-MM-yyyy')}`
        }
        else if (this.state.dateFilter === 5) {
            return `${this.state.QueryFromDate.split("-").reverse().join("-")}  to  ${this.state.QueryToDate.split("-").reverse().join("-")}`
        }
    }
    /* date label funcion end */

    render() {
        const { t } = this.props
        const filtereddata = this.state.apiData.filter((data) => {
            return (
                (data.bill_date === null ? null : data.bill_date.toLowerCase().includes(this.state.searchText.toLowerCase())) ||
                (data.invoice_number === null ? null : data.invoice_number.toLowerCase().includes(this.state.searchText.toLowerCase())) ||
                (data.vendor_name === null ? null : data.vendor_name.toLowerCase().includes(this.state.searchText.toLowerCase())) ||
                (data.batch_no === null ? null : data.batch_no.toLowerCase().includes(this.state.searchText.toLowerCase())) ||
                (data.generic_name === null ? null : data.generic_name.toLowerCase().includes(this.state.searchText.toLowerCase())) ||
                (data.brand_name === null ? null : data.brand_name.toLowerCase().includes(this.state.searchText.toLowerCase())) ||
                (data.dosage_type === null ? null : data.dosage_type.toLowerCase().includes(this.state.searchText.toLowerCase())) ||
                (data.dosage_strength === null ? null : data.dosage_strength.toLowerCase().includes(this.state.searchText)) ||
                (data.stock_in_quantity === null ? null : Math.round(data.stock_in_quantity * 100) === Math.round(this.state.searchText * 100)) ||
                (data.free_quantity === null ? null : Math.round(data.free_quantity * 100) === Math.round(this.state.searchText * 100)) ||
                (data.price_per_quantity === null ? null : Math.round(data.price_per_quantity) === Math.round(this.state.searchText)) ||
                (data.total_price === null ? null : Math.round(data.total_price) === Math.round(this.state.searchText)) ||
                (data.total_buying_price === null ? null : Math.round(data.total_buying_price * 100) === Math.round(this.state.searchText * 100)) ||
                (data.cgst_percentage === null ? null : Math.round(data.cgst_percentage * 100) === Math.round(this.state.searchText * 100)) ||
                (data.cgst_amount === null ? null : Math.round(data.cgst_amount * 100) === Math.round(this.state.searchText * 100)) ||
                (data.sgst_percentage === null ? null : Math.round(data.sgst_percentage * 100) === Math.round(this.state.searchText * 100)) ||
                (data.sgst_amount === null ? null : Math.round(data.sgst_amount * 100) === Math.round(this.state.searchText * 100)) ||
                (data.cgst_percentage === null ? null : Math.round(data.cgst_percentage * 100 + data.sgst_percentage * 100) === Math.round(this.state.searchText * 100)) ||
                (data.cgst_amount && data.sgst_amount === null ? null : Math.round(data.cgst_amount * 100 + data.sgst_amount * 100) === Math.round(this.state.searchText * 100))
            );
        });
        // Sorting Function   ==== starts
        const sorting = (col, SortOrder) => {
            if (SortOrder === "asc") {
                const Sorted = [...this.state.apiData].sort((a, b) => {
                    if (typeof (a[col]) == "string") {
                        return a[col].toLowerCase() > b[col].toLowerCase() ? 1 : -1
                    } else {
                        return a[col] > b[col] ? 1 : -1
                    }
                }

                )
                this.setState({ apiData: Sorted })

                if (col === "bill_date") {
                    this.setState({
                        returnDateSortOrder: "desc",
                        returnDateSortIcon: 'asc',
                        billNoSortOrder: "asc",
                        billNoSortIcon: 'none',
                        brandNameSortOrder: "asc",
                        brandNameSortIcon: 'none',
                        genericNameSortOrder: "asc",
                        genericNameSortIcon: 'none',
                        quantitySortOrder: "asc",
                        quantitySortIcon: 'none',
                        freeQuantitySortOrder: "asc",
                        freeQuantitySortIcon: 'none',
                    })
                }
                else if (col === "invoice_number") {
                    this.setState({
                        returnDateSortOrder: "asc",
                        returnDateSortIcon: 'none',
                        billNoSortOrder: "desc",
                        billNoSortIcon: 'asc',
                        brandNameSortOrder: "asc",
                        brandNameSortIcon: 'none',
                        genericNameSortOrder: "asc",
                        genericNameSortIcon: 'none',
                        quantitySortOrder: "asc",
                        quantitySortIcon: 'none',
                        freeQuantitySortOrder: "asc",
                        freeQuantitySortIcon: 'none',
                    })
                }
                else if (col === "brand_name") {
                    this.setState({
                        returnDateSortOrder: "asc",
                        returnDateSortIcon: 'none',
                        billNoSortOrder: "asc",
                        billNoSortIcon: 'none',
                        brandNameSortOrder: "desc",
                        brandNameSortIcon: 'asc',
                        genericNameSortOrder: "asc",
                        genericNameSortIcon: 'none',
                        quantitySortOrder: "asc",
                        quantitySortIcon: 'none',
                        freeQuantitySortOrder: "asc",
                        freeQuantitySortIcon: 'none',
                    })
                }
                else if (col === "generic_name") {
                    this.setState({
                        returnDateSortOrder: "asc",
                        returnDateSortIcon: 'none',
                        billNoSortOrder: "asc",
                        billNoSortIcon: 'none',
                        brandNameSortOrder: "asc",
                        brandNameSortIcon: 'none',
                        genericNameSortOrder: "desc",
                        genericNameSortIcon: 'asc',
                        quantitySortOrder: "asc",
                        quantitySortIcon: 'none',
                        freeQuantitySortOrder: "asc",
                        freeQuantitySortIcon: 'none',
                    })
                } else if (col === "stock_in_quantity") {
                    this.setState({
                        returnDateSortOrder: "asc",
                        returnDateSortIcon: 'none',
                        billNoSortOrder: "asc",
                        billNoSortIcon: 'none',
                        brandNameSortOrder: "asc",
                        brandNameSortIcon: 'none',
                        genericNameSortOrder: "asc",
                        genericNameSortIcon: 'none',
                        quantitySortOrder: "desc",
                        quantitySortIcon: 'asc',
                        freeQuantitySortOrder: "asc",
                        freeQuantitySortIcon: 'none',
                    })
                } else if (col === "free_quantity") {
                    this.setState({
                        returnDateSortOrder: "asc",
                        returnDateSortIcon: 'none',
                        billNoSortOrder: "asc",
                        billNoSortIcon: 'none',
                        brandNameSortOrder: "asc",
                        brandNameSortIcon: 'none',
                        genericNameSortOrder: "asc",
                        genericNameSortIcon: 'none',
                        quantitySortOrder: "asc",
                        quantitySortIcon: 'none',
                        freeQuantitySortOrder: "desc",
                        freeQuantitySortIcon: 'asc',
                    })
                }

            }
            else if (SortOrder === "desc") {
                const Sorted = [...this.state.apiData].sort((a, b) => {
                    if (typeof (a[col]) == "string") {
                        return a[col].toLowerCase() < b[col].toLowerCase() ? 1 : -1
                    } else {
                        return a[col] < b[col] ? 1 : -1
                    }
                }

                )
                this.setState({ apiData: Sorted })

                if (col === "bill_date") {
                    this.setState({ returnDateSortOrder: 'default', returnDateSortIcon: "desc", })
                } else if (col === "invoice_number") {
                    this.setState({ billNoSortOrder: 'default', billNoSortIcon: "desc" })
                } else if (col === "brand_name") {
                    this.setState({ brandNameSortOrder: 'default', brandNameSortIcon: "desc" })
                } else if (col === "generic_name") {
                    this.setState({ genericNameSortOrder: 'default', genericNameSortIcon: "desc" })
                } else if (col === "stock_in_quantity") {
                    this.setState({ quantitySortOrder: 'default', quantitySortIcon: "desc" })
                } else if (col === "free_quantity") {
                    this.setState({ freeQuantitySortOrder: 'default', freeQuantitySortIcon: "desc" })
                }
            } else if (SortOrder === "default") {
                this.setState({
                    returnDateSortOrder: 'asc',
                    returnDateSortIcon: 'none',
                    billNoSortOrder: 'asc',
                    billNoSortIcon: 'none',
                    brandNameSortOrder: 'asc',
                    brandNameSortIcon: 'none',
                    genericNameSortOrder: 'asc',
                    genericNameSortIcon: 'none',
                    quantitySortOrder: 'asc',
                    quantitySortIcon: 'none',
                    freeQuantitySortIcon: 'none',
                    freeQuantitySortOrder: 'asc',
                    apiData: this.state.defaultData
                })
            }
        }
        // Sorting Function   ==== ends


        //  Sorting Icons Change func === starts 
        const SortIcon = (sortType) => {
            if (sortType === "asc") {
                return <Tooltip disableInteractive placement='top' title={t("InAscendingOrder")}><KeyboardArrowUpIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
            }
            else if (sortType === 'desc') {
                return <Tooltip disableInteractive placement='top' title={t("InDecendingOrder")}><KeyboardArrowDownIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
            }
            else return <Tooltip disableInteractive placement='top' title={t("Sorting")}><FilterListIcon sx={{ color: Colors.SortIconColor }} id='eMed_sorting_icon' /></Tooltip>
        }

        //  Sorting Icons Change func === ends 

        const handleDate = () => {
            var today = DateTime.now().toFormat('yyyy-MM-dd')
            var yesterday = DateTime.now().minus({ days: 1 }).toFormat('yyyy-MM-dd')
            var startweek = DateTime.now().minus({ days: 7 }).toFormat('yyyy-MM-dd')
            var startmonth = DateTime.now().minus({ days: 30 }).toFormat('yyyy-MM-dd')

            if (this.state.dateFilter === 0) {
                this.setState({
                    QueryFromDate: "",
                    QueryToDate: "",
                }, () => {
                    this.getPurchaseDetails()
                })
            }
            else if (this.state.dateFilter === 1) {
                this.setState({
                    QueryFromDate: today,
                    QueryToDate: today,
                }, () => {
                    this.getPurchaseDetails()
                })

            }
            else if (this.state.dateFilter === 2) {
                this.setState({
                    QueryFromDate: yesterday,
                    QueryToDate: yesterday,
                }
                    , () => {
                        this.getPurchaseDetails()
                    })
            }
            else if (this.state.dateFilter === 3) {
                this.setState({
                    QueryFromDate: startweek,
                    QueryToDate: yesterday,
                },
                    () => {
                        this.getPurchaseDetails()
                    })
            }
            else if (this.state.dateFilter === 4) {
                this.setState({
                    QueryFromDate: startmonth,
                    QueryToDate: yesterday,
                },
                    () => {
                        this.getPurchaseDetails()
                    })
            }
            else if (this.state.dateFilter === 5) {
                this.setState({ datePickerPop: true })
            }

        }

        return (
            <div>
                <div className="eMed_page_header">
                    <div className="eMed_purdetail_tblandcard">
                        <div id="eMed_table_name" style={{ color: Colors.TextColor }}>
                            {t(this.props.menuName)}
                        </div>
                        <div id="eMed_tbl_paymentcard">
                            <div onClick={() => this.setState({ cardOpen: !this.state.cardOpen, searchFieldShow: false })} style={{ display: this.state.cardOpen ? "none" : "inline" }}>
                                <PaymentCard Color={Colors.blue} Icons={<CurrencyRupeeIcon />} Name={t("Total")} Amount={this.state.cardNetAmt} />
                            </div>
                            {this.state.cardOpen ?
                                <div id="eMed_tbl_paymentcard" onClick={() => this.setState({ cardOpen: !this.state.cardOpen, searchFieldShow: false })}>
                                    <PaymentCard Color={Colors.blue} Icons={<CurrencyRupeeIcon />} Name={t("Amount")} Amount={this.state.cardTotalAmt} />
                                    <PaymentCard Color={Colors.orange} Icons={<AssessmentIcon />} Name={t("CGST")} Amount={this.state.cardCGSTamt} />
                                    <PaymentCard Color={Colors.liteViolet} Icons={<ArticleIcon />} Name={t("SGST")} Amount={this.state.cardSGSTamt} />
                                    <PaymentCard Color={Colors.liteGreen} Icons={<DiscountIcon />} Name={t("DisAmount")} Amount={this.state.cardDisAmt} />
                                    <PaymentCard Color={Colors.brown} Icons={<AutoAwesomeMotionIcon />} Name={t("NetAmount")} Amount={this.state.cardNetAmt} />
                                </div>
                                : null}
                        </div>
                    </div>
                    <div id="eMed_tbl_filters">
                        <div>
                            <FormControl id="eMed_tbl_datefilter" >
                                <InputLabel sx={{ color: Colors.TextColor }}>{t("SelectDate")}</InputLabel>
                                <Select
                                    sx={{ color: Colors.TextColor }}
                                    size='small'
                                    onChange={(event) => { this.setState({ dateFilter: event.target.value }, () => handleDate()) }}
                                    value={this.state.dateFilter}
                                    labelId="demo-simple-select-required-label"
                                    label={t("SelectDate")}
                                    id="emed_demo-simple-select-required"
                                >
                                    <MenuItem value={0}>All</MenuItem>
                                    <MenuItem value={1}>Today</MenuItem>
                                    <MenuItem value={2}>Yesterday</MenuItem>
                                    <MenuItem value={3}>Last Week</MenuItem>
                                    <MenuItem value={4}>Last Month</MenuItem>
                                    <MenuItem value={5} onClick={() => { this.setState({ datePickerPop: true }) }} >Custom</MenuItem>
                                </Select>
                                <FormHelperText sx={{ color: Colors.HelperText }} id="eMed_form_helpertext">{this.DateHelperText()}</FormHelperText>
                            </FormControl>
                        </div>
                        {this.state.datePickerPop ? <DatePicker PopClose={this.PopClose} CustomDates={this.CustomDates} /> : null}

                        {this.state.searchFieldShow ?
                            <div id='eMed_tbl_searchbox'>
                                <TextField
                                    sx={{ input: { color: Colors.TextColor } }}
                                    size='small'
                                    focused
                                    onChange={(e) => this.setState({ searchText: e.target.value })}
                                    value={this.state.searchText}
                                    label={t("SearchHere")}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <Tooltip disableInteractive title={t("Clear")} placement='top'><CloseIcon sx={{ color: Colors.ThemeColor, cursor: 'pointer' }} onClick={() => { this.setState({ searchText: '' }) }} /></Tooltip>
                                        ),
                                    }}
                                />
                            </div> : null}


                        <div className="eMed_tbl_searchicon">
                            <Tooltip disableInteractive placement='top' title={this.state.searchFieldShow ? t("CloseSearch") : t("OpenSearch")}>
                                <Button id="eMed_tbl_searchicon" variant='contained' style={{ backgroundColor: Colors.ThemeColor }} onClick={() => this.setState({ searchFieldShow: !this.state.searchFieldShow, searchText: '', cardOpen: false })} >
                                    {this.state.searchFieldShow ? <CloseIcon /> : <SearchIcon />}
                                </Button>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div>
                    <div>
                        <Card sx={{ bgcolor: Colors.TableCard }} className="eMed_tbl_card" >
                            <TableContainer className="eMed_tbl_container" >
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className='eMed_tbl_headwidth_small eMed_sticky_start' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                                <div style={{ color: Colors.TableHeaderTextColor }} className="eMed_sortable_center" id='eMed_tbl_headtext'>{t("S.No")}</div>
                                            </TableCell>
                                            <TableCell className='eMed_tbl_headwidth_large' id="eMed_stickyHeadtwo" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                                <div className="eMed_sortable_name">
                                                    <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("BrandName")}</div>
                                                    <div id="eMed-SortIcons" onClick={() => { sorting("brand_name", this.state.brandNameSortOrder) }}>{SortIcon(this.state.brandNameSortIcon)}</div></div>
                                            </TableCell>
                                            <TableCell className='eMed_tbl_headwidth_small' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                                <div className="eMed_sortable_center">
                                                    <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_tbl_headtext'>{t("BillNo")}</div>
                                                    <div id="eMed-SortIcons" onClick={() => { sorting("invoice_number", this.state.billNoSortOrder) }}>{SortIcon(this.state.billNoSortIcon)}</div></div>
                                            </TableCell>
                                            <TableCell align="left" className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                                <div style={{ color: Colors.TableHeaderTextColor }} className="eMed_sortable_name" id="eMed_tbl_headtext">
                                                    {t("VendorName")}
                                                </div>
                                            </TableCell>
                                            <TableCell align="right" className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                                <div style={{ color: Colors.TableHeaderTextColor }} className="eMed_sortable_center" id="eMed_tbl_headtext">
                                                    {t("BatchNo")}
                                                </div>
                                            </TableCell>
                                            <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                                <div className="eMed_sortable_center">
                                                    <div style={{ color: Colors.TableHeaderTextColor }} id="eMed_tbl_headtext">{t("Date")}</div>
                                                    <div id="eMed-SortIcons" onClick={() => { sorting("bill_date", this.state.returnDateSortOrder) }}>{SortIcon(this.state.returnDateSortIcon)}</div></div>
                                            </TableCell>
                                            <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                                <div className="eMed_sortable_name">
                                                    <div style={{ color: Colors.TableHeaderTextColor }} id="eMed_tbl_headtext">{t("GenericName")}</div>
                                                    <div id="eMed-SortIcons" onClick={() => { sorting("generic_name", this.state.genericNameSortOrder) }}>{SortIcon(this.state.genericNameSortIcon)}</div></div>
                                            </TableCell>
                                            <TableCell align="left" className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                                <div style={{ color: Colors.TableHeaderTextColor }} className="eMed_sortable_center" id="eMed_tbl_headtext">
                                                    {t("DosageType")}
                                                </div>
                                            </TableCell>
                                            <TableCell align="center" className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                                <div style={{ color: Colors.TableHeaderTextColor }} id="eMed_tbl_headtext">
                                                    {t("DosageStrength")}
                                                </div>
                                            </TableCell>
                                            <TableCell className='eMed_tbl_headwidth_small' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                                <div className="eMed_sortable_number">
                                                    <div style={{ color: Colors.TableHeaderTextColor }} id="eMed_tbl_headtext">{t("Quantity")}</div>
                                                    <div id="eMed-SortIcons" onClick={() => { sorting("stock_in_quantity", this.state.quantitySortOrder) }}>{SortIcon(this.state.quantitySortIcon)}</div></div>
                                            </TableCell>
                                            <TableCell className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                                <div className="eMed_sortable_number" >
                                                    <div style={{ color: Colors.TableHeaderTextColor }} id="eMed_tbl_headtext">{t("FreeQty")}</div>
                                                    <div id="eMed-SortIcons" onClick={() => { sorting("free_quantity", this.state.freeQuantitySortOrder) }}>{SortIcon(this.state.freeQuantitySortIcon)}</div></div>
                                            </TableCell>
                                            <TableCell align="right" className='eMed_tbl_headwidth_small' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                                <div style={{ color: Colors.TableHeaderTextColor }} className="eMed_sortable_number" id="eMed_tbl_headtext">
                                                    {t("CGST(%)")}
                                                </div>
                                            </TableCell>
                                            <TableCell align="right" className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                                <div style={{ color: Colors.TableHeaderTextColor }} className="eMed_sortable_number" id="eMed_tbl_headtext">
                                                    {t("CGSTAmount(₹)")}
                                                </div>
                                            </TableCell>
                                            <TableCell align="right" className='eMed_tbl_headwidth_small' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                                <div style={{ color: Colors.TableHeaderTextColor }} className="eMed_sortable_number" id="eMed_tbl_headtext">
                                                    {t("SGST(%)")}
                                                </div>
                                            </TableCell>
                                            <TableCell align="right" className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                                <div style={{ color: Colors.TableHeaderTextColor }} className="eMed_sortable_number" id="eMed_tbl_headtext">
                                                    {t("SGSTAmount(₹)")}
                                                </div>
                                            </TableCell>
                                            <TableCell align="right" className='eMed_tbl_headwidth_small' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                                <div style={{ color: Colors.TableHeaderTextColor }} className="eMed_sortable_number" id="eMed_tbl_headtext">
                                                    {t("GST(%)")}
                                                </div>
                                            </TableCell>
                                            <TableCell align="right" className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                                <div style={{ color: Colors.TableHeaderTextColor }} className="eMed_sortable_number" id="eMed_tbl_headtext">
                                                    {t("GSTAmount(₹)")}
                                                </div>
                                            </TableCell>
                                            <TableCell align="right" className='eMed_tbl_headwidth_large' id="eMed_nonsticky_head" sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>
                                                <div style={{ color: Colors.TableHeaderTextColor }} className="eMed_sortable_number" id="eMed_tbl_headtext">
                                                    {t("TaxableAmount")}
                                                </div>
                                            </TableCell>
                                            <TableCell align="right" className='eMed_sticky_end eMed_tbl_headwidth_small' sx={{ bgcolor: Colors.TableHeaderBarBGColor }}>

                                                <div style={{ color: Colors.TableHeaderTextColor }} className="eMed_sortable_number" id="eMed_tbl_headtext">{t("PriceUnit")}</div>


                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {filtereddata.length > 0 ? filtereddata
                                        .slice(this.state.page * this.state.rowsPerPage,
                                            this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                                        .map((data, index) => {
                                            return (
                                                <TableBody key={index}>
                                                    <TableRow>
                                                        <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} align="center" id="eMed_tbl_bodytext" className="eMed_sticky_startbody" >{index + 1}</TableCell>
                                                        <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} align="left" id="eMed_stickycelltwo">
                                                            <Tooltip title={data.brand_name}>
                                                                <div id="eMed_tbl_bodytext">
                                                                    {data.brand_name.length > 10 ? data.brand_name.slice(0, 10) + ".." : data.brand_name}
                                                                </div>
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="center">{data.invoice_number}</TableCell>
                                                        <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} align="left">
                                                            <Tooltip title={data.vendor_name}>
                                                                <div id="eMed_tbl_bodytext">
                                                                    {data.vendor_name.length > 10 ? data.vendor_name.slice(0, 10) + ".." : data.vendor_name}
                                                                </div>
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="center">{data.batch_no}</TableCell>
                                                        <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="center">{data.bill_date}</TableCell>
                                                        <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="left">
                                                            <Tooltip title={data.generic_name}>
                                                                <div id="eMed_tbl_bodytext">
                                                                    {data.generic_name.length > 10 ? data.generic_name.slice(0, 10) + ".." : data.generic_name}
                                                                </div>
                                                            </Tooltip>
                                                        </TableCell>
                                                        <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="center">{DosageType(data.dosage_type)}</TableCell>
                                                        <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="center">{data.dosage_strength}</TableCell>
                                                        <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="right">{data.stock_in_quantity}</TableCell>
                                                        <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="right">{data.free_quantity}</TableCell>
                                                        <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="right">{data.cgst_percentage}</TableCell>
                                                        <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="right">{data.cgst_amount}</TableCell>
                                                        <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="right">{data.sgst_percentage}</TableCell>
                                                        <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="right">{data.sgst_amount}</TableCell>
                                                        <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="right">{data.cgst_percentage + data.sgst_percentage}</TableCell>
                                                        <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="right">{data.cgst_amount + data.sgst_amount}</TableCell>
                                                        <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="right">{AmountFormat(data.total_price)}</TableCell>
                                                        <TableCell sx={{ color: Colors.TableProfilDetails, bgcolor: Colors.TableBody }} id="eMed_tbl_bodytext" align="right" className="eMed_sticky_endbody">{AmountFormat(data.price_per_quantity)}</TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            )
                                        }) : <div style={{ color: Colors.TableHeaderTextColor }} id='eMed_Norecords'>
                                        {t("NoRecords")}
                                    </div>}
                                </Table>
                            </TableContainer>
                            <TablePagination
                                component='div'
                                id="eMed_tbl_pagination"
                                sx={{ color: Colors.PagiantionTxtColor }}
                                rowsPerPageOptions={[5, 10, 15]}
                                count={filtereddata.length}
                                rowsPerPage={this.state.rowsPerPage}
                                page={this.state.page}
                                onPageChange={this.handleChangePage}
                                onRowsPerPageChange={this.handleChangeRowsPerPage}
                            />
                        </Card>
                        {this.state.dialogBox ?
                            <Dialog
                                type={"Notification"}
                                DialogClose={this.dialogCloseFunction}
                                header={"Message"}
                                content={this.state.ErrorMsg}
                                icon={<img className="eMed_dilboxAnimations" alt="Warning" src={Failed} />}
                                secondary={"Close"}
                                closeButton={this.dialogCloseFunction}
                            /> : null}
                    </div>
                </div>
            </div>
        )
    }
}
export default withTranslation()(PurchaseDetails);