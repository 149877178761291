import React from "react";
import eMedHub from "../Assets/Logo/eMedHub.jpeg";
import { TextField } from "@mui/material";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Button from "@material-ui/core/Button";
import Logo from "../Assets/Logo/logo.png";
import { Trans, withTranslation } from 'react-i18next';


class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      error: '',
      helperText: '',
    };
  }
  validate = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // if (this.state.username == "") {
    //   console.log("please enter valid username");    
    // }
    this.setState({
      [event.target.name]: value,
    });

  };

  Submit = () => {
    // console.log(this.state.username);
    if (this.state.username == "") {
      this.setState({
        error: 'error',
        helperText: 'Enter  Valid username',

      })
    }
    else {
      this.setState({
        error: "",
        helperText: ""
      })
    }
  };
  render() {
    const { t } = this.props
    return (
      <div class="total">
        <div class="left">
          <img src={eMedHub} width="100%" height="100%" />
        </div>
        <img src={Logo} class="logo_img" />
        <div class="right">
          <form>
            <div>
              <h1> {t("ForgotPassword")}</h1>
            </div>
            <div class="login_input">
              <div>
                <TextField
                  error={this.state.error}
                  helperText={this.state.helperText}
                  name="username"
                  value={this.state.username}
                  onChange={this.validate}
                  style={{ maxWidth: "90%" }}
                  placeholder={t("userName/phonenumber")}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AccountCircle />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

            </div>

            <div class="btn">
              {/* <Button class="Button"   ><Link to="/" class="link">Back</Link></Button> */}
              {/* </Link> */}
              <Button class="Button" onClick={this.Submit}>{t("Sendotp")} </Button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default withTranslation()(ForgotPassword);
